import React, {FunctionComponent, useCallback} from 'react'
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import FormDashboardConfCmp from "./FormDashboardConfCmp"
import {FormDashboardConfProps, FormKeys} from "components/forms/Form.types"

const FormDashboardConf: FunctionComponent<FormDashboardConfProps> = ({environmentId, onConfirm, onCancel, data, metaModel}) => {
  const loadDictionaryEntries = useCallback(
    (dictionaryCode: string) => loadDictionaryEntriesService(environmentId, dictionaryCode),
    [environmentId],
  )

  return <FormDashboardConfCmp {...{
    formType: {
      type: FormKeys.DASHBOARD_CONF,
    },
    data,
    metaModel,
    loadDictionaryEntries,
    environmentId,
    onCancel,
    onConfirm,
  }}/>
}


export default FormDashboardConf

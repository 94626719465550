import React, {memo, useCallback, useEffect, useMemo} from 'react'
import TableChartHeader from 'components/charts/table/TableChart.Header'
import TableFooter from 'components/charts/table/TableFooter'
import TableChartDom from 'components/charts/table/TableChart.Dom'
import TablesWorkflow from 'classes/workflows/chart-workflows/TablesWorkflow'
import {remap} from "commons/remap"
import {Alert, Empty} from 'antd'
import ChartSummary from "components/charts/Chart.Summary"
import {captureError} from "services/SentryService"
import Language from "language"
import styled from "styled-components"
import {ChartSelection, Pagination, TableQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import {OrderBy, ShapeDimension, TableColumn} from "types/charts"
import {isEqual} from "lodash"
import usePrevious from "hooks/usePrevious"

export interface TableChartProps {
  withSummary: boolean
  dimensions: ShapeDimension
  chartData: TableQueryWorkflowResult,
  selection: ChartSelection
  onSelectionChange: (newSelection: ChartSelection) => void
}

interface State {
  columns: TableColumn[]
  maxRow?: number

  chartData?: TableQueryWorkflowResult
  chartDimensions: ShapeDimension
  withTotalRow: boolean
}

// eslint-disable-next-line react/display-name
const TableChart = memo<TableChartProps>(({
                                            chartData: originalData,
                                            dimensions,
                                            selection,
                                            withSummary,
                                            onSelectionChange,
                                          }) => {
  const [state, setState] = React.useState<State>({
    chartData: undefined,
    chartDimensions: {
      width: 0,
      height: 0,
    },
    withTotalRow: false,
    columns: [],
    maxRow: undefined,
  })
  const prevMaxRow = usePrevious(state.maxRow)
  const prevColumn = usePrevious(state.columns)
  const prevDimensions = usePrevious(dimensions)

  const workflow = useMemo(() => new TablesWorkflow('', originalData, dimensions, (newState) => {
    setState(old => ({
      ...old,
      ...newState,
    }))
    // Do not update when originalData is modified, because data will be reinitialized causing rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  useEffect(() => {
    return () => {
      if (workflow) {
        workflow.unsubscribe()
      }
    }
  }, [workflow])

  /**
   * fetch from server
   * @param {{pagination: ?object, sorters: ?object, search: ?string}} selection initial params
   * @returns {null} : setState
   */
  const fetch = useCallback(({pagination, ...newSelection}: ChartSelection) => {
    onSelectionChange(remap(selection || {}, currentSelection => ({
        ...newSelection,
        pagination: remap(currentSelection?.pagination, () => pagination),
      })),
    )
  }, [onSelectionChange, selection])


  useEffect(() => {
    const {maxRow} = state
    const hasColumnChanged = state.columns && prevColumn
      && state.columns.length > 0 && prevColumn.length > 0
      && (state.columns.length !== prevColumn.length || (state.columns.length === prevColumn.length && !state.columns.every((column, columnIndex) => prevColumn[columnIndex] === column)))
    if (maxRow && ((maxRow !== prevMaxRow) || hasColumnChanged)) {
      const current = selection.pagination?.current ?? 1
      const start = ((current - 1) * (prevMaxRow ?? 0)) + 1
      const newCurrent = isNaN(start) ? 1 : Math.ceil(start / maxRow)
      fetch({
        pagination: {
          pageSize: maxRow,
          current: newCurrent,
        },
      })
    }
  }, [fetch, prevColumn, prevMaxRow, selection.pagination, state])


  /**
   * search change callback
   * @param {string} search: new search value
   * @returns {null} : call fetch
   */
  const searchChange = useCallback((search: string) => {
    if (selection.pagination) {
      fetch({
        pagination: {
          ...selection.pagination,
          current: 1,
        },
        search,
      })
    }
  }, [fetch, selection.pagination])

  /**
   * pagination page change callback
   * @param {number} current: new pagination page
   * @returns {null} : call fetch
   */
  const paginationChange = useCallback((current: number) => {
    if (selection.pagination) {
      fetch({
        pagination: {
          ...selection.pagination,
          current,
        },
      })
    }
  }, [fetch, selection.pagination])


  useEffect(() => {
    if (!isEqual(originalData, state.chartData) || !isEqual(prevDimensions, dimensions)) {
      if (selection.pagination
        && selection.pagination.current !== 1
        && ((selection.pagination.pageSize * selection.pagination.current) - selection.pagination.pageSize) >= originalData?.lineCount) {
        paginationChange(1)
      } else {
        workflow.update(originalData, dimensions)
      }
    }
  }, [originalData, dimensions, state.chartData, prevDimensions, selection.pagination, paginationChange, workflow])

  /**
   * sorters change callback
   * @param {object} sorters: new {field,order}[] sort
   * @returns {null} : call fetch
   */
  const sorterChange = useCallback((sorters: OrderBy[] | undefined) => {
    if (selection.pagination) {
      fetch({
        pagination: {
          ...selection.pagination,
          current: 1,
        },
        sorters,
      })
    }
  }, [fetch, selection.pagination])

  return useMemo(() => {
    const {
      columns,
      chartData,
      chartDimensions,
      withTotalRow,
    } = state
    const meta = chartData?.meta
    const data = chartData?.data
    const {pagination} = selection

    if (!data) {
      return <></>
    }

    if (selection && !pagination) {
      captureError("Could not render table, because no pagination informations are given ", {})
      return <Alert
        type={"error"}
        message={<span title={"no-table-pagination"}>{Language.get("chart-message-query-error")}</span>}
        banner
      />
    } else {
      return <FlexDiv style={dimensions}>
        {withSummary && meta?.effectiveConf && <ChartSummary effectiveConf={meta?.effectiveConf} showMetricSlicer={false}/>}
        {(data.length > 0 || selection.search) && <TableChartHeader search={selection.search} searchChange={searchChange}/>}
        {data.length > 0
          ? <FlexDiv>
            <TableChartDom {...{
              columns,
              chartData,
              withTotalRow,
              chartDimensions,
              onSort: sorterChange,
              // loading: currentSelectionLoading,
              sorters: selection.sorters ?? [],
              lineCount: chartData.lineCount,
              pagination: pagination as Pagination,
              printPercentage: selection.asPercentage ?? false,
            }}
            />
            {selection.pagination && <TableFooter {...{
              lineCount: chartData.lineCount,
              current: selection.pagination.current,
              pageSize: selection.pagination.pageSize,
              onChange: paginationChange,
            }}/>}
          </FlexDiv>
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
      </FlexDiv>
    }
  }, [dimensions, paginationChange, searchChange, selection, sorterChange, state, withSummary])
})

export default TableChart


const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`
import React, {ChangeEvent, memo} from 'react'
import Language from 'language'
import {Col, Input, InputProps, Row} from 'antd'

interface Props {
  value?: string
  placeholder?: string
  onChange: (newValue: ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  span?: number
}

// eslint-disable-next-line react/display-name
const BironInput = memo<Props & InputProps>(function BironInput({
                                                                        value,
                                                                        placeholder,
                                                                        maxLength = 255,
                                                                        onChange,
                                                                        span = 14,
                                                                        ...props
                                                                }) {
  return <Row>
    <Col span={span}>
      <Input {...{
        className: "biron-react-input",
        value,
        maxLength,
        placeholder: placeholder ? Language.get(placeholder) : undefined,
        onChange,
        ...props,
      }}/>
    </Col>
    <Col span={101}></Col>
  </Row>
  },
)

export default BironInput

import {RootState} from "redux/store"
import {createSelector} from "reselect"
import {ChartDtoDetailTypes} from "types/charts"
import {RematchRootState} from "@rematch/core"
import RootModel from "redux/models"
import {NormalizedDashboard} from "schemas/workspace"
import {isEmpty} from "commons/object.utils"


export const getMenuId = (state: RootState) => state.currentDashboard.menuId
export const getMenu = (state: RootState) => state.workspace.entities.menus[getMenuId(state) as string]
export const getRedirect = (state: RootState) => state.currentDashboard.redirect
export const getDashboardId = (state: RootState) => state.currentDashboard?.dashboardId
export const getDashboard = (state: RootState): NormalizedDashboard | undefined => {
  const dashboardId = getDashboardId(state)
  return (dashboardId !== undefined && dashboardId !== null) ? state.workspace.entities.dashboards[dashboardId] : undefined
}
export const getCharts = createSelector(
  (state: RematchRootState<RootModel, Record<string, never>>) => getDashboard(state)?.charts ?? [],
  (state: RematchRootState<RootModel, Record<string, never>>): { [k: string]: ChartDtoDetailTypes } => state.workspace.entities.charts,
  (dashBoardChartIds: number[], charts: {
    [k: string]: ChartDtoDetailTypes
  }) => dashBoardChartIds.map(id => charts[id]).filter(chart => !isEmpty(chart)),
)
export const getSelection = (state: RootState) => state.currentDashboard.selection

import {Checkbox, Col} from "antd"
import Language from "language"
import React, {memo, useCallback} from "react"
import styled from "styled-components"

interface GrowthInvertSelectorProps {
  value?: boolean
  onChange?: (newState: boolean) => void
}

const GrowthInvertSelector = memo<GrowthInvertSelectorProps>(function GrowthInvertSelector({
                                                                value,
                                                                onChange,
                                                                                           }) {

  const handleInvertColor = useCallback((e: any) => {
      onChange?.(e.target?.checked)
    },
    [onChange],
  )
  return <ColWithMarginLeft span={24}>
    <Checkbox
      checked={value}
      onClick={handleInvertColor}>
      <StyledGrowthInvertLabel>{Language.get('configuration-invert-color')}</StyledGrowthInvertLabel>
    </Checkbox>
  </ColWithMarginLeft>
})

export default GrowthInvertSelector

const ColWithMarginLeft = styled(Col)`
`

const StyledGrowthInvertLabel = styled.span`
  font-size: 12px;
  font-weight: 200;
  line-height: 15px;
  display: flex;
  align-items: center;
`
import React, {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import AppContainer from "AppContainer"
import {getConsts} from "redux/appEnvironment.selector"
import Loader from "components/loader/Loader"
import {BrowserRouter as Router, useNavigate} from 'react-router-dom'
import useDispatch from "hooks/useDispatch"
import {AuthProvider, AuthProviderProps} from "react-oidc-context"
import {oidcConfigTemplate, onSigninCallback} from "@biron-data/react-oidc"

const AppSecuredContent = () => {
  const dispatch = useDispatch()
  const consts = useSelector(getConsts)
  const loadedConsts = Boolean(consts.OIDC_ISSUER)

  const navigate = useNavigate()

  const oidcConfig: AuthProviderProps = {
    ...oidcConfigTemplate,
    authority: consts?.OIDC_ISSUER ?? "",
    // eslint-disable-next-line camelcase
    client_id: consts?.OIDC_CLIENTID ?? "",
    onSigninCallback: useCallback(() => onSigninCallback(navigate), [navigate]),
  }

  useEffect(() => {
    dispatch.appEnvironment.loadStatic()
  }, [dispatch])

  return loadedConsts
    ? <AuthProvider
      {...oidcConfig}
    >
      <AppContainer/>
    </AuthProvider>
    : <Loader/>
}

const AppSecured = () => {
  return <Router>
    <AppSecuredContent/>
  </Router>
}

export default AppSecured

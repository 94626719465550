import React, {memo} from 'react'
import Language from 'language'
import Confirm from 'components/confirm/Confirm'
import MdView from 'components/mdView/MdView'
import {Button, Col, Row} from 'antd'
import styled from "styled-components"

export interface FormFooterAltSubmit {
  trigger: JSX.Element
  title: string
  confirm: {
    title: string
    on: {
      open: () => void,
      ok: () => void,
      cancel: () => void,
    },
  },
}

export interface FormFooterProps {
  altSubmit?: FormFooterAltSubmit
  altDefault?: React.ReactNode
  submitting: boolean,
  cancel: () => void,
  submit: () => void,
  hasHelp?: boolean
  $hasmargin?: boolean
}

// eslint-disable-next-line react/display-name
const FormFooter = memo<FormFooterProps>(({
                                            altSubmit,
                                            altDefault,
                                            submitting,
                                            cancel,
                                            submit,
                                            hasHelp = true,
                                            $hasmargin = false,
                                          }) => (
  <FlexDiv>
    <Row className={'button-list'}>
      <Col span={6} offset={1}>
        {altSubmit ? <Confirm {...altSubmit.confirm} trigger={altSubmit.trigger}/> : null}
        {altDefault ? altDefault : <></>}
      </Col>
      <ColWithContentEnd span={13} offset={3} $hasmargin={$hasmargin ? 1 : 0}>
        <Button key={0} onClick={cancel}>{Language.get('button-cancel')}</Button>
        <Button key={1} type={'primary'} loading={submitting} onClick={submit}>{Language.get('button-apply')}</Button>
      </ColWithContentEnd>
      <Col span={1}/>
    </Row>
    {hasHelp ? <HelpFooter><MdView description={Language.get('configuration-footer-help')}/></HelpFooter> : <></>}
  </FlexDiv>
))

export default FormFooter

const ColWithContentEnd = styled(Col)<{ $hasmargin: number }>`
  display: flex;
  justify-content: flex-end;
    gap: 8px;

    ${({$hasmargin}) => $hasmargin ? `
  button {
    margin-left: 10px;
  }
  ` : ""}
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const HelpFooter = styled.div`
  a, .configuration-footer-help a:hover {
    color: var(--light-grey);
  }
`

import React, {memo} from 'react'
import ValueDescription from "components/forms/description/ValueDescription"
import {TooltipContent} from "components/common/TooltipContent"
import View from 'themes/svg/filled/view.svg?react'

interface ValueDescriptionParams {
  title: string
  description: string | undefined
  code: string
}

// eslint-disable-next-line react/prop-types
const ViewDescription = memo<ValueDescriptionParams>(function ViewDescription({
                                                                                title,
                                                                                description,
                                                                                code,
                                                                              }) {
  return <ValueDescription>
    <TooltipContent description={description} Icon={View} title={title} code={code} nature={"view"}/>
  </ValueDescription>
})

export default ViewDescription

import React, {FunctionComponent, useCallback, useMemo} from 'react'
import {formatToDisplayablePeriod, getResolvedFromPeriod} from "commons/period/periodsList"
import {Row} from "antd"
import Language from "language"
import styled from "styled-components"
import dayjs from "dayjs"
import PeriodEdgeInput from "components/forms/period/selectors/PeriodEdgeInput"
import {PeriodDynamic, PeriodEdge, PeriodEdgePoint, PeriodType} from "types/period.types"

interface Props {
  value: PeriodDynamic
  onChange: (newValue: PeriodDynamic) => void
}

const DynamicPeriod: FunctionComponent<Props> = ({
                                                   onChange,
                                                   value,
                                                 }) => {
  const onStartChange = useCallback((edge: PeriodEdge) => {
    onChange({
      ...value,
      start: edge,
    })
  }, [onChange, value])

  const onEndChange = useCallback((edge: PeriodEdge) => {
    onChange({
      ...value,
      end: edge,
    })
  }, [onChange, value])

  const resolvedPeriod = useMemo(() => {
    switch (value.type) {
      case PeriodType.dynamic:
        return getResolvedFromPeriod(formatToDisplayablePeriod(value))
      default: {
        const exhaustiveCheck: never = value as never
        return exhaustiveCheck
      }
    }
  }, [value])

  const isStartAfterEnd = useMemo(() => Boolean(((resolvedPeriod?.start.diff(resolvedPeriod?.end)) ?? 0) > 0), [resolvedPeriod])

  return <Row>
    {
      value && <>
        <PeriodEdgeInput label={Language.get("period.start")} value={value.start} onChange={onStartChange}
                         options={Object.values(PeriodEdgePoint).map(v => ({
                           label: Language.get(`period.period-edge-start-point.${v}`),
                           value: v,
                         }))}/>
        <PeriodEdgeInput label={Language.get("period.end")} value={value.end} onChange={onEndChange}
                         options={Object.values(PeriodEdgePoint).map(v => ({
                           label: Language.get(`period.period-edge-end-point.${v}`),
                           value: v,
                         }))}/>
        <Preview>
          <span>{Language.get("period.form.dynamic.preview.title", dayjs())}</span>
          <div><DateText>{resolvedPeriod?.start.format("DD MMM YYYY")}</DateText> <span>{'->'}</span>
            <DateText>{resolvedPeriod?.end.format("DD MMM YYYY")}</DateText></div>
          {
            isStartAfterEnd && <Warning>{Language.get("period.form.start-after-end-warning", dayjs())}</Warning>
          }
        </Preview>
      </>
    }
  </Row>
}

export default DynamicPeriod

const Warning = styled.div`
  color: var(--negative);
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 65px;
  margin: 19px;
  `

const DateText = styled.span`
  color: var(--primary);
  `
import React from 'react'

const withSortableElement = (WrappedComponent: any) => {
  const SortableElement = ({...props}) => {
    return <WrappedComponent {...{
      sortable: true,
      ...props,
    }}/>
  }
  return SortableElement
}

export default withSortableElement

import {
  ConfFilterRangeTypes,
  ConfFilterScalarTypes,
  FilterOperator,
  FilterType,
  SelectorFilterTypes,
} from "components/forms/selector/comps/box/filters"

export const consolidateUniversalConfFilterToMetric = (filter: SelectorFilterTypes) => {
  switch (filter.predicate.operator) {
    case FilterOperator.EQ:
    case FilterOperator.GTE:
    case FilterOperator.GT:
    case FilterOperator.LT:
    case FilterOperator.LTE: {
      const scalarFilter: ConfFilterScalarTypes = {
        predicate: filter.predicate,
        metricCode: filter.reference.code,
        type: FilterType.metric,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return scalarFilter
    }
    case FilterOperator.BETWEEN: {
      const rangeFilter: ConfFilterRangeTypes = {
        predicate: filter.predicate,
        metricCode: filter.reference.code,
        type: FilterType.metric,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return rangeFilter
    }
    default:
      return undefined
  }
}

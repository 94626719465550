import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import {FilterInputType} from "types/filter"
import Language from "language"
import ValueDescription from "components/forms/description/ValueDescription"
import {TooltipPlacement} from "antd/lib/tooltip"
import styled from "styled-components"

interface FilterTypeDescriptionProps {
  type: FilterInputType
}

const FilterTypeDescription: FunctionComponent<FilterTypeDescriptionProps> = ({type}) => {
  const [placement, setPlacement] = useState<TooltipPlacement | undefined>("right")
  const ref = useRef<any>()
  useEffect(() => {
    if (ref.current.getBoundingClientRect().x > window.innerWidth - 600 && placement !== "bottom") {
      setPlacement("bottom")
    }
  }, [placement])

  return <DescriptionContainer ref={ref}>
    <ValueDescription
      title={Language.get(`filter.type.${type}.title`)}
      description={Language.get(`filter.type.${type}.description`)}
      placement={placement}
    />
  </DescriptionContainer>
}

export default FilterTypeDescription

const DescriptionContainer = styled.div`
  display: flex;
`
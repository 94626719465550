/* eslint-disable max-lines */
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {Col, Row} from 'antd'
import styled from "styled-components"
import AnimateHeight, {Height} from "react-animate-height"
import {TrashIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {isFilterEmpty, useCurrentIcon, useFilterValue} from "components/forms/selector/comps/box/utils"
import FilterTypeDropDown from "components/forms/selector/comps/box/FilterTypeDropDown"
import FilterInputs from "components/forms/selector/comps/box/FilterInputs"
import {isEqual} from "lodash"
import {ConfFilter} from './filters'

interface Props {
  environmentId: number
  withBackground?: boolean
  filter: ConfFilter
  close: () => void
  handleFilterChange: (filter: ConfFilter) => void
  isResumable?: boolean
  bordered?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement,
  onEdit?: (state: number) => void
}

/*
 * props description
 * @param {object} box : the box, defined by a {labels} object
 * @param {callback} close : close box callback
 * @param {callback} move : move box callback, setting it undefined wont render the arrows
 * @param {boolean} moveTop : defined if the box can be moved to the top (depending on its position with others boxes)
 * @param {boolean} moveDown : defined if the box can be moved to the bottom (depending on its position with others boxes)
 */
const Box = memo<Props>(function Box({
                                       filter,
                                       environmentId,
                                       close,
                                       handleFilterChange,
                                       isResumable = true,
                                       withBackground,
                                       bordered = false,
                                       getPopupContainer,
                                       onEdit,
                                     }) {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [temporaryFilter, setTemporaryFilter] = useState(filter)
  const [isBoxFocused, setIsBoxFocused] = useState(false)
  const CurrentIcon = useCurrentIcon(temporaryFilter)

  useEffect(() => {
    setTemporaryFilter(filter)
  }, [filter])

  const [optionFocused, setOptionFocused] = useState(false)

  const isEditable = useMemo(() => (!isResumable || isBoxFocused || isFilterEmpty(temporaryFilter) || optionFocused) ? 1 : 0, [isBoxFocused, isResumable, optionFocused, temporaryFilter])

  useEffect(() => {
    onEdit?.(isEditable)
  }, [isEditable, onEdit])

  const filterValues = useFilterValue(temporaryFilter)

  const onFilterBlur = useCallback((newFilter = temporaryFilter) => {
    if (!isEqual(newFilter, filter)) {
      handleFilterChange(newFilter)
    }
  }, [filter, handleFilterChange, temporaryFilter])

  const onFilterInputBlur = useCallback((newFilter?: ConfFilter) => {
    setOptionFocused(false)

    onFilterBlur(newFilter)
  }, [onFilterBlur])

  const handleMouseLeave = useCallback(() => {
    setIsBoxFocused(false)
  }, [])

  const handleMouseEnter = useCallback(() => {
    setIsBoxFocused(true)
  }, [])
  const [height, setHeight] = useState<Height>(80)

  useEffect(() => {
    const element = ref.current
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries.length > 0) {
        if (isEditable) {
          const clientHeight = entries[0].target?.clientHeight
          setHeight((clientHeight === undefined || clientHeight === null || clientHeight === 0) ? 'auto' : clientHeight)
        } else {
          setHeight(80)
        }
      }
    })
    if (element) {
      resizeObserver.observe(element)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [isEditable])

  return <AnimateHeight
    duration={500}
    height={height}
    style={{
      width: "100%",
      fontSize: "13px",
      lineHeight: "20px",
      borderRadius: "4px",
      backgroundColor: "white",
    }}
  ><StyledBox
    className={"filterBox"}
    ref={ref}
    $bordered={bordered ? 1 : 0}
    title={filter.reference.alias}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
    <StyledBoxContent>
      <HeaderRow>
        <Col span={22}>
          <StyledBoxTitle>{filter.reference.alias}</StyledBoxTitle>
        </Col>
        <StyledCol span={2} onClick={close}>
          {isEditable === 1 && <IconContainer size={16} color={"var(--light-grey)"} clickable={true}><TrashIcon/></IconContainer>}
        </StyledCol>
      </HeaderRow>
      <BoxContent>
        <StyledIconContainer $isediting={isEditable}>
          {isEditable ? <FilterTypeDropDown {...{
              filter: temporaryFilter,
              handleFilterChange,
              getPopupContainer,
            }}/>
            : <DivWithTopMargin><IconContainer size={16} color={"var(--light-grey)"}><CurrentIcon/></IconContainer></DivWithTopMargin>
          }
        </StyledIconContainer>
        <FilterEntriesContainer
          $isediting={isEditable}>
          {filter.reference ? <FilterInputs {...{
            filter,
            temporaryFilter,
            isEditable,
            environmentId,
            handleTemporaryFilterChange: setTemporaryFilter,
            handleFilterChange,
            withBackground,
            getPopupContainer,
            onApply: onFilterBlur,
            onFocus: () => setOptionFocused(true),
            onBlur: onFilterInputBlur,
          }}/> : <></>}
          <ResumeContainer $isediting={isEditable}><Resume
            className="resume">{filterValues}</Resume></ResumeContainer>
        </FilterEntriesContainer>
      </BoxContent>
    </StyledBoxContent>
  </StyledBox>
  </AnimateHeight>
})

export default Box

const DivWithTopMargin = styled.div`
  height: 32px;
`

const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 32px;
  min-height: 34px;
`

const StyledIconContainer = styled(Col)<{ $isediting: number }>`
  padding: 0px !important;
    flex: 0 0 ${props => props.$isediting ? "45px" : "44px"};
    max-width: ${props => props.$isediting ? "45px" : "44px"};

  width: 100%;
  height: 32px;
  display: flex;
  align-items: baseline;
  justify-content: start;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const FilterEntriesContainer = styled(Col)<{ $isediting: number }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0 !important;
  flex: 0 0 ${props => props.$isediting ? "calc(100% - 45px)" : "calc(100% - 28px)"};
  max-width: ${props => props.$isediting ? "calc(100% - 45px)" : "calc(100% - 28px)"};
  line-height: 20px;
  flex: 1;
  min-width: 0;
  gap: 8px;

  &&& {
    line-height: 20px !important;

    .ant-select {
      line-height: 20px !important;
    }

    .ant-select-selector {

      line-height: 20px !important;
    }
  }
`
const ResumeContainer = styled.div<{ $isediting: number }>`
  overflow: hidden;
  position: absolute;
  top: 1px;
  left: 0;
  ${props => props.$isediting ? `display: none;` : `display: flex; flex-direction: column; justify-content: center;`};
  color: var(--light-grey);
  height: 29px;
  width: 100%;
`
const Resume = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  width: 100%;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: end;
`

// styles
const HeaderRow = styled(Row)`
  display: flex;
  align-items: center;
`

const StyledBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: initial;
  padding: 12px 8px 8px 12px;
  justify-content: space-between;
  min-height: 80px;
  gap: 6px;
`

const StyledBoxTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: var(--dark-text);
  max-height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledBox = styled.div<{ $bordered: number }>`
  min-height: 80px;
  ${({$bordered}) => $bordered ? `border: 1px solid var(--border-color-input);
  border-radius: var(--main-border-radius);` : ''}
`
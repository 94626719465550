import React, {FunctionComponent, useState} from 'react'
import Language from 'language'
import {captureEvent} from 'services/GoogleAnalyticsService'
import Confirm from 'components/confirm/Confirm'
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import {IconContainer} from "components/common/IconContainer"
import {XCircleIcon} from "@heroicons/react/outline"

interface Props {
  type: WidgetTypes
  onClose: () => Promise<void>
}

const WidgetTopperClose: FunctionComponent<Props> = ({type, onClose}) => {
  const [loading, setLoading] = useState(false)

  return <TooltipPopover
    size={'small'}
    placement="topRight"
    content={Language.get('chart-remove-button')}>
    <Confirm
      title={Language.get('chart-delete-confirmation')}
      trigger={<div><IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><XCircleIcon/></IconContainer>
      </div>}
      on={{
        cancel: () => {
          captureEvent({
            category: 'charts',
            action: 'close_chart_cancel',
            // @ts-ignore
            widgetType: type,
          })
        },
        open: () => {
          captureEvent({
            category: 'charts',
            action: 'close_chart_click',
            // @ts-ignore
            widgetType: type,
          })
        },
        ok: async () => {
          if (!loading) {
            setLoading(true)
            captureEvent({
              category: 'charts',
              action: 'close_chart_confirm',
              // @ts-ignore
              widgetType: type,
            })
            await onClose()
            setLoading(false)
          }
        },
      }}/>
  </TooltipPopover>
}

export default WidgetTopperClose

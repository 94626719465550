import {SemanticType} from "classes/MetaModel"

export enum FilterInputType {
  VALUE_SELECTION = "VALUE_SELECTION",
  INCLUDE = "INCLUDE",
  START_WITH = "START_WITH",
  END_WITH = "END_WITH",
  REGEX = "REGEX",
  GREATER = "GREATER",
  GREATER_OR_EQ = "GREATER_OR_EQ",
  LOWER = "LOWER",
  LOWER_OR_EQ = "LOWER_OR_EQ",
  BETWEEN = "BETWEEN",
  EQUAL = "EQUAL"
}

export const FilterInputTypeBySemanticType: { [key in SemanticType | "metric"]: FilterInputType[] } = {
  [SemanticType.STRING]: [FilterInputType.VALUE_SELECTION, FilterInputType.INCLUDE, FilterInputType.START_WITH, FilterInputType.END_WITH, FilterInputType.REGEX],
  [SemanticType.NUMBER]: [FilterInputType.VALUE_SELECTION, FilterInputType.GREATER, FilterInputType.GREATER_OR_EQ, FilterInputType.LOWER, FilterInputType.LOWER_OR_EQ, FilterInputType.BETWEEN],
  [SemanticType.DATE]: [FilterInputType.VALUE_SELECTION],
  [SemanticType.UUID]: [FilterInputType.VALUE_SELECTION],
  [SemanticType.DATETIME]: [FilterInputType.VALUE_SELECTION],
  "metric": [FilterInputType.GREATER, FilterInputType.GREATER_OR_EQ, FilterInputType.LOWER, FilterInputType.LOWER_OR_EQ, FilterInputType.EQUAL, FilterInputType.BETWEEN],
}
import {EChartOption} from "echarts"
import {LEGEND_ID, OPACITY_BLUR, OPACITY_EMPHASIS} from "components/charts/Chart.constants"
import YAxis = echarts.EChartOption.YAxis

export const standardLegendOption = (sideLegend: boolean, sideLegendWidth: number): EChartOption.Legend => ({
  type: "scroll",
  top: 0,
  id: LEGEND_ID,
  tooltip: {
    show: true,
  },
  itemHeight: 10,
  itemWidth: 15,

  orient: sideLegend ? "vertical" : "horizontal",
  right: sideLegend ? 0 : undefined,
  textStyle: {
    ...standardTextStyle(),
    // @ts-ignore
    overflow: "truncate",
    width: sideLegend ? sideLegendWidth : undefined,
  },
})

export const standardTextStyle = (): EChartOption.TextStyle => ({
  color: "#94949E",
  fontSize: 10,
  fontFamily: "Poppins",
})

export const standardTooltipOptions = (): EChartOption.Tooltip => ({
  hideDelay: 40,
  enterable: false,
  showContent: true,
  appendToBody: true,
  alwaysShowContent: false,
  transitionDuration: 0.25,
  padding: 7,
})

export const standardYAxisOptions = (): YAxis => ({
  type: "value",
  max(value) {
    return value.max > 0 ? value.max : 0
  },
  min(value) {
    return value.min < 0 ? value.min : 0
  },
  axisLine: {
    lineStyle: {
      color: "#DCE0E4",
    },
  },
  axisLabel: {
    color: "#94949E",
    fontWeight: "normal",
  },
})

export const standardSerieEmphasis = (): EChartOption.SeriesBar["emphasis"] => ({
  // @ts-ignore
  focus: 'series',
  lineStyle: {
    opacity: OPACITY_EMPHASIS,
  },
  areaStyle: {
    opacity: OPACITY_EMPHASIS,
  },
  itemStyle: {
    opacity: OPACITY_EMPHASIS,
  },
})

// @ts-ignore
export const standardSerieBlur = (): EChartOption.SeriesBar["blur"] => ({
  itemStyle: {
    opacity: OPACITY_BLUR,
  },
  lineStyle: {
    opacity: OPACITY_BLUR,
  },
  areaStyle: {
    opacity: OPACITY_BLUR,
  },
})

export const standardGridOptions = (padding: number, legendAndToolbarHeight: number): EChartOption.Grid => ({
  top: padding + legendAndToolbarHeight,
  bottom: padding,
  left: padding,
  right: padding,
  containLabel: true,
})

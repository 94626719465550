import {denormalize, normalize, NormalizedSchema, schema} from 'normalizr'
import {Dashboard, ExpandedWorkspace, Menu, RawDashboard, RawExpandedWorkspace} from "redux/models/workspace"
import {ChartDtoDetail, ChartDtoDetailTypes, RawChartTypes} from "types/charts"
import {deserializeExtraConf} from "services/api"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {omit} from "lodash"

export interface NormalizedMenu extends Omit<Menu, "dashboards"> {
  dashboards: number[]
}

export interface NormalizedWorkspaceDetail extends Omit<ExpandedWorkspace, "homepage" | "menus"> {
  menus: number[]
  homepage: number
}

export interface NormalizedDashboard extends Omit<Dashboard, "charts"> {
  charts: number[]
}

const workspaceResume = new schema.Entity(
  'workspaces',
)
const workspaceResumes = new schema.Array(workspaceResume)
export const normalizeWorkspaceResumes = (data: any) => normalize(data, workspaceResumes)

const chartDetail = new schema.Entity(
  'charts',
  {},
  {
    processStrategy: (chart) => {
      switch (chart.type) {
        case WidgetTypes.GENERIC:
          return deserializeExtraConf({
            ...omit(chart, ["bqConf"]),
            period: chart.bqConf.period ?? undefined,
            slicers: chart.bqConf.slicers,
            filters: chart.bqConf.filters,
            metrics: chart.bqConf.metrics,
            orderBys: chart.bqConf.orderBys,
            ignoreMetrics0: chart.bqConf.ignoreMetrics0,
          })
        default:
          return deserializeExtraConf(chart)
      }

    },
  },
)
export const normalizeChartDetail = (data: RawChartTypes) => normalize(data, chartDetail)
const dashboardDetail = new schema.Entity(
  'dashboards',
  {
    charts: [chartDetail],
  },
  {
    processStrategy: dashboard => ({
      ...dashboard,
      uri: `${dashboard.relativeId}-${dashboard.title.replace(/\W/g, '-').replace(/-{2,}/g, '-')}`,
    }),
  },
)

interface NormalizedDashboardModels {
  dashboards: Record<string, NormalizedDashboard>
  charts: Record<string, ChartDtoDetail>
}

export const normalizeDashboardDetail: (data: RawDashboard) => NormalizedSchema<NormalizedDashboardModels, number> = (data) => normalize(data, dashboardDetail)
const menuDetail = new schema.Entity(
  'menus',
  {
    dashboards: [dashboardDetail],
  },
)
const workspaceDetail = new schema.Entity(
  'workspaces',
  {
    homepage: dashboardDetail,
    menus: [menuDetail],
  },
)

// As this PR is not merged, we must specify normalizr types by hand https://github.com/paularmstrong/normalizr/pull/474

export interface NormalizedWorkspaceModels {
  workspaces: Record<string, NormalizedWorkspaceDetail>
  menus: Record<string, NormalizedMenu>
  dashboards: Record<string, NormalizedDashboard>
  charts: Record<string, ChartDtoDetailTypes>
}

export const normalizeWorkspaceDetail: (data: RawExpandedWorkspace) => NormalizedSchema<NormalizedWorkspaceModels, number> = (data) => normalize(data, workspaceDetail)
export const denormalizeWorkspaceDetail = (input: number, entities: any) => denormalize(input, workspaceDetail, entities)

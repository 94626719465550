import React from 'react'
import dayjs from "dayjs"
import styled from "styled-components"

class DashboardFooter extends React.Component<any, any> {
  render() {
    return <StyledDiv role="contentinfo">
      <div className="powered-by"> © Biron {dayjs().format('YYYY')} - v{import.meta.env.VITE_VERSION}</div>
    </StyledDiv>
  }
}

export default DashboardFooter

const StyledDiv = styled.div`
  text-align: right;
  background-color: var(--light-background);
  flex: 0 0 auto;
  padding: 1px 5px;
  width: inherit;
  font-size: x-small;
  line-height: 20px;
`
import {useCallback} from "react"
import {FilterOption} from "types/select"
import _ from "lodash"
import {converterFilterToConfModel, getMetricLabel, SimplifiedMetaModelView} from "components/forms/chart/utils"
import {isEmpty} from "commons/object.utils"
import {ChartMetricDtoDetail} from "types/savedConfs"
import {ConfFilter} from "components/forms/selector/comps/box/filters"
import MetaModel, {SemanticType} from "classes/MetaModel"
import {buildMetricLabel} from "classes/workflows/query-workflows/genericDataSetUtil"
import {getMetricDef} from "components/forms/selector/metrics/utils"
import {getAvailableDimensions} from "components/forms/chart/useRequirements"

export const useMetricOptions = (metrics: Pick<ChartMetricDtoDetail, "metricCode" | "viewCode" | "metricAlias" | "titlePartOverrides" | "additionalFilters">[]) => useCallback((
    metaModel: MetaModel,
    viewsWithMetrics: SimplifiedMetaModelView[],
    isMultiView: boolean,
    value: Pick<ConfFilter, "reference">[]): FilterOption[] => _(metrics)
    .map(metric => {
      const metricDef = metric ? getMetricDef(metaModel, metric) : undefined
      const metricAlias = metric && metricDef ? buildMetricLabel({
        ...metric,
        additionalFilters: converterFilterToConfModel(metric.additionalFilters, getAvailableDimensions(metaModel, metrics.map(m => m.viewCode))),
        metricDef,
      }) : undefined
      const metricLabel = metric && viewsWithMetrics && metricAlias ? getMetricLabel(viewsWithMetrics, {
        metricCode: `${metric.metricCode}`,
        metricAlias,
        viewCode: metric.viewCode,
      }, isMultiView) : undefined

      return {
        type: SemanticType.NUMBER,
        label: metricLabel ?? "",
        value: metric.metricCode,
        selected: !isEmpty(value.find(filter => filter.reference.alias === metricLabel)),
      }
    })
    .sortBy('label')
    .value()
  , [metrics])
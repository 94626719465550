import {IconContainer} from "components/common/IconContainer"
import {DuplicateIcon} from "@heroicons/react/outline"
import {Button} from "antd"
import React, {ElementType, memo} from "react"

interface PasteButtonProps {
  handleClick: () => void
  disabled?: boolean
  style?: React.CSSProperties
  size?: number
  Icon?: ElementType
  type?: "link" | "text" | "primary" | "default" | "dashed"
}

const PasteButton = memo<PasteButtonProps>(function PasteButton({
                                                                  handleClick,
                                                                  disabled = false,
                                                                  style,
                                                                  Icon = DuplicateIcon,
                                                                  type = "primary",
                                                                  size = 19,
                                                                }) {
  return <Button type={type} disabled={disabled} onClick={handleClick} style={style}>
    <IconContainer size={size} clickable={true}>
      <Icon/>
    </IconContainer>
  </Button>
})

export default PasteButton
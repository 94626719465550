import dayjs from 'dayjs'
import {buildMetricLabel, parseFormat} from 'classes/workflows/query-workflows/genericDataSetUtil'
import {TargetWorkflowFunction} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartTargetDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {EffectiveConfMetric, MetricDef} from "components/charts/line/LineChart.types"
import {Format} from "types/charts"
import {TargetData} from "services/QueryService"
import {getFromPeriod, predefinedPeriod, SimpleTimeIntervalWithSource} from "commons/period/periodsList"
import {getMetricDef} from "components/forms/selector/metrics/utils"
import {Source} from "components/forms/selector/comps/box/filters"

export const targetQueryWorkflow: TargetWorkflowFunction = (qc) =>
  qc.prepareQuery(qc.query<TargetData>()).then((data) => {
    const noTargetConfigured = data[0][2] === 0
    const metricDef = getMetricDef(qc.metaModel, qc.chart)
    const view = qc.metaModel.views[qc.chart.viewCode]
    const metric: Omit<EffectiveConfMetric, "view"> = {
      viewCode: qc.chart.viewCode,
      metricCode: qc.chart.metricCode,
      metricAlias: metricDef ? buildMetricLabel({
        metricDef,
        additionalFilters: [],
        titlePartOverrides: {},
      }) : '',
      viewAlias: view.alias,
      metricDef: qc.metaModel.getView(qc.chart.viewCode)?.metrics.find(({code}) => code === qc.chart.metricCode) as MetricDef,
      extraConf: {},
      additionalFilters: [],
      titlePartOverrides: {},
    }
    const format = parseFormat(metric)
    const series = parseTargetSeries(data, format, qc.chart)
    return {
      type: "target",
      meta: {
        effectiveConf: {
          type: "target",
          timeInterval: {
            start: (getFromPeriod(qc.chart.period) as SimpleTimeIntervalWithSource).start().toString(),
            end: (getFromPeriod(qc.chart.period) as SimpleTimeIntervalWithSource).end().toString(),
            period: qc.chart.period,
            source: Source.CHART,
          },
          metrics: [{
            ...metric,
            view,
          }],
        },
        format,
      },
      series,
      noTargetConfigured,
    }
  })

function parseTargetSeries(data: TargetData[], format: Format, chart: ChartTargetDetailWithoutLayout) {
  const {period = predefinedPeriod.MONTH_TO_DATE} = chart
  const {printPrevisions = false, ignoreSeasonality = false} = chart.extraConf
  const [achieved, achievedPrevious, target, fullPrevious] = data[0]
  // ratio of % achieved last year or % of period
  let ratio = achievedPrevious / fullPrevious
  if (ignoreSeasonality) {
    if (period.code === predefinedPeriod.MONTH_TO_DATE.code) { // month to date
      ratio = (dayjs().startOf('month').diff(dayjs(), 'day')) / (dayjs().startOf('month').diff(dayjs().endOf('month'), 'day') - 1)
    }
    if (period.code === predefinedPeriod.QUARTER_TO_DATE.code) { // quarter to date
      ratio = (dayjs().startOf('quarter').diff(dayjs(), 'day')) / (dayjs().startOf('quarter').diff(dayjs().endOf('quarter'), 'day') - 1)
    }
    if (period.code === predefinedPeriod.YEAR_TO_DATE.code) { // year to date
      ratio = (dayjs().startOf('year').diff(dayjs(), 'day')) / (dayjs().startOf('year').diff(dayjs().endOf('year'), 'day') - 1)
    }
  }

  // la target 'to date' est calculee au pro rata des chiffres de l'annee precedente
  const targetToDate = ratio * target

  // ensuite on modifie les dataSet pour que cela corresponde a un barchart dont les series seraient current, currentTarget et target
  const series = [
    {
      label: 'Target',
      values: [target],
      format,
    },
    {
      label: 'Achieved',
      values: [achieved],
      format,
    },
    {
      label: 'Target to date',
      values: [targetToDate],
      format,
    },
  ]

  if (printPrevisions) {
    const prevision = achieved / ratio
    series.push({
      label: 'Prevision',
      values: [prevision],
      format,
    })
  }
  return series
}

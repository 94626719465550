import React, {MouseEvent, PropsWithChildren, ReactNode, useState} from 'react'
import {restrictToVerticalAxis, restrictToWindowEdges} from "@dnd-kit/modifiers"
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor as LibMouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {arrayMoveImmutable} from "array-move"

interface Props<T> extends PropsWithChildren<any> {
  value: T[]
  overlayRender: (activeIndex: number) => ReactNode
  handleChange?: (values: T[], oldIndex: number, newIndex: number) => void
}

function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false
    }
    cur = cur.parentElement
  }

  return true
}

class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({nativeEvent: event}: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement)
      },
    },
  ]
}

// dndkit sorter doesn't support id 0, so we need to shift index to get a relevant id
export const getIndexFromID = (id: UniqueIdentifier) => Number(id) - 1
export const getIdFromIndex = (index: number) => index + 1

const SortableContainerContext = <T, >({value, overlayRender, handleChange, children}: Props<T>) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier>()
  const sensors = useSensors(
    useSensor(MouseSensor),
  )

  function handleDragStart(event: DragStartEvent) {
    const {active} = event

    setActiveId(active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event

    if (active && over && active.id !== over.id) {
      const oldIndex = value.findIndex((el, i) => i === getIndexFromID(active.id))
      const newIndex = value.findIndex((el, i) => i === getIndexFromID(over.id))

      handleChange?.(arrayMoveImmutable(value, oldIndex, newIndex), oldIndex, newIndex)
    }
    setActiveId(undefined)
  }

  return <DndContext
    sensors={sensors}
    collisionDetection={closestCenter}
    onDragStart={handleDragStart}
    onDragEnd={handleDragEnd}
    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
  >
    {children}
    {activeId && overlayRender(Number(activeId))}
  </DndContext>
}

export default SortableContainerContext
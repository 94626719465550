import React, {ReactNode} from "react"
import styled from "styled-components"
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"

export const StyledItemWithHelp: (props: { help: ReactNode; children: ReactNode; [p: string]: any }) => JSX.Element = ({
                                                                                                                         help,
                                                                                                                         children,
                                                                                                                         ...props
                                                                                                                       }) => <TooltipPopover
  size={'large'} mouseEnterDelay={0.5} content={help} overlayInnerStyle={{minWidth: 450, maxWidth: 550}} overlayStyle={{minWidth: 450}}>
  <StyledItem {...props}>{children}</StyledItem>
</TooltipPopover>


export const StyledItem = styled.div`
  margin: 0 7px;
  &:first-child {
    margin-right: 7px;
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-left: 7px;
  }

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 11px;
  line-height: 1em;
  color: var(--light-grey);
  opacity: .7;
  gap: 3px;

  &:hover {
    opacity: 1;
  }
`


import React, {memo, useCallback} from 'react'
import Language from 'language'
import Box from './box/Box'
import {Col, Row} from 'antd'
import BoxWithDescription from './boxWithDescription/BoxWithDescription'
import {NUMBER_OF_FILTER_PER_LINE} from "components/dashboard/Dashboard.constants"
import styled from "styled-components"
import _ from "lodash"
import {ConfFilter} from './box/filters'
import {captureEvent} from "services/GoogleAnalyticsService"

interface Props {
  environmentId: number
  filters: ConfFilter[]
  label?: string
  className?: string
  layout: string
  seeDescription?: boolean
  onFiltersChange: (newFilters: ConfFilter[]) => void
  numberOfFilterPerLine?: number
  bordered?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  GACategory: string
  isResumable?: boolean
}

const BoxesWrapper = memo<Props>(function BoxesWrapper({
                                                         filters = [],
                                                         environmentId,
                                                         label,
                                                         layout,
                                                         seeDescription,
                                                         className,
                                                         onFiltersChange,
                                                         numberOfFilterPerLine = NUMBER_OF_FILTER_PER_LINE,
                                                         bordered,
                                                         getPopupContainer,
                                                         GACategory,
                                                         isResumable,
                                                       }) {
  const handleFilterChange = useCallback((filter: ConfFilter, index: number) => {
    const newFilters = Object.assign([] as ConfFilter[], filters)
    newFilters[index] = filter
    onFiltersChange([...newFilters])
    captureEvent({
      category: GACategory,
      action: `add_${filter.type}_filter_values`,
    })
  }, [GACategory, filters, onFiltersChange])

  const removeFilter = useCallback((index: number) => {
    const newFilters = Object.assign([] as ConfFilter[], filters)
    newFilters.splice(index, 1)
    onFiltersChange([...newFilters])
    captureEvent({
      category: GACategory,
      action: `remove_${newFilters[index]?.type}_filter`,
    })
  }, [GACategory, filters, onFiltersChange])


  return <div className={`boxes-wrapper boxes-wrapper-layout-${layout} ${className}`}>
    {label && <div className={'boxes-wrapper-label'}>{Language.get(label)}</div>}
    <Row gutter={[10, 10]}>
      {filters.map((filter, index) => {
        const BoxComponent = seeDescription ? BoxWithDescription : Box
        const boxCmp = <BoxComponent key={index}
                                     environmentId={environmentId}
                                     filter={filter}
                                     close={() => removeFilter(index)}
                                     handleFilterChange={(newFilter) => handleFilterChange(newFilter, index)}
                                     bordered={bordered}
                                     getPopupContainer={getPopupContainer}
                                     isResumable={isResumable}
        />
        return <StyledCol key={index} $nb={numberOfFilterPerLine}><React.Fragment key={index}>{boxCmp}</React.Fragment></StyledCol>
      })}
    </Row>
  </div>
})


export default BoxesWrapper

const StyledCol = styled(Col)<{ $nb: number }>`
    flex: 0 0 ${({$nb}) => 100 / $nb}%;
    max-width: ${({$nb}) => 100 / $nb}%;
  
  ${props => {
    const mediaQueryPoints = [1550, 1250, 950, 750]
    return _.compact(mediaQueryPoints.map((point, index) => {
        const newNb = props.$nb - (index + 1)
      if (newNb > 0) {
        return `@media (max-width: ${point}px) {
              flex: 0 0 ${100 / newNb}%;
              max-width: ${100 / newNb}%;
          }`
      } else {
        return undefined
      }
    })).join('')
}}
`


import React, {memo, useEffect, useMemo, useRef} from 'react'
import {useSelector} from "react-redux"
import * as WorkspaceSelectors from "redux/workspace.selector"
import WorkspaceBridgeContainer from "components/workspace/WorkspaceBridge.Container"
import {pageview} from "services/GoogleAnalyticsService"
import useDispatch from "hooks/useDispatch"
import {NavigateFunction, useLocation, useNavigate, useParams} from "react-router-dom"
import useMemoDeepCached from "hooks/useMemoDeepCached"
import Loader from "components/loader/Loader"

// eslint-disable-next-line react/display-name
const WorkspaceBridge = memo(() => {
  const location = useLocation()
  const navigateRef = useRef<NavigateFunction>(useNavigate())
  const params = useParams()
  const {workspace: workspaceUri} = params
  const navigationUrl = location.pathname

  const dispatch = useDispatch()
  const currentWorkspaceUri = useSelector(WorkspaceSelectors.getCurrentWorkspaceUri)
  const isLoading = useSelector(WorkspaceSelectors.isLoading)

  // the `match` object change each time a user (re-)navigate on a dashboard
  const refreshTimestamp = useMemo(() => {
    return location && Date.now()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceUri, location]) // We specify scalar values here as we don't want to refresh if only the reference changed


  useEffect(() => {
    pageview(navigationUrl)
  }, [navigationUrl, refreshTimestamp])

  // compute currentWorkspace from uri
  useEffect(() => {
    dispatch.workspace.setCurrentFromUri({
      uri: workspaceUri,
    }).then(url => {
      if (url) {
        navigateRef.current(url)
      }
      dispatch.workspace.setLoading(false)
    })
  }, [dispatch, workspaceUri])

  const canDisplayWorkspace = useMemoDeepCached(() => {
    return currentWorkspaceUri && currentWorkspaceUri === workspaceUri && !isLoading
  }, [currentWorkspaceUri, isLoading, workspaceUri])

  return canDisplayWorkspace ? <WorkspaceBridgeContainer/>
    : <Loader/>
})

export default WorkspaceBridge

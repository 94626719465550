import React, {forwardRef, memo, useImperativeHandle, useRef} from 'react'
import {converter} from 'commons/mdParser'
import HtmlDiv from 'components/mdView/MdView.HtmlDiv'
import {WCBodyRef} from "components/widgetContainer/WidgetContainer.Body"

interface Props {
  description?: string
  padding?: number
  justifyContent?: string
}

// eslint-disable-next-line react/display-name
const MdView = memo(forwardRef<WCBodyRef, Props>(({description, padding, justifyContent}, ref) => {
  const html = (description || '').match('<p>') ? description ?? '' : converter.makeHtml(description || '')
  const refCmp = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => ({
    getDynamicHeight: () => {
      return refCmp.current?.clientHeight
    },
  }), [])

  return <HtmlDiv ref={refCmp} html={html} padding={padding} justifyContent={justifyContent}/>
}))

export default MdView

import React, {forwardRef, FunctionComponent, useCallback, useEffect, useMemo} from 'react'
import {Button, Checkbox, Form, Input, Select} from 'antd'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {locales} from "commons/locales/locales"
import Language from "language"
import Popconfirm from "components/common/Popconfirm"
import {useFields} from "hooks/useFields"
import {UAC, User} from "components/admin/user/UserManager.types"
import {ModelUser} from "redux/models/user"
import {SimplifiedUserFormProps, UserConfModel} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"

type UserFormProps = SimplifiedUserFormProps & ConsolidatedFormProps<UserConfModel>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, UserFormProps>(({data, errors, onValuesChange, userCanSetPowerUser, defaultEmailFqdn}, ref) => {
  const [form] = Form.useForm()
  const fields = useFields(data, errors)

  useEffect(() => {
    if (Object.entries(data).length === 0) {
      form.resetFields()
    }
  }, [form, data])

  return <Form ref={ref} form={form} fields={fields} name={Object.entries(data).length > 0 ? 'editUser' : 'newUser'}
               labelCol={{span: 6}} wrapperCol={{span: 18}} onValuesChange={onValuesChange}>
    <Form.Item label={Language.get('entityField.user.email')} name={'email'} initialValue={`@${defaultEmailFqdn}`} rules={[{type: 'email'}, {required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item label={Language.get('entityField.user.firstName')} name={'firstName'} rules={[{required: true}]}>
      <Input />
    </Form.Item>
    <Form.Item label={Language.get('entityField.user.lastName')} name={'lastName'} rules={[{required: true}]}>
      <Input/>
    </Form.Item>
    <Form.Item label={Language.get('entityField.user.locale')} name={'locale'} initialValue={locales[0]} rules={[{required: true}]}>
      <Select virtual={false}>
        {locales.map(locale => <Select.Option key={locale} value={locale}>{locale}</Select.Option>)}
      </Select>
    </Form.Item>
    {userCanSetPowerUser && <Form.Item label={Language.get('entityField.user.powerUser')} name={'powerUser'} valuePropName={'checked'}>
      <Checkbox/>
    </Form.Item>}
  </Form>
})

interface Props {
  uac?: UAC
  getPermission: (key: string) => boolean
  clientAllowedEmailFqdns: string[]
  onActiveChange: (uac: UAC, active: boolean) => void
  onResetPassword: (user: User) => void
  onDelete: (uac: UAC) => void
  onConfirm: (modelUser: ModelUser) => void
  onCancel: () => void
}

const UserForm: FunctionComponent<Props> = ({uac, getPermission, clientAllowedEmailFqdns, onActiveChange, onResetPassword, onDelete, onConfirm, onCancel}) => {
  const userCanSetPowerUser = getPermission('userCanSetPowerUser')
  const defaultEmailFqdn = clientAllowedEmailFqdns[0]
  const handleActiveChange = useCallback(() => {
    if (uac) {
      onActiveChange(uac, !uac.active)
    }
  }, [uac, onActiveChange])
  const handleDelete = useCallback(() => {
    if (uac) {
      onDelete(uac)
    }
  }, [uac, onDelete])
  const handleResetPassword = useCallback(() => {
    if (uac) {
      onResetPassword(uac.user)
    }
  }, [uac, onResetPassword])
  const extraButtons = useMemo(() => {
    if (uac && uac.id) {
      if (uac.active) {
        return [
          <Button key="editActive" type="primary" danger
                  onClick={handleActiveChange}>{Language.get('admin.user.actionInactiveUser')}</Button>,
          <Button key="resetPassword" type="primary"
                  onClick={handleResetPassword}>{Language.get('admin.user.actionResetPassword')}</Button>,
        ]
      } else {
        return [
          <Button key="editActive" onClick={handleActiveChange}>{Language.get('admin.user.actionActiveUser')}</Button>,
          <Popconfirm key="delete" title={Language.get('admin.user.actionDeleteUserConfirm')} onConfirm={handleDelete}>
            <Button danger>{Language.get('admin.user.actionDeleteUser')}</Button>
          </Popconfirm>,
        ]
      }
    } else {
      return []
    }
  }, [uac, handleActiveChange, handleDelete, handleResetPassword])
  const formProps = useMemo(() => ({
      defaultEmailFqdn,
      userCanSetPowerUser,
    }),
    [defaultEmailFqdn, userCanSetPowerUser],
  )

  const data: UserConfModel = useMemo(() => (uac && uac.user) || {}, [uac])

  return <FormModal<UserConfModel, SimplifiedUserFormProps> {...{
    defaultTitle: uac && uac.user.id ? Language.get('admin.user.formUpdateTitle', {user: uac.user}) : Language.get('admin.user.formCreateTitle'),
    open: !!uac,
    data,
    width: 700,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    extraButtons,
    onConfirm,
    onCancel,
    formProps,
  }} />
}
export default UserForm

import {useCallback, useMemo} from "react"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import MetaModel from "classes/MetaModel"
import {GenericChartTypes} from "types/widgets"
import {MetricGrowthType} from "types/savedConfs"
import {ChartFormat} from "components/forms/chart/types"
import {isNeedingToBeOnSecondaryAxis} from "components/forms/selector/metrics/utils"

export const useAddSecondaryAxisOption = () => useCallback((indexOfModifiedMetric: number, modifiedMetric: Omit<MetricSelectorValue, "metricAlias">, metrics: MetricSelectorValue[], metaModel: MetaModel) => {
  return {
    extraConf: {
      ...modifiedMetric.extraConf,
      isDisplayedOnSecondaryAxis: isNeedingToBeOnSecondaryAxis(indexOfModifiedMetric, modifiedMetric, metrics, metaModel),
    },
    extraConfDefaultProperties: {
      isDisplayedOnSecondaryAxis: true,
    },
  }
}, [])

export const useModifiedSecondaryAxisOption = () => useCallback((indexOfModifiedMetric: number, modifiedMetric: Omit<MetricSelectorValue, "metricAlias">, metrics: MetricSelectorValue[], metaModel: MetaModel): Pick<MetricSelectorValue, 'extraConf' | 'extraConfDefaultProperties'> => {
  const isSecondaryAxisOptionModified = modifiedMetric.extraConf?.isDisplayedOnSecondaryAxis === metrics[indexOfModifiedMetric].extraConf?.isDisplayedOnSecondaryAxis
  if (isSecondaryAxisOptionModified) {
    const isNeedingSecondaryAxis = isNeedingToBeOnSecondaryAxis(indexOfModifiedMetric, modifiedMetric, metrics, metaModel)
    const isDefaultApplied = Boolean(modifiedMetric.extraConfDefaultProperties?.isDisplayedOnSecondaryAxis)
    const isDisplayedOnSecondaryAxis = isDefaultApplied ? isNeedingSecondaryAxis : modifiedMetric.extraConf?.isDisplayedOnSecondaryAxis
    return {
      extraConf: {
        ...modifiedMetric.extraConf,
        isDisplayedOnSecondaryAxis,
      },
      extraConfDefaultProperties: {
        isDisplayedOnSecondaryAxis: isDefaultApplied,
      },
    }
  } else {
    return {
      extraConf: modifiedMetric.extraConf,
      extraConfDefaultProperties: {
        isDisplayedOnSecondaryAxis: false,
      },
    }
  }
}, [])


export const useDefaultGrowth = (displayType?: GenericChartTypes) => useMemo(() => {
  switch (displayType) {
    case GenericChartTypes.TABLES:
      return MetricGrowthType.RATIO
    default:
      return MetricGrowthType.VALUE
  }
}, [displayType])

export const useIsAxisOptionAllowed = (
  displayType?: GenericChartTypes,
  format?: ChartFormat | null) => useMemo(() =>
  Boolean(displayType && ([GenericChartTypes.LINE].includes(displayType) || (displayType === GenericChartTypes.BARS && format === ChartFormat.V_GROUPED))), [displayType, format])

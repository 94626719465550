import React, {FunctionComponent} from 'react'
import styled from "styled-components"
import {NormalizedDashboard, NormalizedMenu} from "schemas/workspace"

interface Props {
  dashboard: NormalizedDashboard
  menu?: NormalizedMenu
}

const DashboardTitle: FunctionComponent<Props> = ({dashboard, menu}) => {
  return <StyledContainer>
    {menu && <>
      <MenuTitle>
        {menu.name}
      </MenuTitle>
      <Separator>
        {"/"}
      </Separator></>}
    <span>
      {dashboard.title}
    </span>
  </StyledContainer>
}

export default DashboardTitle

const MenuTitle = styled.div`
  color: var(--primary);
`

const Separator = styled.div`
  color: var(--border-color-base);
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 300;
`

const StyledContainer = styled.div`
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 1;
  font-size: 24px;
  font-weight: 600;
`

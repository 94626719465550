import React, {memo, useCallback, useState} from 'react'
import Language from 'language'
import {Input} from 'antd'
import {useDebouncedCallback} from 'use-debounce'
import {SearchIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import styled from "styled-components"

interface Props {
  search?: string
  searchChange: (search: string) => void
}

const TableChartHeader = memo<Props>(function TableHeader({search: initialSearch, searchChange}) {
  const [search, setSearch] = useState(initialSearch)

  const searchChangeDebounced = useDebouncedCallback(searchChange, 500)

  const searchChangeLifecycle = useCallback(({target: {value}}: { target: { value: string } }) => {
    setSearch(value)
    searchChangeDebounced(value)
  }, [setSearch, searchChangeDebounced])

  return <div className="table-header">
    <div className="table-header-item">
      <StyledInput
        className="table-search-input"
        variant="borderless"
        prefix={<IconContainer color={"var(--light-grey)"} margin={10}><SearchIcon/></IconContainer>}
        placeholder={Language.get('input-type-to-search')}
        value={search}
        onChange={searchChangeLifecycle}
      />
    </div>
  </div>
})

export default TableChartHeader

const StyledInput = styled(Input)`
  padding: 0;
    line-height: var(--line-height);
  .ant-input::placeholder {
    color: var(--dark-text);
  }
  
  
  .ant-input:focus::placeholder {
    color: var(--light-grey);
  }


    &:hover {
    background-color: var(--option-list-hover-bg);
  }
`

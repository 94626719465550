import {ColorName} from "components/charts/Chart.Theme"
import {OrderBy} from "types/charts"

export interface Limit {
  limitSeries?: number,
  hideOthers: boolean
}

export interface ConfLimit extends Limit {
  isDefault: boolean
}

export interface ConfOrderBy extends OrderBy {
  isDefault: boolean
  value?: string
  id?: string
}

export enum GenericChartTypes {
  AREA = 'area',
  LINE = 'lines',
  BOXES = 'boxes',
  BARS = 'bars',
  TABLES = 'tables',
  PIE = 'pie',
}

// JSON persisted in lego that starlight is free to use as it sees fit
export type ChartExtraConf = Partial<{
  displayType: GenericChartTypes
  displayLabels: boolean
  description: string
  cssClass: ColorName
  style: string
  sortSeries: boolean
  printPrevisions: boolean
  ignoreSeasonality: boolean
  asPercentage: boolean
  limits: Limit[]
}>

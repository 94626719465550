import API, {buildUrl} from './api'
import {ChartQueryParams} from "commons/parsers/queries"
import {SlicerDtoDetailTypes} from "types/savedConfs"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {FilterDtoDetailTypes, Source} from "components/forms/selector/comps/box/filters"

export type TargetData = number[]
export type QueryResponseDataWithCategory = (number | string)[]
// matches slices and metrics (+ one line if withDateSlicer is true)

export type QueryResponseData = TargetData | QueryResponseDataWithCategory

export interface AppliedQuery extends Omit<ChartQueryParams, "timeInterval" | "filters"> {
  timeInterval: {
    source: Source.QUERY,
    value: ChartQueryParams["timeInterval"]
  }
  slicers: SlicerDtoDetailTypes[]
  filters: {
    source: Source.QUERY,
    value: FilterDtoDetailTypes
  }[]
}

interface Response<T extends QueryResponseData> {
  appliedQuery: AppliedQuery,
  data: T[]
}

// const getConsolidatedChart = (chart: ChartDtoDetailTypes) => {
//   switch(chart.type) {
//     case WidgetTypes.GENERIC:
//       return {
//         ...omit(chart, ["period", "slicers", "filters", "metrics"]),
//         bqConf: {
//           period: chart.period,
//           ignoreMetrics0: chart.extraConf.ignoreMetrics0,
//           slicers: chart.slicers,
//           filters: chart.filters,
//           metrics: chart.metrics,
//           orderBys: chart.extraConf.sorts,
//         }
//       }
//     case WidgetTypes.TARGET:
//       return {
//         ...omit(chart, ["period", "slicers", "filters", "metrics"]),
//         bqConf: {
//           period: chart.period,
//           ignoreMetrics0: chart.extraConf.ignoreMetrics0,
//           slicers: [],
//           filters: [],
//           metrics: [{
//             metricCode: chart.metricCode,
//             viewCode: chart.viewCode,
//             viewAlias: chart.viewAlias
//           }],
//           orderBys: chart.extraConf.sorts,
//         }
//       }
//     default:
//       return undefined
//   }
//
// }

// Typings: https://github.com/axios/axios/issues/1510#issuecomment-396894600
export const genericBigQuery = <T extends QueryResponseData>(chart: ChartDetailWithoutLayout, dashboardId: number, chartQP: ChartQueryParams, queryId: string) => {
  return API.instanceData.put<void, Response<T>>(`bigQuery/${dashboardId}/${chart.type}?queryId=${queryId}${chart.id ? `&chartId=${chart.id}` : ''}`, {
      ...chartQP,
      timeInterval: {
        ...chartQP.timeInterval,
        period: undefined,
      },
    }, {

      // axios allow passing of custom param so they can be used in interceptors
      // @ts-ignore
      failSilently: true,
    })
    .then((data) => (data as any))
}

export const chartBql = (dashboardId: number, chartQP: ChartQueryParams) => {

  return API.instanceData.put<void, string>(`bigQuery/bql/${dashboardId}/generic`, {
      ...chartQP,
      timeInterval: {
        ...chartQP.timeInterval,
        period: undefined,
      },
    }, {

      // axios allow passing of custom param so they can be used in interceptors
      // @ts-ignore
      failSilently: true,
    })
    .then((data) => (data as any))
}
export const targetBigQuery = <T extends QueryResponseData>(chart: ChartDetailWithoutLayout, chartQP: Record<any, any>) => API.instanceData.put<void, Response<T>>(`bigQuery/${WidgetTypes.TARGET}/${chart.id}`, {...chartQP}, {

    // axios allow passing of custom param so they can be used in interceptors
    // @ts-ignore
    failSilently: true,
  })
  .then((data) => (data as any))
export const downloadQueryByToken = (chartId: number, chartQP: ChartQueryParams) => API.instanceData.put(`bigQuery/${chartId}/excelCsvByToken`, {...chartQP})
  .then(token => buildUrl({path: `bigQuery/excelCsvByToken/${token}`}))


import React, {FunctionComponent, PropsWithChildren} from 'react'
import {useSortable} from "@dnd-kit/sortable"
import {Col, Row} from "antd"
import {CSS} from "@dnd-kit/utilities"
import SortHandle from "components/common/sortable/SortHandle"
import styled from "styled-components"

interface Props extends PropsWithChildren<any> {
  id: number
}

const SortableItem: FunctionComponent<Props> = ({id, children}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id})

  return <Row ref={setNodeRef} style={{
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  }}>
    {
      <DragRow $isdragging={isDragging ? 1 : 0}>
        <HandleColumn span={1}><SortHandle id={`${id}`} {...attributes} {...listeners} isDragging={isDragging ? 1 : 0}/></HandleColumn>
        <Col span={23} data-no-dnd="true">{children}</Col>
      </DragRow>
    }
  </Row>
}

export default SortableItem

const HandleColumn = styled(Col)`
  max-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DragRow = styled(Row)<{ $isdragging: number }>`
    opacity: ${({$isdragging}) => $isdragging ? "0" : "1"};
  width: ${100 - (100 / 24) + 1}%;
`
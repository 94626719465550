/* eslint-disable max-lines */
import {getResolvedFromPeriod} from "commons/period/periodsList"
import {getCurrentEnvironmentId} from "redux/environment.selector"
import {getDashboard, getDashboardId} from "redux/currentDashboard.selector"
import {getPeriods} from "redux/period.selector"
import {findDashboardInfosWith} from "redux/workspace.selector"
import {addChart, deleteChart, updateChartConf, updateDashboardLayout} from "services/DashboardService"
import {updateDashboardPeriodAndFilters} from "services/NavigationService"
import {captureError} from "services/SentryService"
import {updateTargets} from "services/TargetService"
import {getSessionTime, saveSessionTime} from "services/TimeService"
import {omit, pick} from "lodash"
import {WorkspaceSchemas} from "schemas"
import {returnOrExecBatch} from "commons/batch"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {RootState} from "redux/store"
import {buildWorkspaceUri} from "components/workspace/Workspace.types"
import {computeDefaultGranularity} from "components/forms/selector/dashboardDate/DashboardDateSelector"
import {Dayjs} from "dayjs"
import {NormalizedDashboard, NormalizedMenu} from "schemas/workspace"
import {ChartDtoDetailToAdd, ChartDtoDetailTypes, ChartGenericDtoDetail, ChartTargetDtoDetail, RawChartTypes} from "types/charts"
import {serializeChart} from "services/api"
import {SlicerDtoDetailTypes} from "types/savedConfs"
import {ChartWithMetricDefLayoutTypes} from "components/widgetContainer/WidgetContainer"
import {consolidatedChartPeriod, consolidateRawDashboardPeriod} from "redux/models/utils.model"
import {isFilterEmpty} from "components/forms/selector/comps/box/utils"
import {DimensionFilterDtoDetail} from "components/forms/selector/comps/box/filters"
import {Granularity, PeriodTypes} from "types/period.types"

export interface DateInterval {
  name?: string,
  start: Dayjs,
  end: Dayjs,
  period: PeriodTypes
  granularity?: Granularity
}

// Represents set of options to know which data retrieve
// 'Data'Selection To avoid type confusion
export interface DataSelection {
  initTimestamp: number,
  date: DateInterval,
  filters: DimensionFilterDtoDetail[]
  slicers?: SlicerDtoDetailTypes[]
}

type Id = string | number

interface CurrentDashboard {
  dashboardId?: Id,
  selection?: DataSelection
  redirect?: string
  menuId?: Id
}

export interface LayoutWithId {
  id: number
  x: number
  y: number
  w: number
  h: number
}


const initialState: CurrentDashboard = {}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSelection(state: CurrentDashboard, selection: DataSelection) {
      return {
        ...state,
        selection,
      }
    },
    set(state, {redirect, menu, dashboard, selection = state.selection}: {
      redirect?: string,
      menu?: { id: Id }
      dashboard?: { id: Id }
      selection?: DataSelection
    }) {
      return {
        ...state,
        redirect,
        menuId: menu?.id,
        dashboardId: dashboard?.id,
        selection,
      }
    },
    clear() {
      return initialState
    },
    'workspace/cleanStates': () => {
      return initialState
    },
  },
  effects: (dispatch) => {
    return {
      setFromUri({uri, timestamp, chartHashLink}: { uri: string, timestamp: number, chartHashLink?: string }, state) {
        const {redirect, dashboard, menu} = findFromUri(state, uri, chartHashLink)

        dispatch.currentDashboard.set({
          redirect,
          menu,
          dashboard,
          selection: dashboard
            ? {
              initTimestamp: timestamp || Date.now(),
              date: {
                granularity: Granularity.DAY,
                ...(getSessionTime() || getResolvedFromPeriod(dashboard.period)) as DateInterval,
              },
              filters: Object.assign([], dashboard.filters),
            }
            : undefined,
        })
      },
      async confUpdate(data: NormalizedDashboard, state) {
        const dashboard = getDashboard(state)
        if (!dashboard) {
          captureError("Could not edit dashboard, because the dashboard could not be found", {})
          return
        }
        const consolidatedData = pick({...omit(dashboard, ['slicers']), ...omit(data, ['title'])}, ['filters', 'period', 'title']) as Pick<NormalizedDashboard, 'filters' | 'period' | 'title'>
        const periods = await getPeriods(state)

        const newDashboardRaw = consolidateRawDashboardPeriod(await updateDashboardPeriodAndFilters(dashboard.id, {
          ...consolidatedData,
          period: {
            code: consolidatedData.period.code,
          },
        }), periods)
        dispatch.workspace.setEntities({
          // update only dashboards entities because charts should be deep equals to their existing version although they are not referentially equals
          dashboards: WorkspaceSchemas.normalizeDashboardDetail(newDashboardRaw).entities.dashboards,
          charts: WorkspaceSchemas.normalizeDashboardDetail(newDashboardRaw).entities.charts,
        })

        const date = data.period ? getResolvedFromPeriod(data.period) : undefined
        const granularity = date ? computeDefaultGranularity({start: date?.start, end: date?.end}) : undefined

        dispatch.currentDashboard.setSelection({
          ...state.currentDashboard.selection as DataSelection,
          ...{
            filters: data.filters,
            date: {
              ...(state.currentDashboard.selection as DataSelection).date,
              ...date,
              granularity: granularity ?? (state.currentDashboard.selection as DataSelection).date.granularity,
            },
          },
        })

      },
      async layoutUpdate(data: LayoutWithId[], state) {
        const newDashboard = await updateDashboardLayout(
          getDashboardId(state),
          data.map((chart: LayoutWithId) => pick(chart, ['id', 'x', 'y', 'w', 'h'])),
        )
        const periods = await getPeriods(state)
        dispatch.workspace.setEntities(WorkspaceSchemas.normalizeDashboardDetail(consolidateRawDashboardPeriod(newDashboard, periods)).entities)
      },
      selectionUpdate(data: DataSelection) {
        if (data.date) {
          saveSessionTime(data.date)
        }
        dispatch.currentDashboard.setSelection(data)
      },
      async chartAdd({
                       // @ts-ignore
                       targets,
                       ...data
                     }, state) {
        // Must be specified as any otherwise type circular reference itself
        const dashboard: any = getDashboard(state)
        const periods = await getPeriods(state)
        const newChart = consolidatedChartPeriod(await addChart(dashboard.id, {
            ...serializeChart({
              ...data,
              period: data?.period ? {
                code: data?.period.code,
              } : undefined,
            }),
            id: undefined,
          },
        ), periods)

        const normalized = WorkspaceSchemas.normalizeChartDetail(newChart)
        dispatch.workspace.setEntities({
          ...normalized.entities,
          dashboards: {
            [dashboard.id]: {
              ...dashboard,
              charts: [...dashboard.charts, newChart.id],
            },
          },
        })

        if (targets) {
          const environmentId = getCurrentEnvironmentId(state)
          await updateTargets({
            environmentId,
            viewCode: (data as ChartTargetDtoDetail).viewCode,
            metricCode: (data as ChartTargetDtoDetail).metricCode,
            targets,
          })
        }
        return newChart
      },
      async chartConfUpdate(
        {
          forBatch = false,
          data: {
            // @ts-ignore
            targets,
            ...data
          },
        }: { forBatch: boolean, data: ChartDtoDetailToAdd & { id: number } },
        state) {
        const chart = state.workspace.entities.charts[data.id]
        const periods = await getPeriods(state)
        const consolidatedData = {...chart, ...data} as ChartDtoDetailTypes
        if (targets) {
          const environmentId = getCurrentEnvironmentId(state)
          await updateTargets({
            environmentId,
            viewCode: (consolidatedData as ChartTargetDtoDetail).viewCode,
            metricCode: (consolidatedData as ChartTargetDtoDetail).metricCode,
            targets,
          })
        }
        const serializedExtraConf = serializeChart({
          ...consolidatedData,
          period: (consolidatedData.type !== WidgetTypes.DIVIDER) && consolidatedData.period ? {
            code: consolidatedData.period.code,
          } : undefined,
          filters: consolidatedData && consolidatedData.type === WidgetTypes.GENERIC && (consolidatedData as ChartGenericDtoDetail).filters ? (consolidatedData as ChartGenericDtoDetail).filters.filter(filter => !isFilterEmpty(filter)) : [],
        } as ChartWithMetricDefLayoutTypes)
        const newChart = consolidatedChartPeriod(await updateChartConf(chart.id, serializedExtraConf), periods)
        return returnOrExecBatch(forBatch,
          () => dispatch.workspace.setEntities(WorkspaceSchemas.normalizeChartDetail(newChart as RawChartTypes).entities),
        )
      },
      async chartDelete(id: number | string | undefined, state) {
        const dashboard = getDashboard(state)
        if (!dashboard) {
          return
        }
        await deleteChart(id ?? dashboard.id)
        dispatch.workspace.setEntities({
          dashboards: {
            [dashboard.id]: {
              ...dashboard,
              charts: dashboard.charts.filter(candidateId => candidateId !== id),
            },
          },
        })
      },
    }
  },
})

const findFromUri: (state: RootState, uri: string, chartHashLink?: string) =>
  { redirect?: string, dashboard?: NormalizedDashboard, menu?: NormalizedMenu }
  = (state: RootState, uri: string, chartHashLink) => {
  if (uri) {
    const relativeIdMatch = uri.match(/^(\d+)(:?-|$)/)
    const relativeId = relativeIdMatch && Number(relativeIdMatch[1])
    const lookupResult = findDashboardInfosWith(state, 'relativeId', relativeId)
    if (lookupResult) {
      lookupResult.dashboard.chartHashLink = chartHashLink
      return {...lookupResult}
    }
  }
  if (!state.workspace.currentWorkspace) {
    return {redirect: "/"}
  }
  // redirect to the home of this dashboard
  // We rewrite absolute path here to prevent infinite loop
  return {redirect: buildWorkspaceUri(state.workspace.currentWorkspace, state.workspace.entities.dashboards[state.workspace.currentWorkspace.homepage], chartHashLink)}
}

import React, {memo, useEffect, useMemo} from 'react'
import {useSelector} from "react-redux"
import {getCurrentMetaModel} from "redux/environment.selector"
import {getDashboardId, getRedirect} from "redux/currentDashboard.selector"
import DashboardContainer from "components/dashboard/Dashboard.Container"
import {Navigate, useLocation, useParams} from "react-router-dom"
import useDispatch from "hooks/useDispatch"
import MetaModel from "classes/MetaModel"


interface Props {
  canEdit: boolean
}

const DashboardBridge: (props: Props) => any = memo<Props>(function DashboardBridge({
                                                                                      canEdit,
                                                                                    }) {
  const params = useParams()
  const {dashboard: dashboardUri} = params
  const dispatch = useDispatch()
  const redirect = useSelector(getRedirect)
  const currentDashboardId = useSelector(getDashboardId)
  const metaModel = useSelector(getCurrentMetaModel) as MetaModel
  const location = useLocation()

  // the location object change each time a user (re-)navigate on a dashboard
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshTimestamp = useMemo(() => Date.now(), [location])

  useEffect(() => {
    if (dashboardUri) {
      dispatch.currentDashboard.setFromUri({
        uri: dashboardUri,
        timestamp: refreshTimestamp,
        chartHashLink: location.hash,
      })
    }
    return () => {
      dispatch.currentDashboard.clear()
    }
  }, [dispatch, refreshTimestamp, dashboardUri, location])

  if (redirect) {
    return <Navigate to={redirect}/>
  } else if (currentDashboardId) {
    return <DashboardContainer canEdit={canEdit} metaModel={metaModel}/>
  } else {
    return null
  }
})

export default DashboardBridge;

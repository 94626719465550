import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react'
import {Layout} from 'antd'
import MainLayoutHeader from 'components/mainLayout/MainLayout.Header'
import {useSelector} from "react-redux"
import {getSelection} from "redux/currentDashboard.selector"
import styled from "styled-components"

interface MainLayoutProps extends PropsWithChildren {
  canEdit?: boolean,
  editMode?: boolean,
  sideContent?: ReactNode,
  onEditModeChange?: (state: boolean) => void
  onDashboardSelectionChange?: any
}

const MainLayout: FunctionComponent<MainLayoutProps> = ({
                                                          children,
                                                          canEdit,
                                                          editMode,
                                                          onDashboardSelectionChange,
                                                          onEditModeChange,
                                                          sideContent,
                                                        }) => {
  const dashboardSelection = useSelector(getSelection)

  return <Layout>
    {sideContent}
    <Layout className="main-layout">
      <Layout>
        {dashboardSelection && <MainLayoutHeader {...{
          dashboardSelection,
          onDashboardSelectionChange,
          onEditModeChange,
          canEdit,
          editMode,
        }} />}
        <LayoutContent>
          {children}
        </LayoutContent>
      </Layout>
    </Layout>
  </Layout>
}

const LayoutContent = styled(Layout.Content)`
  overflow-y: auto;
`

export default MainLayout


import React, {forwardRef, FunctionComponent, useMemo, useState} from 'react'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {Form} from 'antd'
import {DimensionFiltersField, DimensionFiltersWrapper} from ".././confItems/DimensionFilters"
import {PeriodWrapper} from ".././confItems/Period"
import {useFields} from "hooks/useFields"
import {ConfFormDashboardData, FormDashboardConfProps} from "components/forms/Form.types"
import {SimplifiedDashboardFormProps} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"
import Language from "language"
import {converterFilterToConfModel, useDimensionFilterConverterToConfModel} from "components/forms/chart/utils"
import {useHandlePartialFormDataChange} from "components/forms/chart/hooks"
import PeriodSelector from "components/forms/selector/period/PeriodSelector"

type FormDashboardComponent = SimplifiedDashboardFormProps & ConsolidatedFormProps<ConfFormDashboardData>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, FormDashboardComponent>(({
                                                                          data,
                                                                          errors,
                                                                          availableDimensionsForFilters,
                                                                          loadDictionaryEntries,
                                                                          environmentId,
                                                                          onValuesChange,
                                                                        }, ref) => {
  const fields = useFields(data, errors)

  return <Form ref={ref} fields={fields} name={'dashboardConf'} onValuesChange={onValuesChange}>
    <PeriodWrapper name={'period'} withDescription={true}>
      <PeriodSelector {...{
        forTarget: false,
        withOverrideOption: false,
        withDateSlicerGranularity: false,
        name: 'period',
        span: 24,
        environmentId,
      }} />
    </PeriodWrapper>
    <DimensionFiltersWrapper name={'filters'}>
      <DimensionFiltersField {...{
        availableDimensions: availableDimensionsForFilters,
        loadDictionaryEntries,
        span: 24,
        environmentId,
        GACategory: 'dashboards',
      }}/>
    </DimensionFiltersWrapper>
  </Form>
})


const FormDashboardConfCmp: FunctionComponent<FormDashboardConfProps> = ({
                                                                           data,
                                                                           metaModel,
                                                                           loadDictionaryEntries,
                                                                           onConfirm,
                                                                           onCancel,
                                                                           environmentId,
                                                                         }) => {
  const [formData, setData] = useState<ConfFormDashboardData>({
    ...data,
    filters: converterFilterToConfModel(data.filters),
  })

  const formProps: SimplifiedDashboardFormProps = useMemo(() => ({
      availableDimensionsForFilters: metaModel.listDimensions(),
      loadDictionaryEntries,
      environmentId,
    }),
    [metaModel, loadDictionaryEntries, environmentId],
  )

  const dimensionFilterConverter = useDimensionFilterConverterToConfModel(formData.filters, formProps.availableDimensionsForFilters)

  const consolidatedFormData: ConfFormDashboardData = useMemo(() => {
    return {
      ...formData,
      filters: dimensionFilterConverter(),
    }
  }, [dimensionFilterConverter, formData])

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return <FormModal<ConfFormDashboardData, SimplifiedDashboardFormProps> {...{
    defaultTitle: Language.get("navigation-tools-edit-dashboard"),
    width: 750,
    data: consolidatedFormData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    formProps,
    onConfirm,
    onCancel,
  }} />
}

export default FormDashboardConfCmp

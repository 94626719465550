import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import Language from "language"
import styled from "styled-components"
import {Input} from "antd"
import {IconContainer} from "components/common/IconContainer"
import {ExclamationIcon} from "@heroicons/react/outline"
import {
  escapePattern,
  getFilterValue,
  useGetFilterValue,
  useIsFilterContainingLeadingOrTrailingSpace,
  useIsFilterValueValid,
} from "components/forms/selector/comps/box/utils"
import {FilterInputType} from "types/filter"
import {
  ConfDimensionFilterLikeDtoDetail,
  ConfDimensionFilterMatchDtoDetail,
  ConfFilter,
  FilterOperator,
  FilterType,
} from "components/forms/selector/comps/box/filters"

interface FilterPatternProps {
  filter: ConfFilter
  temporaryFilter: ConfFilter
  type: FilterInputType
  handleTemporaryFilterChange: (filter: ConfDimensionFilterLikeDtoDetail | ConfDimensionFilterMatchDtoDetail) => void
  handleFilterChange: (filter: ConfDimensionFilterLikeDtoDetail | ConfDimensionFilterMatchDtoDetail) => void
  onFocus: () => void
  onBlur: () => void
}

// eslint-disable-next-line react/display-name
const FilterPattern = forwardRef<any, FilterPatternProps>(({
                                                             filter,
                                                             temporaryFilter,
                                                             type,
                                                             handleTemporaryFilterChange,
                                                             handleFilterChange,
                                                             onFocus,
                                                             onBlur,
                                                           }, ref) => {
  const [filterValue, setFilterValue] = useState<string>(getFilterValue(temporaryFilter))
  const getFilterValueFormatted = useGetFilterValue(type)

  const consolidatedFilterValue = useMemo(() => filterValue === " " ? "" : filterValue, [filterValue])
  const [temporaryValue, setTemporaryValue] = useState(consolidatedFilterValue)
  const isFieldValid = useIsFilterValueValid(type, temporaryValue)
  const isContainingSpace = useIsFilterContainingLeadingOrTrailingSpace(temporaryValue)
  const inputRef = useRef<any>()

  useImperativeHandle(ref,
    () => {
      return {
        focus() {
          inputRef.current.focus()
        },
        blur() {
          inputRef.current.blur()
        },
      }
    }, [])

  useEffect(() => {
    if (isFieldValid) {
      setTemporaryValue(filter.predicate.operator === FilterOperator.IN || getFilterValue(filter) === " " ? "" : getFilterValue(filter))
    }
    // Here we want to listen to filter change (updated by the dashboard conf or after validation) in order to update the local state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (isFieldValid) {
      setFilterValue(temporaryValue)
    } else {
      setFilterValue("")
    }
  }, [isFieldValid, setFilterValue, temporaryValue])

  const createFilterObject = useCallback(<T extends ConfDimensionFilterLikeDtoDetail | ConfDimensionFilterMatchDtoDetail>(sourceFilter: T, value: T["predicate"]["value"]) => ({
    ...sourceFilter,
    predicate: {
      ...sourceFilter.predicate,
      value,
    },
    isValid: isFieldValid,
  }), [isFieldValid])

  useEffect(() => {
    if (temporaryFilter.predicate.operator === FilterOperator.Like || temporaryFilter.predicate.operator === FilterOperator.Match) {
      if (temporaryFilter.predicate.value.pattern !== getFilterValueFormatted(escapePattern(filterValue))) {
        handleTemporaryFilterChange(createFilterObject(temporaryFilter as ConfDimensionFilterLikeDtoDetail | ConfDimensionFilterMatchDtoDetail, {
          ...temporaryFilter.predicate.value,
          "@type": FilterType.PATTERN,
          pattern: getFilterValueFormatted(escapePattern(filterValue)),
        }))
      }
    }
  }, [temporaryFilter, handleTemporaryFilterChange, filterValue, getFilterValueFormatted, type, isFieldValid, createFilterObject])

  const handleInputChange = useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryValue(v.target.value)
  }, [])

  const handleCaseSensitiveChange = useCallback(() => {
    if (temporaryFilter.predicate.operator === FilterOperator.Like) {
      handleFilterChange(createFilterObject(temporaryFilter as ConfDimensionFilterLikeDtoDetail, {
        ...temporaryFilter.predicate.value,
        caseSensitive: !temporaryFilter.predicate.value.caseSensitive,
      }))
    }
    if (temporaryFilter.predicate.operator === FilterOperator.Match) {
      handleFilterChange(createFilterObject(temporaryFilter as ConfDimensionFilterMatchDtoDetail, {
        ...temporaryFilter.predicate.value,
        caseSensitive: !temporaryFilter.predicate.value.caseSensitive,
      }))
    }
  }, [createFilterObject, handleFilterChange, temporaryFilter])

  return <div>
    <FlexContainer>
      <StyledInput
        ref={inputRef}
        value={temporaryValue}
        status={isFieldValid ? undefined : "error"}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={() => onBlur()}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onBlur()
          }
        }}
      />
      <ButtonContainer
        $clicked={(temporaryFilter.predicate.operator === FilterOperator.Like || temporaryFilter.predicate.operator === FilterOperator.Match) && temporaryFilter.predicate.value.caseSensitive}
        onClick={handleCaseSensitiveChange}>
        <IconContainer clickable={true} titleKey={"filter.matchCase"}>
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.791 11.012H2.717L2.017 13H0.351L3.837 3.256H5.685L9.171 13H7.491L6.791 11.012ZM6.343 9.71L4.761 5.188L3.165 9.71H6.343ZM10.1547 9.108C10.1547 8.33333 10.3134 7.64733 10.6307 7.05C10.9574 6.45267 11.3961 5.99067 11.9467 5.664C12.5067 5.328 13.1227 5.16 13.7947 5.16C14.4014 5.16 14.9287 5.28133 15.3767 5.524C15.8341 5.75733 16.1981 6.05133 16.4687 6.406V5.286H18.0787V13H16.4687V11.852C16.1981 12.216 15.8294 12.5193 15.3627 12.762C14.8961 13.0047 14.3641 13.126 13.7667 13.126C13.1041 13.126 12.4974 12.958 11.9467 12.622C11.3961 12.2767 10.9574 11.8007 10.6307 11.194C10.3134 10.578 10.1547 9.88267 10.1547 9.108ZM16.4687 9.136C16.4687 8.604 16.3567 8.142 16.1327 7.75C15.9181 7.358 15.6334 7.05933 15.2787 6.854C14.9241 6.64867 14.5414 6.546 14.1307 6.546C13.7201 6.546 13.3374 6.64867 12.9827 6.854C12.6281 7.05 12.3387 7.344 12.1147 7.736C11.9001 8.11867 11.7927 8.576 11.7927 9.108C11.7927 9.64 11.9001 10.1067 12.1147 10.508C12.3387 10.9093 12.6281 11.2173 12.9827 11.432C13.3467 11.6373 13.7294 11.74 14.1307 11.74C14.5414 11.74 14.9241 11.6373 15.2787 11.432C15.6334 11.2267 15.9181 10.928 16.1327 10.536C16.3567 10.1347 16.4687 9.668 16.4687 9.136Z"/>
          </svg>
        </IconContainer>
      </ButtonContainer>
    </FlexContainer>
    {
      isContainingSpace ? <WarningContainer><IconContainer
        size={12}><ExclamationIcon/></IconContainer><span>{Language.get(`filter.spaceWarning`)}</span></WarningContainer> : <></>
    }
    {
      isFieldValid ? <></> : <ErrorContainer><IconContainer
        size={12}><ExclamationIcon/></IconContainer><span>{Language.get(`filter.type.${FilterInputType.REGEX}.warning`)}</span></ErrorContainer>
    }
  </div>
})

export default FilterPattern

const WarningContainer = styled.div`
  display: flex;
  color: var(--orange);
  font-size: 10px;
  align-content: center;
  align-items: center;
  gap: 4px;
`

const ErrorContainer = styled.div`
  display: flex;
  color: var(--target-red);
  font-size: 10px;
  align-content: center;
  align-items: center;
  gap: 4px;
`

const ButtonContainer = styled.span<{ $clicked: boolean }>`
  display: flex;
  align-content: center;
  justify-content: end;
  align-items: center;

  svg {
      fill: ${({$clicked}) => $clicked ? "var(--primary)" : "var(--border-color-base)"}
  }

  &:hover {
    svg {
      fill: var(--primary-light);
    }
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 6px;
`

const StyledInput = styled(Input)`
  line-height: 28px;
  padding: 0 6px;
  box-shadow: none !important;
`
import DashboardAddWidgetButton, {
  DashboardAddWidgetButtonProps,
} from "components/dashboard/header/addWidgetButton/DashboardAddWidgetButton"
import React, {memo, useMemo, useState} from "react"
import styled from "styled-components"


interface DividerAddWidgetProps extends Omit<DashboardAddWidgetButtonProps, "style"> {
  style?: string
}

// eslint-disable-next-line react/display-name
const DividerAddWidget = memo(({
                                 style,
                                 ...props
                               }: DividerAddWidgetProps) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)

  const isVisible = useMemo(() => isHover || isPopupVisible, [isHover, isPopupVisible])

  return <AddChartDivider className={"add-chart"} $additionnalstyle={style} onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          $isvisible={isVisible ? 1 : 0}>
    <Separator/>
    <DashboardAddWidgetButton {...props} GASource={"dashboardGrid"} style={`position: absolute; width: 100%;`} onVisibleChange={(state) => {
      setIsPopupVisible(state)
      setIsHover(false)
    }}/>
  </AddChartDivider>
})

export default DividerAddWidget

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 20px;
`

const AddChartDivider = styled.div<{ $additionnalstyle?: string, $isvisible: number }>`
  position: absolute;
  height: 40px;
  z-index: 10;
  width: 100%;
  bottom: -20px;

    ${({$additionnalstyle}) => $additionnalstyle}

  div {
        display: ${({$isvisible}) => $isvisible ? 'flex' : 'none'};
  }
`

import {IconContainer} from "components/common/IconContainer"
import React, {memo, useCallback, useEffect, useRef, useState} from "react"
import {useSelector} from "react-redux"
import {getPermission} from "redux/appEnvironment.selector"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import styled from "styled-components"
import {Input, Tooltip} from "antd"
import {PencilAltIcon} from "@heroicons/react/outline"
import {captureEvent} from "services/GoogleAnalyticsService"

interface WidgetTitleProps {
  chart: ChartDetailWithoutLayout,
  editable?: boolean
  onConfirm: (newConf: ChartDetailWithoutLayout) => Promise<any>
  iconColor?: string
}

// eslint-disable-next-line react/display-name
const WidgetTitle = memo<WidgetTitleProps>(({
                                              chart,
                                              editable,
                                              onConfirm,
                                              iconColor = "var(--light-grey)",
                                            }) => {
  const canEdit = useSelector(getPermission)('workspaceCanEdit')
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [title, setTitle] = useState(chart.title)
  const titleInput = useRef<any>()

  useEffect(() => {
    setTitle(chart.title)
  }, [chart.title])

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.focus()
    }
  }, [isEditingTitle])

  const onApply = useCallback(() => {
    setIsEditingTitle(false)
    if (chart.title !== title) {
      captureEvent({
        category: 'charts',
        action: 'dashboard_chart_title_update',
        widgetType: chart?.type,
      })
      onConfirm({
        ...chart,
        title,
      })
    }
  }, [chart, onConfirm, title])
  const handleFocus = useCallback((event: any) => event.target.select(), [])

  return <FlexContainer>
    {canEdit && editable && !isEditingTitle && <IconContainer margin={12} color={iconColor} size={19} onClick={() => {
      setIsEditingTitle(old => !old)
    }} clickable={true}><PencilAltIcon/></IconContainer>}
    <CustomInput ref={titleInput} className="title-input" onFocus={handleFocus} $displayed={isEditingTitle ? 1 : 0} {...{
      autoSize: {
        minRows: 1,
        maxRows: 6,
      },
      value: title,
      onChange: (newValue) => {
        setTitle(newValue.target.value)
      },
      onBlur: () => {
        onApply()
      },
      onKeyDown: (event) => {
        if (event.keyCode === 13) {
          onApply()
        }
      },
    }}/>
    {
      !isEditingTitle && <Tooltip {...{
        placement: "top",
        title,
        mouseEnterDelay: 1,
        overlayStyle: {maxWidth: 400},
      }}>
        <TitleSpan translate="no">{title}</TitleSpan>
      </Tooltip>
    }
  </FlexContainer>
})

export default WidgetTitle

const FlexContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 34px;
`

const CustomInput = styled(Input.TextArea)<{ $displayed: number }>`
    &&& {
        ${({$displayed}) => $displayed ? "display: block;" : "display: none;"}
        background-color: white;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px !important;
        letter-spacing: 0em;
        text-align: left;
        color: #425FF0;
        position: absolute;
        width: 100%;
        z-index: 1;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
    }
`

const TitleSpan = styled.span`
    text-align: left;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-align-self: auto;
    align-self: auto;
    font-size: 20px;
    line-height: 32px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;`


import React, {forwardRef, memo} from 'react'
import styled from "styled-components"
import {isEmpty} from "commons/object.utils"

interface Props {
  html: string
  padding?: number
  justifyContent?: string
}

// eslint-disable-next-line react/display-name
const MdViewHtmlDiv = memo(forwardRef<HTMLDivElement, Props>(function HtmlDiv({html, padding, justifyContent = "center"}, ref) {
  return <StyledContent ref={ref} $padding={padding} $justifycontent={justifyContent} dangerouslySetInnerHTML={{__html: html}}/>
}))

export default MdViewHtmlDiv

const StyledContent = styled.div<{ $padding?: number, $justifycontent?: string }>`
  line-height: 20px;
  min-height: 38px;
    padding: ${props => isEmpty(props.$padding) ? "12px" : `${props.$padding}px`};
  display: flex;
  flex-direction: column;
    justify-content: ${({$justifycontent}) => $justifycontent};
  height: 100%;
  
  img {
    width: 100%;
    padding-top: 3%;
  }
  
  p {
    margin-bottom: 0px;
    overflow-wrap: break-word;
  }
`

import {Row} from "antd"
import React, {memo} from "react"
import {DescriptionCol} from "components/widgets/topper/help-popover/HelpPopoverBody"
import {EffectiveConfMetric, TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import styled from "styled-components"
import {useDayDifference} from "components/forms/chart/useFixHooks"
import ViewDescription from "components/forms/description/ViewDescription"
import MetricDescription from "components/forms/description/MetricDescription"
import MetricOptionsResume from "components/forms/selector/metrics/display/MetricOptionsResume"
import MetricDisplay from "components/metricDisplay/MetricDisplay"

interface Props {
  metric: EffectiveConfMetric
  timeInterval: TimeIntervalWithSource
}

// eslint-disable-next-line react/display-name
const MetricRepresentation = memo<Props>(({metric, timeInterval}) => {
  const dayDifference = useDayDifference(timeInterval)

  return <div>
    <Row>
      <DescriptionCol span={24}>
        <span translate="no">{metric.viewAlias}</span>
        <StyledViewDescription description={metric.view.description} title={metric.view.alias} code={metric.view.code}/>
        <span>{" - "}</span>
        <ImportantText>
          <MetricDisplay metric={metric}/>
        </ImportantText>
        <MetricDescriptionWithoutLeftMargin metric={metric} timeInterval={timeInterval}/>
      </DescriptionCol>
    </Row>
    <MetricResumeContainer>
      <MetricOptionsResume iconSize={14} value={metric} timeInterval={timeInterval} dayDifference={dayDifference}/>
    </MetricResumeContainer>
  </div>
})

export default MetricRepresentation

export const StyledViewDescription = styled(ViewDescription)`
  color: rgba(0, 0, 0, 0.3);
`
export const StyledMetricDescription = styled(MetricDescription)`
  color: rgba(0, 0, 0, 0.3);
`

const MetricDescriptionWithoutLeftMargin = styled(StyledMetricDescription)`
    margin-left: 0 !important;
`

const MetricResumeContainer = styled.div`
    margin-left: 20px;
    width: 70%;
`

const ImportantText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 104.68%;
  color: var(--light-text);
  margin-right: 4px;
`

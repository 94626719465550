import React, {memo, PropsWithChildren} from "react"
import {useHasFilterApplied, useHasGrowthApplied, useHasPeriodApplied} from "components/metricDisplay/MetricDisplay.hooks"
import GrowthIcon from 'themes/svg/outlined/growth.svg?react'
import FilterIcon from 'themes/svg/outlined/filter.svg?react'
import PeriodIcon from 'themes/svg/outlined/date.svg?react'
import styled from "styled-components"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {IconContainer} from "components/common/IconContainer"

interface MetricDisplayProps extends PropsWithChildren {
  metric: MetricSelectorValue
}

// eslint-disable-next-line react/display-name
const MetricDisplay = memo<MetricDisplayProps>(({
                                                  metric,
                                                  children,
                                                }) => {
  const hasGrowthApplied = useHasGrowthApplied(metric)
  const hasFilterApplied = useHasFilterApplied(metric)
  const hasPeriodApplied = useHasPeriodApplied(metric)
  return <Container
    $iswidthlimited={[hasGrowthApplied, hasFilterApplied, hasPeriodApplied].filter(condition => condition).length > 1 && !children ? 1 : 0}>
    <FlexDiv>
      {children}
      {hasFilterApplied && <IconContainer size={14}><FilterIcon/></IconContainer>}
      {hasPeriodApplied && <IconContainer size={14}><PeriodIcon/></IconContainer>}
      {hasGrowthApplied && <IconContainer size={14}><GrowthIcon/></IconContainer>}
    </FlexDiv>
  </Container>
})

export default MetricDisplay

const Container = styled.div<{ $iswidthlimited: number }>`
    display: flex;
    ${({$iswidthlimited}) => $iswidthlimited ? 'width: 38px;' : ''}
    overflow: hidden;
    flex-wrap: wrap;
    height: 100%;
`

const FlexDiv = styled.div`
    display: flex;
    gap: 3px;
    height: 100%;
`
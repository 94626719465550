import React, {memo, useCallback} from 'react'
import SortSelectorSelect from "components/forms/selector/sorts/SortSelectorSelect"
import {isEmpty} from "commons/object.utils"
import {ConfOrderBy} from "types/widgets"
import SortableOverlay from "components/common/sortable/SortableOverlay"
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable"
import SortableItem from "components/common/sortable/SortableItem"
import SortableContainerContext, {getIdFromIndex, getIndexFromID} from "components/common/sortable/SortableContainerContext"
import styled from "styled-components"
import {GroupedOptions} from "components/forms/chart/types"
import {getSortOptionByIndex} from "components/forms/chart/utils"

export const dateOption = {label: "Date"}

interface Props {
  value?: ConfOrderBy[]
  onChange?: (sort: ConfOrderBy[]) => void
  placeholder: string,
  handleDelete: (index: number) => void
  handleChange: (index: number, newValue: ConfOrderBy) => void
  groupedOptions: GroupedOptions
}

const MultipleSortSelector = memo<Props>(function MultipleSortSelector({
                                                                         value,
                                                                         onChange,
                                                                         placeholder,
                                                                         handleDelete,
                                                                         handleChange,
                                                                         groupedOptions,
                                                                       }) {
  const handleMove = useCallback((orderBys: ConfOrderBy[], oldIndex: number, newIndex: number) => {
      const newValue = orderBys

      newValue[oldIndex].isDefault = false
      newValue[newIndex].isDefault = false
      onChange?.(orderBys)

    },
    [onChange],
  )

  const addOrderBy = useCallback((orderByIndex: unknown) => {
    if (orderByIndex === undefined) {
      return
    }
    const currentValue = value ?? []
    const option = getSortOptionByIndex(groupedOptions, Number(orderByIndex))

    onChange?.([
      ...currentValue.map(s => ({...s, isDefault: false})),
      {
        asc: false,
        column: option?.value ?? 0,
        isDefault: false,
        value: option?.label,
        id: option?.id,
      },
    ])
  }, [value, groupedOptions, onChange])

  const onSelect = useCallback((orderByIndex: number, index: number) => {
    const option = getSortOptionByIndex(groupedOptions, Number(orderByIndex))

    handleChange(index, {
      asc: false,
      column: option?.value ?? 0,
      isDefault: false,
      value: option?.label,
      id: option?.id,
    })
  }, [groupedOptions, handleChange])

  const onSelectorChange = useCallback((index: number, orderByIndex?: number) => {
    if (isEmpty(orderByIndex)) {
      handleDelete(index)
    }
  }, [handleDelete])

  const onDirectionChange = useCallback((asc: boolean, index: number) => {
    if (value) {
      handleChange(index, {
        ...value[index],
        asc,
      })
    }
  }, [handleChange, value])

  return <>
    {value && <SortableContainerContext
      value={value}
      handleChange={handleMove}
      overlayRender={(activeId) => <SortableOverlay activeId={activeId}>
        <SortSelectorSelect {...{
          id: activeId,
          groupedOptions,
          value: value?.[getIndexFromID(activeId)],
          allowClear: true,
          placeholder,
        }} />
      </SortableOverlay>}>
      <SortableContext items={value?.map((v, index) => ({
        ...v,
        id: getIdFromIndex(index),
      })) ?? []} strategy={verticalListSortingStrategy}>
        {value?.map((valueItem, index) => (
          <SortableItem id={getIdFromIndex(index)} key={`${valueItem.column}-${index}`}>
            <SortSelectorSelect key={`${valueItem.column}-${index}`} {...{
              id: getIdFromIndex(index),
              groupedOptions,
              value: valueItem,
              allowClear: true,
              placeholder,
              onChange: (orderByIndex) => onSelectorChange(index, orderByIndex as number),
              onSelect: (orderByIndex: number) => onSelect(orderByIndex, index),
              onDirectionChange: (asc: boolean) => onDirectionChange(asc, index),
              isDefault: valueItem.isDefault,
            }} />
          </SortableItem>
        ))}
      </SortableContext>
    </SortableContainerContext>}
    <SortSelectorContainer>
      <SortSelectorSelect {...{
        groupedOptions,
        placeholder,
        id: value?.length ?? 0,
        value: undefined,
        onChange: addOrderBy,
        handleChange,
      }} />
    </SortSelectorContainer>
  </>
})

export default MultipleSortSelector

const SortSelectorContainer = styled.div`
  width: ${100 - (100 / 24) - 3}%;
  margin-left: ${100 / 24}%;
  `
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react'
import styled, {createGlobalStyle} from "styled-components"
import PresetSelector from "./PresetSelector"
import {ConfigProvider, DatePicker, Space} from 'antd'
import Language from "language"
import {getResolvedFromPeriod, SimpleTimeIntervalWithSourceResolved} from "commons/period/periodsList"
import {CalendarIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {DateInterval} from "redux/models/currentDashboard"
import {Dayjs} from "dayjs"
import {Category, PeriodType, PeriodTypes} from "types/period.types"
import frFR from "antd/locale/fr_FR"
import enUS from "antd/locale/en_US"


interface Props {
  value: DateInterval
  onChange?: (newValue: Omit<SimpleTimeIntervalWithSourceResolved, 'name'>) => void,
  readOnly?: boolean
}

const IntervalSelector: FunctionComponent<Props> = ({value, onChange, readOnly = false}) => {
  const [open, setOpen] = useState(false)
  const refRangePicker = useRef<any>()
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])
  const handleDatePickerOpenChange = setOpen
  const handleChange = useCallback(
    (v: Omit<SimpleTimeIntervalWithSourceResolved, 'name'>) => {
      setOpen(false)
      onChange?.(v)
    },
    [onChange],
  )
  const handlePresetLibClick = useCallback(
    () => {
      setOpen(!open)
    },
    [open, setOpen],
  )
  const handleDatePickerChange = useCallback(
    ([newStart, newEnd]: [Dayjs, Dayjs]) => handleChange({
      start: newStart, end: newEnd, period: {
        id: -1,
        code: "",
        nameFR: "",
        nameEN: "",
        category: Category.DAY,
        type: PeriodType.fixed,
        start: newStart.format(),
        end: newEnd.format(),
        position: 1,
      },
    }),
    [handleChange],
  )
  const handlePresetMouseEnter = useCallback(
    (newPeriod: PeriodTypes | null) => {
      const newInterval = newPeriod ? getResolvedFromPeriod(newPeriod) : null
      if (newInterval) {
        setLocalValue(dateInterval => ({
          ...dateInterval,
          start: newInterval.start,
          end: newInterval.end,
        }))
      }
    },
    [],
  )
  const handlePresetMouseLeave = useCallback(
    () => {
      setLocalValue(value)
    }, [value],
  )
  const handlePresetSelect = useCallback(
    (newPeriod: PeriodTypes) => {
      const interval = newPeriod ? getResolvedFromPeriod(newPeriod) : null
      if (interval) {
        handleChange(interval)
      }
    },
    [handleChange],
  )

  const handleRangeExtraFooter = useCallback(
    () => <PresetSelector {...{
      value: value.period,
      onMouseEnter: handlePresetMouseEnter,
      onMouseLeave: handlePresetMouseLeave,
      onSelect: handlePresetSelect,
    }}/>,
    [value, handlePresetMouseEnter, handlePresetMouseLeave, handlePresetSelect],
  )
  const presetName = Language.getTranslatedName(value.period) || Language.get('timePreset.custom')
  return <Container>
    <StyledInputGroup className="ant-input biron-calendar-interval-selector" $readonly={readOnly ? 1 : 0}>
    <GlobalStyle/>
    <CalendarIconContainer>
      <StyledIconContainer color={"var(--light-grey)"}><CalendarIcon/></StyledIconContainer>
    </CalendarIconContainer>
      <RangePickerContainer>
        <ConfigProvider locale={Language.getLabel() === "fr" ? frFR : enUS}>
          <DatePicker.RangePicker {...{
            ref: refRangePicker,
            open: readOnly ? false : open,
            onOpenChange: handleDatePickerOpenChange,
            separator: '➞',
            renderExtraFooter: handleRangeExtraFooter,
            allowClear: false,
            value: [localValue.start, localValue.end],
            onChange: (dates) => {
              if (dates && dates.length >= 2 && dates[0] && dates[1]) {
                handleDatePickerChange([dates[0], dates[1]])
              }
            },
            suffixIcon: <></>,
            format: Language.getLabel() === "fr" ? "DD MMM YYYY" : "MMM DD, YYYY",
            order: true,
          }} />
        </ConfigProvider>
      </RangePickerContainer>
    {presetName && <PresetContainer>
      <StyledSpan className="preset-lib" onClick={handlePresetLibClick}>{presetName}</StyledSpan>
    </PresetContainer>}
  </StyledInputGroup>
  </Container>
}

export default IntervalSelector

const RangePickerContainer = styled.div`
    border-right: 1px solid var(--main-background-color) !important;
`

const Container = styled.div`
    border: none;
    .ant-picker-outlined {
        border: none;
    }
`

const CalendarIconContainer = styled.div`
  @media (max-width: 550px) {
    display: none;
  }
`

const StyledIconContainer = styled(IconContainer)`
  color: var(--light-grey);
`

const PresetContainer = styled.div`
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
`

const StyledSpan = styled.span`
  @media (max-width: 1250px) {
    display: none;
  }
`

const StyledInputGroup = styled(Space.Compact)<{ $readonly: number }>`
  width: auto;
    ${({$readonly}) => $readonly ? '' : 'cursor: pointer;'}
  display: flex !important;
  align-items: center;
  padding-left: 10px;
  border: 1px solid var(--border-color-base);
    background-color: white;

    &&& {
    color: var(--dark-text) !important;
  }

  &:hover {
    border-color: var(--border-color-base);
  }

  &:hover {
    * {
        ${({$readonly}) => $readonly ? '' : `
        color: var(--primary) !important;
        transition: none !important;
        `}
    }
  }


  .preset-lib {
    width: auto;
    font-weight: normal;
    margin-right: 10px;
    margin-left: 15px;
  }
`

const GlobalStyle: any = createGlobalStyle`

`

import React, {useCallback} from "react"
import {Select} from 'antd'
import styled from "styled-components"
import {ViewsWithMetrics, ViewWithMetrics} from "types/viewsWithMetrics"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"

interface Props {
  value?: SingleMetricSelectorValue
  canRemove: boolean
  onChange: (newValue: SingleMetricSelectorValue | null) => void
  viewsWithMetrics: ViewsWithMetrics
  placeholder: string
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
}

const MetricSelectorSelect = ({value, canRemove, onChange, viewsWithMetrics, ...props}: Props) => {
  const view: ViewWithMetrics | undefined = viewsWithMetrics[0]
  const handleMetricChange = useCallback(
    (metricCode: string | undefined) => {
      onChange(metricCode ? {
        metricCode,
        viewCode: view?.code,
        viewAlias: view?.alias,
        additionalFilters: [],
        titlePartOverrides: {},
      } : null)
    },
    [onChange, view],
  )
  return <StyledSelect<any> {...{
    ...props,
    showSearch: true,
    allowClear: canRemove,
    optionFilterProp: "children",
    value: value?.metricCode ?? null,
    onChange: handleMetricChange,
    virtual: false,
  }}>
    {view?.metrics.map(({alias, code}) => <Select.Option key={code}>{alias}</Select.Option>)}
  </StyledSelect>
}

export default MetricSelectorSelect

const StyledSelect = styled(Select)`
  width: 100%;
`

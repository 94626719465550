import React, {memo, useMemo} from 'react'
import {Col, Row} from 'antd'
import SimpleSelect, {BaseOption} from "./SimpleSelect"

interface Props {
  disabled?: boolean
  name: string
  options: BaseOption[]
  value?: string
  onChange?: (newValue: string) => void
  showSearch?: boolean
  defaultOption?: string
  span?: number
}

const BironSelect = ({value: originalValue, defaultOption, span = 14, ...props}: Props) => {
  const value = useMemo(() => {
    if (!originalValue && defaultOption) {
      return defaultOption
    } else {
      return originalValue
    }
  }, [defaultOption, originalValue])

  return <Row>
    <Col span={span}>
      <SimpleSelect {...{
        style: {width: '100%'},
        ...props,
        value,
      }} />
    </Col>
  </Row>
}

export default memo(BironSelect) as typeof BironSelect

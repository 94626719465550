import React, {memo} from "react"
import styled from "styled-components"

interface ColorizedTagProps {
  text: string
  color: string
}

// eslint-disable-next-line react/display-name
const ColorizedTag = memo<ColorizedTagProps>(({text, color}) => {
  return <div style={{
    minWidth: "calc(100%)",
    padding: "0px 12px",
    backgroundColor: color,
    borderRadius: "6px",
  }}><StyledContainer style={{
  }}>
    {text}
  </StyledContainer>
  </div>
})

export default ColorizedTag

const StyledContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  text-align: center;
`
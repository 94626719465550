import MdView from "components/mdView/MdView"
import React, {memo, ReactNode, useCallback, useMemo} from "react"
import styled from "styled-components"
import {isEmpty} from "commons/object.utils"
import {concatInformationLinkToText} from "components/forms/description/utils"
import {TooltipAlias, TooltipContainer, TooltipContentElement, TooltipText} from "themes/SharedStyledComponent"
import {IconContainer} from "components/common/IconContainer"
import {useDataDocViewLink} from "components/dataSourceDoc/DataSource.hooks"

interface TooltipProps {
  Icon: any
  title: string
  description?: string | ReactNode
  code?: string
  nature?: "dimension" | 'date' | "other" | "view"
}

// eslint-disable-next-line react/display-name
export const TooltipContent = memo<TooltipProps>(({
                                                    Icon,
                                                    title,
                                                    description,
                                                    code,
                                                    nature = "other",
                                                  }) => {
  const viewLink = useDataDocViewLink(code)

  const getConsolidatedDescription = useCallback((stringDescription?: string) => {
    switch (nature) {
      case "view":
        return concatInformationLinkToText(stringDescription, viewLink)
      default:
        return stringDescription
    }
  }, [nature, viewLink])

  const descriptionContent = useMemo(() => {
    if (isEmpty(description)) {
      return getConsolidatedDescription()
    }
    if (typeof description === "string") {
      if (description.trim() === "") {
        return undefined
      } else {
        return <MdView description={getConsolidatedDescription(description)} padding={0}/>
      }
    }
    return <StyledDiv>{description}</StyledDiv>
  }, [description, getConsolidatedDescription])

  return <TooltipContainer>
    <TooltipAlias translate="no"><IconContainer size={22}><Icon/></IconContainer>{title}</TooltipAlias>
    <TooltipContentElement>
      <TooltipText>
        {descriptionContent}
      </TooltipText>
    </TooltipContentElement>
  </TooltipContainer>
})


const StyledDiv = styled.div`
  line-height: 20px;
  `
/* eslint-disable max-lines */
import React, {memo, useCallback, useMemo} from 'react'
import {Dropdown} from 'antd'
import styled from "styled-components"
import {ChevronDownIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {useCurrentIcon} from "components/forms/selector/comps/box/utils"
import Language from "language"
import {ConfFilter, FilterNegationType, filterTypeToIcon} from "components/forms/selector/comps/box/filters"

export interface FilterTypeDropDownProps {
  filter: ConfFilter
  handleFilterChange: (filter: ConfFilter) => void
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
}

const FilterTypeDropDown = memo<FilterTypeDropDownProps>(function Box({
                                                                        filter,
                                                                        handleFilterChange,
                                                                        getPopupContainer,
                                                                      }) {
  const CurrentIcon = useCurrentIcon(filter)
  const handleMenuClick = useCallback((e: { key: string }) => {
    handleFilterChange(Object.assign({}, {
      ...filter,
      predicate: {
        ...filter.predicate,
        negate: e.key === FilterNegationType.negate,
      },
    } as ConfFilter))
  }, [filter, handleFilterChange])

  const menu = useMemo(() => {
    const menuItems: any[] = []

    filterTypeToIcon.forEach((FilterTypeIcon, filterType) => {
      menuItems.push({
        key: filterType,
        label: Language.get(`filterType.${filterType}`),
        icon: <IconContainer><FilterTypeIcon/></IconContainer>,
      })
    })
    return menuItems
  }, [])

  return <StyledDropdown menu={{items: menu, onClick: handleMenuClick}} trigger={['click']} getPopupContainer={getPopupContainer}
                         overlayClassName={"filter-type"}>
    <StyledButton>
      <IconContainer size={16}>
        <CurrentIcon/>
      </IconContainer>
      <IconContainer size={16}>
        <ChevronDownIcon/>
      </IconContainer>
    </StyledButton>
  </StyledDropdown>
})

export default FilterTypeDropDown

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

const StyledButton = styled.div`
  height: 100%;
  gap: 5px;
  color: var(--light-grey);

  &:hover {
    color: var(--primary);
  }
`

/* eslint-disable max-lines */
import MetricDescription from "components/forms/description/MetricDescription"
import {Col, Popover, Row} from "antd"
import {IconContainer} from "components/common/IconContainer"
import {CalendarIcon, CogIcon, FilterIcon} from "@heroicons/react/outline"
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import React, {memo, useCallback, useMemo} from "react"
import {getIndexFromID} from "components/common/sortable/SortableContainerContext"
import Language from "language"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import {ChartTypeWithDisabledReason, MetricTarget} from "components/forms/chart/types"
import {GenericChartTypes} from "types/widgets"
import {DataSelection} from "redux/models/currentDashboard"
import {PeriodTypes} from "types/period.types"
import MetaModel, {Dimension} from "classes/MetaModel"
import styled from "styled-components"
import {useDayDifference} from "components/forms/chart/useFixHooks"
import {consolidateTimeInterval} from "commons/parsers/utils"
import CopyDuplicate from "components/copyDuplicate/CopyDuplicate"
import {EllipsisOutlined} from "@ant-design/icons"
import {DesktopComputerIcon, TrendingUpIcon} from "@heroicons/react/solid"
import {captureEvent} from "services/GoogleAnalyticsService"
import SubMenu from "themes/img/submenu.svg?react"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import MetricOptionsForm from "components/forms/selector/metrics/options/MetricOptionsForm"
import {ColWithLeftMargin} from "components/forms/selector/utils"
import {isUnitsAllowed} from "components/forms/selector/metrics/utils"

interface MetricOptionsSelectorProps {
  environmentId: number
  availableDimensions: Dimension[]
  id: number
  value?: MetricSelectorValue
  defaultView?: string
  canInvert: boolean
  canRemove: boolean
  onChange: (id: number, data: SingleMetricSelectorValue) => void
  onDelete?: (id: number) => void
  groupMetricsByView: boolean
  viewsWithMetrics: ViewsWithMetrics
  unavailableViews: ViewsWithMetrics
  optionsDisabled: boolean
  configuration?: ChartTypeWithDisabledReason
  numberOfSlicer?: number
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  isAxisOptionAvailable?: boolean
  isAxisOptionDisabled?: boolean
  displayType?: GenericChartTypes
  dashboardSelection: DataSelection
  period?: PeriodTypes
  metaModel: MetaModel
  activeKey: MetricOptionsFormKeys | undefined
  setActiveKey: (key: MetricOptionsFormKeys | undefined) => void
  handleDuplicate: () => void
  onCopy: () => void
  handlePaste: (pickedKeys: (keyof ConfMetricWithActivationState)[]) => void
  copiedMetric?: MetricSelectorValue
  isOptionsDisplayed: boolean
  setIsOptionsDisplayed: (state: boolean) => void
}

const getMetricTargetFormatted = (metricTarget: MetricTarget, nbSlicer?: number, nbMetric?: number) => {
  switch (metricTarget) {
    case MetricTarget.COLUMN:
      return `${Language.get(`configuration-metric-target.${MetricTarget.COLUMN}`)} ${nbSlicer && nbMetric ? nbSlicer + nbMetric : ''}`
    case MetricTarget.BAR:
      return `${Language.get(`configuration-metric-target.${MetricTarget.BAR}`)} ${nbMetric && !nbSlicer ? nbMetric : ''}`
    default:
      return Language.get(`configuration-metric-target.${metricTarget}`)
  }
}
// eslint-disable-next-line react/display-name
const MetricOptionsSelector = memo<MetricOptionsSelectorProps>(({
                                                                  id,
                                                                  environmentId,
                                                                  availableDimensions,
                                                                  value,
                                                                  canInvert,
                                                                  onChange,
                                                                  viewsWithMetrics,
                                                                  optionsDisabled,
                                                                  configuration,
                                                                  numberOfSlicer,
                                                                  getPopupContainer,
                                                                  isAxisOptionDisabled = false,
                                                                  displayType,
                                                                  dashboardSelection,
                                                                  period,
                                                                  metaModel,
                                                                  setActiveKey,
                                                                  isAxisOptionAvailable,
                                                                  activeKey,
                                                                  handleDuplicate,
                                                                  handlePaste,
                                                                  onCopy,
                                                                  copiedMetric,
                                                                  isOptionsDisplayed,
                                                                  setIsOptionsDisplayed,
                                                                }) => {

  const consolidatedTimeIntervalWithSource = useMemo(() => consolidateTimeInterval({
    dashboardSelection,
    chartPeriod: period,
  }, undefined), [dashboardSelection, period])
  const dayDifference = useDayDifference(consolidatedTimeIntervalWithSource)

  const onValuesChange = useCallback((newValue: Partial<SingleMetricSelectorValue>) => {
    if (value) {
      onChange(getIndexFromID(id), {
        ...value,
        ...newValue,
      })
    }
    setIsOptionsDisplayed(false)
  }, [id, onChange, setIsOptionsDisplayed, value])

  const handleDisplayOnOwnAxis = useCallback(() => {
    if (value && !isAxisOptionDisabled) {
      onChange(getIndexFromID(id), {
        ...value,
        extraConf: {
          ...value?.extraConf,
          isDisplayedOnSecondaryAxis: !value?.extraConf?.isDisplayedOnSecondaryAxis,
        },
      })
    }
  }, [id, isAxisOptionDisabled, onChange, value])

  const axisButtonHelp = useMemo(() => {
    if (isAxisOptionDisabled) {
      return Language.get("configuration-chart-cannot-modify-axis")
    }
    return value?.extraConf && value?.extraConf.isDisplayedOnSecondaryAxis ? Language.get("configuration-chart-set-to-first-axis") : Language.get("configuration-chart-set-to-second-axis")
  }, [isAxisOptionDisabled, value?.extraConf])

  const SecondaryAxisIcon = useMemo(() => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 17.8161L9.17284 14.2695M6.8823 16.8299L10.0551 13.2833" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M12.9449 14.2674L16.1177 10.7209M13.8272 13.2812L17 9.73467" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M10.0551 13.2833L12.3161 14.0533M10.6839 13.4974L12.9449 14.2674" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path
      d="M20.48 6.36C20.24 6.68 19.76 6.68 19.52 6.36L17.72 3.96C17.4233 3.56446 17.7056 3 18.2 3L21.8 3C22.2944 3 22.5767 3.56446 22.28 3.96L20.48 6.36Z"
      fill="currentColor"/>
    <path d="M2 21H16.8661M6.13393 21H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M20 9L20 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>, [])

  const PrimaryAxisIcon = useMemo(() => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 17.8161L9.17284 14.2695M6.8823 16.8299L10.0551 13.2833" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M12.9449 14.2674L16.1177 10.7209M13.8272 13.2812L17 9.73467" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M10.0551 13.2833L12.3161 14.0533M10.6839 13.4974L12.9449 14.2674" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M2 21H16.8661M6.13393 21H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path
      d="M3.48 6.36C3.24 6.68 2.76 6.68 2.52 6.36L0.72 3.96C0.423344 3.56446 0.705573 3 1.2 3L4.8 3C5.29443 3 5.57666 3.56446 5.28 3.96L3.48 6.36Z"
      fill="currentColor"/>
    <path d="M3 9L3 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>, [])

  const description = value && viewsWithMetrics.find(({code}) => code === value.viewCode)?.metrics?.find(({code}) => code === value.metricCode)?.description

  const pasteFiltersEnabled = useMemo(() => copiedMetric === undefined
    || copiedMetric?.additionalFilters.length === 0
    || copiedMetric?.additionalFilters.every(filter => availableDimensions.find(dimension => dimension.code === filter.dimensionCode)) ? 1 : 0
    , [availableDimensions, copiedMetric])

  return <StyledRow><StyledCol $value={5} span={2}>
    {description && <MetricDescription metric={value} timeInterval={consolidatedTimeIntervalWithSource}/>}
  </StyledCol>
    {!optionsDisabled && <><ClickableCol span={2} title={Language.get("configuration-metric-options.info.options")}>
      {value ? <Popover
        trigger={"click"}
        open={isOptionsDisplayed}
        destroyTooltipOnHide={true}
        onOpenChange={(state) => {
          setIsOptionsDisplayed(state)
          setActiveKey(MetricOptionsFormKeys.filters)
        }}
        overlayInnerStyle={{
          backgroundColor: "var(--popup-background)",
        }}
        overlayClassName={"metric-options-popup"}
        arrow={{pointAtCenter: true}}
        align={{
          points: ['tr', 'br'],
          offset: [328, 0],
          targetOffset: [0, 0],
        }}
        getPopupContainer={getPopupContainer}
        content={<MetricOptionsForm {...{
          environmentId,
          availableDimensions,
          id,
          value,
          configuration,
          onValuesChange,
          getPopupContainer,
          dashboardSelection,
          displayType,
          canInvert,
          dayDifference,
          period,
          activeKey,
          metaModel,
          onCancel: () => {
            setIsOptionsDisplayed(false)
          },
        }} />}
      >
        <IconContainer clickable={true}>
          <CogIcon/>
        </IconContainer>
      </Popover> : <></>}
    </ClickableCol>
      <ClickableCol span={2} title={Language.get("configuration-metric-options.info.paste/duplicate")}>
        <CopyDuplicate handleDuplicate={handleDuplicate} onCopy={onCopy}/>
      </ClickableCol>
      <ColWithLeftMargin $value={5} span={2} title={Language.get("configuration-metric-options.info.more")}>
        <Popover trigger="click"
                 placement={"rightTop"}
                 overlayClassName={"paste-options"}
                 content={<List>
                   <ListOption onClick={() => {
                     captureEvent({
                       category: 'metric-options',
                       action: `paste-options`,
                     })
                     handlePaste(["decimals", "details", "units", "color", "overridePeriod", "overridePeriodLabel", "additionalFilters", "additionalFiltersLabel", "growthType", "growthPeriod", "growthInvert", "growthLabel"])
                   }}>
                     <IconContainer clickable={true}><CogIcon/></IconContainer>
                     {Language.get('configuration-metric-options.paste.metric-options')}
                   </ListOption>
                   <ListOption $margin={8} $enabled={pasteFiltersEnabled} onClick={() => {
                     captureEvent({
                       category: 'metric-options',
                       action: 'paste-filters',
                     })
                     handlePaste(["additionalFilters", "additionalFiltersLabel"])
                   }}>
                     <IconContainer size={12} color={"var(--medium-grey)"}><SubMenu/></IconContainer>
                     <IconContainer clickable={pasteFiltersEnabled === 1}><FilterIcon/></IconContainer>
                     {Language.get('configuration-metric-options.paste.filters')}
                   </ListOption>
                   <ListOption $margin={8} onClick={() => {
                     captureEvent({
                       category: 'metric-options',
                       action: 'paste-period',
                     })
                     handlePaste(["overridePeriod", "overridePeriodLabel"])
                   }}>
                     <IconContainer size={12} color={"var(--medium-grey)"}><SubMenu/></IconContainer>
                     <IconContainer clickable={true}><CalendarIcon/></IconContainer>
                     {Language.get('configuration-metric-options.paste.period')}
                   </ListOption>
                   <ListOption $margin={8} $enabled={value?.metricDef?.asRatio ? 0 : 1} onClick={() => {
                     captureEvent({
                       category: 'metric-options',
                       action: 'paste-growth',
                     })
                     handlePaste(["growthType", "growthPeriod", "growthInvert", "growthLabel"])
                   }}>
                     <IconContainer size={12} color={"var(--medium-grey)"}><SubMenu/></IconContainer>
                     <IconContainer clickable={true}><TrendingUpIcon/></IconContainer>
                     {Language.get('configuration-metric-options.paste.growth')}
                   </ListOption>
                   <ListOption $margin={8} onClick={() => {
                     captureEvent({
                       category: 'metric-options',
                       action: 'paste-display',
                     })
                     handlePaste([
                       "color",
                       "decimals",
                       "details",
                       ...((value && isUnitsAllowed(value)) ? (["units"] as (keyof ConfMetricWithActivationState)[]) : []),
                     ])
                   }}>
                     <IconContainer size={12} color={"var(--medium-grey)"}><SubMenu/></IconContainer>
                     <IconContainer clickable={true}><DesktopComputerIcon/></IconContainer>
                     {Language.get('configuration-metric-options.paste.display')}
                   </ListOption>
                 </List>}>
          <IconContainer clickable={true}>
            <EllipsisOutlined/>
          </IconContainer>
        </Popover>
      </ColWithLeftMargin>
    </>
    }
    {isAxisOptionAvailable && <StyledCol $value={7} span={2}>
      <TooltipPopover
        size={'small'} mouseEnterDelay={0.5} content={axisButtonHelp}>
        <ColoredDiv $defaultvalue={value?.extraConfDefaultProperties?.isDisplayedOnSecondaryAxis ? 1 : 0}>
          <IconContainer onClick={handleDisplayOnOwnAxis} disable={isAxisOptionDisabled}
                         color={value?.extraConfDefaultProperties?.isDisplayedOnSecondaryAxis === false || value?.extraConf?.isDisplayedOnSecondaryAxis ? 'var(--primary)' : undefined}
                         clickable={true}>{value?.extraConf?.isDisplayedOnSecondaryAxis ? SecondaryAxisIcon : PrimaryAxisIcon}</IconContainer>
        </ColoredDiv>
      </TooltipPopover>
    </StyledCol>}
    <ColWithLeftMargin $value={5} span={8}>
      <TargetContainer>
        {configuration?.metricTarget ? getMetricTargetFormatted(configuration.metricTarget, numberOfSlicer, id) : ""}
      </TargetContainer>
    </ColWithLeftMargin></StyledRow>
})

export default MetricOptionsSelector

const StyledRow = styled(Row)`
    height: 100%;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 10px;
`

const ListOption = styled.div<{ $enabled?: number, $margin?: number }>`
    text-align: center;
    flex-basis: 100%;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    ${({$margin}) => $margin ? `margin-left: ${$margin}px;` : ''}
    ${({$enabled}) => $enabled === undefined || $enabled === 1 ? `
    &:hover {
        cursor: pointer;
        color: var(--primary);
    }
    ` : `
    opacity: 0.2;
    * {
      cursor: unset;
    }
    `}



`

const ColoredDiv = styled.div<{ $defaultvalue: number }>`
    margin-right: 5px;
    margin-left: 5px;
    padding-bottom: 3px;

    * {
        ${({$defaultvalue}) => $defaultvalue ? 'opacity: 0.85;' : ''}
    }
`

const TargetContainer = styled.div`
    color: var(--light-grey);
    height: 100%;
    display: flex;
    align-items: center;
`

const StyledCol = styled(ColWithLeftMargin)`
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 5px;
`

const ClickableCol = styled(Col)`
    display: flex;
    align-items: center;

    &:hover {
        color: var(--primary);
    }`
import {Checkbox} from "antd"
import React, {memo, useCallback} from "react"
import {isEmpty} from "lodash"
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import styled from "styled-components"
import GAInput from "components/GAInput/GAInput"

interface CustomDisplaySelectorProps {
  value?: string
  onChange?: (newState?: string) => void
  activated?: boolean
  defaultLabel: string | undefined
  gaevent?: {
    category: string
    action: string
  }
}

// eslint-disable-next-line react/display-name
const CustomDisplaySelector = memo<CustomDisplaySelectorProps>(({
                                                                  value,
                                                                  onChange,
                                                                  activated = true,
                                                                  defaultLabel,
                                                                  gaevent,
                                                                }) => {

  const handleSwitch = useCallback((event: CheckboxChangeEvent) => {
    if (event.target.checked && defaultLabel) {
      onChange?.(defaultLabel)
    } else {
      onChange?.(undefined)
    }
  }, [defaultLabel, onChange])

  return <FlexDiv>
    <Checkbox checked={!isEmpty(value)} onChange={handleSwitch}/>
    <GAInput value={value ?? defaultLabel}
             type={undefined}
             onChange={(event) => onChange?.(event.target.value)}
             disabled={!activated || !value}
             gaevent={gaevent}/>
  </FlexDiv>
})

export default CustomDisplaySelector

const FlexDiv = styled.div`
  display: flex;
  gap: 8px;
`
import {Col, Row} from "antd"
import Language from "language"
import {intervalToString, toInterval} from "commons/parsers/queries"
import React, {memo, useState} from "react"
import styled from "styled-components"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import GrowthIcon from 'themes/svg/outlined/growth.svg?react'
import {TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import {ContentCol, DeleteContainer, FlexMetricOptionRow} from "themes/SharedStyledComponent"
import {IconContainer} from "components/common/IconContainer"
import {XCircleIcon} from "@heroicons/react/solid"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import {getGrowthPeriodAsLabel, isMetricOptionResumeClickable} from "components/forms/selector/metrics/utils"

interface MetricGrowthResumeProps {
  value?: MetricSelectorValue
  setActiveKey?: (key: MetricOptionsFormKeys) => void
  onDelete?: (key: (keyof ConfMetricWithActivationState)[]) => void
  timeInterval?: TimeIntervalWithSource
  dayDifference: number
  iconSize: number
  iconSpan: number
}

// eslint-disable-next-line react/display-name
const MetricGrowthResume = memo<MetricGrowthResumeProps>(({
                                                            value,
                                                            setActiveKey,
                                                            onDelete,
                                                            timeInterval,
                                                            dayDifference,
                                                            iconSize,
                                                            iconSpan,
                                                          }) => {
  const [isDeleteDisplayed, setIsDeleteDisplayed] = useState(false)
  const growthPeriodAsLabel = getGrowthPeriodAsLabel(value?.growth?.period)

  const isClickable = isMetricOptionResumeClickable(setActiveKey)

  return <DivWithFullWidth>
    {value?.growth?.period && value.growth?.type && <GrowthResume>
      <GrowthPreview>
        <Row>
          <Col span={iconSpan} onClick={() => setActiveKey?.(MetricOptionsFormKeys.growth)}>
            <FlexMetricOptionRow $clickable={isClickable}>
              <IconContainer size={iconSize}>
                <GrowthIcon/>
              </IconContainer>
            </FlexMetricOptionRow>
          </Col>
          <ContentCol span={19} onClick={() => setActiveKey?.(MetricOptionsFormKeys.growth)}>
            <FlexMetricOptionRow $clickable={isClickable} onMouseOver={() => setIsDeleteDisplayed(true)}
                                 onMouseLeave={() => setIsDeleteDisplayed(false)}>
              <span>{`${Language.get(`growth.type-preset.${value?.growth?.type.toLowerCase()}`)} ${Language.get(`growth.value.${growthPeriodAsLabel}`, value.growth?.period.quantity)}`}</span>
              {timeInterval && <Interval>{intervalToString(toInterval(
                  value.growth?.period.unit,
                  value.growth?.period.quantity,
                  timeInterval,
                  dayDifference,
                ),
                'DD MMM YYYY',
              )}</Interval>}
              {isDeleteDisplayed && onDelete && <DeleteContainer>
                <IconContainer clickable={true} size={14} color={"var(--light-grey)"}>
                  <XCircleIcon onClick={() => onDelete(["growthLabel", "growthType", "growthPeriod", "growthInvert"])}/>
                </IconContainer>
              </DeleteContainer>}
            </FlexMetricOptionRow>
          </ContentCol>
        </Row>
      </GrowthPreview>
    </GrowthResume>}
  </DivWithFullWidth>
})

export default MetricGrowthResume

const Interval = styled.span`
  font-style: italic;
  font-weight: 400;
  line-height: 18px;

  color: #C6C6C6;
`

const GrowthPreview = styled.div`
  display: flex;
  flex-direction: column;
`

const DivWithFullWidth = styled.div`
  width: 100%;
  cursor: pointer;
`

const GrowthResume = styled.div`
  align-items: center;
  gap: 10px;
`
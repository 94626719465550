/* eslint-disable max-lines */
import React, {memo, PropsWithChildren} from 'react'
import {Col, Row} from 'antd'
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {GenericChartTypes} from "types/widgets"
import {DataSelection} from "redux/models/currentDashboard"
import {PeriodTypes} from "types/period.types"
import MetaModel from "classes/MetaModel"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import SingleMetricSelector from "components/forms/selector/metrics/SingleMetricSelector"
import PasteButton from "components/pasteButton/PasteButton"
import {ColWithLeftMargin} from "components/forms/selector/utils"
import {captureEvent} from "services/GoogleAnalyticsService"
import {useHandleAddMetricFromClipboard} from "hooks/useHandleAddMetricFromClipboard"
import {useSelector} from "react-redux"
import {getCopiedMetric} from "redux/clipboard.selector"
import PasteIcon from 'themes/svg/filled/paste.svg?react'
import Language from "language"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"

interface MetricAddProps extends PropsWithChildren {
  environmentId: number
  id: number
  value?: MetricSelectorValue
  canInvert: boolean
  canRemove: boolean
  handleAdd: (data: SingleMetricSelectorValue) => void
  onDelete?: (id: number) => void
  groupMetricsByView: boolean
  viewsWithMetrics: ViewsWithMetrics
  unavailableViews: ViewsWithMetrics
  growthDisabled?: boolean
  configuration?: ChartTypeWithDisabledReason
  numberOfSlicer?: number
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  isAxisOptionAvailable?: boolean
  isAxisOptionDisabled?: boolean
  displayType?: GenericChartTypes
  dashboardSelection: DataSelection
  period?: PeriodTypes
  metaModel: MetaModel
}


const MetricAdd = memo<MetricAddProps>(function MetricInputs({
                                                               id,
                                                               environmentId,
                                                               value,
                                                               canInvert,
                                                               canRemove,
                                                               handleAdd,
                                                               onDelete,
                                                               groupMetricsByView,
                                                               viewsWithMetrics,
                                                               unavailableViews,
                                                               growthDisabled,
                                                               configuration,
                                                               numberOfSlicer,
                                                               getPopupContainer,
                                                               isAxisOptionAvailable = false,
                                                               displayType,
                                                               dashboardSelection,
                                                               period,
                                                               metaModel,
                                                             }) {
  const copiedMetric = useSelector(getCopiedMetric())

  const handleMetricAddFromClipboard = useHandleAddMetricFromClipboard(
    copiedMetric,
    () => {
      if (copiedMetric) {
        handleAdd(copiedMetric)
      }
    },
  )

  return <Row gutter={[0, 5]} style={{width: "100%"}}>
    {/* select for metric */}
    <Col span={24} style={{maxHeight: 40}}>
      <Row>
        <Col span={13} offset={1}>
          <SingleMetricSelector
            {...{
              environmentId,
              id,
              value,
              groupMetricsByView,
              viewsWithMetrics,
              unavailableViews,
              growthDisabled,
              canInvert,
              canRemove,
              onChange: (_, val) => handleAdd(val),
              onDelete,
              configuration,
              numberOfSlicer,
              getPopupContainer,
              isAxisOptionAvailable,
              isAxisOptionDisabled: id === 0,
              displayType,
              metaModel,
              dashboardSelection,
              period,
            }}/>
        </Col>
        <ColWithLeftMargin $value={5} span={9} style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }} title={Language.get("configuration-metric-options.info.paste")}>
          <div>
            <PasteButton handleClick={() => {
              captureEvent({
                category: 'metrics',
                action: `add_metric_paste`,
              })
              handleMetricAddFromClipboard()
            }} style={{width: 16, height: 16, display: "flex", padding: 0}} size={16} Icon={PasteIcon} type={"text"}/>
          </div>
        </ColWithLeftMargin>
      </Row>
    </Col>
  </Row>
})

export default MetricAdd

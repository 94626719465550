import {useEffect} from "react"

const useFixValue = <T, >(onChange: ((newValue: T) => void) | undefined, originalValue: T | null | undefined, value: T | null | undefined) => {
  useEffect(() => {
      if (originalValue !== value && value) {
        onChange?.(value)
      }
    },
    [onChange, originalValue, value],
  )
}
export default useFixValue

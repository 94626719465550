/* eslint-disable max-lines */

import dayjs, {Dayjs} from 'dayjs'
import locale from 'dayjs/locale/fr'
import moment from 'moment'
// use the dist version of the locale to avoid the bundle of the umd version
// https://github.com/vitejs/vite/issues/3755#issuecomment-860548526
import 'moment/dist/locale/fr'
import {ColorName} from "components/charts/Chart.Theme"
import {MetricTarget, SlicerTarget, TargetAffect} from 'components/forms/chart/types'
import {Days} from "components/forms/Form.types"
import {User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {ChartDtoDetailTypes} from "types/charts"
import {FilterInputType} from "types/filter"
import {ChartSource, DataSourceObjectType, ObjectType} from "components/dataSourceDoc/DataSource.types"
import {FilterNegationType, FilterOperator} from "components/forms/selector/comps/box/filters"

export const configure = () => {
  dayjs.locale(locale)
  moment.locale('fr') // used only by antd
}

export default {
  'by': 'par',
  'by-default': 'Par défaut',
  'all': 'Tous',
  'axis': 'Axis',
  'day': 'jour',
  'chart': 'Chart',
  'preview': 'Prévisualiser le markdown',
  'date-slicer-option-label': 'Date de référence',
  'button-save': 'Save',
  'button-apply': 'Appliquer',
  'button-cancel': 'Annuler',
  'button-confirm': 'Confirmer',
  'button-search': 'Chercher',
  'button-reset': 'Reinitialiser',
  'button-edit': 'Modifier',
  'button-yes': 'Oui',
  'button-no': 'Non',
  'search': 'Chercher',
  'reset': 'Reinitialiser',
  'no-data': 'Pas de données',
  'no-description': 'Pas de description',
  'authentication': {
    'signin': 'Connection en cours...',
    'signout': 'Déconnexion en cours...',
    'redirect': 'Redirection vers la page de connexion...',
  },
  'granularity': 'Granularité',
  'calendar-granularity-day': 'Jour',
  'calendar-granularity-week': 'Semaine',
  'calendar-granularity-month': 'Mois',
  'calendar-granularity-quarter': 'Trimestre',
  'calendar-granularity-year': 'Année',
  'chart-definition-button': 'Description',
  'chart-copy-button': 'Copier (simple clic) / Dupliquer en dessous (double-clic)',
  'chart-download-button': 'Télécharger les données',
  'chart-download-data-button': 'Télécharger les données',
  'chart-download-png-button': "Télécharger le chart",
  'chart-link-button': 'Copier le lien',
  'chart-link-button-clicked': 'Lien copié dans le presse-papiers',
  'chart-remove-button': 'Supprimer le chart',
  'chart-definition': 'Description du chart',
  'chart-definition-period': 'Période',
  'chart-definition-from-chart': 'en provenance de la configuration du chart',
  'chart-definition-from-dashboard': 'en provenance de la configuration du dashboard',
  'chart-definition-period-from-dashboard': 'Période du dashboard',
  'chart-definition-granularity-from-dashboard': 'Granularité du dashboard',
  'chart-definition-sort': 'Tri',
  'chart-definition-slicers': 'Slicers',
  'chart-definition-metrics': 'Métriques',
  'chart-definition-filters': {
    "title": "Filtres",
    "conditions": {
      'and': 'ET',
      'or': 'OU',
    },
    "type": {
      "included": "Inclus",
      "not-included": "Non inclus",
    },
  },
  'chart-message-concurrent-query': 'La résolution de cette requête a été interrompue par le chargement concurrent du même chart',
  'chart-message-query-too-complex': 'La résolution de cette requête a été interrompue parce qu\'elle est trop complexe ou l\'intervalle de temps est trop grand.',
  'chart-message-query-error': 'Une erreur inattendue s\'est produite.',
  'chart-message-query-memory-exceed': 'La résolution de cette requête demande trop de mémoire',
  'chart-message-invalid-conf': 'La configuration est erronée.',
  'chart-others-series': 'Autres',
  'chart-growth-d-1': (quantity: number) => `J-${quantity}`,
  'chart-growth-w-1': (quantity: number) => `S-${quantity}`,
  'chart-growth-m-1': (quantity: number) => `M-${quantity}`,
  'chart-growth-q-1': (quantity: number) => `T-${quantity}`,
  'chart-growth-s-1': "Semestre-1",
  'chart-growth-y-1': (quantity: number) => `A-${quantity}`,
  'chart-growth-d-30': 'J-30',
  'chart-growth-translated': 'Translatée',
  'click-to-configure-chart': 'cliquez pour configurer le chart',
  'configuration': 'Configuration',
  'configuration-add-filter': (isFirstLetterUpperCase = false) => `${isFirstLetterUpperCase ? "A" : "a"}jouter un filtre`,
  'configuration-search-filter': (isFirstLetterUpperCase = false) => `${isFirstLetterUpperCase ? "R" : "r"}echercher un filtre`,
  'configuration-add-value': 'ajouter une valeur',
  'configuration-search-value': 'rechercher une valeur',
  'configuration-add-metric': 'ajouter une métrique',
  'configuration-add-slicer': 'ajouter un slicer',
  'configuration-add-sort': 'ajouter un tri',
  'configuration-no-sort-selected': 'Pas de tri',
  'configuration-sort-types': {
    'asc': 'Croissant',
    'desc': 'Décroissant',
  },
  'configuration-add-target': 'ajouter un objectif',
  'configuration-see-definitions': 'Voir les définitions',
  'configuration-definitions-more-informations': 'Plus d\'informations',
  'configuration-hide-definitions': 'Masquer les definitions',
  'configuration-footer-help': () => `**Besoin d'aide ?** Consultez le [guide d'utilisation](${window.location.origin}/delegate/kb){:target="_blank"} et la [liste des données disponibles](./help){:target="_blank"}`,
  'configuration-label-portlet-title': 'Titre',
  'configuration-label-portlet-size': 'Taille',
  'configuration-label-portlet-title-class': 'Décoration',
  'configuration-label-color': 'Couleur',
  'configuration-label-text': 'Texte',
  'configuration-label-view': 'Vue',
  'configuration-label-slicers': 'Slicers',
  'configuration-label-slicers-single': 'Slicer',
  'configuration-label-metrics-filters': 'Filtres Résultats',
  'configuration-label-filters': 'Filtres',
  'configuration-label-metrics': 'Métriques',
  'configuration-label-orderBys': 'Tris',
  'configuration-label-date': 'Date',
  'configuration-label-dimensions': 'Dimensions',
  'configuration-label-metrics-single': 'Métrique',
  'configuration-label-period': 'Période',
  'configuration-label-targets': 'Configuration',
  'configuration-label-options': 'Options',
  'configuration-label-limits': 'Limites',
  'configuration-label-destination': 'Destination',
  'configuration-label-days': 'Jour(s) d\'exécution',
  'configuration-label-override': 'Ecraser le fichier',
  'configuration-description-view': `**Une vue (ou Une source de donnée)** est une **compilation d'informations sur un objet.** Les sources de données portent des noms explicites dans Biron qui se rapportent à l'objet manipulé : "**commandes**", "**produits vendus**", "**clients**", etc.  Cette notion est importante, car pour construire un tableau de bord il faudra d'abord choisir la bonne source de donnée.\n\n Pour voir la liste des vues disponibles, vous pouver consulter la [liste des données disponibles](./help){:target="_blank"}.`,
  'configuration-description-metrics': `**Une métrique** est une grandeur que l'on **dénombre** ou que l'on **additionne**. On peut par exemple compter le **nombre de clients actifs** ou compter le **chiffre d'affaires** généré sur une période. Ce sont deux métriques distinctes. \n\nA titre d'exemple, pour afficher le chiffre d'affaires, on utilisera la source de donnée "Commandes" et la métrique "CA TTC". Pour afficher le délai moyen de livraison on utilisera la source de donnée "Commandes" et la métrique "Délai de livraison".\n\n Pour voir la liste des métriques disponibles, vous pouver consulter la [liste des données disponibles](./help){:target="_blank"}.`,
  'configuration-description-slicer': `**Un slicer** est un angle de découpage des objets manipulés. Si l'ensemble des commandes peuvent être réparties entre les commandes de France, les commandes d'Allemagne et les commandes de Belgique, alors le "**Pays de la commande**" **est un slicer**. \n\nDe la même manière on peut découper les clients avec le slicer "**Anciens clients / nouveaux clients**"  ou découper les produits vendus par le slicer "**Tranche de prix**" : "0-10 euros", "10-20 euros", etc. \n\nLes données pourront être découpées via les slicers Référence / Univers / Couleur. On pourra également filtrer sur un de ces slicers. Par exemple choisir Univers = "Pantalon".\n\n Pour voir la liste des slicers, vous pouver consulter la [liste des données disponibles](./help){:target="_blank"}.`,
  'configuration-description-metrics-filters': `Les filtres de résultats permettent de limiter l’affichage de certaines données en fonction du résultat d’une métrique sur la granularité du chart. `,
  'configuration-description-filters': `Les filtres permettent de limiter l'affichage à certaines données (par exemple une marque, un pays, etc.).\n\n Pour voir la liste des dimensions disponibles, vous pouver consulter la [liste des données disponibles](./help){:target="_blank"}.`,
  'configuration-description-md': 'Ecrivez la description de votre divider.\n\n Supporte la syntaxe markdown pour une afficher une mise en page (voir [la documentation Mardown](https://docs.framasoft.org/fr/grav/markdown.html){:target="_blank"} pour plus d\'information)',
  'configuration-description-orderBys': 'Permets de trier par défaut sur une métrique/dimension ou par date si l\'option afficher une ligne par date est sélectionnée.',
  'configuration-chart-type-disable': {
    description: (reasons: string[]) => `Type indisponible car ${reasons.join(' et ')}`,
    reasons: {
      withMetricMultiple: "plusieurs métriques sont sélectionnées",
      withMetricAsRatio: "une métrique sélectionnée est un ratio",
      withMetricWithGrowthAsRatio: "une métrique sélectionnée a une croissance en pourcentage",
      withSlicer: "un slicer est sélectionné",
      withSlicerMultiple: "plusieurs slicers sont sélectionnés",
      withSlicerAsSimple: "un slicer sélectionnée à une trop grande cardinalité",
      withLessThanTwoMetric: "moins de deux metriques sont sélectionnées",
      withMetricUnique: "seulement une metrique est sélectionnée",
      withSlicerUnique: "seulement un slicer est sélectionné",
      withoutSlicer: "aucun slicer est sélectionné",
      withMoreThanTwoSlicers: "plus de deux slicers sont sélectionnés",
      withLessThanTwoSlicers: "moins de deux slicers sont sélectionnés",
      withFilter: "un filtre est sélectionné",
      withSlicerAndMetricMultiple: "un slicer et plusieurs metriques sont sélectionnés",
      withSlicerMultipleAndMetricMultiple: "plusieurs metriques et slicers sont sélectionnés",
      withMoreThanTwoSlicersAndMetricMultiple: "plus de deux slicers et plusieurs metriques sont sélectionnés",
      withMetricAndWithSlicer: "seulement une metric ou plusieurs slicers sont sélectionnés",
      withMultipleSlicerAndMetric: "plusieurs slicers ou metrics sont sélectionnés",
      withOnlyOneSlicer: "seulement un slicer est sélectionné",
      withSlicerMultipleWithoutDefaultSlicer: "plusieurs slicers hors valeur par default sont sélectionnés",
      withSlicerAsSimpleWithoutDefaultSlicer: "un slicer sélectionnée à une trop grande cardinalité",
      withMoreThanOneSlicer: "plus de deux slicers sont sélectionnés",
      withSlicerWithoutDefaultSlicer: "un slicer sélectionnée hormis la valeur par defaut",
      withMoreThanOneSlicerNotAnAxis: "plusieurs slicers hors la valeur d'axe sont sélectionnés",
      withMultipleMetricAndMultipleSlicers: "plusieurs métriques et plusieurs slicers sont sélectionnés en même temps",
    },
  },
  'configuration-chart-format-disable': {
    description: (reasons: string[]) => `Format indisponible car ${reasons.join(' et ')}`,
    reasons: {
      hasMultipleFormat: "les métriques sélectionnées ont des formats différents",
      notEnoughData: "il n'y a pas assez de slicer ou de métrique sélectionnés",
      withMetricAsRatio: "une métrique sélectionnée est un ratio",
    },
  },
  'configuration-chart-additional-detail': {
    asPercentage: {
      label: 'Afficher les pourcentages',
    },
    ignoreMetrics0: {
      label: 'Exclure les lignes à zéro',
      description: "<strong>Exclure les lignes à zéro</strong><br/>Permet d’exclure les lignes du tableau dont toutes les métriques valent 0",
    },
    withDateSlicer: {
      label: 'Afficher une ligne par date',
      description: "Par défaut, le graphique n'affichera pas les données par date. En sélectionnant cette option, le graphique affichera une ligne par date.",
    },
    sortSeries: {
      label: 'Trier les séries par ordre decroissant',
      description: 'Trier les séries par ordre decroissant (au lieu de ne pas les trier)',
    },
    limitSeries: {
      label: 'N\'afficher que ### series',
      shortDescription: `Les autres series seront affichées dans la série "Autres"`,
      description: `Pour gagner en lisibilité, vous pouvez limiter l'affichage à un nombre donné de séries. Le chart selectionnera les top X séries et les affichera.\n\nLes séries restantes seront regroupées sous **'Autres'**`,
    },
    displayLabels: {
      label: 'Afficher les labels des series',
      description: `Pour ajouter des details vous pouvez ajouter les labels sur les series, pour avoir les valeurs de celle-ci`,
    },
    hideOthers: {
      label: 'Cacher la série "Autres"',
      description: `Pour gagner en lisibilité, vous pouvez cacher la série "Autres"`,
    },
    ignoreSeasonality: {
      label: 'Ignorer la saisonalité',
      description: 'Comparer les periodes passées/restantes au lieu de prendre en compte la saisonalité de l\'année passée.',
    },
    printPrevisions: {
      label: 'Afficher les prévisions',
      description: 'Afficher les résultat attendus à la fin de la période (calculé selon le temps restant)',
    },
  },
  'configuration-chart-filter-description': {
    header: ({alias, description}: { alias?: string, description?: string }) => `<strong>${alias} :</strong> ${description}`,
    details: {
      empty: (alias: string) => `Aucune valeur de filtre sélectionnée, le filtre renverra toutes les données de <strong>${alias}</strong>`,
      filled: ({
                 filterType,
                 filterValue,
               }: { filterType: string, filterValue: string }) => `Affichera toutes les lignes où les données <strong>${filterType} :</strong> ${filterValue}`,
    },
    filterType: {
      "equal": "sont inclues dans",
      "different": "ne sont pas inclues dans",
    },
    operator: {
      "or": "ou",
    },
  },
  'configuration-metric-target': {
    [MetricTarget.TARGET]: 'Objectif',
    [MetricTarget.COLUMN]: 'Colonne',
    [MetricTarget.AREA]: 'Area',
    [MetricTarget.BAR]: 'Barre',
    [MetricTarget.PART]: 'Part',
    [MetricTarget.NUMBER]: 'Chiffre',
    [MetricTarget.LINE]: 'Ligne',
  },
  'configuration-slicer-target': {
    [SlicerTarget.AXE]: 'Axe horizontal',
    [SlicerTarget.COLOR]: 'Couleur',
    [SlicerTarget.COLUMN]: 'Colonne',
    [SlicerTarget.PART]: 'Part',
  },
  'configuration-affect': {
    [TargetAffect.AXIS]: 'Axe',
    [TargetAffect.BAR]: 'Barres',
    [TargetAffect.AREA]: 'Area',
    [TargetAffect.LINE]: 'Lignes',
    [TargetAffect.PART]: 'Parts',
    [TargetAffect.TABLE]: 'Tableau',
    [SlicerTarget.COLOR]: 'Couleur',
  },
  'configuration-preview-insufficient-configuration': 'Ajouter une métrique pour afficher une prévisualisation',
  'configuration-export-select-destination': 'Selectionnez votre destination',
  'configuration-export-select-days': 'Selectionnez vos jours d\'exécution',
  'configuration-chart-target-value': 'Valeur de l\'objectif',
  'configuration-chart-target-duplicate-entry': 'Ce mois a déjà un objectif associé.',
  'configuration-invert-color': 'Couleurs inversées (croissance en rouge)',
  'configuration-chart-uniqueView-all': 'Multiple',
  'configuration-chart-sort': {
    'metric-asc': 'Métriques croissante',
    'metric-desc': 'Métriques décroissante',
    'slicer-asc': 'Slicers alphabétique',
  },
  'configuration-chart-cannot-modify-axis': 'Il est impossible de modifier l\'axe de cette métrique',
  'configuration-chart-set-to-first-axis': 'Enlever cette métrique de l\'axe secondaire',
  'configuration-chart-set-to-second-axis': 'Afficher cette métrique sur l\'axe secondaire',
  'configuration-warning-information-hidden': 'Attention des valeurs inférieures ou égales à 0 sont présentes, mais ne peuvent pas être affichées.',
  'configuration-metric-options': {
    'custom-display': 'Affichage custom',
    'custom': 'Custom',
    'metric-copy-fail': 'Rien n\'a été copié',
    'metric-copy-fail-description': 'Rien n\'a été copié car le presse papier est vide',
    'copied-metric': 'Métrique copié',
    'copied-metric-description': 'Coller le via le bouton à coté du selecteur de métrique',
    'info': {
      'options': 'Configurer les options de métriques',
      'paste/duplicate': 'Copie/Duplique métrique et  options',
      'more': 'Actions supplémentaires',
      'paste': 'Coller métrique',
    },
    'paste': {
      'metric-options': 'Coller Options Métrique',
      'filters': 'Coller Filtre',
      'period': 'Coller Période',
      'growth': 'Coller Croissance',
      'display': 'Coller Affichage',
    },
    'growth': {
      'title': 'Croissance',
      'activated': 'Activé',
      'period': 'Période',
      'type': 'Type',
      'options': 'Options',
      'display': 'Affichage custom',
    },
    'period': {
      'title': 'Periode',
      'override-option-label': 'Période du chart',
    },
    'filters': {
      'title': 'Filtres',
    },
    'display': {
      'title': 'Affichage',
      'custom-title': 'Affichage custom',
      'decimals': 'Décimales',
      'units': {
        'title': 'Affichage Valeur',
        'options': {
          'units': 'Unités',
          'k': 'Milliers',
          'm': 'Millions',
        },
      },
      'color': 'Couleur',
      'color-warning': 'La surcharge des couleurs est uniquement disponible pour les chart Barres sans slicers et les charts Lignes et Aires avec un slicer au maximum',
      'details': {
        'title': 'Unités',
        'options': {
          'suffix': 'Suffix',
          'prefix': 'Préfixe',
          'none': 'Aucune',
        },
      },
    },
  },
  'dashboard-edit': 'Paramètres',
  'dashboard-edit-mode': 'Mode édition',
  'dashboard-add-chart': 'Nouveau chart',
  'dashboard-addFromClipboard-label': 'Coller',
  'dashboard-addFromClipboard-newTitle': (chart: ChartDtoDetailTypes) => `Copie de : ${chart.title}`,
  'dashboard-chartCopied-confirmation-title': 'Chart copié',
  'dashboard-chartCopied-confirmation-description': 'Collez-le via le boutton "+ chart"',
  'dashboard-nexusQlCopy-title': "Copier NexusQL",
  'dashboard-nexusQlCopied-confirmation-title': 'Requête NexusQL copié',
  'dashboard-nexusQlCopied-confirmation-description': 'Vous pouvez la coller en utilisant le press papier',
  'dashboard-add-boxes': (displayImage: boolean) => '### **Boxes**\nLes boxes permettent d\'afficher simplement les totaux d\'une ou plusieurs métriques\n'
    + `${displayImage ? '\n![](/img/screen-boxes.png)' : ''}`
    + '\n###Options:'
    + '\n- **N métriques** - et leur croissance'
    + '\n- **N filtres**'
    + '\n- **Pas de slicer**',
  'dashboard-add-boxes-label': 'Boxes',
  'dashboard-add-bars': (displayImage: boolean) => '### **Barres**\nLes charts en barre affichent le total d\'une métrique sur la période avec le détail sur une dimension (par ex. CA par pays).'
    + `${displayImage ? '\n![](/img/screen-barres.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 métrique** - et sa croissance'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-bars-label': 'Barres',
  'dashboard-add-evolution-label': 'Evolution',
  'dashboard-add-evolution': (displayImage: boolean) => '### **Evolution**\nLes charts évolution représentent une métrique sur la date avec une autre dimension en option (par ex. Panier moyen par pays).'
    + `${displayImage ? '\n![](/img/screen-lines.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 métrique** - ou sa croissance'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-divider': (displayImage: boolean) => '### **Texte**\nVous pouvez ajouter un bloc de texte pour contextualiser les données affichées ou guider vos colloborateurs.'
    + `${displayImage ? '\n![](/img/screen-text.png)' : ''}`,
  'dashboard-add-divider-label': 'Texte',
  'dashboard-add-lines': (displayImage: boolean) => '### **Lignes**\nLes charts en lignes affichent l\'évolution d\'une métrique avec le détail sur une dimension (par ex. Panier moyen par pays).'
    + `${displayImage ? '\n![](/img/screen-lines.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 métrique** - ou sa croissance'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-lines-label': 'Lignes',
  'dashboard-add-area': (displayImage: boolean) => '### **Aires**\nLes charts en aires affichent l\'évolution du cumul d\'une métrique avec le détail sur une dimension (par ex. CA par pays).'
    + `${displayImage ? '\n![](/img/screen-area.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 métrique** - pas de croissance ou de métrique qui ne peut être cumulée (moyennes, etc.)'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-area-label': 'Aires',
  'dashboard-add-tables': (displayImage: boolean) => '### **Tableaux**\nLes tableaux permettent d\'afficher des informations détaillées, avec autant de slicers / métriques que souhaitées.'
    + `${displayImage ? '\n![](/img/screen-tables.png)' : ''}`
    + '\n###Options:'
    + '\n- **N métriques** - et leur croissance'
    + '\n- **N filtres**'
    + '\n- **N slicers** - dont la date ',
  'dashboard-add-tables-label': 'Tableau',
  'dashboard-add-pie': (displayImage: boolean) => '### **Camembert**\nLes charts en camembert affichent le total d\'une métrique sur la période avec le détail sur une dimension (par ex. CA par pays).'
    + `${displayImage ? '\n![](/img/screen-pie.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 métrique**'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-pie-label': 'Camembert',
  'dashboard-add-target': '### **Objectifs**\nLes objectifs affichent et comparent les données cumulées sur une période par rapport aux objectifs fixés sur cette même période.'
    + '\n![](/img/screen-target.png)'
    + '\n###Options:'
    + '\n- **1 métrique**'
    + '\n- **Pas de filtre**'
    + '\n- **Pas de slicer**'
    + '\n- **N objectifs**, représentés par un mois et une valeur à atteindre dans ce mois. Si vous faites une recherche sur un trimestre ou une année, alors tous les mois de cette période seront cumulés.',
  'dashboard-add-target-label': 'Objectif',
  'definitions-link-label': 'Dictionnaire de données',
  'definitions-link-text': 'Vous pouvez consulter la liste des métriques et des dimensions dans le dictionnaire de donnée',
  'enforced-legend-view': 'Vue',
  'enforced-legend-period': 'Période',
  'enforced-legend-slicers': 'Slicers',
  'enforced-legend-filters': 'Filtres',
  'enforced-legend-metrics': 'Métriques',
  'error-occurred': 'Une erreur est survenue',
  'form-position-after-xxx': ({ref}: { ref: string }) => `Après ${ref}`,
  'form-position-at-the-start': 'Au début',
  'form-position-at-the-end': 'A la fin',
  'form-title-new-dashboard': 'Nouveau Dashboard',
  'form-title-new-menu': 'Nouveau Menu',
  'form-title-copy-of-xxx': ({ref}: { ref: string }) => `Copie de ${ref}`,
  'form-label-chart-type': 'Type',
  'form-label-parent-menu': 'Menu',
  'form-label-name-fr': 'Nom FR',
  'form-label-name-en': 'Nom EN',
  'form-label-code': 'Code',
  'form-label-position': 'Position',
  'granularity-label-year': 'par année',
  'granularity-label-quarter': 'par trimestre',
  'granularity-label-month': 'par mois',
  'granularity-label-week': 'par semaine',
  'granularity-label-day': 'par jour',
  'growth': {
    'group': {
      "last-period": 'Période précédente',
      "dynamic-period": 'Périodes dynamique',
      "fixed-period": 'Périodes Fixes',
    },
    'value': {
      // eslint-disable-next-line no-irregular-whitespace
      'd-n': (quantity: number) => `J-${quantity}`,
      // eslint-disable-next-line no-irregular-whitespace
      'w-n': (quantity: number) => `S-${quantity}`,
      // eslint-disable-next-line no-irregular-whitespace
      'm-n': (quantity: number) => `M-${quantity}`,
      // eslint-disable-next-line no-irregular-whitespace
      'q-n': (quantity: number) => `T-${quantity}`,
      // eslint-disable-next-line no-irregular-whitespace
      'y-n': (quantity: number) => `A-${quantity}`,
      'previous': 'Précédente',
    },
    'preset': {
      'd-n': 'VS Jour',
      'w-n': 'VS Semaine',
      'm-n': 'VS Mois',
      'q-n': 'VS Trimestre',
      'y-n': 'VS Année',
      'previous': 'Précédente',
    },
    'type-preset': {
      'ratio': 'Pourcentage',
      'difference': 'Différence',
      'value': 'Valeur',
    },
  },
  'input-type-to-search': (searchedField?: string) => `Ecrivez pour rechercher ${searchedField ? `un ${searchedField.toLowerCase()}` : ''}...`,
  'login-error': 'Erreur de login',
  'login-retry': 'Réessayer',
  'login-clock-correct': "Assurez-vous que l'horloge de votre ordinateur est correctement configurée",
  'navigation-admin': 'Administration',
  'navigation-admin-user-list': 'Utilisateurs',
  'navigation-newVersion-title': 'Nouvelle version disponible',
  'navigation-newVersion-description': 'Une nouvelle version de Biron est disponible, cliquez sur ce message pour recharger la page.',
  'navigation-noAuthorization': "Vous n'avez aucune autorisation sur cette application",
  'navigation-help': 'Aide',
  'navigation-knowledge-base': 'Base de connaissances',
  'navigation-tickets': 'Tickets',
  'navigation-log-out': 'Déconnexion',
  'navigation-tools-add-dashboard': 'Ajouter un dashboard',
  'navigation-tools-add-menu': 'Ajouter un menu',
  'navigation-tools-clone-dashboard': 'Cloner le dashboard',
  'navigation-tools-edit-dashboard': 'Modifier le dashboard',
  'navigation-tools-edit-menu': 'Modifier le menu',
  'navigation-tools-delete-dashboard': 'Supprimer le dashboard',
  'navigation-tools-delete-dashboard-confirmation': 'Voulez vous vraiment supprimer le dashboard ?',
  'navigation-tools-delete-menu': 'Supprimer le menu',
  'navigation-tools-delete-menu-confirmation': 'Voulez vous vraiment supprimer le menu ? Cela entrainera la suppression de tous les dashboards de ce menu',
  'new-chart-title': 'Nouveau chart',
  'new-text-chart-title': 'Nouveau chart text',
  'enter-description': 'Entrer une description',
  'new-export-title': 'Nouvel export',
  'new-period-title': 'Nouvel periode',
  'no-target-configured': 'Aucun objectif n\'a été configuré pour cette période. Veuillez en configurer un.',
  'chartConfSwitches': {
    'asPercentage': {
      'on': 'Afficher les pourcentages du total',
      'off': 'N\'afficher que les valeurs',
    },
    'sortSeries': {
      'on': 'Trier par valeur décroissante',
      'off': 'Ne pas trier',
    },
    'withDateSlicer': {
      'on': 'Afficher une ligne par date',
      'off': 'Ne pas grouper par date',
    },
    'displayLabels': {
      'on': 'Afficher les labels',
      'off': 'Cacher les labels',
    },
    'withChartOverriddenPeriod': {
      'on': 'Rétablir la période et granularité du chart',
      'off': 'Ignorer la période et granularité du chart',
    },
  },
  'reload-app': "Recharger l'application",
  'switch-deactivate-edit-mode': 'Désactiver le mode édition',
  'switch-activate-edit-mode': 'Activer le mode édition',
  'table-expand-show-less': 'Réduire',
  'table-expand-show-more': 'Afficher toutes les colonnes',
  'table-footer-lines-count': '# ligne(s)',
  'target-achieved-of': 'de',
  'target-legend-achieved': 'Achevé',
  'target-legend-target': 'Objectif',
  'time-preset-selector-futures-date': 'dates futures',
  'time-preset-category-days': 'Jours',
  'time-preset-category-months': 'Mois',
  'time-preset-category-years': 'Années',
  'time-preset-category-others': 'Autres',
  'time-preset-category-days-future': 'Jours futurs',
  'time-preset-category-months-future': 'Mois futurs',
  'time-preset-category-years-future': 'Années futures',
  'time-preset-category-others-future': 'Autres futures',
  'chart-delete-confirmation': 'Voulez-vous supprimer le chart ?',
  'timePreset': {
    'custom': 'personnalisée',
    'yesterday': 'hier',
    'today': 'aujourd\'hui',
    'tomorrow': 'demain',
    'currentWeek': 'depuis lundi',
    'totalCurrentWeek': 'semaine complète en cours',
    '7lastDays': '7 derniers jours',
    '7nextDays': '7 prochains jours',
    '30lastDays': '30 derniers jours',
    '30nextDays': '30 prochains jours',
    '90lastDays': '90 derniers jours',
    '180lastDays': '180 derniers jours',
    'currentMonth': `mois en cours (MTD)`,
    'totalCurrentMonth': `mois complet en cours`,
    'untilEndMonth': 'aujd. à fin de mois',
    'nextMonth': `prochain mois`,
    'currentQuarter': `trimestre en cours (QTD)`,
    'lastQuarter': `trimestre précédent`,
    '3lastMonths': '3 derniers mois',
    '3nextMonths': '3 prochains mois',
    '6lastMonths': '6 derniers mois',
    '6nextMonths': '6 prochains mois',
    '12lastMonths': '12 derniers mois',
    '12nextMonths': '12 prochains mois',
    '24lastMonths': '24 derniers mois',
    'previousMonth': 'mois précédent',
    'previousWeek': 'semaine précédente (S-1)',
    'lastYear': () => `année précédente (${dayjs().subtract(1, 'year').format('YY')})`,
    'nextYear': 'année prochaine',
    'currentYear': `année en cours (YTD)`,
    'lastYearToDate': `année dernière en cours (LYTD)`,
    'currentAndLastYear': () => `2 années en cours (${dayjs().subtract(1, 'year').format('YY')}-${dayjs().format('YY')})`,
    'totalCurrentYear': () => `année complête en cours (${dayjs().format('YY')})`,
    'untilEndYear': 'jusqu\'au 31/12',
    '2lastYears': () => `2 années précédentes (${dayjs().subtract(2, 'year').format('YY')}-${dayjs().subtract(1, 'year').format('YY')})`,
    '2nextYears': '2 prochaines années',
    'lastYearCurrentWeek': 'semaine en cours l\'an dernier',
    'lastYearCurrentMonth': 'mois en cours l\'an dernier',
    'last': 'Last',
    '2last': 'Last 2',
    '3last': 'Last 3',
    '6last': 'Last 6',
    '7last': 'Last 7',
    '12last': 'Last 12',
    '30last': 'Last 30',
  },
  common: {
    formModal: {
      actionSave: 'Sauvegarder',
      actionCancel: 'Annuler',
    },
    popconfirm: {
      okText: 'OK',
      cancelText: 'Annuler',
    },
    notificationSuccess: {
      message: 'Confirmation',
      description: {
        action: 'Action enregistrée',
      },
    },
  },
  entityField: {
    user: {
      '$': 'Utilisateurs',
      email: 'Email',
      fullName: 'Nom',
      firstName: 'Prénom',
      lastName: 'Nom',
      locale: 'Langue',
      powerUser: 'Accès tickets',
      aces: 'Roles',
    },
    userAssocClient: {
      active: 'Actif ?',
    },
    accessControlEntry: {
      role: 'Rôle',
      target: 'Cible',
    },
  },
  enum: {
    aceTargetType: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      environment: 'Tous workspaces',
      workspace: (w: WorkspacesForAce) => w.name,
    },
    role: {
      ENVIRONMENT_SUPERADMIN: 'SuperAdmin',
      ENVIRONMENT_ADMIN: 'Admin',
      WORKSPACE_MANAGER: 'Writer',
      WORKSPACE_USER: 'Reader',
    },
    roleDescription: {
      ENVIRONMENT_ADMIN: `gestion des utilisateurs d'un Client. Un Admin peut Créer/Modifier/Supprimer un utilisateur, ainsi que gérer les droits de tout utilisateur y compris lui-même.`,
      WORKSPACE_MANAGER: `modification sur l'ensemble des dashboards du Workspace. Inclut la possibilité de Créer/Modifier/Supprimer un Chart, un Dashboard ou un Menu. Un Writer peut donc modifier intégralement un workspace.`,
      WORKSPACE_USER: `consultation sur l'ensemble des dashboards du Workspace, sans modification possible de la configuration des charts, dashboards ou menus.`,
    },
  },
  filter: {
    separator: {
      and: "Et",
    },
    spaceWarning: "Attention la valeur commence ou finit par un espace",
    matchCase: "Respect de la casse",
    operator: {
      [FilterOperator.Like]: "Comme",
      [FilterOperator.IN]: "Dans",
      [FilterOperator.Match]: "Regex",
      [FilterOperator.BETWEEN]: (value?: {
        min: number,
        max: number
      }) => `Compris entre ${value ? `${value.min ?? ""} et ${value.max ?? ""}` : ""}`,
      [FilterOperator.EQ]: (value?: number) => `Egal à ${value ?? ""}`,
      [FilterOperator.LTE]: (value?: number) => `Inférieur ou égal à ${value ?? ""}`,
      [FilterOperator.LT]: (value?: number) => `Inférieur à ${value ?? ""}`,
      [FilterOperator.GTE]: (value?: number) => `Supérieur ou égal à ${value ?? ""}`,
      [FilterOperator.GT]: (value?: number) => `Supérieur à ${value ?? ""}`,
    },
    type: {
      [FilterInputType.START_WITH]: {
        title: "Commence avec",
        description: "Renvoie toutes les valeurs de la dimension qui commencent par la chaîne de caractère renseignée",
      },
      [FilterInputType.END_WITH]: {
        title: "Finit avec",
        description: "Renvoie toutes les valeurs de la dimension qui finissent par la chaîne de caractère renseignée",
      },
      [FilterInputType.INCLUDE]: {
        title: "Contient",
        description: "Renvoie toutes les valeurs de la dimension qui contiennent la chaîne de caractère renseignée",
      },
      [FilterInputType.VALUE_SELECTION]: {
        title: "Sélection valeur",
        description: "Choisissez directement parmi toutes les valeurs de la dimension.\nIl est possible de sélectionner plusieurs valeurs simultanément",
      },
      [FilterInputType.REGEX]: {
        title: "Regex",
        description: "Renvoie toutes les valeurs de la dimension qui respectent l’expression régulière renseignée.<br/><br/><a href='https://regex101.com/' target=”_blank”>Regex101</a> pour en apprendre plus sur les expressions régulières",
        warning: "L’expression régulière est incorrecte",
      },
      [FilterInputType.EQUAL]: {
        title: "Egal à",
        description: "Affiche toutes les valeurs de la dimension pour laquelle elle est egal à la valeur entrée",
        warning: "La valeur doit être numérique",
        placeholder: "Entrer une valeur",
      },
      [FilterInputType.GREATER]: {
        title: "Supérieur à",
        description: "Affiche toutes les valeurs de la dimension pour laquelle elle est strictement supérieur à la valeur entrée",
        warning: "La valeur doit être numérique",
        placeholder: "Entrer une valeur",
      },
      [FilterInputType.GREATER_OR_EQ]: {
        title: "Supérieur ou égal à",
        description: "Affiche toutes les valeurs de la dimension pour laquelle elle est supérieur ou égale à la valeur entrée",
        warning: "La valeur doit être numérique",
        placeholder: "Entrer une valeur",
      },
      [FilterInputType.LOWER]: {
        title: "Inférieur à",
        description: "Affiche toutes les valeurs de la dimension pour laquelle elle est strictement inférieur à la valeur entrée",
        warning: "La valeur doit être numérique",
        placeholder: "Entrer une valeur",
      },
      [FilterInputType.LOWER_OR_EQ]: {
        title: "Inférieur ou égal à",
        description: "Affiche toutes les valeurs de la dimension pour laquelle elle est inférieur ou égale à la valeur entrée",
        warning: "La valeur doit être numérique",
        placeholder: "Entrer une valeur",
      },
      [FilterInputType.BETWEEN]: {
        title: "Compris entre",
        description: "Affiche toutes les valeurs de la dimension comprise entre les bornes",
        warning: {
          notNumeric: "La valeur doit être numérique",
          maxBeforeMin: "Le minimum est supérieur au maximum",
        },
        placeholder: {
          min: "Minimum",
          max: "Maximum",
        },
      },
    },
  },
  filterType: {
    [FilterNegationType.default]: "Filtrer sur les données",
    [FilterNegationType.negate]: "Exclure les données",
  },
  dataDoc: {
    views: "Toutes les vues",
    usages: "Usages dans la workspace courante",
    links: "Liens",
    nav: {
      name: 'Dictionnaire de données',
      type: (type: DataSourceObjectType) => {
        switch (type) {
          case DataSourceObjectType.DIMENSION:
            return 'Dimension'
          case DataSourceObjectType.METRIC:
            return 'Métrique'
          case DataSourceObjectType.VIEW:
            return 'Vue'
          default:
            return 'not found'
        }
      },
    },
    columns: {
      object: "Objet",
      name: "Nom",
      objectName: "Nom de l'objet",
      dashboardName: "Nom du dashboard",
      menuName: "Nom du menu",
      usage: "Utilisation",
      view: "Vue",
      code: "Code",
      alias: "Alias",
      description: "Description",
      type: "Type",
      id: "Id NexusQL",
      value: "Valeur",
    },
    lists: {
      dimensions: "Dimensions",
      metrics: "Métriques",
      all: "Tous"
    },
    details: {
      availableObject: "Objets disponibles",
      usages: "Usages",
      values: "Valeurs",
      dimension: "Dimension",
      metric: "Métrique",
      stats: (source: string) => `Utilisation ${source}`,
      linkTitle: {
        dimension: "Liens vers les vues dans lequelle la dimension est disponible",
        metric: "Liens vers les vues utilisant la même métrique",
      },
      informations: {
        title: "Informations",
        description: "Description",
        suffix: "Suffixe",
        decimales: "Décimales",
        asRatio: "Ratio",
        linkedViews: "Vues liées",
        idNexusQL: "ID NexusQL",
      },
      sources: (source: ChartSource) => {
        switch (source) {
          case ChartSource.FILTER:
            return 'Filtre'
          case ChartSource.METRIC:
            return 'Métrique'
          case ChartSource.SLICER:
            return "Slicer"
          default:
            return 'not found'
        }
      },
      object: (object: ObjectType) => {
        switch (object) {
          case ObjectType.EXPORT:
            return "Export"
          case ObjectType.CHART:
            return "Chart"
          case ObjectType.DASHBOARD:
            return "Dashboard"
          default:
            return "not found"
        }
      },
      type: (type: DataSourceObjectType) => {
        switch (type) {
          case DataSourceObjectType.VIEW:
            return "Vue"
          case DataSourceObjectType.METRIC:
            return 'Métrique'
          case DataSourceObjectType.DIMENSION:
            return "Dimension"
          default:
            return "not found"
        }
      },
    },
  },
  export: {
    "sider-title": "Exports",
    "title": "Gestion des exports",
    "add": (destination: string) => `Ajouter un export ${destination}`,
    "informations": "Informations sur l'export",
    "execution": "Options d'exécution",
    "action": "Action",
    'execution-day': 'Jour(s) d\'éxecution',
    'override': 'Ecrasement',
    'override-checked': 'Le fichier sera réécrit à chaque fois avec le nom de l\'export',
    'override-notchecked': 'Le fichier d\'export sera ajouté à chaque fois avec la date prétendant le nom de l\'export',
    'ignoreMetrics0': 'Ignorer les métriques à 0',
    'destination': 'Destination d\'export',
    'next-execution': 'Prochaine execution dans',
    'file-name': 'Exemple de nom du fichier',
    alert: {
      "no-destination": "Il n'y a pas de destination pour ce client",
    },
    "days": {
      [Days.MONDAY]: "Lundi",
      [Days.TUESDAY]: "Mardi",
      [Days.WEDNESDAY]: "Mercredi",
      [Days.THURSDAY]: "Jeudi",
      [Days.FRIDAY]: "Vendredi",
      [Days.SATURDAY]: "Samedi",
      [Days.SUNDAY]: "Dimanche",
    },
  },
  period: {
    "sider-title": "Périodes",
    "title": "Gestion des périodes",
    "add": "Ajouter une période",
    "delete": {
      title: "Supprimer",
      description: {
        question: "Êtes vous sûr de vouloir supprimer cette période ?",
        content: "Cette opération est irréversible. Si la période est utilisée dans un dashboard, alors elle sera remplacée par mois en cours et par période du dashboard sur les charts.",
      },
    },
    "informations": "Informations",
    "period": "Période",
    "minus": "moins",
    "start": "Début",
    "end": "Fin",
    "form": {
      "title": "Nouvelle période",
      "usage-warning": "Attention cette période est utilisée dans un export",
      "start-after-end-warning": "Attention : La date de fin de période précède la date de début",
      "dynamic": {
        "preview": {
          "title": (date: Dayjs) => `Période résultante au ${date.format("DD MMM")} :`,
        },
      },
      "code-error": "Le code doit avoir une valeur unique",
    },
    "type": {
      "fix": "Fixe",
      "dynamic": "Dynamique",
    },
    "category": {
      "DAY": "Jour",
      "MONTH": "Mois",
      "YEAR": "Année",
      'OTHER': "Autre",
    },
    'period-edge-start-point': {
      TODAY: "Aujourd'hui",
      YESTERDAY: "Hier",
      WEEK: "Semaine",
      MONTH: "Mois",
      QUARTER: "Trimestre",
      YEAR: "Année",
    },
    'period-edge-end-point': {
      TODAY: "Aujourd'hui",
      YESTERDAY: "Hier",
      WEEK: "Semaine",
      MONTH: "Mois",
      QUARTER: "Trimestre",
      YEAR: "Année",
    },
    'granularity': {
      YEAR: 'Année',
      QUARTER: 'Trimestre',
      MONTH: 'Mois',
      WEEK: 'Semaine',
      DAY: 'Jour',
    },
  },
  admin: {
    user: {
      homeTitle: `Gestion des utilisateurs`,
      actionCreateUser: `Créer un nouvel utilisateur`,
      actionCreateUac: `Importer un utilisateur existant`,
      actionManageUserAces: `Gérer les rôles`,
      actionEditUser: `Modifier l'utilisateur`,
      actionActiveUser: `Réactiver`,
      actionInactiveUser: `Désactiver`,
      actionResetPassword: `Réinitialiser le mot de passe`,
      actionResetPasswordSuccess: {
        message: "Mot de passe réinitialisé",
        description: ({user}: { user: User }) => `un e-mail a été envoyé à ${user.email}`,
      },
      actionDeleteUser: `Supprimer`,
      actionDeleteUserConfirm: `Êtes vous sûr de vouloir supprimer cet utilisateur ? Cette opération est irréversible.`,
      formCreateTitle: `Création d'un nouvel utilisateur`,
      formUpdateTitle: ({user}: { user: User }) => `Edition de l'utilisateur ${user.email}`,
      formNewUacTitle: `Importer un utilisateur existant`,
      formAcesTitle: ({user}: { user: User }) => `Gestion des rôles pour ${user.email}`,
      filterOnAces: `Filtrer sur les rôles`,
      uacInactiveFlag: `Désactivé`,
      userNoAces: `aucun`,
    },
  },
  colors: {
    [ColorName["blue-border"]]: "Bordure Bleu",
    [ColorName["blue-pastel"]]: "En-tête Bleu",
    [ColorName["orange-border"]]: "Bordure Orange",
    [ColorName["orange-pastel"]]: "En-tête Orange",
    [ColorName["red-border"]]: "Bordure Rouge",
    [ColorName["red-pastel"]]: "En-tête Rouge",
    [ColorName["green-border"]]: "Bordure Verte",
    [ColorName["green-pastel"]]: "En-tête Verte",
    [ColorName["bluegreen-border"]]: "Bordure Violette",
    [ColorName["bluegreen-pastel"]]: "En-tête Violet",
  },
  sessions: {
    'expired': 'Session expirée',
    'expiredDescription': 'Cliquez sur le bouton ci-dessous pour vous reconnecter',
    'expiredContinue': 'Continuer',
  },
}

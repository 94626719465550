import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import Loader from "components/loader/Loader"
import DashboardHeader from 'components/dashboard/header/DashboardHeader'
import DashboardGrid, {Handles, Handles as GridHandles} from 'components/dashboard/grid/DashboardGrid'
import DashboardFooter from 'components/dashboard/Dashboard.Footer'
import styled from "styled-components"
import MetaModel, {Dimension} from "classes/MetaModel"
import {DataSelection, LayoutWithId} from "redux/models/currentDashboard"
import {hashLinkToChartId} from "components/dashboard/grid/dashboardGridLayoutUtils"
import {NormalizedDashboard, NormalizedMenu} from "schemas/workspace"
import {ChartDtoDetail, ChartDtoDetailTypes, RawChartTypes} from "types/charts"
import {loadDictionaryEntries} from "services/MetaModelService"
import {captureWarn} from "services/SentryService"
import FilterLine from "components/dashboard/header/filterLine/FilterLine"
import {isEqual} from "lodash"
import {SizeMe} from 'react-sizeme'
import {isFilterEmpty} from "components/forms/selector/comps/box/utils"
import {ConfDimensionFilterTypes, DimensionFilterDtoDetail} from "components/forms/selector/comps/box/filters"
import {converterFilterToConfModel, useDimensionFilterConverterToConfModel} from "components/forms/chart/utils"

interface Props {
  environmentId: number
  metaModel: MetaModel
  availableDimensions: Dimension[]
  menu?: NormalizedMenu
  dashboard: NormalizedDashboard
  currentSelection: DataSelection,
  charts: ChartDtoDetailTypes[]
  canEdit: boolean,
  editMode: boolean,
  copiedChart: ChartDtoDetail
  onConfChange: (data: any) => void
  onLayoutChange: (data: LayoutWithId[]) => void
  onChartAdd: ({targets, ...data}: any) => Promise<RawChartTypes>
  onSelectionChange: (data: DataSelection) => void
}

export default memo<Props>(function Dashboard({
                                                environmentId,
                                                metaModel,
                                                availableDimensions,
                                                menu,
                                                dashboard,
                                                currentSelection,
                                                charts,
                                                canEdit,
                                                editMode,
                                                onConfChange: originalOnConfChange,
                                                onLayoutChange,
                                                onSelectionChange,
                                                copiedChart,
                                              }) {
  const layoutRef = useRef(null)
  const gridRef = useRef<GridHandles | null>(null)
  const [hasGridRefBeenInitialized, setHasGridRefBeenInitialized] = useState(false)
  const dimensionFilterConverter = useDimensionFilterConverterToConfModel(currentSelection.filters, availableDimensions)
  const [filters, setFilters] = useState(dimensionFilterConverter())
  const [isFilterLineFullSize, setIsFilterLineFullSize] = useState(false)
  const filtersOnQuery: (dictionaryCode: string) => Promise<any> = useCallback((dictionaryCode: string) => loadDictionaryEntries(environmentId, dictionaryCode), [environmentId])

  useEffect(() => {
    const dashboardFilters = dimensionFilterConverter()
    if (!isEqual(dashboardFilters, filters)) {
      setFilters(dashboardFilters)
    }
    // In this case we want to reset the filters when dashboard is changed,
    // but we don't want to update filters when currentSelection change because we want them to be separated
    // (currentSelection.filters is a simplified version of the state filters in order to restrict number of queries triggered by any filters update)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard])

  const onConfChange = useCallback((data: NormalizedDashboard) => {
    setFilters(converterFilterToConfModel(data.filters.filter(filter => !isFilterEmpty(filter)), availableDimensions))

    originalOnConfChange(data)
  }, [availableDimensions, originalOnConfChange])


  const handleFilterChange = useCallback((filter: ConfDimensionFilterTypes, index: number) => {
    const newFilters = Object.assign([] as DimensionFilterDtoDetail[], filters)
    newFilters[index] = filter

    setFilters(newFilters)
    onSelectionChange({
      ...currentSelection,
      filters: newFilters,
    })
  }, [currentSelection, filters, onSelectionChange])

  const removeFilter = useCallback((index: number) => {
    const newFilters = Object.assign([] as DimensionFilterDtoDetail[], filters)
    newFilters.splice(index, 1)
    onSelectionChange({
      ...currentSelection,
      filters: newFilters.filter((f) => !isFilterEmpty(f)),
    })
    setFilters(newFilters)
  }, [currentSelection, filters, onSelectionChange])

  const onTemporaryFiltersChange = useCallback(
    (newFilters: DimensionFilterDtoDetail[]) => {
      setIsFilterLineFullSize(true)
      setFilters(Object.assign([], newFilters))
    },
    [setFilters],
  )

  const toggleFilterLine = useCallback(() => {
    setIsFilterLineFullSize(oldValue => !oldValue)
  }, [])

  const setGridRef = useCallback((node: Handles) => {
    if (node) {
      gridRef.current = node
      setHasGridRefBeenInitialized(true)
      if (dashboard.chartHashLink) {
        try {
          node.scrollToChart(hashLinkToChartId(dashboard.chartHashLink))
        } catch (e) {
          captureWarn(`Could not find chart to scroll to (link: ${dashboard.chartHashLink})`)
        }
      }
    }
  }, [dashboard.chartHashLink])

  const [isFilterInEdition, setIsFilterInEdition] = useState<number>(0)

  const onEdit = useCallback((state: number) => {
    setIsFilterInEdition(state)
  }, [])

  return <SizeMe monitorHeight monitorWidth>{({size}) => <StyledLoader loading={!size.width}
                                                                       className="loading-container">
    {hasGridRefBeenInitialized && <DashboardHeader {...{
      environmentId,
      metaModel,
      availableDimensions,
      menu,
      dashboard,
      currentSelection,
      editMode,
      onConfChange,
      filters,
      onTemporaryFiltersChange,
      copiedChart,
      onChartAdded: (id) => {
        (gridRef.current as Handles).scrollToChart(id)
      },
      getChartLayoutForAdd: (gridRef.current as Handles).getChartLayoutForAdd,
    }}/>}
    <DashboardLayout ref={layoutRef}>
      <FilterLine {...{
        environmentId,
        filters,
        query: filtersOnQuery,
        availableDimensions,
        onFiltersChange: handleFilterChange,
        onFiltersDelete: removeFilter,
        isFilterLineFullSize,
        toggleFilterLine,
        onEdit,
      }}/>
      <DashboardGrid ref={setGridRef} {...{
        environmentId,
        metaModel,
        dashboard,
        currentSelection,
        charts,
        editMode,
        canEdit,
        onLayoutChange,
        filtersLength: filters.length,
        isFilterInEdition,
        isFilterLineFullSize,
        width: size.width ?? undefined,
        copiedChart,
      }}/>
    </DashboardLayout>
    <DashboardFooter/>
  </StyledLoader>}
  </SizeMe>
  },
)

const StyledLoader = styled(Loader)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .loading-container {
    height: inherit;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
  }
`

const DashboardLayout = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 0;
`

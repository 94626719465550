import React from 'react'
import BironSelect from "components/forms/selector/select/BironSelect"
import {Checkbox, Col, InputNumber, Row} from "antd"
import Language from "language"
import {Granularity, PeriodEdge} from "types/period.types"
import {BaseOption} from "components/forms/selector/select/SimpleSelect"
import styled from "styled-components"
import {CheckboxChangeEvent} from "antd/lib/checkbox"

export interface PeriodEdgeInputProps<T extends PeriodEdge> {
  label: string
  options: BaseOption[]
  value: T
  onChange: (newValue: T) => void
}

const PeriodEdgeInput = <T extends PeriodEdge, >({label, options, value, onChange}: PeriodEdgeInputProps<T>) => {
  return <Col span={24}>
    <Row>
      <Col span={3}>
        {label}
      </Col>
      <Col span={7} offset={1}>
        <BironSelect
          name={"edgeValue"}
          span={24}
          value={value.value}
          options={options}
          showSearch={true}
          onChange={newValue => {
            onChange({
              ...value,
              value: newValue as any,
            })
          }}/>
      </Col>
      <Col span={4} offset={1}>
        <Checkbox checked={!!value.offset} onChange={(e: CheckboxChangeEvent) => {
          if (e.target.checked) {
            onChange({
              ...value,
              offset: {
                quantity: 1,
                granularity: Granularity.DAY,
              },
            })
          } else {
            onChange({
              ...value,
              offset: undefined,
            })
          }
        }
        }/> <OptionnalSpan $disabled={value.offset ? 0 : 1}>{Language.get("period.minus")}</OptionnalSpan>
      </Col>
      <Col span={3}>
        <InputNumber
          style={{width: '100%'}}
          value={value.offset?.quantity}
          disabled={!value.offset}
          onChange={newValue => {
            onChange({
              ...value,
              offset: {
                ...value.offset,
                quantity: newValue,
              } as any,
            })
          }}/>
      </Col>
      <Col span={4} offset={1}>
        <BironSelect
          name={"edgeOffset"}
          span={24}
          disabled={!value.offset}
          value={value.offset?.granularity}
          options={Object.values(Granularity).map(v => ({
            label: Language.get(`period.granularity.${v}`),
            value: v,
          }))}
          showSearch={true}
          onChange={newValue => {
            onChange({
              ...value,
              offset: {
                ...value.offset,
                granularity: newValue,
              } as any,
            })
          }}/>
      </Col>
    </Row>
  </Col>
}

export default PeriodEdgeInput

const OptionnalSpan = styled.span<{ $disabled: number }>`
    ${({$disabled}) => $disabled ? 'color: #D9D9D9;' : ''}
`
import React, {FunctionComponent, memo, PropsWithChildren} from 'react'
import Language from 'language'
import {Col, Row} from 'antd'
import {AdditionalDetailField, AdditionalDetailWrapper} from './AdditionalDetail'
import styled from "styled-components"
import {IconContainer} from "components/common/IconContainer"
import ValueDescription from "components/forms/description/ValueDescription"
import {CheckboxAdditionalDetails} from "components/forms/chart/useRequirements"

interface Props extends PropsWithChildren {
  icon?: FunctionComponent
  label?: string
  description?: string
  additionalDetails?: CheckboxAdditionalDetails[]
  itemColumnWidth?: number
}

const WrapperRoot = memo<Props>(function Wrapper({
                                                   icon: Icon,
                                                   label,
                                                   description,
                                                   additionalDetails = [],
                                                   itemColumnWidth = 4,
                                                   children,
                                                 }) {
  return <ConfItemRow>
    {Icon && label && <ConfLabel span={itemColumnWidth}>
      <StyledRow>
        <Col span={5}>
          <IconContainer><Icon/></IconContainer>
        </Col>
        {/*  /!* TODO: refacto  *!/*/}
        <ColWithAlignedContent span={15}>
          {Language.get(label)}
          <DivWithPadding>
            {description && <ValueDescription title={Language.get(label)} description={Language.get(description)}/>}
          </DivWithPadding>
        </ColWithAlignedContent>
      </StyledRow>
    </ConfLabel>}
    <Col span={Icon && label ? (24 - itemColumnWidth) : 24}>
      {children}
      {additionalDetails.length > 0 && <StyledAdditionalDetailsCtn>
        {additionalDetails.map((additionalDetail) =>
          <AdditionalDetailWrapper key={additionalDetail.textKey} additionalDetail={additionalDetail}>
            <AdditionalDetailField additionalDetail={additionalDetail}/>
          </AdditionalDetailWrapper>,
        )}
      </StyledAdditionalDetailsCtn>}
    </Col>
    <Col span={1}>
    </Col>
  </ConfItemRow>
})

export default WrapperRoot

const StyledRow = styled(Row)`
  display: flex;
  gap: 10px;
`

const StyledAdditionalDetailsCtn = styled.div`
  margin-top: 8px;
`
const DivWithPadding = styled.div`
  padding-left: 10px;
`

const ColWithAlignedContent = styled(Col)`
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 20px; /* 142.857% */
`

const ConfItemRow = styled(Row)`
  padding: 15px 10px 10px;
  border-bottom: 1px solid var(--main-background-color);
  border-top: 1px solid var(--main-background-color);
  
  &:not(:first-child) {
    border-top: none;
  }
  
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
  
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`

const ConfLabel = styled(Col)`
  text-align: start;
`
import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {CheckCircleIcon} from "@heroicons/react/outline"
import {CheckboxAdditionalDetails} from "components/forms/chart/useRequirements"
import TargetSelector from "components/forms/selector/target/TargetSelector"

interface Props extends PropsWithChildren {
  name: string
  additionalDetails: CheckboxAdditionalDetails[]
}

export const TargetsWrapper: FunctionComponent<Props> = ({children, name, ...props}) => (
  <WrapperRoot {...{
    icon: CheckCircleIcon,
    label: "configuration-label-targets",
    ...props,
  }}>
    <WrapperItem name={name}>
    {children}
  </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TargetsField = forwardRef<any, any>(function Field({...props}, ref) {
  return <TargetSelector {...props}/>
})

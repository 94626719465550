import React, {forwardRef, PropsWithChildren} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import ChartFormatSelector from "components/forms/selector/chartFormat/ChartFormatSelector"
import {ChartFormat, ConfigurationWithDisabledReasons} from "components/forms/chart/types"

export interface Props<T> extends PropsWithChildren {
  name: string
  initialValue?: T
}

export const ChartFormatWrapper = <T, >({children, name, initialValue}: Props<T>) => <WrapperRoot>
  <WrapperItem name={name} initialValue={initialValue}>
    {children}
  </WrapperItem>
</WrapperRoot>

interface FieldProps {
  configuration: ConfigurationWithDisabledReasons<ChartFormat>[],
  defaultFormat?: ChartFormat
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChartFormatField = forwardRef<any, FieldProps>(function Field({...props}, ref) {
  return <ChartFormatSelector {...props}/>
})

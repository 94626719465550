import ChartSummary from "components/charts/Chart.Summary"
import {Alert, Empty} from 'antd'
import React, {forwardRef, ReactNode, useMemo, useState} from "react"
import {ELineChartProps} from "components/charts/line/LineChart.types"
import {EAreaChartProps} from "components/charts/area/AreaChart"
import {captureError} from "services/SentryService"
import Language from "language"

type EChartProps = (ELineChartProps | EAreaChartProps) & {
  chart: (props: Omit<EChartProps, "chart">) => ReactNode,
} & React.RefAttributes<any>

const ChartContainer = forwardRef<any, EChartProps>(function EChartContainer(props, ref) {
  const {parsedData, meta} = props.rawChartData
  const Chart = props.chart
  const [summaryHeight, setSummaryHeight] = useState<number>()

  const childDimension = useMemo(() => ({
    ...props.dimensions,
    height: props.dimensions.height - (summaryHeight ?? 0), // We compute canvas height manually, so widget height - char summary height
  }), [props.dimensions, summaryHeight])

  if (!props.dimensions || !props.dimensions.width || !props.dimensions.height) {
    captureError("Attempt to create a chart without width or height", {
      dimensions: props.dimensions,
      chartType: props.chartConfig?.type,
      chartId: props.chartConfig?.id,
    })
    return <Alert
      type={"error"}
      message={<span title={"no-width-or-height"}>{Language.get("chart-message-query-error")}</span>}
      banner
    />
  }

  return parsedData
    ? <div style={props.dimensions}>
      {props.withSummary && <div ref={(node) => setSummaryHeight(node?.getBoundingClientRect().height)}>
        <ChartSummary effectiveConf={meta.effectiveConf}/>
      </div>}
      {parsedData && parsedData.length > 0 && !parsedData[0].isEmpty()
        ? <Chart ref={ref} {...props} dimensions={childDimension}/>
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{
          ...childDimension,
          margin: 0,
          padding: 32,
        }}/>
      }
    </div>
    : null
})

export default ChartContainer

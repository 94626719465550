/* eslint-disable max-lines */
import React, {memo, PropsWithChildren, useCallback, useMemo, useRef, useState} from 'react'
import {Col, notification, Row} from 'antd'
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {GenericChartTypes} from "types/widgets"
import {DataSelection} from "redux/models/currentDashboard"
import {consolidateTimeInterval} from "commons/parsers/utils"
import {PeriodTypes} from "types/period.types"
import {useDayDifference} from "components/forms/chart/useFixHooks"
import MetaModel from "classes/MetaModel"
import MetricOptionsResume from "components/forms/selector/metrics/display/MetricOptionsResume"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {isArray, pick} from "lodash"
import {getIndexFromID} from "components/common/sortable/SortableContainerContext"
import {getAvailableDimensions} from "components/forms/chart/useRequirements"
import Language from "language"
import {formatFormDataToMetricDto, formatMetricDtoToFormData, validateFormDataValues} from "components/forms/chart/utils"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import {SortableRow} from "components/forms/selector/utils"
import SingleMetricSelector from "components/forms/selector/metrics/SingleMetricSelector"
import MetricOptionsSelector from "components/forms/selector/metrics/MetricOptionsSelector"

interface MetricInputProps extends PropsWithChildren {
  environmentId: number
  id: number
  value?: MetricSelectorValue
  canInvert: boolean
  canRemove: boolean
  onChange: (id: number, data: SingleMetricSelectorValue) => void
  handleAdd: (data: SingleMetricSelectorValue) => void
  onDelete?: (id: number) => void
  groupMetricsByView: boolean
  viewsWithMetrics: ViewsWithMetrics
  unavailableViews: ViewsWithMetrics
  optionsDisabled: boolean
  configuration?: ChartTypeWithDisabledReason
  numberOfSlicer?: number
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  isAxisOptionAvailable?: boolean
  isAxisOptionDisabled?: boolean
  displayType?: GenericChartTypes
  dashboardSelection: DataSelection
  period?: PeriodTypes
  metaModel: MetaModel
  onCopy: () => void
  copiedMetric?: MetricSelectorValue
}


const MetricUpdate = memo<MetricInputProps>(function MetricInputs({
                                                                    id,
                                                                    environmentId,
                                                                    value,
                                                                    canInvert,
                                                                    canRemove,
                                                                    onChange,
                                                                    handleAdd,
                                                                    onDelete,
                                                                    groupMetricsByView,
                                                                    viewsWithMetrics,
                                                                    unavailableViews,
                                                                    optionsDisabled,
                                                                    configuration,
                                                                    numberOfSlicer,
                                                                    getPopupContainer,
                                                                    isAxisOptionAvailable = false,
                                                                    displayType,
                                                                    dashboardSelection,
                                                                    period,
                                                                    metaModel,
                                                                    onCopy,
                                                                    copiedMetric,
                                                                  }) {
  const [isOptionsDisplayed, setIsOptionsDisplayed] = useState<boolean>(false)
  const activeKey = useRef<MetricOptionsFormKeys>()

  const availableDimensions = useMemo(() => getAvailableDimensions(metaModel, value?.viewCode ? [value.viewCode] : []), [metaModel, value?.viewCode])

  const consolidatedTimeIntervalWithSource = useMemo(() => consolidateTimeInterval({
    dashboardSelection,
    chartPeriod: period,
  }, undefined), [dashboardSelection, period])
  const dayDifference = useDayDifference(consolidatedTimeIntervalWithSource)

  const handleDuplicate = useCallback(() => {
    if (value) {
      handleAdd(value)
    }
  }, [handleAdd, value])

  const handlePaste = useCallback((pickedKeys: (keyof ConfMetricWithActivationState)[]) => {
    if (!copiedMetric) {
      notification.info({
        duration: 2.5,
        key: 'copied-metric-fail',
        message: Language.get(`configuration-metric-options.metric-copy-fail`),
        description: Language.get(`configuration-metric-options.metric-copy-fail-description`),
        placement: 'bottomRight',
      })
    }
    if (value && copiedMetric) {
      const formData = formatMetricDtoToFormData(value, availableDimensions)
      onChange(getIndexFromID(id), pickedKeys.length > 0 ? formatFormDataToMetricDto(validateFormDataValues({
        ...formData,
        ...pick(formatMetricDtoToFormData(copiedMetric, availableDimensions), pickedKeys),
      }, formData)) : copiedMetric)
    }
  }, [availableDimensions, copiedMetric, id, onChange, value])

  const handleDelete = useCallback((keys: (keyof ConfMetricWithActivationState)[]) => {
    if (value) {
      onChange(getIndexFromID(id), formatFormDataToMetricDto(validateFormDataValues(keys.reduce((acc, key) => ({
        ...acc,
        [key]: isArray(acc[key]) ? [] : undefined,
      }), formatMetricDtoToFormData(value, availableDimensions)), value)))
    }
  }, [availableDimensions, id, onChange, value])

  return <SortableRow gutter={[0, 5]}>
    {/* select for metric */}
    <Col span={24} style={{maxHeight: 40}}>
      <Row>
        <Col span={14}>
          <SingleMetricSelector
            {...{
              environmentId,
              id,
              value,
              groupMetricsByView,
              viewsWithMetrics,
              unavailableViews,
              canInvert,
              canRemove,
              onChange,
              onDelete,
              configuration,
              numberOfSlicer,
              getPopupContainer,
              isAxisOptionAvailable,
              isAxisOptionDisabled: id === 1,
              displayType,
              metaModel,
              dashboardSelection,
              period,
            }}/>
        </Col>
        <Col span={10}>
          <MetricOptionsSelector {...{
            environmentId,
            availableDimensions,
            id,
            value,
            groupMetricsByView,
            viewsWithMetrics,
            unavailableViews,
            optionsDisabled,
            canInvert,
            canRemove,
            onChange,
            onDelete,
            configuration,
            numberOfSlicer,
            getPopupContainer,
            isAxisOptionAvailable,
            isAxisOptionDisabled: id === 1,
            displayType,
            metaModel,
            dashboardSelection,
            period,
            setActiveKey: (key) => {
              activeKey.current = key
            },
            activeKey: activeKey.current,
            handleDuplicate,
            handlePaste,
            copiedMetric,
            onCopy,
            isOptionsDisplayed,
            setIsOptionsDisplayed,
          }}/>
        </Col>
      </Row>
    </Col>
    <MetricOptionsResume iconSize={14} value={value} additionnalStyle={"width: 65%; margin-left: 3%;"} isNameDisplayed={true}
                         timeInterval={consolidateTimeInterval({dashboardSelection, chartPeriod: period}, true)}
                         dayDifference={dayDifference}
                         setActiveKey={(key) => {
                           setIsOptionsDisplayed(key !== undefined)
                           activeKey.current = key
                         }}
                         onDelete={handleDelete}/>
  </SortableRow>
})

export default MetricUpdate
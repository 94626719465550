import React, {memo, useCallback} from "react"
import {flatten, groupBy, sortBy} from 'lodash'
import Language from "language"
import styled from "styled-components"
import {Button} from 'antd'
import {PencilIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {ACES, User} from "components/admin/user/UserManager.types"

interface Props {
  aces: ACES[]
  user: User
  onUserAcesEdit: (user: User) => void
}

const UserManagerAceList = memo<Props>(function UserAceList({aces, user, onUserAcesEdit}) {
  const acesByTargetType = groupBy(aces, 'targetType')

  const handleEditClick = useCallback(() => {
    onUserAcesEdit(user)
  }, [onUserAcesEdit, user])

  const lis = flatten([
    displayAces(true, acesByTargetType.environment),
    displayAces(false, acesByTargetType.workspace),
  ])

  return <StyledDiv>
    <FlexButton onClick={handleEditClick}
                title={Language.get('admin.user.actionManageUserAces')}><IconContainer><PencilIcon/></IconContainer></FlexButton>
    <ul>
      {lis.length > 0 ? lis : <NoRole>{Language.get('admin.user.userNoAces')}</NoRole>}
    </ul>
  </StyledDiv>
})

export default UserManagerAceList

const roleDisplayPriority = ['WORKSPACE_USER', 'WORKSPACE_MANAGER', 'ENVIRONMENT_ADMIN', 'ENVIRONMENT_SUPERADMIN']

const displayAces = (important: boolean, aces: any[] = []) => {
  return aces.map(ace => {
      const soredRoles = sortBy(ace.roles, role => roleDisplayPriority.indexOf(role))
      return <li key={ace.id} className={important ? 'important' : ''}>
        <label>{Language.get(`enum.aceTargetType.${ace.targetType}`, ace.targetObject)}</label>
        {soredRoles.map(role => Language.get(`enum.role.${role}`)).join(', ')}
      </li>
    },
  )
}

const StyledDiv = styled.div`
  position: relative;
  
  > button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1; // to overlap NoRole
  }

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    
    li {
      &.important {
        font-weight: bold;
        font-size: 15px;
      }
      label {
        display: inline-block;
        width:  250px;
        text-align: right;
        margin-right: 8px;

          &::after {
          content: ':';
          padding-left: 3px;
        }
      }
    }
  }
`

const NoRole = styled.div`
  text-align: center;
  font-style: italic;
  opacity: .8;
`

const FlexButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0px;
`
import React, {FunctionComponent, MouseEventHandler, useCallback} from "react"
import styled from "styled-components"
import {DuplicateIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"

interface Props {
  onClick: MouseEventHandler<SVGSVGElement>
  onDoubleClick: MouseEventHandler<SVGSVGElement>
}

const IconAnimated: FunctionComponent<Props> = ({onClick, ...props}) => {
  const handleClick: MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      const target = e.currentTarget
      target.classList.add('icon-active')
      window.setTimeout(() => {
        target.classList.remove('icon-active')
      }, 230)
      return onClick(e)
    },
    [onClick],
  )
  return <IconContainer><StyledIcon {...{
    onClick: handleClick,
    ...props,
  }} /></IconContainer>
}

export default IconAnimated

const StyledIcon = styled(DuplicateIcon)`
  transition: transform 0.15s ease-in-out;
  &.icon-active {
    transform: scale(1.3);
}
`

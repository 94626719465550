import {UserSchemas} from "schemas"
import {
  createAssocClient,
  createUser,
  deleteAssocClient,
  getAces,
  getAssocEnvironment,
  resetPassword,
  updateAssocClient,
  updateUser,
  updateUserAces,
} from "services/UserService"
import {deleteEntity, setEntities} from "redux/models/utils.model"
import {returnOrExecBatch} from 'commons/batch'
import {getCurrentEnvironmentId} from "redux/environment.selector"
import RootModel from "redux/models/index"
import {createModel} from "@rematch/core"
import {AxiosResponse} from "axios"
import {UAC, User} from "components/admin/user/UserManager.types"

export interface ModelUser {
  "acesByUserId": {
    [id: string]: {
      id: number,
      target: string,
      roles: string[]
    }[]
  },
  "entities": {
    "users": any,
    "userAssocClients": any
  }
}

const initialState: ModelUser = {
  acesByUserId: {},
  entities: {
    users: {},
    userAssocClients: {},
  },
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEntities,
    deleteEntity,
    setAcesByUserId(state: ModelUser, acesByUserId: any) {
      return {
        ...state,
        acesByUserId: {
          ...state.acesByUserId,
          ...acesByUserId,
        },
      }
    },
    'environment/cleanStates': () => {
      return initialState
    },
  },
  effects: (dispatch) => {
    return {
      async loadAssocClient({forBatch = false}: { forBatch?: boolean } = {}, state): Promise<any> {
        const environmentId = getCurrentEnvironmentId(state)
        const normalized = UserSchemas.normalizeAssocClientResumes(await getAssocEnvironment(environmentId))
        return returnOrExecBatch(forBatch, () => dispatch.user.setEntities(normalized.entities))
      },
      async loadAces({forBatch = false}: { forBatch?: boolean } = {}, state): Promise<any> {
        const environmentId = getCurrentEnvironmentId(state)
        const acesByUserId = await getAces(environmentId)
        return returnOrExecBatch(forBatch, () => dispatch.user.setAcesByUserId(acesByUserId))
      },
      async createUser({data}: { data: ModelUser }, state) {
        const environmentId = getCurrentEnvironmentId(state)
        return createUser(data, environmentId)
          .then(newUser => {
            dispatch.user.loadAssocClient({})
            return newUser
          })
      },
      async createAssocClient({userEmail}: { userEmail: any }, state): Promise<UAC> {
        const environmentId = getCurrentEnvironmentId(state)
        return createAssocClient(userEmail, environmentId)
          .then(uac => {
            const normalized = UserSchemas.normalizeAssocClientResume(uac)
            dispatch.user.setEntities(normalized.entities)
            return uac
          })
      },
      async updateUser({id, data}: { id: any, data: any }, state): Promise<User> {
        const environmentId = getCurrentEnvironmentId(state)
        return updateUser(id, data, environmentId)
          .then(newData => {
            const normalized = UserSchemas.normalizeUserResume(newData)
            dispatch.user.setEntities(normalized.entities)
            return newData
          })
      },
      async updateUserAces({user, aces}: { user: any, aces: any }, state): Promise<AxiosResponse<any>> {
        const environmentId = getCurrentEnvironmentId(state)
        return updateUserAces(user.id, aces, environmentId)
          .then(newAces => {
            dispatch.user.setAcesByUserId({[user.id]: newAces})
            return newAces
          })
      },
      async updateAssocClientActive({forBatch = false, uac, active}: {
        forBatch?: boolean
        uac: { id: number | string },
        active: boolean
      }): Promise<any> {
        const normalized = UserSchemas.normalizeAssocClientResume(await updateAssocClient(uac.id, {active}))
        return returnOrExecBatch(forBatch, () => dispatch.user.setEntities(normalized.entities))
      },
      async resetPassword({id}: { id: number | string }, state): Promise<AxiosResponse<any>> {
        const environmentId = getCurrentEnvironmentId(state)
        return resetPassword(id, environmentId)
      },
      async deleteAssocClient({forBatch = false, uac}: {
        forBatch?: boolean
        uac: { id: string | number }
      }): Promise<any> {
        await deleteAssocClient(uac.id)
        return returnOrExecBatch(forBatch, () => dispatch.user.deleteEntity({
          type: 'userAssocClients',
          id: uac.id,
        }))
      },
    }
  },
})

import React, {FunctionComponent, useCallback, useMemo} from 'react'
import FormChartGenericCmp from "components/forms/chart/FormChartGenericCmp"
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import {ChartGenericDtoDetail, ChartTargetDtoDetail} from "types/charts"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import FormChartTargetCmp from "components/forms/chart/FormChartTargetCmp"
import {ChartFormProps} from "components/forms/Form.types"

const FormChart: FunctionComponent<ChartFormProps> = ({
                                                        data,
                                                        formType,
                                                        environmentId,
                                                        metaModel,
                                                        onConfirm,
                                                        dashboardSelection,
                                                        ...props
                                                      }) => {
  const loadDictionaryEntries = useCallback(
    (dictionaryCode: string) => loadDictionaryEntriesService(environmentId, dictionaryCode),
    [environmentId],
  )

  const formProps = useMemo(() => ({
    ...props,
    environmentId,
    metaModel,
    loadDictionaryEntries,
    onConfirm,
  }), [environmentId, loadDictionaryEntries, metaModel, onConfirm, props])

  if (formType.chartType === WidgetTypes.TARGET) {
    return <FormChartTargetCmp {...{
      ...formProps,
      data: data as ChartTargetDtoDetail,
      dashboardSelection,
    }}/>
  } else if (formProps.dashboardId) {
    return <FormChartGenericCmp {...{
      ...formProps,
      dashboardId: formProps.dashboardId,
      data: data as ChartGenericDtoDetail,
      dashboardSelection,
    }}/>
  } else {
    return null
  }
}

export default FormChart

import React, {FunctionComponent} from 'react'
import styled from "styled-components"
import {IconContainer} from "components/common/IconContainer"
import {MenuIcon} from "@heroicons/react/solid"
import {omit} from "lodash"

interface Props {
  id: string
  isDragging: number
}

const SortHandle: FunctionComponent<Props> = (props) => {
  return (
    <HandleWrapper {...{...omit(props, ["isDragging"]), $isdragging: props.isDragging}}>
      <IconContainer size={14} color={"var(--light-grey)"}>
        <MenuIcon/>
      </IconContainer>
    </HandleWrapper>
  )
}

export default SortHandle

const HandleWrapper = styled.div<{ $isdragging: number }>`
  vertical-align: bottom;
  display: inline-block;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

    cursor: ${({$isdragging}) => ($isdragging ? "grabbing" : "move")};
`


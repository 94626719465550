import {useImperativeHandle} from "react"

const useForwardFocusOnInput = (sourceRef: React.ForwardedRef<any>, inputRef: React.MutableRefObject<any>) => {
  useImperativeHandle(sourceRef,
    () => {
      return {
        focus() {
          inputRef.current.focus()
        },
        blur() {
          inputRef.current.blur()
        },
      }
    }, [inputRef])
}
export default useForwardFocusOnInput
import React, {FunctionComponent, memo, useCallback} from 'react'
import Language from 'language'
import {Checkbox, Col, Row} from 'antd'
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import {CheckboxAdditionalDetails} from "components/forms/chart/useRequirements"
import styled from "styled-components"
import ValueDescription from "components/forms/description/ValueDescription"
import MdView from "components/mdView/MdView"

interface Props {
  additionalDetail: CheckboxAdditionalDetails
  value?: any
  onChange?: (value: any) => void
}

const AdditionalDetailSelector = memo<Props>(function AdditionalDetailSelector({additionalDetail, ...props}) {
  const label = Language.get(`configuration-chart-additional-detail.${additionalDetail.textKey}.label`)

  return <FlexDiv>
    <SelectorCheckbox {...{
      label,
      ...props,
    }} />
    <HelpCol span={1}>
      {!additionalDetail.hideDescription && <ValueDescription>
        <MdView description={Language.get(`configuration-chart-additional-detail.${additionalDetail.textKey}.description`)}/>
      </ValueDescription>}
    </HelpCol>
  </FlexDiv>
})

export default AdditionalDetailSelector

const HelpCol = styled(Col)`
  display: flex;
  margin-left: 5px;
  justify-content: center;
`
const FlexDiv = styled(Row)`
  display: flex;
  flex-direction: row;
`

interface SelectorCheckbox {
  label: string
  value?: boolean
  onChange?: (newValue: boolean) => void
}

const SelectorCheckbox: FunctionComponent<SelectorCheckbox> = ({label, value, onChange}) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onChange?.(e.target.checked)
    },
    [onChange],
  )
  return <Checkbox
    checked={value}
    onChange={handleChange}>
    {label}
  </Checkbox>
}
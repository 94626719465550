import React, {FunctionComponent, useCallback, useMemo} from 'react'
import {Radio} from 'antd'
import styled from "styled-components"
import ChartFormatItem from "components/forms/selector/chartFormat/ChartFormatItem"
import {ChartFormat, ConfigurationWithDisabledReasons} from "components/forms/chart/types"
import useFixValue from "hooks/useFixValue"
import {RadioChangeEvent} from "antd/lib/radio/interface"

interface Props {
  value?: ChartFormat,
  onChange?: (data: ChartFormat) => void,
  configuration: ConfigurationWithDisabledReasons<ChartFormat>[]
  defaultFormat?: ChartFormat
}

const ChartFormatSelector: FunctionComponent<Props> = ({
                                                         configuration,
                                                         value: originalValue,
                                                         onChange,
                                                         defaultFormat,
                                                       }) => {
  const handleChange = useCallback((e: RadioChangeEvent) => {
      onChange?.(e.target.value)
    },
    [onChange],
  )

  const value: ChartFormat | undefined = useMemo(() => {
      const currentConfiguration = configuration.find(({type}) => type === originalValue)
      const availableConfiguration = configuration.find((conf) => conf.disabledReasons.length === 0)
      if (originalValue) {
        if (!currentConfiguration || currentConfiguration.disabledReasons.length > 0) {
          return defaultFormat
        }
        return originalValue
      }
      return originalValue === null ? availableConfiguration?.type : undefined
    },
    [configuration, defaultFormat, originalValue],
  )

  useFixValue(onChange, originalValue, value)

  return <FlexContainer>
    <RadioGroup onChange={handleChange} value={value}>
        {configuration.map(itemProps => <ChartFormatItem key={itemProps.type} {...{
          ...itemProps,
          checked: itemProps.type === value,
        }}/>)}
    </RadioGroup>
  </FlexContainer>
}

export default ChartFormatSelector

const RadioGroup = styled(Radio.Group)`
  // Override ant-radio-group in order to properly align content
  &&& {
    display: flex;
  }
  .ant-radio-button-wrapper {
    padding: 0;
    border-radius: 4px;
  }
  gap: 15px;
  width: 100%;
  border: none;
`

const FlexContainer = styled.div`
  display: flex;
`
import React, {memo, ReactNode} from "react"
import styled from "styled-components"
import MetricDetails from "components/metricDetails/MetricDetails"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"

interface MetricDisplayProps {
  metric: MetricSelectorValue
  metricAliasFormatter?: (metricAlias: string) => ReactNode
}

// eslint-disable-next-line react/display-name
const MetricDisplay = memo<MetricDisplayProps>(({
                                                  metric,
                                                  metricAliasFormatter = (metricAlias: string) => metricAlias,
                                                }) => {
  return <FlexDiv>
    <MetricDetails metric={metric}>
      <SpanWithCenteredText title={metric.metricAlias}>{metricAliasFormatter(metric.metricAlias)}</SpanWithCenteredText>
    </MetricDetails>
  </FlexDiv>
})

export default MetricDisplay

const FlexDiv = styled.div`
  display: flex;
  gap: 5px;
    align-items: center;
    height: 100%;
`

const SpanWithCenteredText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    align-content: center;
`
/* eslint-disable max-lines */
import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {WidgetRef} from "components/widgets/Widget"
import MetaModel from "classes/MetaModel"
import {ChartDtoDetail, ChartDtoDetailTypes} from "types/charts"
import {DataSelection} from "redux/models/currentDashboard"
import {NormalizedDashboard} from "schemas/workspace"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"
import {UseHandleChartAddType} from "hooks/useHandleAddChart"
import WidgetContainer from "components/widgetContainer/WidgetContainer"
import Loader from "components/loader/Loader"
import DividerAddWidget from "components/dashboard/header/dividerAddWidget/DividerAddWidget"
import styled from "styled-components"

interface Props {
  environmentId?: number,
  metaModel: MetaModel,
  dashboard: Pick<NormalizedDashboard, "id" | "title">
  dashboardSelection: DataSelection
  chart: ChartDtoDetailTypes,
  isLinkedTo?: boolean,
  editable?: boolean,
  resizing?: boolean,
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
  handleChartAdd: ReturnType<UseHandleChartAddType>
  handleChartAddFromClipboard: (buttonPosition?: ({
    x: number,
    y: number
  } | undefined)) => Promise<void> | null
  copiedChart: ChartDtoDetail
}

const WidgetContainerLoader = forwardRef<WidgetRef, Props>(function WidgetContainerLoader(
  props,
  refFromParent) {

  const [inLoadingBounds, setInLoadingBounds] = useState(false)
  const container = useRef<any>()

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setInLoadingBounds(true)
      }
    })
    observer.observe(container.current)
    return () => observer.disconnect()
  }, [])

  return <Container ref={container}>
    <Loader loading={!inLoadingBounds} className="widget-content"/>
    <WidgetContainer ref={refFromParent} {...{...props, inLoadingBounds}} />
    {inLoadingBounds && props.editable && <DividerAddWidget {...{
      environmentId: props.environmentId,
      metaModel: props.metaModel,
      dashboard: props.dashboard,
      dashboardSelection: props.dashboardSelection,
      copiedChart: props.copiedChart,
      handleChartAdd: (type) => props.handleChartAdd(type, {y: props.chart.y + props.chart.h, x: props.chart.x}),
      handleChartAddFromClipboard: () => props.handleChartAddFromClipboard({y: props.chart.y + props.chart.h, x: props.chart.x}),
      scrollToChart: props.scrollToChart,
      style: 'width: calc(100% - 20px);',
      GASource: "dashboardGrid",
    }}/>}
  </Container>
})

export default WidgetContainerLoader


const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: var(--big-border-radius);
`

import React, {memo} from "react"
import styled from "styled-components"
import {Button} from "antd"
import Language from "language"
import FormFooter from "components/forms/FormFooter"

interface MetricOptionsFormFooterProps {
  onSave: () => void
  onCancel: () => void
  onReset: () => void
}

const MetricOptionsFormFooter = memo<MetricOptionsFormFooterProps>(function MetricOptionsFormFooter({
                                                                      onSave,
                                                                      onCancel,
                                                                      onReset,
                                                                                                    }) {
  return <StyledRow>
    <FormFooter {...{
      altDefault: <Button key={2} type={'primary'} danger onClick={onReset}>{Language.get('button-reset')}</Button>,
      cancel: onCancel,
      submitting: false,
      submit: onSave,
      hasHelp: false,
      hasMargin: true,
    }} />
  </StyledRow>
})

export default MetricOptionsFormFooter

const StyledRow = styled.div`
    border-top: 1px solid var(--main-background-color);
  padding-top: 13px;
  padding-bottom: 13px;
`

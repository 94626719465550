import {GenericChartTypes} from "types/widgets"
import {ChartDtoDetail} from "types/charts"
import {ExtendedWidgetTypes, WidgetTypes} from "commons/dashboard/dashboard.types"

export function extendedWidgetTypeToChartConf(type: ExtendedWidgetTypes): Pick<ChartDtoDetail, "type" | "extraConf"> {
  switch (type) {
    case WidgetTypes.DIVIDER:
      return {type: WidgetTypes.DIVIDER, extraConf: {}}
    case WidgetTypes.TARGET:
      return {type: WidgetTypes.TARGET, extraConf: {}}
    case GenericChartTypes.AREA:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.AREA, limits: []}}
    case GenericChartTypes.LINE:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.LINE, limits: []}}
    case GenericChartTypes.BOXES:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.BOXES, limits: []}}
    case GenericChartTypes.BARS:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.BARS, limits: []}}
    case GenericChartTypes.TABLES:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.TABLES, limits: []}}
    case GenericChartTypes.PIE:
      return {type: WidgetTypes.GENERIC, extraConf: {displayType: GenericChartTypes.PIE, limits: []}}
    default:
      return {type: WidgetTypes.UNKNOWN, extraConf: {}}
  }
}

import {useMemo} from "react"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {GenericChartTypes} from "types/widgets"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"

export const useConsolidatedChartType = (chartTypes: ChartTypeWithDisabledReason[], originalValue: GenericChartTypes | undefined) => useMemo(() => {
    const indexOfCurrentType = chartTypes.findIndex(t => t.type === originalValue)
    if ((chartTypes.find(({type}) => type === originalValue)?.disabledReasons?.length ?? 0) > 0) {
      const priorityType = chartTypes.find(({disabledReasons}, i) => disabledReasons.length === 0 && i > indexOfCurrentType)?.type
      const destinationType = priorityType ?? chartTypes.find(({disabledReasons}) => disabledReasons.length === 0)?.type
      if (destinationType !== WidgetTypes.EXPORT && destinationType !== WidgetTypes.TARGET) {
        return destinationType as GenericChartTypes
      } else {
        return undefined
      }
    } else {
      return originalValue
    }
  },
  [chartTypes, originalValue],
)
/* eslint-disable max-lines */
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {useMemo} from "react"
import {GenericChartTypes} from "types/widgets"
import {pickBy} from "lodash"
import {
  ChartFormat,
  ChartType,
  ChartTypeWithDisabledReason,
  ConfigCache,
  DimensionCacheElement,
  LimitType,
  MetricCacheElement,
  MetricTarget,
  SlicerTarget,
  SortType,
  TargetAffect,
} from "./types"
import {doesFormatDiffer} from "components/forms/selector/metrics/utils"
import {Format} from "types/charts"
import {
  getAreaDisablingReason,
  hasMetricAsRatio,
  hasMetricMultiple,
  hasMetricWithGrowthAsRatio,
  hasNotEnoughData,
  percentageBarChartDisablingReason,
  useBarChartLimits,
  useDateSlicer,
  useDefaultAxisOrderBy,
  useGetLineChartLimits,
  useOrderByMetricDesc,
  useOrderBySlicersAsc,
  usePieOrderBys,
  useTableOrderBys,
  useWithAxisDateSlicer,
  useWithoutSlicer,
  useWithSlicer,
} from "./hooks"
import {isBarColorOverridable, isLineColorOverridable} from "components/forms/chart/useChartTypes.utils"

export function useGenericChartConfiguration(
  slicers: Pick<DimensionCacheElement, "code" | "isDefault" | "isAxis">[],
  metrics: Pick<MetricCacheElement, "isRatio" | "growth" | "format">[]): ChartType[] {
  const dateSlicer = useDateSlicer(slicers)
  const orderBySlicersAsc = useOrderBySlicersAsc()
  const orderByMetricDesc = useOrderByMetricDesc(slicers)

  const slicersWithoutDefault = slicers?.filter(slicer => !slicer.isDefault)
  const withMetricMultiple = hasMetricMultiple(metrics)
  const withMetricUnique = Boolean(metrics && metrics?.length === 1)
  const withMetricAsRatio = hasMetricAsRatio(metrics)
  const withMetricWithGrowthAsRatio = hasMetricWithGrowthAsRatio(metrics)
  const withSlicer = useWithSlicer(slicers)
  const withoutSlicer = useWithoutSlicer(slicers)
  const withSlicerMultiple = Boolean(slicers && slicers?.length > 1)

  const withMoreThanOneSlicer = Boolean(slicers.length > 2)

  const withSlicerUniqueWithoutDefaultSlicer = Boolean(slicersWithoutDefault && slicersWithoutDefault?.length === 1)
  const withSlicerWithoutDefaultSlicer = Boolean(slicersWithoutDefault && slicersWithoutDefault?.length > 0)

  const slicersWithoutDefaultAndAxis = slicersWithoutDefault.filter(slicer => !slicer.isAxis)

  const withMoreThanOneSlicerNotAnAxis = Boolean(slicersWithoutDefaultAndAxis?.length > 1)

  const withAxisDateSlicer = useWithAxisDateSlicer(slicers)
  const withDateSlicerNotAsAxis = Boolean(dateSlicer && !dateSlicer.isAxis)
  const withDateSlicer = Boolean(dateSlicer)

  const isFirstSlicerOfTypeDate = slicers && slicers.length > 0 && slicers[0].code === "date"

  const withMetricAndWithSlicer = !(withMetricMultiple && !withSlicerWithoutDefaultSlicer)
  const withMultipleSlicerAndMetric = !(withSlicerUniqueWithoutDefaultSlicer && withMetricUnique)

  const withMultipleMetricAndMultipleSlicers = (withMetricMultiple && slicers.length > 1) || withMoreThanOneSlicer

  const withOnlyOneSlicer = withSlicer && !withSlicerMultiple

  const notEnoughData = hasNotEnoughData(metrics, slicers)

  const formats = metrics.map(m => m.format)
  const hasMultipleFormat = formats.filter((format, i) => {
      return i !== 0
        && format
        && formats[i - 1]
        && doesFormatDiffer(format, formats[i - 1] ?? {} as Format)
    },
  ).length > 0

  const defaultAxisOrderBy = useDefaultAxisOrderBy(
    withSlicer,
    withAxisDateSlicer,
    orderBySlicersAsc,
    orderByMetricDesc,
  )
  const pieSort = usePieOrderBys(
    withSlicer,
    withMetricMultiple,
    withoutSlicer,
    orderBySlicersAsc,
    orderByMetricDesc,
  )
  const tableSort = useTableOrderBys(
    orderBySlicersAsc,
  )

  const getLineChartLimit = useGetLineChartLimits(
    isFirstSlicerOfTypeDate,
    withSlicerMultiple,
    withOnlyOneSlicer,
  )

  const barChartLimits = useBarChartLimits(
    isFirstSlicerOfTypeDate,
    withSlicer,
    withSlicerMultiple,
    withAxisDateSlicer,
    withDateSlicerNotAsAxis,
    withOnlyOneSlicer,
  )
  return useMemo(() => [
    {
      displayOrder: 1,
      type: GenericChartTypes.BARS,
      enableDateSlicer: true,
      metricTarget: MetricTarget.BAR,
      isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: barChartLimits,
      format: [
        {
          displayOrder: 0,
          type: ChartFormat.V_GROUPED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {},
        },
        {
          displayOrder: 1,
          type: ChartFormat.V_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {
            hasMultipleFormat,
            notEnoughData,
          },
        },
        {
          displayOrder: 2,
          type: ChartFormat.PERCENTAGE_V_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: percentageBarChartDisablingReason(metrics, slicers),
        },
        {
          displayOrder: 3,
          type: ChartFormat.H_GROUPED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {},
        },
        {
          displayOrder: 4,
          type: ChartFormat.H_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: {
            hasMultipleFormat,
            notEnoughData,
          },
        },
        {
          displayOrder: 5,
          type: ChartFormat.PERCENTAGE_H_STACKED,
          isMetricsFilterEnabled: false,
          isColorOverridable: isBarColorOverridable(slicers.length, metrics.length),
          disablingReasons: percentageBarChartDisablingReason(metrics, slicers),
        },
      ],
      isMetricsFilterEnabled: withOnlyOneSlicer && !withDateSlicer,
      disablingReasons: {
        withMultipleMetricAndMultipleSlicers,
      }
    },
    {
      displayOrder: 0,
      type: GenericChartTypes.BOXES,
      format: [],
      enableDateSlicer: false,
      metricTarget: MetricTarget.NUMBER,
      slicerTargets: [],
      isMetricsFilterEnabled: false,
      isColorOverridable: false,
      disablingReasons: {
        withSlicerWithoutDefaultSlicer,
      },
    },
    {
      displayOrder: 2,
      type: GenericChartTypes.TABLES,
      enableDateSlicer: true,
      isColorOverridable: false,
      metricTarget: MetricTarget.COLUMN,
      slicerTargets: [SlicerTarget.COLUMN],
      format: [],
      orderBys: [tableSort],
      limits: [
        {
          type: LimitType.SIMPLE,
          affect: TargetAffect.TABLE,
          mandatory: false,
          default: {
            value: undefined,
            enabled: true,
          },
          disablingReasons: {},
          displaySlicerOtherOption: false,
        },
      ],
      isMetricsFilterEnabled: true,
      disablingReasons: {},
    },
    {
      displayOrder: 3,
      type: GenericChartTypes.PIE,
      enableDateSlicer: true,
      isColorOverridable: withoutSlicer,
      metricTarget: MetricTarget.PART,
      slicerTargets: [SlicerTarget.COLOR],
      format: [
        {
          displayOrder: 0,
          type: ChartFormat.PIE_STANDARD,
          isMetricsFilterEnabled: false,
          isColorOverridable: withoutSlicer,
          disablingReasons: {},
        },
        {
          displayOrder: 1,
          type: ChartFormat.DONUT,
          isMetricsFilterEnabled: false,
          isColorOverridable: withoutSlicer,
          disablingReasons: {},
        },
      ],
      orderBys: pieSort,
      limits: [{
        type: LimitType.SIMPLE,
        mandatory: withSlicer,
        default: withSlicer ? {
          enabled: true,
          value: 20,
        } : undefined,
        disablingReasons: {},
        displaySlicerOtherOption: withSlicer,
        affect: TargetAffect.PART,
      }],
      isMetricsFilterEnabled: false,
      disablingReasons: {
        withMetricAsRatio,
        withMetricWithGrowthAsRatio,
        withMetricMultiple,
        withMoreThanOneSlicerNotAnAxis,
      },
    },
    {
      displayOrder: 4,
      type: GenericChartTypes.LINE,
      enableDateSlicer: true,
      isColorOverridable: isLineColorOverridable(slicers.length),
      metricTarget: MetricTarget.LINE,
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      defaultSlicer: {
        value: {
          type: "date",
          granularity: undefined,
        },
        disablingReasons: {
          withMetricAndWithSlicer,
          withMultipleSlicerAndMetric,
        },
      },
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: getLineChartLimit(TargetAffect.LINE),
      format: [{
        displayOrder: 0,
        type: ChartFormat.LINE_STANDARD,
        isMetricsFilterEnabled: false,
        isColorOverridable: isLineColorOverridable(slicers.length),
        disablingReasons: {},
      }],
      disablingReasons: {
        withMultipleMetricAndMultipleSlicers,
      },
      isMetricsFilterEnabled: false,
    },
    {
      displayOrder: 5,
      type: GenericChartTypes.AREA,
      enableDateSlicer: true,
      isColorOverridable: isLineColorOverridable(slicers.length),
      metricTarget: MetricTarget.AREA,
      slicerTargets: slicers.map((_, index) => index === 0 ? SlicerTarget.AXE : SlicerTarget.COLOR),
      defaultSlicer: {
        value: {
          type: "date",
          granularity: undefined,
        },
        disablingReasons: {
          withMetricAndWithSlicer,
          withMultipleSlicerAndMetric,
        },
      },
      orderBys: [
        defaultAxisOrderBy,
      ],
      limits: getLineChartLimit(TargetAffect.AREA),
      format: [
        {
          displayOrder: 0,
          type: ChartFormat.AREA_STANDARD,
          isMetricsFilterEnabled: false,
          isColorOverridable: isLineColorOverridable(slicers.length),
          disablingReasons: {},
        },
        {
          displayOrder: 1,
          type: ChartFormat.AREA_PERCENTAGE,
          isMetricsFilterEnabled: false,
          isColorOverridable: isLineColorOverridable(slicers.length),
          disablingReasons: {},
        },
      ],
      isMetricsFilterEnabled: false,
      disablingReasons: getAreaDisablingReason(metrics, slicers),
    } as ChartType,
  ], [withSlicerWithoutDefaultSlicer, slicers, defaultAxisOrderBy, barChartLimits, hasMultipleFormat, notEnoughData, metrics, withOnlyOneSlicer, withDateSlicer, withMultipleMetricAndMultipleSlicers, tableSort, withoutSlicer, pieSort, withSlicer, withMetricAsRatio, withMetricWithGrowthAsRatio, withMetricMultiple, withMoreThanOneSlicerNotAnAxis, withMetricAndWithSlicer, withMultipleSlicerAndMetric, getLineChartLimit])
}

const useTargetConfiguration = (): ChartType[] => useMemo(() => [{
  displayOrder: 0,
  type: WidgetTypes.TARGET,
  format: [],
  metricTarget: MetricTarget.TARGET,
  slicerTargets: [],
  disablingReasons: {},
  isMetricsFilterEnabled: false,
  isColorOverridable: false,
} as ChartType], [])

const useExportConfiguration = (): ChartType[] => {
  const SORT_SLICER_ALPHABETICAL = useMemo(() => ({
    column: 0,
    asc: true,
  }), [])

  return useMemo(() => [{
    displayOrder: 0,
    type: WidgetTypes.EXPORT,
    enableDateSlicer: true,
    isColorOverridable: false,
    metricTarget: MetricTarget.COLUMN,
    slicerTargets: [SlicerTarget.COLUMN],
    format: [],
    orderBys: [{
      default: SORT_SLICER_ALPHABETICAL,
      editable: true,
      affect: TargetAffect.TABLE,
      type: SortType.ARRAY,
      disablingReasons: {},
    }],
    limits: [],
    isMetricsFilterEnabled: true,
    disablingReasons: {},
  } as ChartType], [SORT_SLICER_ALPHABETICAL])
}

const useAppropriateConfiguration = (type: WidgetTypes, slicers: DimensionCacheElement[], metrics: MetricCacheElement[]) => {
  const genericConfiguration = useGenericChartConfiguration(slicers, metrics)

  const targetConfiguration = useTargetConfiguration()

  const exportConfiguration = useExportConfiguration()

  switch (type) {
    case WidgetTypes.GENERIC:
      return genericConfiguration
    case WidgetTypes.TARGET:
      return targetConfiguration
    case WidgetTypes.EXPORT:
      return exportConfiguration
    default:
      return []
  }
}

export function useChartTypes(
  type: WidgetTypes,
  configCache?: ConfigCache): ChartTypeWithDisabledReason[] {
  const slicers = useMemo(() => configCache?.slicers ?? [], [configCache])
  const metrics = useMemo(() => configCache?.metrics ?? [], [configCache])

  const configuration = useAppropriateConfiguration(type, slicers, metrics)

  return useMemo(() => configuration.map(test => ({
    ...test,
    ...test,
    disabledReasons: Object.keys(pickBy(test.disablingReasons, Boolean)),
    format: test.format.map(format => ({
        ...format,
        disabledReasons: Object.keys(pickBy(format.disablingReasons, Boolean)),
      })),
  })), [configuration])
}
import MdView from "components/mdView/MdView"
import React, {memo, useMemo} from "react"
import {concatInformationLinkToText} from "components/forms/description/utils"
import NumericDimension from 'themes/svg/filled/numericDimension.svg?react'
import TextDimension from 'themes/svg/filled/text.svg?react'
import {Dimension, SemanticType} from "classes/MetaModel"
import {TooltipAlias, TooltipContainer, TooltipContentElement, TooltipText} from "themes/SharedStyledComponent"
import {useDataDocDimensionLink} from "components/dataSourceDoc/DataSource.hooks"

interface DimensionTooltipContentProps {
  dimension: Pick<Dimension, "alias" | "semanticType" | "description" | "code">
}

// eslint-disable-next-line react/display-name
export const TooltipContentDimension = memo<DimensionTooltipContentProps>(({dimension}) => {
  const dimensionLink = useDataDocDimensionLink(dimension.code)

  const Icon = useMemo(() => {
    switch (dimension.semanticType) {
      case SemanticType.NUMBER:
        return NumericDimension
      default:
        return TextDimension
    }
  }, [dimension.semanticType])

  return <TooltipContainer>
    <TooltipAlias translate="no"><Icon/>{dimension.alias}</TooltipAlias>
    <TooltipContentElement>
      <TooltipText>
        <MdView description={concatInformationLinkToText(dimension.description, dimensionLink)} padding={0}/>
      </TooltipText>
    </TooltipContentElement>
  </TooltipContainer>
})
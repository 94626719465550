import React, {memo} from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components"
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import {IconContainer} from "components/common/IconContainer"
import {QuestionMarkCircleIcon} from "@heroicons/react/outline"
import {RenderFunction} from "antd/lib/_util/getRenderPropValue"

interface Props {
  content?: React.ReactNode | RenderFunction
}

const PopoverHelp = memo<Props>(function UserAceHelp({content, ...props}) {
  return <TooltipPopover size={'small'} content={content}>
    <div>
      <IconContainer>
        <StyleIcon {...props}/>
      </IconContainer>
    </div>
  </TooltipPopover>
})

// @ts-ignore
PopoverHelp.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
}

export default PopoverHelp

const StyleIcon = styled(QuestionMarkCircleIcon)`
  font-weight: 600;
  color: var(--border-color-base);
  line-height: 32px !important;
  font-size: 16px;
`

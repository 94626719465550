import React, {memo} from "react"
import {Select} from "antd"
import {BaseOption} from "components/forms/selector/select/SimpleSelect"
import styled from "styled-components"
import {captureEvent} from "services/GoogleAnalyticsService"
import {SelectProps} from "antd/lib/select"

interface SelectSelectorProps extends SelectProps {
  value?: string
  onChange?: (value: string) => void
  options: BaseOption[]
  gaevent: {
    category: string
    action: string
  }
}

// eslint-disable-next-line react/display-name
const SelectSelector = memo<SelectSelectorProps>(({
                                                    value,
                                                    onChange,
                                                    options,
                                                    gaevent,
                                                    ...props
                                                  }) => {
  return <StyledSelect {...{
    value,
    options,
    onChange: (newValue) => {
      if (gaevent) {
        captureEvent(gaevent)
      }
      onChange?.(newValue)
    },
    ...props,
    }}
  />
})

export default SelectSelector

const StyledSelect = styled(Select)`
  &&& .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  
  &&& .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
` as unknown as typeof Select
/* eslint-disable max-lines */
import useMemoDeepCached from "hooks/useMemoDeepCached"
import _, {uniq} from "lodash"
import {useMemo} from "react"
import MetaModel, {MetaModelView} from "classes/MetaModel"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ConfLimit, ConfOrderBy, GenericChartTypes} from "types/widgets"
import {ChartMetricDtoDetail, SlicerDtoDetailTypes} from "types/savedConfs"
import {extractSlicersDimension} from "commons/configuration"
import {ConfSlicer} from "types/charts"
import {DimensionOption} from "components/forms/selector/utils"
import {convertOrderByToCache, getDimensionLabel, getMetricLabel, isMetricRatio, isSlicerSimple} from "components/forms/chart/utils"
import {CacheElementType, ConfigCache, DimensionCacheElement, MetricCacheElement} from "components/forms/chart/types"
import {parseFormat} from "classes/workflows/query-workflows/genericDataSetUtil"
import {getMetricDef} from "components/forms/selector/metrics/utils"
import {hasMultipleViews} from "components/charts/Chart.utils"
import {ConfMetricFilterTypes} from "components/forms/selector/comps/box/filters"

export const createCache = (
  metaModel: MetaModel,
  viewsWithMetrics: MetaModelView[],
  dimensionOptions: DimensionOption[],
  metrics: ChartMetricDtoDetail[],
  slicers: ConfSlicer[],
  metricFilters: ConfMetricFilterTypes[],
  displayLabels?: boolean,
  orderBys?: ConfOrderBy[],
  limits?: ConfLimit[],
  displayType?: GenericChartTypes): ConfigCache => {
  const isMultiView = hasMultipleViews(metrics)
  const newCache = {
    metrics: metrics.map((m, index): MetricCacheElement => {
      const metricLimitApplied = (slicers.length === 0 || displayType === GenericChartTypes.TABLES) && limits && limits.length > 0 ? limits[0] : undefined
      const label = getMetricLabel(viewsWithMetrics, m, isMultiView)
      const labelOccurence = _.countBy(metrics.slice(0, index), metric => getMetricLabel(viewsWithMetrics, metric, isMultiView))[label] ?? 0
      const id = `${label}-${labelOccurence}`

      const metricDef = getMetricDef(metaModel, m)

      return {
        type: CacheElementType.METRIC,
        id,
        code: m.metricCode,
        viewAlias: m.viewAlias,
        viewCode: m.viewCode,
        metricAlias: m.metricAlias,
        label,
        isAxis: false,
        growth: m.growth,
        growthInvert: m.extraConf?.growthInvert,
        displayLabel: index === 0 && displayLabels,
        limit: metricLimitApplied?.limitSeries,
        hideOthers: metricLimitApplied?.hideOthers,
        limitDefault: metricLimitApplied?.isDefault,
        format: metricDef ? parseFormat({
          ...m,
          metricDef,
        }) : undefined,
        isRatio: isMetricRatio(m, metaModel),
        additionalFilters: m.additionalFilters,
        ...convertOrderByToCache(id, slicers.length + index, orderBys),
      }
    }),
    slicers: [] as DimensionCacheElement[],
    metricFilters,
  }

  const indexOfDateSlicer = slicers.findIndex(s => s.type === "date")
  newCache.slicers = slicers.map((s, index) => {
    const label = getDimensionLabel(dimensionOptions, s)
    const labelOccurence = label ? _.countBy(slicers.slice(0, index), slicer => getDimensionLabel(dimensionOptions, slicer))[label] ?? 0 : 0
    const id = `${label}-${labelOccurence}`
    const slicerLimitApplied = displayType !== GenericChartTypes.TABLES && limits && limits.length > index ? limits[index] : undefined

    const newSort = convertOrderByToCache(id, index, orderBys)
    const cacheSlicer: DimensionCacheElement = {
      type: CacheElementType.SLICER,
      id,
      code: s.type === "dimension" ? s.dimensionCode : "date",
      label,
      hasRefDate: s.type === "date",
      ...newSort,
      limit: slicerLimitApplied?.limitSeries,
      limitDefault: slicerLimitApplied?.isDefault,
      hideOthers: slicerLimitApplied?.hideOthers,
      isDefault: s.isDefault,
      isSimple: isSlicerSimple(s, metaModel),
      isAxis: false,
    }
    switch (displayType) {
      case GenericChartTypes.LINE:
      case GenericChartTypes.AREA:
      case GenericChartTypes.BARS: {
        return {
          ...cacheSlicer,
          isAxis: index === 0,
        }
      }
      default:
        return {
          ...cacheSlicer,
          isAxis: indexOfDateSlicer === -1 ? false : index === indexOfDateSlicer,
        }
    }
  })
  return newCache
}

export const getAvailableDimensions = (metaModel: MetaModel, requiredViewsCode: string[]) => {
  return metaModel.listDimensions()
    // keep only those related to ALL requiredViews
    .filter(dimension => !requiredViewsCode.find(requiredViewCode => !metaModel.checkDimensionInView(dimension, requiredViewCode)))
}


export const useRequirements = (
  metaModel: MetaModel,
  uniqueViewCode: string | null,
  slicers: SlicerDtoDetailTypes[] = [],
  metrics?: ChartMetricDtoDetail[]) => {
  const availableViews = useMemoDeepCached(
    () => {
      const uniqueView = uniqueViewCode ? metaModel.getView(uniqueViewCode) : undefined
      const uniqueViewAsList = uniqueView ? [uniqueView] : []
      return uniqueViewCode
        ? uniqueViewAsList
        : metaModel.listViews().filter(view => slicers?.length === 0 || (slicers && extractSlicersDimension(slicers).every((slicer) =>
          metaModel.checkDimensionInView(slicer.dimensionCode, view),
        )))
    },
    [uniqueViewCode, metaModel, slicers],
  )
  const unavailableViews = useMemoDeepCached(
    () => uniqueViewCode
      ? []
      : metaModel.listViews().filter(view => !availableViews.find((availableView) => availableView?.code === view.code)),
    [uniqueViewCode, metaModel, availableViews],
  )

  const requiredViewsCode = useMemoDeepCached<string[]>(
    () => uniqueViewCode ? [uniqueViewCode] : uniq(metrics?.map(({viewCode}) => viewCode)),
    [uniqueViewCode, metrics],
  )

  const availableDimensions = useMemo(() => getAvailableDimensions(metaModel, requiredViewsCode),
    [metaModel, requiredViewsCode],
  )
  const unavailableDimensions = useMemo(() => metaModel.listDimensions()
      // keep only those related to ALL requiredViews
      .filter(dimension => !availableDimensions.find((availableDimension) => availableDimension.code === dimension.code)),
    [metaModel, availableDimensions],
  )

  return {
    availableViews,
    unavailableViews,
    requiredViewsCode,
    availableDimensions,
    unavailableDimensions,
  }
}

export enum AdditionalDetailsType {
  "checkbox" = "checkbox",
  "select" = "select",
}

export interface AdditionalDetails {
  type: AdditionalDetailsType.checkbox
  textKey: string
  hideDescription?: boolean
}

export interface CheckboxAdditionalDetails extends AdditionalDetails {
  type: AdditionalDetailsType.checkbox
}

export const useAdditionalDetails = (type: WidgetTypes, displayType?: GenericChartTypes): CheckboxAdditionalDetails[] => useMemoDeepCached(() => [
    ...((displayType === GenericChartTypes.AREA || displayType === GenericChartTypes.LINE || displayType === GenericChartTypes.BARS || displayType === GenericChartTypes.PIE) ? [{
      type: AdditionalDetailsType.checkbox,
      textKey: 'displayLabels',
    } as CheckboxAdditionalDetails] : []),
    ...((displayType === GenericChartTypes.TABLES) ? [{
      type: AdditionalDetailsType.checkbox,
      textKey: 'ignoreMetrics0',
    } as CheckboxAdditionalDetails, {
      type: AdditionalDetailsType.checkbox,
      textKey: 'asPercentage',
      hideDescription: true,
    } as CheckboxAdditionalDetails] : []),
    ...((type === WidgetTypes.TARGET) ? [{
      type: AdditionalDetailsType.checkbox,
      textKey: 'printPrevisions',
    } as CheckboxAdditionalDetails, {
      type: AdditionalDetailsType.checkbox,
      textKey: 'ignoreSeasonality',
    } as CheckboxAdditionalDetails] : []),
  ].filter(Boolean),
  [type, displayType],
)

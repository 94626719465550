import React, {memo, ReactNode, useCallback, useEffect, useState} from 'react'
import {SelectableOption} from "types/select"
import styled from 'styled-components'
import Language from "language"
import {CheckCircleIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"

export interface OptionListPagination {
  offset: number
  pageSize: number
}

interface Props {
  options: SelectableOption[]
  search?: string
  pagination: OptionListPagination
  onSelect: (option: SelectableOption, newSelectedState: boolean) => void
  onPaginationChange: (newPagination: OptionListPagination) => void
  noDataTextKey?: string
}

const OptionsList: (props: Props) => ReactNode = memo<Props>(function OptionsList({
                                                                                    pagination = {
                                                                                      pageSize: 20,
                                                                                      offset: 0,
                                                                                    },
                                                                                    options,
                                                                                    onSelect,
                                                                                    onPaginationChange,
                                                                                    noDataTextKey = "no-data",
                                                                                    search,
                                                                                  }) {
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    setCurrPage(1)
  }, [search])

  const onScroll = useCallback(() => {
    if (scrollRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = scrollRef.current
      if (scrollTop + clientHeight >= scrollHeight) {
        setCurrPage(currPage + 1)
        onPaginationChange({
          offset: (currPage * pagination.pageSize),
          pageSize: pagination.pageSize,
        })
        scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight - ((scrollHeight / options.length) * ((pagination.pageSize / 2) - 1)))
      }
    }
  }, [currPage, onPaginationChange, options.length, pagination.pageSize])

  return <StyledOptionList ref={scrollRef} onScroll={onScroll}>
    {options.length > 0 ? options.map((option, i) => <StyledFilterOption key={i}>
      <StyledOption onClick={() => onSelect(option, !option.selected)}
                    title={option.label}
                    currentcolor={option.selected ? 1 : 0}>
        {option.selected && <SelectedIcon>
          <IconContainer margin={10}>
            <CheckCircleIcon/>
          </IconContainer>
        </SelectedIcon>}
        <NonSelectedLabel $selected={option.selected ? 1 : 0}>{option.label}</NonSelectedLabel>
        <SelectedLabel $selected={option.selected ? 1 : 0}>{option.label}</SelectedLabel>
        </StyledOption>
      </StyledFilterOption>,
    ) : <NoData>
      {Language.get(noDataTextKey)}
    </NoData>}
  </StyledOptionList>
})

export default OptionsList

const NoData = styled.div`
  padding: 10px;
`

const SelectedIcon = styled.div`
  position: absolute;
`

const NonSelectedLabel = styled.div<{ $selected: number }>`
    opacity: ${({$selected}) => $selected ? '0' : '1'};
`

const SelectedLabel = styled.div<{ $selected: number }>`
  display: ${({$selected}) => $selected ? '' : 'none'};
  position: absolute;
  margin-left: 30px;
  width: calc(100% - 42px);
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`

const StyledOption = styled.div<{ currentcolor: number }>`
  background-color: ${props => props.currentcolor ? "var(--option-background)" : "transparent"};
  color: ${props => props.currentcolor ? "var(--primary)" : "inherit"};
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  padding: 5px 12px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  &:hover{
    background-color: var(--option-list-hover-bg);
    color: var(--primary);
  }
`


const StyledOptionList = styled.div`
  max-height: 302px;
  max-width: 600px;
  overflow-y: auto;
  padding-right: 4px;
`

const StyledFilterOption = styled.div`
  display: flex;
  line-height: 22px;
`
import {captureEvent} from "services/GoogleAnalyticsService"
import {Input, InputNumber, InputProps} from "antd"
import React, {memo, useRef} from "react"
import {InputNumberProps} from "antd/lib/input-number"


interface GAInput {
  type: "numeric" | "textarea" | undefined
  gaevent?: {
    category: string
    action: string
  }
}
interface NumericGAInput extends GAInput, InputNumberProps {
  type: "numeric"
}

interface StringGAInput extends GAInput, InputProps {
  type: "textarea" | undefined
}


export type GAInputPropsTypes = NumericGAInput | StringGAInput


// eslint-disable-next-line react/display-name
const GAInput = memo<GAInputPropsTypes>((props) => {
  const baseValue = useRef(props.value)

  switch(props.type) {
    case "numeric":
      return <InputNumber {...{
        ...props,
        onBlur: () => {
          if (props.gaevent && baseValue.current !== props.value) {
            baseValue.current = props.value
            captureEvent(props.gaevent)
          }
        },
      }}/>
    default:
      return <Input {...{
        ...props,
        onBlur: () => {
          if (props.gaevent && baseValue.current !== props.value) {
            baseValue.current = props.value
            captureEvent(props.gaevent)
          }
        },
      }}/>
  }
})

export default GAInput
import {Input} from 'antd'
import Language from "language"
import React, {forwardRef, memo, useImperativeHandle, useMemo, useRef, useState} from "react"
import styled from "styled-components"
import {isString} from "lodash"
import {isEmpty} from "commons/object.utils"
import {SearchIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"

export type TargetedElement<T> = (record: T) => string[]

export const filterData = <T, >(data: T[], targetedElement: TargetedElement<T>, searchValue: string) => data.filter(record => targetedElement(record).filter(r => !isEmpty(r) && r.toLowerCase().includes(searchValue.toLowerCase())).length > 0)

interface SearchProps {
  width?: number | string
  onChange: (newData: string) => void
  searchedFieldKey?: string
}

export interface SearchRef {
  clearSearch: () => void
}

// eslint-disable-next-line react/display-name
export const Search = memo(forwardRef<SearchRef, SearchProps>(({width = 100, onChange, searchedFieldKey}, ref) => {
  const placeholder = useMemo(() => Language.get("input-type-to-search", searchedFieldKey ? Language.get(searchedFieldKey) : undefined), [searchedFieldKey])
  const inputRef = useRef<any>()
  const [value, setValue] = useState<string>()

  useImperativeHandle(ref, () => ({
    clearSearch: () => {
      setValue("")
    },
  }))

  return <StyledContainer>
    <StyledInput ref={inputRef}
                 title={placeholder}
                 $width={width}
                 value={value}
                 onClick={(e) => e.preventDefault()}
                 onChange={(event) => {
                   onChange(event.target.value)
                   setValue(event.target.value)
                 }}
                 placeholder={placeholder} prefix={<IconContainer><SearchIcon/></IconContainer>}/>
  </StyledContainer>
}))
const StyledContainer = styled.div`
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border: 1px solid var(--border-color-base);
    box-shadow: none !important;
  }
  
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid var(--border-color-base);
    box-shadow: none !important;
  }
`

const StyledInput = styled(Input)<{ $width: number | string }>`
    width: ${props => isString(props.$width) ? props.$width : `${props.$width}%`};
  margin-top: 10px;
  margin-bottom: 10px;
  
  .ant-input {
    background-color: transparent;
  }
  
  &:hover { 
    background-color: white;
  }

  @media (max-width: 1600px) {
    width: 100%;
  }
`
import {Col, Row} from "antd"
import React, {memo, useState} from "react"
import styled from "styled-components"
import DisplayIcon from 'themes/svg/outlined/display.svg?react'
import {ColorName, strToColor} from "components/charts/Chart.Theme"
import Language from "language"
import {useHasDisplayOptionApplied} from "components/metricDisplay/MetricDisplay.hooks"
import {ContentCol, DeleteContainer, FlexMetricOptionRow} from "themes/SharedStyledComponent"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {IconContainer} from "components/common/IconContainer"
import {XCircleIcon} from "@heroicons/react/solid"
import {isMetricOptionResumeClickable} from "components/forms/selector/metrics/utils"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"

interface MetricDisplayResumeProps {
  value?: MetricSelectorValue
  setActiveKey?: (key: MetricOptionsFormKeys) => void
  onDelete?: (key: (keyof ConfMetricWithActivationState)[]) => void
  iconSize: number
  iconSpan: number
}

// eslint-disable-next-line react/display-name
const MetricDisplayResume = memo<MetricDisplayResumeProps>(({
                                                              value,
                                                              setActiveKey,
                                                              onDelete,
                                                              iconSize,
                                                              iconSpan,
                                                            }) => {
  const [isDeleteDisplayed, setIsDeleteDisplayed] = useState(false)
  const hasOverridenDisplayProperty = useHasDisplayOptionApplied(value)

  const isClickable = isMetricOptionResumeClickable(setActiveKey)

  return <DivWithFullWidth>
    {hasOverridenDisplayProperty && <DisplayResume>
      <DisplayPreview>
        <Row>
          <Col span={iconSpan} onClick={() => {
            setActiveKey?.(MetricOptionsFormKeys.display)
          }}>
            <FlexMetricOptionRowWithAutoHeight $clickable={isClickable}>
              <IconContainer size={iconSize}>
                <DisplayIcon/>
              </IconContainer>
            </FlexMetricOptionRowWithAutoHeight>
          </Col>
          <ContentCol span={19} onClick={() => {
            setActiveKey?.(MetricOptionsFormKeys.display)
          }}>
            <FlexDiv>
              {value?.extraConf?.decimals && <FlexMetricOptionRow $clickable={isClickable} onMouseOver={() => setIsDeleteDisplayed(true)}
                                                                  onMouseLeave={() => setIsDeleteDisplayed(false)}>
                <span>{Language.get("configuration-metric-options.display.decimals")}: {value.extraConf.decimals}</span>
                {isDeleteDisplayed && onDelete && <DeleteContainer>
                  <IconContainer clickable={true} size={14} color={"var(--light-grey)"}>
                    <XCircleIcon onClick={() => onDelete(["decimals", "details", "units", "color"])}/>
                  </IconContainer>
                </DeleteContainer>}
              </FlexMetricOptionRow>}
              {(value?.extraConf?.units || value?.extraConf?.details) && <FlexMetricOptionRow $clickable={isClickable}>
                <span>{Language.get("configuration-metric-options.display.details.title")}: {value.extraConf.units} {value?.extraConf?.details?.type === "prefix" ? `${value?.extraConf?.details.value} -` : `- ${value?.extraConf?.details?.value ?? ''}`}</span>
              </FlexMetricOptionRow>}
              {value?.extraConf?.color && <FlexMetricOptionRow $clickable={isClickable}>
                <FlexMetricOptionRow
                  $clickable={isClickable}><span>{Language.get("configuration-metric-options.display.color")}: </span><Color
                  $color={value.extraConf.color}/></FlexMetricOptionRow>
              </FlexMetricOptionRow>}
            </FlexDiv>
          </ContentCol>
        </Row>
      </DisplayPreview>
    </DisplayResume>}
  </DivWithFullWidth>
})

export default MetricDisplayResume

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`

const FlexMetricOptionRowWithAutoHeight = styled(FlexMetricOptionRow)`
    height: auto;
`

const Color = styled.div<{ $color: ColorName }>`
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background-color: ${({$color}) => strToColor.get($color)};
    margin-left: 5px;
`

const DisplayPreview = styled.div`
    display: flex;
    flex-direction: column;
`

const DivWithFullWidth = styled.div`
    width: 100%;
    cursor: pointer;
`

const DisplayResume = styled.div`
    align-items: center;
    gap: 10px;
`
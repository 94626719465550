import {FilterOperator, FilterType, ScalarQueryPredicate, ValuesQueryPredicate} from "components/forms/selector/comps/box/filters"

export const getDefaultMetricPredicate = (): ScalarQueryPredicate => ({
  operator: FilterOperator.GT,
  value: {
    "@type": FilterType.SCALAR,
    value: undefined,
  },
  negate: false,
})

export const getDefaultDimensionPredicate = (): ValuesQueryPredicate => ({
  operator: FilterOperator.IN,
  value: {
    "@type": FilterType.DICT_ENTRIES,
    entries: [],
  },
  negate: false,
})
export type HexColor = `#${string}`;

export enum Colors {
  "blue_border" = '#425FF0',
  "blue_pastel" = '#7CE0DA',
  "orange_border" = '#FA9866',
  "orange_pastel" = '#F6D010',
  "red_border" = '#EE6873',
  "red_pastel" = '#FFB9B3',
  "green_border" = '#83CF60',
  "green_pastel" = '#2A9E9C',
  "purple_border" = '#A962F0',
  "purple_pastel" = '#C8A1FF',
}

export enum ColorName {
  "blue-border" = "blue-border",
  "blue-pastel" = "blue-pastel",
  "orange-border" = "orange-border",
  "orange-pastel" = "orange-pastel",
  "red-border" = "red-border",
  "red-pastel" = "red-pastel",
  "green-border" = "green-border",
  "green-pastel" = "green-pastel",
  "bluegreen-border" = "bluegreen-border",
  "bluegreen-pastel" = "bluegreen-pastel"
}

export const strToColor = new Map<ColorName, Colors>([
  [ColorName["blue-border"], Colors.blue_border],
  [ColorName["blue-pastel"], Colors.blue_pastel],
  [ColorName["orange-border"], Colors.orange_border],
  [ColorName["orange-pastel"], Colors.orange_pastel],
  [ColorName["red-border"], Colors.red_border],
  [ColorName["red-pastel"], Colors.red_pastel],
  [ColorName["green-border"], Colors.green_border],
  [ColorName["green-pastel"], Colors.green_pastel],
  [ColorName["bluegreen-border"], Colors.purple_border],
  [ColorName["bluegreen-pastel"], Colors.purple_pastel],
])


export const textColor = new Map<ColorName, string>([
  [ColorName["blue-border"], "white"],
  [ColorName["blue-pastel"], "black"],
  [ColorName["orange-border"], "white"],
  [ColorName["orange-pastel"], "black"],
  [ColorName["red-border"], "white"],
  [ColorName["red-pastel"], "white"],
  [ColorName["green-border"], "white"],
  [ColorName["green-pastel"], "white"],
  [ColorName["bluegreen-border"], "white"],
  [ColorName["bluegreen-pastel"], "black"],
])

export const EChartTheme = {
  color: [
    Colors.blue_border,
    Colors.red_border,
    Colors.orange_border,
    Colors.green_border,
    Colors.purple_border,
    Colors.blue_pastel,
    Colors.red_pastel,
    Colors.orange_pastel,
    Colors.green_pastel,
    Colors.purple_pastel,
  ],
}
export const echartColorOther: HexColor = '#94949E'
export const echartColorNa: HexColor = '#BEC5CC'

// Call this for each data point to conserve its color even if array was rearranged.
export function extractColor(idx?: number, colors?: string []): string | undefined {
  colors = colors ?? EChartTheme.color
  return idx === undefined ? undefined : colors[idx % colors.length]
}

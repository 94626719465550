import React, {memo, useMemo} from 'react'
import {useSelector} from "react-redux"
import {getCurrentMetaModel} from "redux/environment.selector"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import {DataSourceObjectType} from "components/dataSourceDoc/DataSource.types"
import {DataSourceList} from "components/dataSourceDoc/DataSource.List"
import {Navigate, Route, Routes} from "react-router-dom"
import {DimensionWithView, MetricDef} from "components/charts/line/LineChart.types"
import {DetailsMetric} from "components/dataSourceDoc/details/Details.Metric"
import {DetailsDimension} from "components/dataSourceDoc/details/Details.Dimension"
import {MetaModelView} from "classes/MetaModel"
import {DetailsView} from "components/dataSourceDoc/details/Details.View"
import {ExpandedWorkspace} from "redux/models/workspace"
import useLoadExports from "hooks/useLoadExports"

export type MatchParams = {
  view: string;
  type: DataSourceObjectType
  code: string
}

export interface Column {
  type: DataSourceObjectType
  alias: string
  code: string
  description: string
  metric?: MetricDefWithView
  dimension?: { alias: string, code: string, dictionaryCode: string }
  view?: { alias: string, code: string }
  key: string
}

interface MetricDefWithView extends MetricDef {
  view: MetaModelView
}

// eslint-disable-next-line react/display-name
const DataSource = memo<any>(() => {
  const currentWorkspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const currentExports = useLoadExports(false)
  // @ts-ignore
  const views = useSelector(getCurrentMetaModel).listViews()

  const metrics = useMemo(() => {
    const uniqMetrics: MetricDefWithView[] = []

    views.forEach(v => {
      v.metrics.forEach((m) => {
        if (!uniqMetrics.find(uniqMetric => uniqMetric.code === m.code)) {
          uniqMetrics.push({
            ...m,
            view: v,
          })
        }
      })
    })
    return uniqMetrics
  }, [views])

  const dimensions = useMemo(() => {
    const uniqDimensions: DimensionWithView[] = []

    views.forEach(v => {
      v.dimensions.forEach((d) => {
        if (!uniqDimensions.find(uniqDimension => uniqDimension.code === d.code)) {
          uniqDimensions.push({
            ...d,
            view: v,
          })
        }
      })
    })
    return uniqDimensions
  }, [views])

  const data: Column[] = useMemo(() => [
    ...dimensions.map((dimension, index) => ({
      ...dimension,
      type: DataSourceObjectType.DIMENSION,
      dimension,
      key: `${index}${dimension.code}`,
    })), ...metrics.map((metric, index) => ({
      ...metric,
      type: DataSourceObjectType.METRIC,
      metric,
      key: `${index}${metric.code}`,
    })), ...views.map((view, index) => ({
      ...view,
      type: DataSourceObjectType.VIEW,
      view,
      key: `${index}${view.code}`,
    })),
  ], [dimensions, metrics, views])

  return <Routes>
    <Route path="/" element={<DataSourceList data={data} currentWorkspace={currentWorkspace!}/>}/>
    <Route path={`${DataSourceObjectType.METRIC}/:code`}
           element={<DetailsMetric data={data} views={views} currentWorkspace={currentWorkspace}
                                   currentExports={currentExports}/>}/>
    <Route path={`${DataSourceObjectType.DIMENSION}/:code`}
           element={<DetailsDimension data={data} views={views} currentWorkspace={currentWorkspace}
                                      currentExports={currentExports}/>}/>
    <Route path={`${DataSourceObjectType.VIEW}/:code`} element={<DetailsView views={views} currentWorkspace={currentWorkspace!}/>}/>
    <Route
      path="*"
      element={<Navigate to="/" replace/>}
    />
  </Routes>
})

export default DataSource

import {createSelector} from "reselect"
import {RootState} from "redux/store"


export const getConsts = (state: RootState) => state.appEnvironment.consts

export const getUser = (state: RootState) => state.appEnvironment.user

export const getPermission = createSelector(
  (state: RootState) => state.appEnvironment.permissions,
  (permissions) => (perm: string) => permissions[perm],
)

export const getClientAllowedEmailFqdns = (state: RootState) => state.appEnvironment?.environment.allowedEmailFqdns

export const hasAuthToken = (state: RootState) => Boolean(state.appEnvironment.authToken)

export const getDashboardEditMode = (state: RootState) => state.appEnvironment.dashboardEditMode

import ChartWorkflow, {DimensionGlobal} from 'classes/workflows/chart-workflows/ChartWorkflow'
import {extractAsImageSrc} from "commons/format/formatter"
import {isEqual} from 'lodash'
import dayjs from "dayjs"
import {TableQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import {State} from "classes/workflows/main-workflows/FormWorkflow"
import {Column} from "types/charts"

const maxColumnWidth = 160
const expandColumnWidth = 20
const headerHeight = 57
const totalRowHeight = 36
const defaultRowHeight = 36
const imgRowHeight = 117
const enforcedLegendHeight = 27
const paginationHeight = 38
const searchHeight = 38
const marginHeight = 20

export default class TableChartWorkflow extends ChartWorkflow {
  columns: Column[]
  // @ts-ignore
  chartData: TableQueryWorkflowResult
  // @ts-ignore
  maxRow: number

  constructor(widgetId: string, chartData: TableQueryWorkflowResult, dimension: DimensionGlobal, setState: (newState: State) => void) {
    super(widgetId, dimension, chartData, setState)
    this.columns = []
    this.dimensions = {
      global: null,
      chart: {
        width: 0,
        height: 0,
      },
    }
    this.draw(chartData, dimension)
  }

  // @ts-ignore
  update(chartData: TableQueryWorkflowResult, dimension: DimensionGlobal) {
    this.draw(chartData, dimension)
  }

  unsubscribe() {
    super.unsubscribe()
  }


  draw(chartData: TableQueryWorkflowResult, dimension: DimensionGlobal) {
    const {chartData: currentChartData, dimensions: currentDimensions, maxRow: currentMaxRow} = this
    const dataChanged = !isEqual(currentChartData, chartData)
    const dataHasBeenInitialized = currentChartData?.data?.length === 0 && chartData?.data?.length > 0
    const selectionChanged = !isEqual(currentChartData?.chartSelection?.search, chartData?.chartSelection?.search)
    if (dataChanged) {
      this.chartData = chartData
    }
    if (!(isEqual(currentDimensions.global, dimension))) {
      this.dimensions = this.recomputeDimensions(dimension)
    }
    const withTotalRowCount = Boolean(this.chartData.totalData)
    if (dataChanged || currentDimensions.chart?.width !== this.dimensions.chart?.width) {
      this.columns = this.buildColumns(this.dimensions.chart?.width)
    }
    const isColumnAddedOrRemoved = currentChartData && chartData
      && currentChartData.data && chartData.data
      && currentChartData.data.length > 0 && chartData.data.length > 0
      && currentChartData.data[0].length !== chartData.data[0].length
    if (dataHasBeenInitialized || selectionChanged || !currentMaxRow || currentDimensions.chart?.height !== this.dimensions.chart?.height || isColumnAddedOrRemoved) {
      this.maxRow = this.computeMaxRow(this.dimensions.chart?.height, this.columns, withTotalRowCount)
    }
    if (currentMaxRow === this.maxRow) {
      this.setState({
        chartData,
        chartDimensions: this.dimensions.chart,
        columns: this.columns,
        withTotalRow: withTotalRowCount,
      })
    } else {
      // only set maxRow to launch a re-fetch, this avoid a flickering on display
      this.setState({maxRow: this.maxRow})
    }
  }

  recomputeDimensions(global: DimensionGlobal) {
    return {
      global,
      chart: {
        width: global.width - 2,
        height: global.height - enforcedLegendHeight - searchHeight - paginationHeight - marginHeight,
      },
    }
  }

  buildColumns(chartWidth?: number) {
    return this.chartData?.columns?.map((col, i) => ({
        ...col,
        hidden: (chartWidth ?? 0) - expandColumnWidth < maxColumnWidth * (i + 1),
        processValue: (v: string) => {
          if (col.nature === 'date') {
            return dayjs(v, 'YYYY-MM-DD').format('L')
          } else if (col.nature === 'dimension') {
            return String(v)
          } else {
            return v
          }
        },
    })) ?? []
  }

  computeMaxRow(chartHeight: number | undefined, columns: Column[], withTotalRow: boolean) {
    const withImg = columns.find((col, colIndex) => col.nature === 'dimension'
      // @ts-ignore col.format can be removed but I only want to type
      && this.chartData.data?.find((record) => extractAsImageSrc(String(record[colIndex]), col.format)))
    const freeHeight = (chartHeight ?? 0) - headerHeight - (withTotalRow ? totalRowHeight : 0)
    const maxRows = Math.floor(freeHeight / (withImg ? imgRowHeight : defaultRowHeight))
    return Math.max(1, maxRows)
  }
}

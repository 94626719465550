// Check if variable is empty or not (undefined or null)
import _ from "lodash"

export const isEmpty = <T>(value: T): boolean => value === undefined || value === null

export const notEmpty = <TValue>(value: TValue): value is NonNullable<TValue> => !isEmpty(value)

export const convertStringToNumber = (value?: string) => _.isEmpty(value) ? undefined : Number(value)

export const convertNumberToString = (value?: number) => isEmpty(value) ? "" : String(value)

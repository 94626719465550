import API from './api'
import {sortBy} from 'lodash'
import {MetricDef} from "components/charts/line/LineChart.types"
import {OptionListPagination} from "components/forms/selector/filters/addFilterPanel/OptionList"
import {SemanticType} from "classes/MetaModel"
import {FilterOption} from "types/select"

interface Dimension {
  "@type": string;
  code: string;
  alias: string;
  description: string;
  dictionaryCode: string;
  semanticType: SemanticType
}

export interface View {
  code: string;
  tableName: string;
  alias: string;
  description: string;
  metrics: MetricDef[];
  dimensions: Dimension[];
}

export const getViews = (environmentId: number) => new Promise<View[]>((resolve, reject) => {
  API.instanceData.get<void, View[] | undefined>(`/view/byEnvironment/${environmentId}`)
    .then(views => {
      if (!views) {
        resolve([])
        return
      }
      views.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
      resolve(views.map(view => {
        view.dimensions.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
        view.metrics.sort((a, b) => a.alias.toLocaleLowerCase().localeCompare(b.alias.toLocaleLowerCase()))
          return view
        }),
      )
    }, reject)
})

interface EntryValue {
  id: string
}

export interface ConsolidatedEntryValue {
  label: string,
  value: string,
}

export const loadDictionaryEntries = (environmentId: number | undefined, dictionaryCode: string, search?: string, pagination?: OptionListPagination): Promise<Omit<FilterOption, "type">[]> => new Promise((resolve, reject) => {
  if (environmentId) {
    API.instanceData.get<void, EntryValue[]>(`/dictionary/entryValues/${environmentId}/${dictionaryCode}`, {
        params: {
          fulltextFilter: search,
          offset: pagination?.offset,
          pageSize: pagination?.pageSize,
        },
      })
      .then(options => {
        sortBy(options, entry => entry.id && entry.id.toLocaleLowerCase())
        resolve(options.map(({id}) => ({
          label: id,
          value: id,
          selected: false,
        })))
      }, reject)
  } else {
    reject(new Error('no environmentId'))
  }
})

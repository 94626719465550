import {OrderedMap} from "immutable"
import {Colors, echartColorNa, echartColorOther, EChartTheme, HexColor} from "components/charts/Chart.Theme"
import {QueryResponseDataWithCategory} from "services/QueryService"
import Language from "language"

const separator = '-'
export type Children = OrderedMap<string, MetricDataNode>
export type Values = number[]
export type Label = string

const sortNodeValuesLabelASC = (a: MetricDataNode, b: MetricDataNode, nodeValues: Map<Label, number>) => (nodeValues.get(b.label) ?? -1) - (nodeValues.get(a.label) ?? -1)
const sortNodeValuesLabelDESC = (a: MetricDataNode, b: MetricDataNode, nodeValues: Map<Label, number>) => (nodeValues.get(a.label) ?? -1) - (nodeValues.get(b.label) ?? -1)

export const sortNodeValuesASC = (a: MetricDataNode, b: MetricDataNode) => {
  if (a.isOther) {
    return 1
  } else if (b.isOther) {
    return -1
  } else {
    return b.isOther ? -1 : b.getNodeValue() - a.getNodeValue()
  }
}

export const sortNodeValuesDESC = (a: MetricDataNode, b: MetricDataNode) => {
  if (a.isOther) {
    return -1
  } else if (b.isOther) {
    return 1
  } else {
    return b.isOther ? 1 : a.getNodeValue() - b.getNodeValue()
  }
}

export class MetricDataNode {
  readonly label: Label
  public readonly isOther: boolean = false
  public readonly isDate: boolean = false
  public readonly isNumeric: boolean = false
  public readonly isRatio: boolean = false
  protected children: Children
  protected values: Values = [] // multiple values: one for each date period aka x axis slicer
  private readonly isXAxisSlicer

  constructor(label: Label, isXAxisSlicer: boolean, values?: Values, isOther = false, isDate = false, isNumeric = false, isRatio = false, children: Children = OrderedMap<string, MetricDataNode>()) {
    this.label = label
    this.isXAxisSlicer = isXAxisSlicer
    if (values) {
      this.values = values.map(Number) // To convert Infinity
    }
    this.isOther = isOther
    this.isDate = isDate
    this.isNumeric = isNumeric
    this.isRatio = isRatio
    this.children = children
  }

  getChildren() {
    return this.children
  }

  copy(children: Children): this {
    return new MetricDataNode(this.label, this.isXAxisSlicer, this.values, this.isOther, this.isDate, this.isNumeric, this.isRatio, children) as this
  }

  listChildLabels(labels: Map<number, Set<Label>>, index: number) {
    let set = labels.get(index)
    if (set) {
      this.children.forEach((node) => set?.add(node.label))
    } else {
      set = new Set<Label>(this.children.keys())
    }
    labels.set(index, set)
    this.children.forEach((child) => child.listChildLabels(labels, index + 1))
  }

  // for tables
  dump(): QueryResponseDataWithCategory[] {
    // return [this.label, [...this.values]]
    return []
  }

  // Fills 'nodeValues' map by adding current Node value to total
  // We use 'this.label' as key. To support multi slicers we should use labels with prefix as in buildSeriesValues
  buildNodeValues(nodeValues: Map<Label, number>, index: number) {
    if (index === 0) {
      nodeValues.set(this.label, (nodeValues.get(this.label) ?? 0) + this.getNodeValue())
    } else {
      this.children.forEach((child) => child.buildNodeValues(nodeValues, index - 1))
    }
  }

  getColorScaleAt(index = 0): HexColor[] {
    if (this.children.size === 0) {
      return []
    }
    if (index === 0) {
      const colorsPerLabel = this.children
        .filter((child) => !(child.isOther || child.label === 'na' || child.label === undefined || child.label === null))
        .sort((a, b) => a.label > b.label ? 1 : -1)
        .toIndexedSeq() // convert to seq to we get idx in reduce
        .reduce((acc: Record<string, Colors>, aSeries, idx) => ({
          ...acc,
          [aSeries.label]: EChartTheme.color[idx % EChartTheme.color.length],
        }), {})

      return this.children.map(child => {
        if (child.isOther) {
          return echartColorOther
        } else if (child.label === undefined || child.label === null || child.label === 'na') {
          return echartColorNa
        } else if (child.isDate) {
          return EChartTheme.color[0]
        } else {
          return colorsPerLabel[child.label]
        }
      }).toIndexedSeq().toArray()
    } else {
      // Expects 'fillBlanks' to have been called
      return this.children.values().next().value.getColorScaleAt(index - 1)
    }
  }

  buildSeriesValues(seriesValues: Map<Label, Values>, xAxisIndex: number, metricLabel: Label, parentLabel: Label = "") {
    const label = this.buildLabel(parentLabel)

    function appendValue(key: Label, value: number) {
      seriesValues.set(key, [
        ...(seriesValues.get(key) ?? []),
        value,
      ])
    }

    if (this.isXAxisSlicer) {
      // We go lower in the tree
      if (this.isLeaf()) {
        // This means there are no slicers other than xAxis so we build a single serie using metric name
        appendValue(metricLabel, this.values[xAxisIndex])
      } else {
        this.children.forEach((child) => child.buildSeriesValues(seriesValues, xAxisIndex, metricLabel))
      }
    } else {
      if (this.children.size > 0) {
        // We are in yAxisNode but there are more children
        this.children.forEach((child) => child.buildSeriesValues(seriesValues, xAxisIndex, metricLabel, label))
      } else {
        appendValue(label, this.values[xAxisIndex])
      }
    }
  }

  ingestRemainingDataRow(data: QueryResponseDataWithCategory, xAxisRemaining: number, yAxisRemaining: number, index = 0) {
    const xAxisMove = xAxisRemaining ? 1 : 0
    const yAxisMove = !xAxisRemaining && yAxisRemaining ? 1 : 0
    if (xAxisMove || yAxisMove) {
      let child = this.children.get(data[index] as string)
      if (!child) {
        child = new MetricDataNode(data[index] as string, Boolean(xAxisMove))
        this.children = this.children.set(data[index] as string, child)
      }
      child.ingestRemainingDataRow(data, xAxisRemaining - xAxisMove, yAxisRemaining - yAxisMove, index + 1)
    } else {
      this.values = data.slice(index).map(Number)
    }
  }

  // returns true if node is at the end of a tree
  isLeaf() {
    return this.children.size === 0
  }

  // Returns sum of values under node
  getNodeValue(): number {
    if (this.isLeaf()) {
      return this.values.reduce((prev, curr) => prev + curr, 0)
    }
    return this.children.reduce((acc, curr) => acc + curr.getNodeValue(), 0)
  }

  // Returns all children values in an array
  getAllValues(): Values[] {
    if (this.isLeaf()) {
      return [this.values]
    }
    const ret: Values[][] = []
    // map function not available on iterator and faster than converting to array
    for (const child of this.children.values()) {
      ret.push(child.getAllValues())
    }
    return ret.flat()
  }

  // Sort - serie 'other' always last
  sortByValues = (asc = true) => this.copy(this.children.sort(asc ? sortNodeValuesASC : sortNodeValuesDESC))

  sortByMaxValues = (maxValues: { [p: string]: number }, asc = true) => this.copy(this.children.sort(asc ? (a, b) => {
    if (a.isOther) {
      return 1
    } else if (b.isOther) {
      return -1
    } else {
      return b.isOther ? -1 : maxValues[a.label] - maxValues[b.label]
    }
  } : (a, b) => {
    if (a.isOther) {
      return -1
    } else if (b.isOther) {
      return 1
    } else {
      return b.isOther ? 1 : maxValues[b.label] - maxValues[a.label]
    }
  }))

  // Asc sort - serie 'other' always last
  sortByLabelsAt = (index = 0): this => {
    return this.copy(
      index === 0
        ? this.children.sort((a, b) => {
          if (a.isOther) {
            return 1
          } else if (b.isOther) {
            return -1
          } else {
            if (a.isNumeric && b.isNumeric) {
              return Number(a.label) > Number(b.label) ? 1 : -1
            }
            return String(a.label).toLocaleLowerCase() > String(b.label).toLocaleLowerCase() ? 1 : -1
          }
        })
        : this.children.map((child) => child.sortByLabelsAt(index - 1)),
    )
  }

  sortByNodeValue = (nodeValues: Map<string, number>, index: number, asc: boolean): this => this.copy(
    index === 0
      ? this.children.sort((a, b) => asc ? sortNodeValuesLabelASC(a, b, nodeValues) : sortNodeValuesLabelDESC(a, b, nodeValues))
      : this.children.map(child => child.sortByNodeValue(nodeValues, index - 1, asc)),
  )


  // Will replace node that exceed limit by a single 'Other' node
  // Expects data to be sorted according to what we want to limit by
  limitAt(sliceOtherSerie: boolean, index: number, limit: number, hideOther?: boolean): MetricDataNode {
    if (index > 0) {
      return this.copy(this.children.map((child) => child.limitAt(sliceOtherSerie, index - 1, limit, hideOther)))
    } else if (hideOther || this.children.size <= limit) {
      return this.copy(this.children.slice(0, limit))
    } else {
      const nbOfOthersValues = this.children.size - limit >= 0 ? this.children.size - limit : 0
      if (sliceOtherSerie) {
        const accumulator: { [key: number]: number } = {}
        this.children.slice(limit)
          .forEach((child) => {
            child.getAllValues().forEach((v, i) => {
              accumulator[i] = accumulator[i] === undefined ? v[0] : accumulator[i] + v[0]
            })
          })
        const labels = this.children.flatMap(child => child.children.map(c => c.label)).toIndexedSeq().toArray()
        return this.copy(this.children.slice(0, limit).set(Language.get('chart-others-series'), new MetricDataNode(Language.get('chart-others-series'), Boolean(this.children.first()?.isXAxisSlicer), [], true, false, false, false, OrderedMap<Label, MetricDataNode>(labels.map((key, i) => {
          return [key, new MetricDataNode(key, false, this.isRatio && nbOfOthersValues ? [accumulator[i] / nbOfOthersValues] : [accumulator[i]], true)]
        })))))
      } else {
        const allRemainingValues = this.children.slice(limit)
          .reduce((acc: Values[], child) => acc.concat(child.getAllValues()), [])
        const otherValues = allRemainingValues?.[0]?.map((__, i) => allRemainingValues.map((elem) => elem[i]).reduce((acc, current) => acc + current, 0)) ?? []

        return this.copy(this.children.slice(0, limit).set(Language.get('chart-others-series'), new MetricDataNode(Language.get('chart-others-series'), Boolean(this.children.first()?.isXAxisSlicer), this.isRatio && nbOfOthersValues ? [otherValues[0] / nbOfOthersValues] : otherValues,
          true)))
      }
    }
  }

  // for xAxis slicer at index,
  // returns a copy of MetricDataTree
  // currently only handles single Metric. To improve, expectedLabels should de double array to fill all xAxis at once
  // can take some time, could be done server side to improve perfs
  fillBlanksAt(labels: Map<number, Set<Label>>, defaultValues: Values, index: number, numberOfXAxis: number, numberOfYAxis: number): this {
    // we start by adding all first nodes that are missing
    const resultChildren = new Map<Label, MetricDataNode>()
    const referenceChildren = this.children.first()
    const isDeepestAxis = index === numberOfXAxis + numberOfYAxis - 1;
    (labels.get(index) ?? []).forEach((expectedLabel) => {
      const existingChild = this.children.get(expectedLabel)
      let newChild: MetricDataNode
      if (isDeepestAxis) {
        newChild = existingChild ?? new MetricDataNode(expectedLabel, index < numberOfXAxis, defaultValues, false, referenceChildren?.isDate)
      } else {
        newChild = (existingChild ?? new MetricDataNode(expectedLabel, index < numberOfXAxis, undefined, false, referenceChildren?.isDate)).fillBlanksAt(labels, defaultValues, index + 1, numberOfXAxis, numberOfYAxis)
      }
      resultChildren.set(expectedLabel, newChild)
    })

    return this.copy(OrderedMap(resultChildren))
  }

  private buildLabel = (parentLabel: Label = ""): Label => parentLabel ? `${parentLabel}${separator}${this.label}` : this.label
}

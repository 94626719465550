import {Row} from "antd"
import React, {memo} from "react"
import styled from "styled-components"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import {
  useHasDisplayOptionApplied,
  useHasFilterApplied,
  useHasGrowthApplied,
  useHasPeriodApplied,
} from "components/metricDisplay/MetricDisplay.hooks"
import {isEmpty} from "lodash"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import MetricGrowthResume from "components/forms/selector/metrics/display/MetricGrowthResume"
import MetricPeriodResume from "components/forms/selector/metrics/display/MetricPeriodResume"
import MetricFiltersResume from "components/forms/selector/metrics/display/MetricFiltersResume"
import MetricDisplayResume from "components/forms/selector/metrics/display/MetricDisplayResume"

interface MetricOptionsResumeProps {
  value?: MetricSelectorValue
  setActiveKey?: (key: MetricOptionsFormKeys) => void
  onDelete?: (key: (keyof ConfMetricWithActivationState)[]) => void
  timeInterval: TimeIntervalWithSource
  dayDifference: number
  isNameDisplayed?: boolean
  iconSize?: number
  additionnalStyle?: string
  hideDisplayOptions?: boolean
  iconSpan?: number
}

// eslint-disable-next-line react/display-name
const MetricOptionsResume = memo<MetricOptionsResumeProps>(({
                                                              value,
                                                              setActiveKey,
                                                              onDelete,
                                                              timeInterval,
                                                              dayDifference,
                                                              isNameDisplayed = false,
                                                              iconSize = 16,
                                                              additionnalStyle,
                                                              hideDisplayOptions = false,
                                                              iconSpan = 1,
                                                            }) => {
  const hasGrowthApplied = useHasGrowthApplied({growth: value?.growth})
  const hasFilterApplied = useHasFilterApplied({additionalFilters: value?.additionalFilters ?? []})
  const hasPeriodApplied = useHasPeriodApplied({overridePeriod: value?.overridePeriod})
  const hasDisplayOptionApplied = useHasDisplayOptionApplied({
    extraConf: value?.extraConf,
  })

  return <StyledRow $additionnalstyle={additionnalStyle}>
    {isNameDisplayed && (value?.titlePartOverrides
      && Object.values(value.titlePartOverrides).filter(title => !isEmpty(title)).length > 0) && <Name>
      {value.metricAlias}
    </Name>}
    {hasGrowthApplied && <MetricGrowthResume iconSpan={iconSpan}
                                             value={value} timeInterval={timeInterval} dayDifference={dayDifference}
                                             setActiveKey={setActiveKey} iconSize={iconSize} onDelete={onDelete}/>}
    {hasPeriodApplied && <MetricPeriodResume iconSpan={iconSpan}
                                             value={value} setActiveKey={setActiveKey} iconSize={iconSize} onDelete={onDelete}/>}
    {hasFilterApplied && <MetricFiltersResume iconSpan={iconSpan}
                                              value={value} setActiveKey={setActiveKey} iconSize={iconSize} onDelete={onDelete}/>}
    {hasDisplayOptionApplied && !hideDisplayOptions && <MetricDisplayResume
      iconSpan={iconSpan} value={value} setActiveKey={setActiveKey} iconSize={iconSize}
      onDelete={onDelete}/>}
  </StyledRow>
})

export default MetricOptionsResume

const Name = styled.div`
  color: var(--medium-grey)
`

const StyledRow = styled(Row)<{ $additionnalstyle?: string }>`
    gap: 4px;
    line-height: 16px;
    * {
        font-size: 12px;
    }
    ${({$additionnalstyle}) => $additionnalstyle}
`
/* eslint-disable max-lines */
import React, {memo, useRef} from "react"
import {Col, Form, Row} from "antd"
import Language from "language"
import styled from "styled-components"
import FormRow from "components/forms/selector/metrics/options/FormRow"
import {useFields} from "hooks/useFields"
import MetaModel from "classes/MetaModel"
import {ColoredElement} from "themes/SharedStyledComponent"
import {ColorName, Colors} from "components/charts/Chart.Theme"
import {isEmpty} from "lodash"
import DeniedIcon from 'themes/svg/denied.svg?react'
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {IconContainer} from "components/common/IconContainer"
import {ExclamationIcon} from "@heroicons/react/solid"
import {
  ConfMetricWithActivationState,
  PartialConfMetricWithActivationState,
} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import DecimalsSelector from "components/forms/selector/metrics/options/selectors/DecimalsSelector"
import DetailsSelector from "components/forms/selector/metrics/options/selectors/DetailsSelector"
import {isUnitsAllowed} from "components/forms/selector/metrics/utils"
import SelectSelector from "components/forms/selector/metrics/options/selectors/SelectSelector"

export interface MetricDisplayOptionSelectorProps {
  environmentId: number,
  configuration?: ChartTypeWithDisabledReason
  temporaryMetric: ConfMetricWithActivationState
  onMetricChange: (data: PartialConfMetricWithActivationState) => void
  metaModel: MetaModel
}

export enum Units {
  "K" = "K",
  "M" = "M"
}

// eslint-disable-next-line react/display-name
const MetricDisplayOptionSelector = memo<MetricDisplayOptionSelectorProps>(({
                                                                              temporaryMetric,
                                                                              onMetricChange,
                                                                              configuration,
                                                                            }) => {
  const [form] = Form.useForm()
  const fields = useFields(temporaryMetric, [])
  const ref = useRef<HTMLDivElement | null>(null)

  return <Form form={form} fields={fields} onValuesChange={(changedValue) => {
    onMetricChange(changedValue)
  }}>
    <StyledDiv ref={ref}>
      <ContainerRow>
        <FormRow
          name={"decimals"}
          title={Language.get("configuration-metric-options.display.decimals")}
          contentSpan={5}>
          <DecimalsSelector defaultLabel={temporaryMetric.metricDef?.formatPrecision ?? 0} gaevent={{
            category: 'metric-options',
            action: 'update-decimals',
          }}/>
        </FormRow>
        {(isEmpty(temporaryMetric.metricDef?.formatPrefix) && isEmpty(temporaryMetric.metricDef?.formatSuffix)) && <FormRow
          name={"details"}
          title={Language.get("configuration-metric-options.display.details.title")}>
          <DetailsSelector gaevent={{
            category: 'metric-options',
            action: 'update-details',
          }}/>
        </FormRow>}
        {isUnitsAllowed(temporaryMetric) && <FormRow
          name={"units"}
          title={Language.get("configuration-metric-options.display.units.title")}
          contentSpan={8}>
          <SelectSelector gaevent={{
            category: 'metric-options',
            action: 'update-units',
          }} dropdownMatchSelectWidth={false} options={[{
            value: null,
            label: <FlexRow>
              <Prefix><DeniedIcon/></Prefix>
              <Separator>-</Separator>
              <Text>{Language.get("configuration-metric-options.display.units.options.units")}</Text>
            </FlexRow>,
          }, {
            value: Units.K,
            label: <FlexRow>
              <Prefix>k</Prefix>
              <Separator>-</Separator>
              <Text>{Language.get("configuration-metric-options.display.units.options.k")}</Text>
            </FlexRow>,
          }, {
            value: Units.M,
            label: <FlexRow>
              <Prefix>M</Prefix>
              <Separator>-</Separator>
              <Text>{Language.get("configuration-metric-options.display.units.options.m")}</Text>
            </FlexRow>,
          }]}/>
        </FormRow>}
        <Row>
          <Col span={11}>
            <FormRow
              name={"color"}
              title={Language.get("configuration-metric-options.display.color")}
              textSpan={15}
              contentSpan={8}
              contentMargin={2}>
              <SelectSelector gaevent={{
                category: 'metric-options',
                action: 'update-color',
              }} options={[{
                value: null,
                label: <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"/>
                </svg>,
              }, {
                value: ColorName["red-border"],
                label: <ColoredElementWithRadius $color={Colors.red_border}/>,
              }, {
                value: ColorName["blue-border"],
                label: <ColoredElementWithRadius $color={Colors.blue_border}/>,
              }, {
                value: ColorName["orange-border"],
                label: <ColoredElementWithRadius $color={Colors.orange_border}/>,
              }, {
                value: ColorName["blue-pastel"],
                label: <ColoredElementWithRadius $color={Colors.blue_pastel}/>,
              }, {
                value: ColorName["green-border"],
                label: <ColoredElementWithRadius $color={Colors.green_border}/>,
              }, {
                value: ColorName["orange-pastel"],
                label: <ColoredElementWithRadius $color={Colors.orange_pastel}/>,
              }]}/>

            </FormRow>
          </Col>
          {!configuration?.isColorOverridable && <Col span={12} offset={1}>
            <WarningMessage>
              <IconContainer><ExclamationIcon/></IconContainer>
              {Language.get("configuration-metric-options.display.color-warning")}
            </WarningMessage>
          </Col>}
        </Row>
      </ContainerRow>
    </StyledDiv>
  </Form>
})

export default MetricDisplayOptionSelector

const WarningMessage = styled.div`
    display: flex;
    justify-content: end;
    text-align: left;
    font-size: 0.7em;
    line-height: 1em;
    color: var(--light-text);
    align-items: center;
    gap: 8px;
    height: 100%;
`

const Prefix = styled.span`
    min-width: 20px;
`

const Separator = styled.span`
    min-width: 15px;
`

const Text = styled.span`
    font-style: italic;
`

const FlexRow = styled.div`
    display: flex;
`

const ColoredElementWithRadius = styled(ColoredElement)`
    border-radius: 3px;
    height: 24px;
    width: 24px;
`

const ContainerRow = styled(Row)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 6px 75px 6px 22px;
    line-height: 20px;
`

const StyledDiv = styled.div`
    width: 550px;
`

import {GenericChartTypes} from "types/widgets"

export enum WidgetTypes {
  DIVIDER = 'divider',
  GENERIC = 'generic',
  EXPORT = 'export',
  TARGET = 'target',
  UNKNOWN = 'unknown',
}

export type ExtendedWidgetTypes = WidgetTypes | GenericChartTypes
import React, {FunctionComponent, PropsWithChildren} from 'react'
import {DragOverlay} from "@dnd-kit/core"
import SortableItem from "components/common/sortable/SortableItem"

interface Props extends PropsWithChildren<any> {
  activeId?: number
}

const SortableOverlay: FunctionComponent<Props> = ({activeId, children}) => {
  return activeId ? <DragOverlay>
    <SortableItem id={activeId}>
      {children}
    </SortableItem>
  </DragOverlay> : null
}

export default SortableOverlay
import {Button} from 'antd'
import React, {FunctionComponent, useCallback} from "react"
import styled from "styled-components"
import Language from "../../../language"
import {User} from "components/admin/user/UserManager.types"
import {IconContainer} from "components/common/IconContainer"
import {PencilIcon} from "@heroicons/react/outline"

interface Props {
  user: User
  active: boolean
  onUserEdit?: (user: User) => void
}

const UserManagerInfo: FunctionComponent<Props> = ({user, active, onUserEdit}) => {
  const handleEditClick = useCallback(() => {
    onUserEdit?.(user)
  }, [onUserEdit, user])
  return <StyledDiv>
    {onUserEdit && <FlexButton icon={<IconContainer><PencilIcon/></IconContainer>} onClick={handleEditClick}
                               title={Language.get('admin.user.actionEditUser')}/>}
    {onUserEdit
      ? <Button type="link" onClick={handleEditClick}>{`${user.firstName} ${user.lastName}`}</Button>
      : `${user.firstName} ${user.lastName}`
    }
    {!active && <span className="inactive">{Language.get('admin.user.uacInactiveFlag')}</span>}
    <Email className="email">{user.email}</Email>
  </StyledDiv>
}

export default UserManagerInfo

const Email = styled.div`
  padding-left: 15px;
`

const FlexButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0px;
`

const StyledDiv = styled.div`
  position: relative;
  
  > .ant-btn-icon-only {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  > .inactive {
    color: var(--negative);
    opacity: .8;
    font-size: 12px;
    margin-left: 10px;
  }
  
  > .email {
    opacity: .45;
  }
`

import {DependencyList, useMemo, useRef} from "react"
import {isEqual} from 'lodash'

// A version of useMemo that returns a new value only if content of object changed
// e.g. if a 'value' is an object and the reference change but not the content, it will still return old value
// in other words, if 'deps' is triggered, 'useMemo' here will be triggered but the reference returned will only change if object content changed
export default function useMemoDeepCached<T>(factory: () => T, deps: DependencyList): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo<T>(factory, deps)
  const ref = useRef<T>()
  if (!isEqual(value, ref.current)) {
    ref.current = value
  }
  return ref.current as T
}

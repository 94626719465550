import React, {memo, PropsWithChildren} from 'react'
import styled from "styled-components"

interface Props extends PropsWithChildren<any> {
  className?: string
  loading?: boolean
}

// eslint-disable-next-line react/display-name
const Loader = memo<Props>(({className = '', loading = true, children}) => {
  return <StyledDiv className={className}>
    {loading && <svg className="spinner" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke="var(--primary)"/>
    </svg>}
    {children || <div/>}
  </StyledDiv>
})
export default Loader

const StyledDiv = styled.div`
  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 35px;
    height: 35px;

    & .path {
      stroke: hsl(210, 70%, 75%);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`
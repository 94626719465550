import React, {memo, useCallback} from 'react'
import TableChartSorter from 'components/charts/table/TableChart.Sorter'
import FormattedText from "components/common/FormattedText"
import styled from "styled-components"
import {TableColumn} from "types/charts"
import {Tooltip} from "antd"

interface HeaderCellProps {
  column: TableColumn
  index: number
  onSort: (index: number) => void
  asc?: boolean
  style?: React.CSSProperties | undefined
  width: number
}

const TableChartHeaderCell = memo<HeaderCellProps>(function HeaderCell({column, index, onSort, asc, style, width}) {
  const onClick = useCallback(() => onSort(index), [onSort, index])

  return <Tooltip {...{
    placement: "top",
    title: column.titleAlt || column.title,
    mouseEnterDelay: 0.5,
    // overlayStyle: {minWidth: 400},
    overlayInnerStyle: {minWidth: 400},
    arrow: {pointAtCenter: true},
    autoAdjustOverflow: true,
  }}>
    <StyledCell onClick={onClick} style={style}>
      <ColumnFlexWrapper $width={width}>
        <FormattedText
          text={column.title}>
          {column.details}
        </FormattedText>
        <TableChartSorter asc={asc}/>
      </ColumnFlexWrapper>
    </StyledCell>
  </Tooltip>
})

export default TableChartHeaderCell

const ColumnFlexWrapper = styled.div<{ $width: number }>`
 ${props => `
    width: ${props.$width}px;
    min-width: ${props.$width}px;
    max-width: ${props.$width}px;
 `}
 display: flex;
 align-items: center;
 gap: 8px;
`

const StyledCell = styled.th`
  cursor: pointer;
  &:hover {
    transition: 1s;
    background-color: #f8f8f8;
  } 
  `
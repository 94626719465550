import Showdown from 'showdown';

Showdown.extension('targetlink', () => [{
  type: 'lang',
  regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{\:target="([^"]*\6)"}/g, // eslint-disable-line
  replace: (wholematch: any, linkText: string, url: string, a: any, b: any, _title: string | null | undefined, c: any, target: string | null | undefined) => {
    let result = `<a href="${url}"`
    let title = ''
    if (typeof _title !== 'undefined' && _title !== '' && _title !== null) {
      title = _title.replace(/"/g, '&quot;')
      title = Showdown.helper.escapeCharacters(title, '*_', false)
      result += ` title="${title}"`
    }
    if (typeof target !== 'undefined' && target !== '' && target !== null) {
      result += ` target="${target}"`
    }
    result += `>${linkText}</a>`;
    return result;
  }
}]);

export const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: ['targetlink']
});

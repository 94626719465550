/* eslint-disable max-lines */
import dayjs, {Dayjs} from 'dayjs'
import {isEmpty} from "commons/object.utils"
import {useMemo} from "react"
import {Category, EnumGranularity, PeriodEdgePoint, PeriodOffset, PeriodType, PeriodTypes} from "types/period.types"

const getValueOfStartEdge = (ref: Dayjs, edge: PeriodEdgePoint) => {
  switch (edge) {
    case PeriodEdgePoint.TODAY:
      return ref
    case PeriodEdgePoint.YESTERDAY:
      return ref.subtract(1, 'day')
    case PeriodEdgePoint.WEEK:
      return ref.subtract(ref.isoWeekday() - 1, 'day')
    case PeriodEdgePoint.MONTH:
      return ref.startOf('month')
    case PeriodEdgePoint.QUARTER:
      return ref.startOf('quarter')
    case PeriodEdgePoint.YEAR:
      return ref.startOf('year')
    default: {
      const exhaustiveCheck: never = edge
      return exhaustiveCheck
    }
  }
}

const getValueOfEndEdge = (ref: Dayjs, edge: PeriodEdgePoint) => {
  switch (edge) {
    case PeriodEdgePoint.TODAY:
      return ref
    case PeriodEdgePoint.YESTERDAY:
      return ref.subtract(1, 'day')
    case PeriodEdgePoint.WEEK:
      return ref.endOf('isoWeek')
    case PeriodEdgePoint.MONTH:
      return ref.endOf('month')
    case PeriodEdgePoint.QUARTER:
      return ref.endOf('quarter')
    case PeriodEdgePoint.YEAR:
      return ref.endOf('year')
    default: {
      const exhaustiveCheck: never = edge
      return exhaustiveCheck
    }
  }
}

const computeOffset = (edge: Dayjs, offset?: PeriodOffset) => {
  const consolidatedOffsetValue = offset?.quantity ?? 0
  if (offset) {
    if (consolidatedOffsetValue < 0) {
      return edge.subtract(offset.quantity * -1, offset.granularity.toString().toLowerCase() as any)
    } else {
      return edge.add(offset.quantity, offset.granularity.toString().toLowerCase() as any)
    }
  }
  return edge
}

export const endOfWeek = (ref: Dayjs) => ref.add(7 - ref.isoWeekday(), "day")
export const startOfWeek = (ref: Dayjs) => ref.subtract(ref.isoWeekday() - 1, "day")

const getResolved = (period: PeriodTypes): SimpleTimeIntervalWithSource | undefined => {
  if (isEmpty(period)) {
    return undefined
  }
  switch (period.type) {
    case PeriodType.dynamic: {
      return {
        name: period?.code,
        start: () => {
          const rawStartValue = computeOffset(getValueOfStartEdge(dayjs(), period.start.value), period.start.offset)
          if ([PeriodEdgePoint.WEEK].includes(period.start.value)
            && (period.start.offset && [EnumGranularity.MONTH, EnumGranularity.QUARTER, EnumGranularity.YEAR].includes(period.start.offset.granularity))) {
            return rawStartValue.isoWeekday() === 1 ? rawStartValue : startOfWeek(rawStartValue).add(1, 'week')
          }
          return rawStartValue
        },
        end: () => {
          const rawEndValue = computeOffset(getValueOfEndEdge(dayjs(), period.end.value), period.end.offset)
          if ([PeriodEdgePoint.MONTH, PeriodEdgePoint.QUARTER].includes(period.end.value)
            && (period.end.offset && [EnumGranularity.MONTH, EnumGranularity.QUARTER, EnumGranularity.YEAR].includes(period.end.offset.granularity))) {
            return rawEndValue.endOf('month')
          }
          if ([PeriodEdgePoint.WEEK].includes(period.end.value)
            && (period.end.offset && [EnumGranularity.MONTH, EnumGranularity.QUARTER, EnumGranularity.YEAR].includes(period.end.offset.granularity))) {
            return endOfWeek(rawEndValue)
          }
          return rawEndValue
        },
      }
    }
    case PeriodType.fixed: {
      return {
        name: period.code,
        start: () => dayjs(period.start),
        end: () => dayjs(period.end),
      }
    }
    default: {
      const exhaustiveCheck: never = period
      return exhaustiveCheck
    }
  }
}

export interface SimpleTimeIntervalWithSource {
  name?: string
  start: () => Dayjs
  end: () => Dayjs
}

export const getFromPeriod = (period: PeriodTypes): SimpleTimeIntervalWithSource => {
  return {
    name: getResolved(period)!.name,
    start: getResolved(period)!.start,
    end: getResolved(period)!.end() < getResolved(period)!.start() ? getResolved(period)!.start : getResolved(period)!.end,
  }
}

export interface SimpleTimeIntervalWithSourceResolved {
  name?: string
  start: Dayjs
  end: Dayjs
  period: PeriodTypes
}

export const getResolvedFromPeriod = (period: PeriodTypes): SimpleTimeIntervalWithSourceResolved => {
  const interval = getFromPeriod(period)
  return {
    name: interval.name,
    start: interval.start(),
    end: interval.end(),
    period,
  }
}

export const formatToDisplayablePeriod = (period: PeriodTypes): PeriodTypes => {
  switch (period.type) {
    case PeriodType.dynamic: {
      return {
        ...period,
        type: PeriodType.dynamic,
        start: {
          ...period.start,
          offset: period.start.offset ? {
            ...period.start.offset,
            quantity: period.start.offset.quantity * -1,
          } : undefined,
        },
        end: {
          ...period.end,
          offset: period.end.offset ? {
            ...period.end.offset,
            quantity: period.end.offset.quantity * -1,
          } : undefined,
        },
      }
    }
    case PeriodType.fixed: {
      return period
    }
    default: {
      const exhaustiveCheck: never = period
      return exhaustiveCheck
    }
  }
}

export const usePeriodCategories = (periods: PeriodTypes[]) => useMemo(() => {
  const sortedPeriods = periods.sort((a, b) => a.position - b.position)
  return [
    {
      title: 'time-preset-category-days',
      presets: sortedPeriods.filter(period => period.category === Category.DAY),
    }, {
      title: 'time-preset-category-months',
      presets: sortedPeriods.filter(period => period.category === Category.MONTH),
    }, {
      title: 'time-preset-category-years',
      presets: sortedPeriods.filter(period => period.category === Category.YEAR),
    }, {
      title: 'time-preset-category-others',
      presets: sortedPeriods.filter(period => period.category === Category.OTHER),
    },
  ]
}, [periods])

export const getDefaultPeriod = () => predefinedPeriod.MONTH_TO_DATE

export const predefinedPeriod: { [key: string]: PeriodTypes } = {
  LAST_7_DAYS: {
    "type": PeriodType.dynamic,
    "id": 8,
    "code": "LAST_7_DAYS",
    "nameFR": "last 7 day",
    "nameEN": "last 7 day",
    "position": 0,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": -7}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  },
  LAST_30_DAYS: {
    "type": PeriodType.dynamic,
    "id": 64,
    "code": "LAST_30_DAYS",

    "nameFR": "last 30 days",
    "nameEN": "last 30 days",
    "position": 10,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": -30}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  },
  LAST_90_DAYS: {
    "type": PeriodType.dynamic,
    "id": 120,
    "code": "LAST_90_DAYS",

    "nameFR": "last 90 days",
    "nameEN": "last 90 days",
    "position": 20,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": -90}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_3_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 176,
    "code": "LAST_3_MONTHS",

    "nameFR": "last 3 months",
    "nameEN": "last 3 months",
    "position": 30,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.MONTH, "quantity": -3}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_180_DAYS: {
    "type": PeriodType.dynamic,
    "id": 232,
    "code": "LAST_180_DAYS",

    "nameFR": "last 180 days",
    "nameEN": "last 180 days",
    "position": 40,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": -180}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_6_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 288,
    "code": "LAST_6_MONTHS",

    "nameFR": "last 6 months",
    "nameEN": "last 6 months",
    "position": 50,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.MONTH, "quantity": -6}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_12_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 344,
    "code": "LAST_12_MONTHS",

    "nameFR": "last 12 months",
    "nameEN": "last 12 months",
    "position": 60,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.MONTH, "quantity": -12}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, MONTH_TO_DATE: {
    "type": PeriodType.dynamic,
    "id": 400,
    "code": "MONTH_TO_DATE",

    "nameFR": "current month (MTD)",
    "nameEN": "current month (MTD)",
    "position": 70,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": false,
  }, QUARTER_TO_DATE: {
    "type": PeriodType.dynamic,
    "id": 456,
    "code": "QUARTER_TO_DATE",

    "nameFR": "current quarter (QTD)",
    "nameEN": "current quarter (QTD)",
    "position": 80,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.QUARTER, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": false,
  }, YEAR_TO_DATE: {
    "type": PeriodType.dynamic,
    "id": 512,
    "code": "YEAR_TO_DATE",

    "nameFR": "current year (YTD)",
    "nameEN": "current year (YTD)",
    "position": 90,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": false,
  }, YESTERDAY: {
    "type": PeriodType.dynamic,
    "id": 568,
    "code": "YESTERDAY",

    "nameFR": "yesterday",
    "nameEN": "yesterday",
    "position": 100,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, TODAY: {
    "type": PeriodType.dynamic,
    "id": 624,
    "code": "TODAY",

    "nameFR": "today",
    "nameEN": "today",
    "position": 110,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "canBeDeleted": true,
  }, CURRENT_WEEK: {
    "type": PeriodType.dynamic,
    "id": 680,
    "code": "CURRENT_WEEK",

    "nameFR": "since monday",
    "nameEN": "since monday",
    "position": 120,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.WEEK, "offset": undefined},
    "end": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "canBeDeleted": true,
  }, CURRENT_AND_LAST_YEAR: {
    "type": PeriodType.dynamic,
    "id": 736,
    "code": "CURRENT_AND_LAST_YEAR",

    "nameFR": "previous and current year",
    "nameEN": "previous and current year",
    "position": 130,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, TOTAL_CURRENT_YEAR: {
    "type": PeriodType.dynamic,
    "id": 792,
    "code": "TOTAL_CURRENT_YEAR",

    "nameFR": "complete current year",
    "nameEN": "complete current year",
    "position": 140,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": undefined},
    "end": {value: PeriodEdgePoint.YEAR, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_YEAR_TO_DATE: {
    "type": PeriodType.dynamic,
    "id": 848,
    "code": "LAST_YEAR_TO_DATE",

    "nameFR": "last year to date (LYTD)",
    "nameEN": "last year to date (LYTD)",
    "position": 150,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "canBeDeleted": true,
  }, LAST_24_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 904,
    "code": "LAST_24_MONTHS",

    "nameFR": "last 24 months",
    "nameEN": "last 24 months",
    "position": 160,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.MONTH, "quantity": -24}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": undefined},
    "canBeDeleted": true,
  }, TOMORROW: {
    "type": PeriodType.dynamic,
    "id": 960,
    "code": "TOMORROW",

    "nameFR": "tomorrow",
    "nameEN": "tomorrow",
    "position": 170,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": 1}},
    "end": {value: PeriodEdgePoint.TODAY, "offset": {granularity: EnumGranularity.DAY, "quantity": 1}},
    "canBeDeleted": true,
  }, NEXT_7_DAYS: {
    "type": PeriodType.dynamic,
    "id": 1016,
    "code": "NEXT_7_DAYS",

    "nameFR": "next 7 days",
    "nameEN": "next 7 days",
    "position": 180,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": {granularity: EnumGranularity.DAY, "quantity": 7}},
    "canBeDeleted": true,
  }, NEXT_30_DAYS: {
    "type": PeriodType.dynamic,
    "id": 1072,
    "code": "NEXT_30_DAYS",

    "nameFR": "next 30 days",
    "nameEN": "next 30 days",
    "position": 190,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": {granularity: EnumGranularity.DAY, "quantity": 30}},
    "canBeDeleted": true,
  }, NEXT_MONTH: {
    "type": PeriodType.dynamic,
    "id": 1128,
    "code": "NEXT_MONTH",

    "nameFR": "next month",
    "nameEN": "next month",
    "position": 200,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 1}},
    "end": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 1}},
    "canBeDeleted": true,
  }, NEXT_3_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 1184,
    "code": "NEXT_3_MONTHS",

    "nameFR": "next 3 months",
    "nameEN": "next 3 months",
    "position": 210,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 1}},
    "end": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 3}},
    "canBeDeleted": true,
  }, NEXT_6_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 1240,
    "code": "NEXT_6_MONTHS",

    "nameFR": "next 6 months",
    "nameEN": "next 6 months",
    "position": 220,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 1}},
    "end": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 6}},
    "canBeDeleted": true,
  }, NEXT_12_MONTHS: {
    "type": PeriodType.dynamic,
    "id": 1296,
    "code": "NEXT_12_MONTHS",

    "nameFR": "next 12 months",
    "nameEN": "next 12 months",
    "position": 230,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 1}},
    "end": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": 12}},
    "canBeDeleted": true,
  }, UNTIL_END_YEAR: {
    "type": PeriodType.dynamic,
    "id": 1352,
    "code": "UNTIL_END_YEAR",

    "nameFR": "until 31st. Dec",
    "nameEN": "until 31st. Dec",
    "position": 240,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.YEAR, "offset": undefined},
    "canBeDeleted": true,
  }, NEXT_YEAR: {
    "type": PeriodType.dynamic,
    "id": 1408,
    "code": "NEXT_YEAR",

    "nameFR": "next year",
    "nameEN": "next year",
    "position": 250,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": 1}},
    "end": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": 1}},
    "canBeDeleted": true,
  }, NEXT_2_YEAR: {
    "type": PeriodType.dynamic,
    "id": 1464,
    "code": "NEXT_2_YEAR",

    "nameFR": "next 2 years",
    "nameEN": "next 2 years",
    "position": 260,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": 1}},
    "end": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": 2}},
    "canBeDeleted": true,
  }, PREVIOUS_MONTH: {
    "type": PeriodType.dynamic,
    "id": 1520,
    "code": "PREVIOUS_MONTH",

    "nameFR": "previous month",
    "nameEN": "previous month",
    "position": 270,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": -1}},
    "end": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.MONTH, "quantity": -1}},
    "canBeDeleted": true,
  }, PREVIOUS_WEEK: {
    "type": PeriodType.dynamic,
    "id": 1576,
    "code": "PREVIOUS_WEEK",

    "nameFR": "previous week",
    "nameEN": "previous week",
    "position": 280,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.WEEK, "offset": {granularity: EnumGranularity.WEEK, "quantity": -1}},
    "end": {value: PeriodEdgePoint.WEEK, "offset": {granularity: EnumGranularity.WEEK, "quantity": -1}},
    "canBeDeleted": true,
  }, UNTIL_END_MONTH: {
    "type": PeriodType.dynamic,
    "id": 1632,
    "code": "UNTIL_END_MONTH",

    "nameFR": "tod. until end of month",
    "nameEN": "tod. until end of month",
    "position": 290,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.TODAY, "offset": undefined},
    "end": {value: PeriodEdgePoint.MONTH, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_YEAR: {
    "type": PeriodType.dynamic,
    "id": 1688,
    "code": "LAST_YEAR",

    "nameFR": "previous year",
    "nameEN": "previous year",
    "position": 300,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "end": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "canBeDeleted": true,
  }, LAST_2_YEARS: {
    "type": PeriodType.dynamic,
    "id": 1744,
    "code": "LAST_2_YEARS",

    "nameFR": "2 previous years",
    "nameEN": "2 previous years",
    "position": 310,
    "category": Category.YEAR,
    "start": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -2}},
    "end": {value: PeriodEdgePoint.YEAR, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "canBeDeleted": true,
  }, TOTAL_CURRENT_WEEK: {
    "type": PeriodType.dynamic,
    "id": 1800,
    "code": "TOTAL_CURRENT_WEEK",

    "nameFR": "complete current week",
    "nameEN": "complete current week",
    "position": 320,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.WEEK, "offset": undefined},
    "end": {value: PeriodEdgePoint.WEEK, "offset": undefined},
    "canBeDeleted": true,
  }, TOTAL_CURRENT_MONTH: {
    "type": PeriodType.dynamic,
    "id": 1856,
    "code": "TOTAL_CURRENT_MONTH",

    "nameFR": "complete current month",
    "nameEN": "complete current month",
    "position": 330,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": undefined},
    "end": {value: PeriodEdgePoint.MONTH, "offset": undefined},
    "canBeDeleted": true,
  }, LAST_QUARTER: {
    "type": PeriodType.dynamic,
    "id": 1912,
    "code": "LAST_QUARTER",

    "nameFR": "previous quarter",
    "nameEN": "previous quarter",
    "position": 340,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.QUARTER, "offset": {granularity: EnumGranularity.QUARTER, "quantity": -1}},
    "end": {value: PeriodEdgePoint.QUARTER, "offset": {granularity: EnumGranularity.QUARTER, "quantity": -1}},
    "canBeDeleted": true,
  }, LAST_YEAR_CURRENT_WEEK: {
    "type": PeriodType.dynamic,
    "id": 1968,
    "code": "LAST_YEAR_CURRENT_WEEK",

    "nameFR": "current week last year",
    "nameEN": "current week last year",
    "position": 350,
    "category": Category.DAY,
    "start": {value: PeriodEdgePoint.WEEK, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "canBeDeleted": true,
  }, LAST_YEAR_CURRENT_MONTH: {
    "type": PeriodType.dynamic,
    "id": 2024,
    "code": "LAST_YEAR_CURRENT_MONTH",

    "nameFR": "current month last year",
    "nameEN": "current month last year",
    "position": 360,
    "category": Category.MONTH,
    "start": {value: PeriodEdgePoint.MONTH, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "end": {value: PeriodEdgePoint.YESTERDAY, "offset": {granularity: EnumGranularity.YEAR, "quantity": -1}},
    "canBeDeleted": true,
  },
}
import {Col, Row} from "antd"
import {IconContainer} from "components/common/IconContainer"
import React, {memo, useState} from "react"
import styled from "styled-components"
import {getFilterResume} from "components/forms/selector/comps/box/utils"
import FilterIcon from 'themes/svg/outlined/filter.svg?react'
import {ContentCol, DeleteContainer, FlexMetricOptionRow} from "themes/SharedStyledComponent"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {BanIcon} from "@heroicons/react/outline"
import {XCircleIcon} from "@heroicons/react/solid"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import {isMetricOptionResumeClickable} from "components/forms/selector/metrics/utils"

interface MetricFiltersResumeProps {
  value?: MetricSelectorValue
  setActiveKey?: (key: MetricOptionsFormKeys) => void
  onDelete?: (key: (keyof ConfMetricWithActivationState)[]) => void
  iconSize: number
  iconSpan: number
}

// eslint-disable-next-line react/display-name
const MetricFiltersResume = memo<MetricFiltersResumeProps>(({
                                                              value,
                                                              setActiveKey,
                                                              onDelete,
                                                              iconSize,
                                                              iconSpan,
                                                            }) => {
  const isClickable = isMetricOptionResumeClickable(setActiveKey)
  const [isDeleteDisplayed, setIsDeleteDisplayed] = useState(false)

  return <DivWithFullWidth>
    {value?.additionalFilters && value?.additionalFilters.length > 0 && <GrowthResume>
      <GrowthPreview>
        <Row>
          <Col span={iconSpan} onClick={() => setActiveKey?.(MetricOptionsFormKeys.filters)}>
            <CustomFlexMetricOptionRow $clickable={isClickable}>
              <IconContainer size={iconSize}>
                <FilterIcon/>
              </IconContainer>
            </CustomFlexMetricOptionRow>
          </Col>
          <ContentCol span={19} onClick={() => setActiveKey?.(MetricOptionsFormKeys.filters)}>
            <DetailsContainer>
              {
                value.additionalFilters.map(filter => {
                  return <>
                    <FlexMetricOptionRow $clickable={isClickable} onMouseOver={() => setIsDeleteDisplayed(true)}
                                         onMouseLeave={() => setIsDeleteDisplayed(false)}>
                      <span>{filter?.reference.alias}</span>
                      {isDeleteDisplayed && onDelete && <DeleteContainer>
                        <IconContainer clickable={true} size={14} color={"var(--light-grey)"}>
                          <XCircleIcon onClick={() => onDelete(["additionalFilters", "additionalFiltersLabel"])}/>
                        </IconContainer>
                      </DeleteContainer>}
                    </FlexMetricOptionRow>
                    <FlexMetricOptionRow $clickable={isClickable} justify={'start'} $gap={3}>
                      <IconContainer size={12}>
                        {filter.predicate.negate ? <BanIcon/> : <FilterIcon/>}
                      </IconContainer>
                      <span>{getFilterResume(filter)}</span>
                    </FlexMetricOptionRow>
                  </>
                })
              }
            </DetailsContainer>
          </ContentCol>
        </Row>
      </GrowthPreview>
    </GrowthResume>}
  </DivWithFullWidth>
})

export default MetricFiltersResume

const CustomFlexMetricOptionRow = styled(FlexMetricOptionRow)`
    align-content: start;
    height: unset;
`

const DetailsContainer = styled.div`

`

const GrowthPreview = styled.div`
  display: flex;
  flex-direction: column;
`

const DivWithFullWidth = styled.div`
  width: 100%;
  cursor: pointer;
`

const GrowthResume = styled.div`
  align-items: center;
  gap: 10px;
`
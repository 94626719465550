import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react"
import Language from "language"
import styled from "styled-components"
import {Input} from "antd"
import {IconContainer} from "components/common/IconContainer"
import {ExclamationIcon} from "@heroicons/react/outline"
import {formatFilterValueToHumanReadable, formatFilterValueToLego, useIsValueNumeric} from "components/forms/selector/comps/box/utils"
import {ConfFilter, ConfFilterScalarTypes, FilterType} from "components/forms/selector/comps/box/filters"
import {FilterInputType} from "types/filter"
import useForwardFocusOnInput from "hooks/useForwardFocusOnInput"
import SuffixPrefixWrapper from "components/forms/selector/comps/box/inputs/SuffixPrefixWrapper"
import {convertNumberToString, convertStringToNumber} from "commons/object.utils"

interface FilterPatternProps {
  filter: ConfFilterScalarTypes
  temporaryFilter: ConfFilterScalarTypes
  handleTemporaryFilterChange: (filter: ConfFilter) => void
  onFocus: () => void
  onBlur: () => void
}

// eslint-disable-next-line react/display-name
const FilterScalar = forwardRef<any, FilterPatternProps>(({
                                                            filter,
                                                            temporaryFilter,
                                                            handleTemporaryFilterChange,
                                                            onFocus,
                                                            onBlur,
                                                          }, ref) => {
  const [filterValue, setFilterValue] = useState<number | undefined>(formatFilterValueToHumanReadable(temporaryFilter, temporaryFilter.predicate.value.value))
  const [temporaryValue, setTemporaryValue] = useState<string>(convertNumberToString(filterValue))
  const isFieldValid = useIsValueNumeric(temporaryValue)
  const inputRef = useRef<any>()

  useForwardFocusOnInput(ref, inputRef)

  useEffect(() => {
    if (isFieldValid) {
      setTemporaryValue(convertNumberToString(formatFilterValueToHumanReadable(temporaryFilter, filter.predicate.value.value)))
    }
    // Here we want to listen to filter change (updated by the dashboard conf or after validation) in order to update the local state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (isFieldValid) {
      setFilterValue(convertStringToNumber(temporaryValue))
    } else {
      setFilterValue(undefined)
    }
  }, [isFieldValid, temporaryValue])

  useEffect(() => {
    const legoValue = formatFilterValueToLego(temporaryFilter, filterValue)
    if (temporaryFilter.predicate.value.value !== legoValue) {
      handleTemporaryFilterChange({
        ...temporaryFilter,
        predicate: {
          ...temporaryFilter.predicate,
          value: {
            ...temporaryFilter.predicate.value,
            "@type": FilterType.SCALAR,
            value: legoValue,
          },
        },
        isValid: isFieldValid,
      })
    }
  }, [filterValue, handleTemporaryFilterChange, isFieldValid, temporaryFilter])

  const handleInputChange = useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryValue(v.target.value)
  }, [])

  return <div>
    <FlexContainer>
      <SuffixPrefixWrapper
        suffix={filter.type === FilterType.metric ? filter.reference.suffix : undefined}
        prefix={filter.type === FilterType.metric ? filter.reference.prefix : undefined}>
        <StyledInput
          ref={inputRef}
          value={temporaryValue}
          status={isFieldValid ? undefined : "error"}
          placeholder={Language.get(`filter.type.${FilterInputType.GREATER}.placeholder`)}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={() => onBlur()}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              onBlur()
            }
          }}
        />
      </SuffixPrefixWrapper>
    </FlexContainer>
    {
      isFieldValid ? <></> : <ErrorContainer><IconContainer
        size={12}><ExclamationIcon/></IconContainer><span>{Language.get(`filter.type.${FilterInputType.GREATER}.warning`)}</span></ErrorContainer>
    }
  </div>
})

export default FilterScalar

const ErrorContainer = styled.div`
  display: flex;
  color: var(--target-red);
  font-size: 10px;
  align-content: center;
  align-items: center;
  gap: 4px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 6px;
`

const StyledInput = styled(Input)`
  line-height: 28px;
  padding: 0 6px;
  box-shadow: none !important;
`
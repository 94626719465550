import React, {FunctionComponent, PropsWithChildren} from 'react'
import WrapperRoot from './WrapperRoot'
import {ClockIcon} from "@heroicons/react/outline"
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {Rule} from "antd/lib/form"

interface WrapperProps extends PropsWithChildren {
  name: string
  withDescription?: boolean
  rules?: Rule[]
}

export const PeriodWrapper: FunctionComponent<WrapperProps> = ({children, name, withDescription = false, rules}) => (
  <WrapperRoot {...{
    ...(withDescription ? {
      icon: ClockIcon,
      label: "configuration-label-period",
    } : {}),
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)



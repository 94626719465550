/* eslint-disable max-lines */

import {useEffect, useMemo} from "react"
import {MetaModelView} from "classes/MetaModel"
import {ConfLimit, ConfOrderBy, GenericChartTypes} from "types/widgets"
import {ChartMetricDtoDetail, SlicerDimensionDtoDetail} from "types/savedConfs"
import {ConfSlicer} from "types/charts"
import {
  ChartTypeWithDisabledReason,
  ConfigCache,
  ConfigurationLimitWithDisablingReasons,
  ConfigurationOrderByWithDisablingReasons,
  GenericExtendedConfModel,
  GroupedOptions,
  SlicerConfiguration,
} from "components/forms/chart/types"
import {Granularity} from "types/period.types"
import {useLimits, useMetricFilters, useOrderBys, useSlicers} from "components/forms/chart/useDependenciesHooks"
import dayjs from "dayjs"
import {TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import {ConfMetricFilterTypes} from "components/forms/selector/comps/box/filters"

export const useFixLimits = (
  cache: ConfigCache,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  availableLimitConfiguration: ConfigurationLimitWithDisablingReasons[],
  metrics: ChartMetricDtoDetail[],
  slicers: ConfSlicer[],
  limits?: ConfLimit[],
  displayType?: GenericChartTypes) => {
  const confLimits = useLimits(cache, metrics, slicers, availableLimitConfiguration, displayType)

  useEffect(() => {
      if ((!limits) || (limits.length !== confLimits.length || !confLimits.every((limit, index) => limits[index].limitSeries === limit.limitSeries && limits[index].hideOthers === limit.hideOthers))) {
        onChange({limits: confLimits})
      }
    },
    [confLimits, limits, onChange],
  )
}

export const useFixSlicers = (
  cache: ConfigCache,
  slicers: ConfSlicer[],
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  slicerHasBeenModified: boolean,
  granularity: Granularity | null,
  slicerConfiguration?: SlicerConfiguration,
  displayType?: GenericChartTypes) => {
  const confSlicers = useSlicers(cache, granularity, slicerConfiguration, displayType)
  useEffect(() => {
      if (!slicerHasBeenModified
        && slicers
        && (slicers.length !== confSlicers.length || !confSlicers.every((slicer, index) => {
          if (slicers[index].type === "dimension" && slicers[index].type === "dimension") {
            return (slicers[index] as SlicerDimensionDtoDetail).dimensionCode === (slicer as SlicerDimensionDtoDetail).dimensionCode
          }
          return slicers[index].type === slicer.type
        }))) {
        onChange({slicers: confSlicers})
      }
    },
    [slicers, confSlicers, onChange, slicerHasBeenModified],
  )
}

export const useFixOrderBys = (
  cache: ConfigCache,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  metrics: ChartMetricDtoDetail[],
  slicers: ConfSlicer[],
  viewsWithMetrics: MetaModelView[],
  groupedOptions: GroupedOptions,
  orderBys?: ConfOrderBy[],
  availableSortsConfiguration?: ConfigurationOrderByWithDisablingReasons[],
  displayType?: GenericChartTypes) => {
  const confOrderBys = useOrderBys(cache, viewsWithMetrics, groupedOptions, availableSortsConfiguration, displayType)
  const validatedSorts = useMemo(() => confOrderBys.filter(sort => sort.column < metrics.length + slicers.length), [confOrderBys, metrics.length, slicers.length])

  useEffect(() => {
      if (orderBys && ((orderBys.length !== validatedSorts.length) || !validatedSorts.every((sort, index) => orderBys[index].column === sort?.column && orderBys[index].value === sort?.value && orderBys[index].asc === sort?.asc))) {
        onChange?.({orderBys: validatedSorts})
      }
    },
    [validatedSorts, onChange, orderBys],
  )
}

export const useFixMetricFilters = (
  cache: Pick<ConfigCache, "metricFilters">,
  onChange: (changedValues: Partial<GenericExtendedConfModel>) => void,
  metricFilters: ConfMetricFilterTypes[],
  configuration?: Pick<ChartTypeWithDisabledReason, "isMetricsFilterEnabled">,
  displayType?: GenericChartTypes,
) => {
  const confFilters = useMetricFilters(cache, configuration, displayType)

  useEffect(() => {
    if (metricFilters.length !== confFilters.length || !metricFilters.every((filter, i) => confFilters[i])) {
      onChange({metricFilters: confFilters})
    }
  }, [confFilters, metricFilters, onChange])
}

export const useFixDisplayLabels = (cache: ConfigCache, onChange: (changedValues: Partial<GenericExtendedConfModel>) => void, displayLabels?: boolean) => useEffect(() => {
  if (cache.metrics.length > 0 && cache.metrics[0].displayLabel !== displayLabels) {
    onChange({displayLabels: cache.metrics[0].displayLabel})
  }
}, [cache.metrics, displayLabels, onChange])

export const getDayDifference = (timeInterval: TimeIntervalWithSource) => {
  const startDate = dayjs(timeInterval.start).startOf('day')
  const endDate = dayjs(timeInterval.end).endOf('day')
  return endDate.diff(startDate, 'day') + 1
}

export const useDayDifference = (timeInterval: TimeIntervalWithSource) => useMemo(() => getDayDifference(timeInterval), [timeInterval])
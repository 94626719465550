import {MetricGrowthPeriodUnit, MetricGrowthType} from "types/savedConfs"

interface GrowthOption {
  value: {
    unit: MetricGrowthPeriodUnit
    quantity?: number
  },
  label: string
  preffix?: string
}

interface DynamicGrowthOption extends GrowthOption {
  preffix: string
}

export enum GrowthGroup {
  "last-period" = "last-period",
  "dynamic-period" = "dynamic-period",
}

export enum GrowthLabel {
  'previous' = 'previous',
  'd-n' = 'd-n',
  'w-n' = 'w-n',
  'm-n' = 'm-n',
  'q-n' = 'q-n',
  'y-n' = 'y-n',
}

export enum GrowthPreffix {
  'd-' = 'd-',
  'w-' = 'w-',
  'm-' = 'm-',
  'q-' = 'q-',
  'y-' = 'y-'
}

export const growthOptions: {
  "last-period": GrowthOption[],
  "dynamic-period": DynamicGrowthOption[],
} = Object.freeze({
  "last-period": [
    {
      value: {
        unit: MetricGrowthPeriodUnit.DAYS,
        quantity: undefined,
      }, label: GrowthLabel.previous,
    },
  ],
  "dynamic-period": [
    {
      value: {
        unit: MetricGrowthPeriodUnit.DAYS,
        quantity: 1,
      }, label: GrowthLabel["d-n"],
      preffix: GrowthPreffix["d-"],
    },
    {
      value: {
        unit: MetricGrowthPeriodUnit.WEEKS,
        quantity: 1,
      }, label: GrowthLabel['w-n'],
      preffix: GrowthPreffix["w-"],
    },
    {
      value: {
        unit: MetricGrowthPeriodUnit.MONTHS,
        quantity: 1,
      }, label: GrowthLabel['m-n'],
      preffix: GrowthPreffix["m-"],
    },
    {
      value: {
        unit: MetricGrowthPeriodUnit.QUARTERS,
        quantity: 1,
      }, label: GrowthLabel['q-n'],
      preffix: GrowthPreffix["q-"],
    },
    {
      value: {
        unit: MetricGrowthPeriodUnit.YEARS,
        quantity: 1,
      }, label: GrowthLabel['y-n'],
      preffix: GrowthPreffix["y-"],
    },
  ],
})

export const growthTypes = Object.freeze([
  {value: MetricGrowthType.RATIO, label: 'growth.type-preset.ratio'},
  {value: MetricGrowthType.DIFFERENCE, label: 'growth.type-preset.difference'},
  {value: MetricGrowthType.VALUE, label: 'growth.type-preset.value'},
])


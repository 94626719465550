import MdView from "components/mdView/MdView"
import React, {memo} from "react"
import styled from "styled-components"
import {useDataDocMetricLink} from "components/dataSourceDoc/DataSource.hooks"
import {concatInformationLinkToText} from "components/forms/description/utils"
import NumericMetric from 'themes/svg/filled/numericMetric.svg?react'
import View from 'themes/svg/filled/view.svg?react'
import {TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import MetricOptionsResume from "components/forms/selector/metrics/display/MetricOptionsResume"
import {useDayDifference} from "components/forms/chart/useFixHooks"
import {useHasFilterApplied, useHasGrowthApplied, useHasPeriodApplied} from "components/metricDisplay/MetricDisplay.hooks"
import {isEmpty} from "lodash"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {IconContainer} from "components/common/IconContainer"
import {TooltipAlias, TooltipContainer, TooltipContentElement} from "themes/SharedStyledComponent"

interface MetricTooltipContentProps {
  metric: MetricSelectorValue
  timeInterval: TimeIntervalWithSource
}

// eslint-disable-next-line react/display-name
export const TooltipContentMetric = memo<MetricTooltipContentProps>(({timeInterval, metric}) => {
  const metricLink = useDataDocMetricLink(metric.metricCode)
  const dayDifference = useDayDifference(timeInterval)

  const hasGrowthApplied = useHasGrowthApplied(metric)
  const hasFilterApplied = useHasFilterApplied(metric)
  const hasPeriodApplied = useHasPeriodApplied(metric)

  return <TooltipContainer>
    <TooltipAlias translate="no">
      <IconContainer size={22}>
        <NumericMetric/>
      </IconContainer>
      <div>
        {metric.metricAlias}
      </div>
    </TooltipAlias>
    <TooltipContentElement>
      <FlexDiv><View/>{metric.viewAlias}</FlexDiv>
      <FlexDiv>
        <IconContainer size={22}><NumericMetric/></IconContainer>
        {metric.metricDef?.alias ?? ""}
      </FlexDiv>
      {!isEmpty(metric.metricDef?.description) && <Text>
        <MdView description={concatInformationLinkToText(metric.metricDef?.description, metricLink)} padding={0}/>
      </Text>}
    </TooltipContentElement>
    {(hasGrowthApplied || hasFilterApplied || hasPeriodApplied) && <Separator/>}
    <TooltipContentElement>
      <MetricOptionsResume value={metric} iconSpan={2} isNameDisplayed={false} timeInterval={timeInterval}
                           dayDifference={dayDifference} additionnalStyle={"gap: 10px;"} hideDisplayOptions={true}/>
    </TooltipContentElement>
  </TooltipContainer>
})

const Separator = styled.div`
  height: 0;
  border-top: 1px solid var(--light-grey);
  margin-bottom: 15px;
    width: calc(100% - 18px);
`

const Text = styled.div`
  margin-bottom: 10px;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
    align-content: start;
    line-height: 22px;
`

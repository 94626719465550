import React, {useCallback, useEffect, useState} from 'react'
import {TooltipPlacement} from "antd/lib/tooltip"
import {MultipleSelectWithOptionList} from "components/forms/selector/filters/addFilterPanel/MultipleSelectWithOptionList"
import Language from "language"
import {OptionListPagination} from "components/forms/selector/filters/addFilterPanel/OptionList"
import useSearch from "hooks/useSearch"
import {ScalarQueryPredicate, SelectorFilterTypes, ValuesQueryPredicate} from "components/forms/selector/comps/box/filters"
import {FilterOption} from "types/select"

export interface FilterPopoverProps {
  filters: SelectorFilterTypes[]
  getOptions: (filter: SelectorFilterTypes[]) => FilterOption[]
  onTemporaryFiltersChange: (filters: SelectorFilterTypes[]) => void
  placementOverride?: (placement: TooltipPlacement) => TooltipPlacement
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  allowMultipleSelection?: boolean
  isSimpleInput?: boolean
  withBackground?: boolean
  defaultPagination?: number
  getDefaultValue: () => ValuesQueryPredicate | ScalarQueryPredicate
}

const FilterPopover: (props: FilterPopoverProps) => JSX.Element = ({
                                                                     filters,
                                                                     getOptions,
                                                                     placementOverride,
                                                                     onTemporaryFiltersChange,
                                                                     allowMultipleSelection,
                                                                     isSimpleInput,
                                                                     withBackground,
                                                                     getPopupContainer,
                                                                     defaultPagination = 20,
                                                                     getDefaultValue,
                                                                   }) => {

  const [placement]: [TooltipPlacement, any] = useState('top')
  const [search, setSearch] = useState<string>()

  useEffect(() => {
    setOptions(getOptions(filters).slice(0, defaultPagination))
    // reset the option when the availableDimensions are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptions])

  const [options, setOptions] = useState<FilterOption[]>(getOptions(filters).slice(0, defaultPagination))

  const applySearch = useSearch<FilterOption>()

  const onPaginationChange = useCallback((pagination: OptionListPagination) => {
    const filteredValue = applySearch(getOptions(filters), search)
    setOptions(filteredValue.slice(0, pagination.pageSize + pagination.offset))
  }, [applySearch, filters, getOptions, search])

  const onSearch = useCallback((newSearch: string) => {
    setSearch(newSearch)
    setOptions(applySearch(getOptions(filters), newSearch))
  }, [applySearch, filters, getOptions])

  const onDimensionOptionsSelect = useCallback((option: Pick<FilterOption, 'value' | 'label'>, selectionState: boolean) => {
    const newOptions = Array.from(options)
    const index = newOptions.findIndex(opt => opt.label === option.label)
    newOptions[index] = {
      ...newOptions[index],
      selected: selectionState,
    }
    setOptions(newOptions)
    const newFilters: SelectorFilterTypes[] = Object.assign([], filters)
    if (selectionState) {
      const newFilter: SelectorFilterTypes = {
        dataType: options[index].type,
        reference: {
          code: newOptions[index].value,
          alias: newOptions[index].label,
        },
        predicate: {
          ...getDefaultValue(),
          negate: false,
        },
        isValid: true,
      }
      newFilters.push(newFilter)
    } else {
      newFilters.splice(newFilters.findIndex((filter) => filter.reference.alias === option.label), 1)
    }
    onTemporaryFiltersChange(newFilters)
  }, [filters, getDefaultValue, onTemporaryFiltersChange, options])

  return <MultipleSelectWithOptionList
    withBackground={withBackground}
    isSimpleInput={isSimpleInput}
    placement={placement}
    placeholder={{
      add: Language.get('configuration-add-filter', !isSimpleInput),
      search: Language.get('configuration-search-filter', !isSimpleInput),
    }}
    placementOverride={placementOverride}
    options={options}
    onChange={onDimensionOptionsSelect}
    allowMultipleSelection={allowMultipleSelection}
    noDataTextKey={"configuration-no-filter-available"}
    getPopupContainer={getPopupContainer}
    onPaginationChange={onPaginationChange}
    onSearch={onSearch}/>
}

export default FilterPopover

import React, {FunctionComponent, ReactNode} from "react"
import {Col, Select} from 'antd'
import styled from "styled-components"
import Language from "language"
import withSortableElement from "components/common/sortable/withSortableElement"
import {ConfOrderBy} from "types/widgets"
import {IconContainer} from "components/common/IconContainer"
import {SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/outline"
import {SortableRow} from "../utils"
import {GroupedOptions} from "components/forms/chart/types"

interface Props {
  id: number
  groupedOptions: GroupedOptions
  value?: ConfOrderBy | null
  placeholder: string | ReactNode
  onChange?: (value: unknown) => void
  onDirectionChange?: (asc: boolean) => void
  isDefault?: boolean
}

// eslint-disable-next-line react/display-name
const SortSelectorSelect: FunctionComponent<Props> = ({groupedOptions, id, value, onChange, onDirectionChange, isDefault, ...props}) => {
  return <SortableRow>
    <StyledCol span={14} $isdefault={isDefault ? 1 : 0}>
      <StyledSelect
        key={`no-sort-StyledSelect-${id}`} {...{
        ...props,
        value: value?.value,
        onChange,
        virtual: false,
        defaultValue: undefined,
      }}>
        {Object.entries(groupedOptions).map(([groupName, groupValues]) =>
            groupValues && <Select.OptGroup key={`${groupName}-groupName-${id}`} label={Language.get(`configuration-label-${groupName}`)}>
              {groupValues.map(({label: optionLabel, value: optionValue, disabled}, index) => {
                  return <Select.Option value={optionValue} key={`${optionValue}-${index}`} disabled={disabled}>{optionLabel}</Select.Option>
                },
              )}
            </Select.OptGroup>,
        )}
      </StyledSelect>
    </StyledCol>
    <Col span={2}>
      {value?.value && <IconContainer clickable={true} onClick={() => {
        onDirectionChange?.(!value.asc)
      }}>
        {value.asc ? <SortAscendingIcon/> : <SortDescendingIcon/>}
      </IconContainer>}
    </Col>
  </SortableRow>
}

export default SortSelectorSelect


// @ts-ignore
SortSelectorSelect.Sortable = withSortableElement(SortSelectorSelect)

const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledCol = styled(Col)<{ $isdefault?: number }>`
    ${props => props.$isdefault ? `
  font-style: italic;
  color: var(--light-grey);
  
  .ant-select-selection-item{
    color: var(--light-grey);
  }
  ` : ''}
`
import React, {ChangeEvent, FunctionComponent} from 'react'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {TranslateIcon} from "@heroicons/react/outline"
import BironInput from "components/forms/selector/input/BironInput"
import WrapperRoot from "components/forms/confItems/WrapperRoot"

interface Props {
  name: string
  value?: string
  onChange?: (newValue: string) => void
  span?: number
}

const NameFR: FunctionComponent<Props> = ({
                                            name,
                                            onChange,
                                            value,
                                            span,
                                          }) => <WrapperRoot
  icon={TranslateIcon}
  label={'form-label-name-fr'}
  itemColumnWidth={8}>
  <WrapperItem name={name}>
    <BironInput
      value={value}
      span={span}
      onChange={(newValue: ChangeEvent<HTMLInputElement>) => {
        onChange?.(newValue.target.value)
      }}/>
  </WrapperItem>
</WrapperRoot>

export default NameFR
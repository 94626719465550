import React, {memo} from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import Loader from "components/loader/Loader"
import PositionMenu from '.././confItems/PositionMenu'
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"

const NavAddMenuFormBody = <T, >({
                                   loading,
                                   availables: {menusPositions = []},
                                   getPopupContainer,
                                 }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
    <PositionMenu
      name={FormItemKeys.POSITION_MENU}
      options={menusPositions}
      span={24}
      {...{
        getPopupContainer,
      }}/>
  </Loader>
}

export default memo(NavAddMenuFormBody) as typeof NavAddMenuFormBody

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useSelector} from "react-redux"
import {getCurrentEnvironment} from "redux/environment.selector"
import {getClientAllowedEmailFqdns, getPermission} from "redux/appEnvironment.selector"
import {getAcesByUserId, getAssocClients} from "redux/user.selector"
import {getAvailablesForAce} from "redux/workspace.selector"
import UserForm from "./UserForm"
import {Button, notification} from 'antd'
import styled from "styled-components"
import UserManagerAssocClientList from "components/admin/user/UserManagerAssocClientList"
import {buildAndBatch} from "commons/batch"
import {PageTitle} from "themes/SharedStyledComponent"
import Language from "language"
import UserAcesForm from "./UserAcesForm"
import NewUacForm from "./NewUacForm"
import notificationSuccess from "commons/notificationSuccess"
import useDispatch from "hooks/useDispatch"
import {UserAddIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {useLocation} from "react-router-dom"
import {ModelUser} from "redux/models/user"
import {ACES, Environment, UAC, User, WorkspacesForAce} from "components/admin/user/UserManager.types"

const UserManager = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const uacs: UAC[] = useSelector(getAssocClients)
  const acesByUserId: { [p: string]: ACES[] } = useSelector(getAcesByUserId)
  const getPermissionSelector = useSelector(getPermission)
  const environment: Environment = useSelector(getCurrentEnvironment)
  const clientAllowedEmailFqdns = useSelector(getClientAllowedEmailFqdns) as string[]
  const availableWorkspacesForAce: WorkspacesForAce[] = useSelector(getAvailablesForAce)
  const [createNewUac, setCreateNewUac] = useState(false)
  const [editedUac, setEditedUac] = useState<UAC>()
  const [editedUserForAces, setEditedUserForAces] = useState<User>()
  const [loading, setLoading] = useState(true)
  const userCanAssocToEnvironment = getPermissionSelector('userCanAssocToEnvironment')

  useEffect(() => {
    buildAndBatch([
      dispatch.user.loadAssocClient({forBatch: true}),
      dispatch.user.loadAces({forBatch: true}),
      () => setLoading(false),
    ])
  }, [dispatch, location])

  const handleClickNewUser = useCallback(() => {
    setEditedUac(Object.assign({} as UAC, {user: {}}))
  }, [])
  const handleClickNewUac = useCallback(() => {
    setCreateNewUac(true)
  }, [])

  const handleActiveChange = useCallback((uac: UAC, active: boolean) => buildAndBatch([
    () => setEditedUac(Object.assign({}, {
      ...uac,
      active,
    })),
    () => notificationSuccess.action(Language.get(`admin.user.${active ? 'actionActiveUser' : 'actionInactiveUser'}`)),
    dispatch.user.updateAssocClientActive({
      forBatch: true,
      uac,
      active,
    }),
  ]), [dispatch])
  const handleResetPassword = useCallback((user: User) => buildAndBatch([
    () => setEditedUac(undefined),
    () => dispatch.user.resetPassword(user)
      .then(() => {
        notification.success({
          message: Language.get(`admin.user.actionResetPasswordSuccess.message`),
          description: Language.get(`admin.user.actionResetPasswordSuccess.description`, {user}),
        })
      }),
  ]), [dispatch])
  const handleDelete = useCallback((uac: UAC) => buildAndBatch([
    () => setEditedUac(undefined),
    dispatch.user.deleteAssocClient({
      forBatch: true,
      uac,
    }),
  ]), [dispatch])
  const handleUserEdit = useCallback((user: User) => setEditedUac(uacs.find(candidate => candidate.user.id === user.id)), [uacs])
  const handleUserAcesEdit = useCallback((user: User) => setEditedUserForAces(Object.assign({}, user)), [setEditedUserForAces])

  const handleUserFormConfirm = useCallback(
    (data: ModelUser) => {
      return (editedUac?.user.id
          ? dispatch.user.updateUser({
            id: editedUac.user.id,
            data,
          })
          : dispatch.user.createUser({data})
      )
        .then(() => {
          setEditedUac(undefined)
        })
    }
    , [dispatch, editedUac])
  const handleUserFormCancel = useCallback(() => {
    setEditedUac(undefined)
  }, [])

  const handleNewUacFormConfirm = useCallback(
    ({userEmail}: { userEmail: string }) => dispatch.user.createAssocClient({userEmail})
      .then(() => {
        setCreateNewUac(false)
      })
    , [dispatch])
  const handleNewUacFormCancel = useCallback(() => {
    setCreateNewUac(false)
  }, [])

  const editedUserAces = useMemo<ACES[] | undefined>(() => editedUserForAces && (acesByUserId[String(editedUserForAces.id)] || []),
    [editedUserForAces, acesByUserId],
  )
  const handleUserAcesFormConfirm = useCallback((newAces: ACES[]) => {
    const previousRolesPerTarget: { [target: string]: string[] } | undefined = editedUserAces?.reduce((acc, {target, roles}) => ({
      ...acc,
      [target]: roles,
    }), {})
    const newAcesFiltered = newAces
      // remove entry for a target when no role previously exists and none is newly selected
      .filter(({target, roles}) => roles.length > 0 || previousRolesPerTarget?.[target])

    return dispatch.user.updateUserAces({
        user: editedUserForAces,
        aces: newAcesFiltered,
      })
      .then(() => {
        setEditedUserForAces(undefined)
      })
  }, [dispatch, editedUserForAces, editedUserAces])
  const handleUserAcesFormCancel = useCallback(() => {
    setEditedUserForAces(undefined)
  }, [])

  const addUserActions = useMemo(() => {
    const basicAdd = <FlexButton key="actionCreateUser"
                                 onClick={handleClickNewUser}><IconContainer
      margin={10}><UserAddIcon/></IconContainer>{Language.get('admin.user.actionCreateUser')}</FlexButton>
    if (userCanAssocToEnvironment) {
      return [basicAdd,
        <FlexButton key="actionCreateUac"
                    onClick={handleClickNewUac}><IconContainer
          margin={10}><UserAddIcon/></IconContainer>{Language.get('admin.user.actionCreateUac')}</FlexButton>,
      ]
    } else {
      return [basicAdd]
    }
  }, [userCanAssocToEnvironment, handleClickNewUser, handleClickNewUac])

  return <div>
    <PageTitle>{Language.get('admin.user.homeTitle')}</PageTitle>
    <StyledBtnCtn>{addUserActions}</StyledBtnCtn>
    <UserManagerAssocClientList {...{
      uacs,
      acesByUserId,
      loading,
      getPermission: getPermissionSelector,
      clientAllowedEmailFqdns,
      environment,
      availableWorkspacesForAce,
      onUserEdit: handleUserEdit,
      onUserAcesEdit: handleUserAcesEdit,
    }} />
    <UserForm {...{
      uac: editedUac,
      getPermission: getPermissionSelector,
      clientAllowedEmailFqdns,
      onActiveChange: handleActiveChange,
      onResetPassword: handleResetPassword,
      onDelete: handleDelete,
      onConfirm: handleUserFormConfirm,
      onCancel: handleUserFormCancel,
    }}/>
    <NewUacForm {...{
      visible: createNewUac,
      onConfirm: handleNewUacFormConfirm,
      onCancel: handleNewUacFormCancel,
    }}/>
    <UserAcesForm {...{
      user: editedUserForAces,
      aces: editedUserAces ?? [],
      getPermission: getPermissionSelector,
      environment,
      availableWorkspacesForAce,
      onConfirm: handleUserAcesFormConfirm,
      onCancel: handleUserAcesFormCancel,
    }}/>
  </div>
}

export default UserManager

const StyledBtnCtn = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`

const FlexButton = styled(Button)`
  display: flex;
`
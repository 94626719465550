import {Col, Row} from "antd"
import React, {PropsWithChildren, ReactNode} from "react"
import styled from "styled-components"
import {WrapperItem} from "components/forms/confItems/WrapperItem"

interface FormRowProps<T> {
  name: string
  title: string
  activated?: boolean
  initialValue?: T
  additionalComponent?: ReactNode
  textAlignment?: "center" | "start"
  contentSpan?: number
  textSpan?: number
  contentMargin?: number
}

const FormRow = <T, >({
                        name,
                        title,
                        activated = true,
                        initialValue,
                        additionalComponent,
                        textAlignment = "center",
                        contentSpan = 17,
                        textSpan = 7,
                        contentMargin = 0,
                        children,
                      }: PropsWithChildren<FormRowProps<T>>) => {
  return <RowWithFixedHeight $activated={activated ? 1 : 0}>
    <TextCol span={textSpan} $textalignment={textAlignment}>
      {title}
    </TextCol>
    <ContentCol span={contentSpan} $margin={contentMargin}>
      <WrapperItem name={name} initialValue={initialValue}>
        {children}
      </WrapperItem>
    </ContentCol>
    {additionalComponent}
  </RowWithFixedHeight>
}

export default FormRow

const RowWithFixedHeight = styled(Row)<{ $activated: number }>`
    ${({$activated}) => $activated ? '' : 'color: var(--medium-grey);'}
`
const TextCol = styled(Col)<{ $textalignment: string }>`
  display: flex;
  align-items: ${({$textalignment}) => $textalignment};
    height: 40px;
`

const ContentCol = styled(Col)<{ $margin: number }>`
  display: flex;
  align-items: center;
    margin: ${({$margin}) => $margin}px;
`
import {SlicerDateDtoDetailWithSource, SlicerDtoDetailTypes} from "types/savedConfs"
import {DataSelection} from "redux/models/currentDashboard"
import {extractSlicerDate, extractSlicersDimension} from "commons/configuration"
import {isEmpty} from "commons/object.utils"
import {Granularity, PeriodTypes} from "types/period.types"
import {getResolvedFromPeriod} from "commons/period/periodsList"
import {TimeInterval, TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import {isFilterEmpty} from "components/forms/selector/comps/box/utils"
import {
  DimensionFilterDtoDetail,
  DimensionFilterTypesWithSource,
  FilterDtoDetailTypes,
  Source,
} from "components/forms/selector/comps/box/filters"
import {ConfSlicer} from "types/charts"
import {ExportMetricWithViewCodeAndAlias} from "components/forms/Form.types"
import {convertFilterDetailToConfFilter} from "components/forms/chart/utils"
import {Dimension} from "classes/MetaModel"


export const consolidateSlicers = (slicers: SlicerDtoDetailTypes[], dashboardSelection: DataSelection, withDateSlicer: boolean, withChartOverriddenPeriod: boolean | undefined) => {
  const dateSlicer = extractSlicerDate(slicers)
  const consolidatedDateSlicer: SlicerDateDtoDetailWithSource = !dateSlicer || isEmpty(dateSlicer?.granularity) || withChartOverriddenPeriod === false ? {
    type: "date",
    granularity: dashboardSelection.date.granularity ?? Granularity.DAY,
    source: Source.QUERY,
  } : {
    ...dateSlicer,
    granularity: dateSlicer.granularity as Granularity,
    source: Source.CHART,
  }

  const consolidatedSlicers = slicers.map(slicer => {
    switch (slicer.type) {
      case "dimension":
        return slicer
      case "date":
        return consolidatedDateSlicer
      default:
        // eslint-disable-next-line no-case-declarations
        const checkExhaustive: never = slicer
        return checkExhaustive
    }
  })

  if (dateSlicer === undefined && withDateSlicer) {
    return [consolidatedDateSlicer, ...consolidatedSlicers]
    // eslint-disable-next-line no-negated-condition
  } else if (!withDateSlicer) {
    return extractSlicersDimension(consolidatedSlicers)
  } else {
    return consolidatedSlicers
  }
}

export const consolidateFilters = (dashboardSelection: DataSelection, filters: FilterDtoDetailTypes[]): FilterDtoDetailTypes[] => [
  ...dashboardSelection.filters.filter(filter => !isFilterEmpty(filter)),
  ...filters,
]

export const consolidateFiltersAndAddSource = (dashboardSelection: DataSelection, filters: DimensionFilterDtoDetail[]): DimensionFilterTypesWithSource[] => [
  ...dashboardSelection.filters.filter(filter => !isFilterEmpty(filter)).map(filter => ({source: Source.QUERY, ...filter})),
  ...filters.map(filter => ({source: Source.CHART, ...filter})),
]

interface ChartPeriodDefined {
  dashboardSelection?: DataSelection
  chartPeriod: PeriodTypes
}

interface DashboardPeriodDefined {
  dashboardSelection: DataSelection
  chartPeriod?: PeriodTypes
}

export const consolidateTimeInterval = ({
                                          dashboardSelection,
                                          chartPeriod,
                                        }: ChartPeriodDefined | DashboardPeriodDefined, withChartOverriddenPeriod: boolean | undefined): TimeIntervalWithSource => {
  const timeInterval = chartPeriod && withChartOverriddenPeriod ? {
    period: chartPeriod,
    start: getResolvedFromPeriod(chartPeriod)?.start,
    end: getResolvedFromPeriod(chartPeriod)?.end,
    source: Source.CHART,
  } : {
    period: (dashboardSelection as DataSelection).date.period,
    start: (dashboardSelection as DataSelection).date.start,
    end: (dashboardSelection as DataSelection).date.end,
    source: Source.QUERY,
  }
  return {
    period: timeInterval.period,
    start: timeInterval.start ? timeInterval.start.format('YYYY-MM-DD') : '',
    end: timeInterval.end ? timeInterval.end.format('YYYY-MM-DD') : '',
    source: timeInterval.source,
  }
}

export const getMetricTimeInterval = (metricPeriod: PeriodTypes | undefined): TimeInterval | undefined => metricPeriod ? {
  start: getResolvedFromPeriod(metricPeriod)?.start.format('YYYY-MM-DD'),
  end: getResolvedFromPeriod(metricPeriod)?.end.format('YYYY-MM-DD'),
} : undefined

export const convertSlicersToConfModel = (slicers: SlicerDtoDetailTypes[]): ConfSlicer[] => slicers.map(slicer => ({
  ...slicer,
  isDefault: false,
}))

export const convertMetricToConfModel = (metrics: ExportMetricWithViewCodeAndAlias[], availableDimensions?: Dimension[]) => metrics.map(metric => ({
  ...metric,
  additionalFilters: convertFilterDetailToConfFilter(metric.additionalFilters, availableDimensions),
}))
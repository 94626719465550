import {FunctionComponent} from "react"
import {FilterIcon} from "@heroicons/react/solid"
import {BanIcon} from "@heroicons/react/outline"
import {SemanticType} from "classes/MetaModel"
import {DictionaryEntry} from "types/savedConfs"

export enum FilterType {
  DICT_ENTRIES = "dictEntries",
  PATTERN = "pattern",
  SCALAR = "scalar",
  RANGE = "range"
}

export enum FilterNegationType {
  "negate" = "negate",
  "default" = "default",
}

export const filterTypeToIcon = new Map<FilterNegationType, FunctionComponent>([
  [FilterNegationType.default, FilterIcon],
  [FilterNegationType.negate, BanIcon],
])

export enum Source {
  "CHART" = "CHART",
  "QUERY" = "QUERY"
}

export enum FilterOperator {
  IN = "In",
  Like = "Like",
  Match = "Match",
  BETWEEN = "BETWEEN",
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
  EQ = "EQ",
}

interface FilterValue {
  "@type": FilterType
}

export interface DictEntries extends FilterValue {
  "@type": FilterType.DICT_ENTRIES
  entries: DictionaryEntry[],
}

export interface Pattern extends FilterValue {
  "@type": FilterType.PATTERN
  pattern: string
  caseSensitive: boolean
}

export interface Scalar extends FilterValue {
  "@type": FilterType.SCALAR
  value?: number
}

export interface Range extends FilterValue {
  "@type": FilterType.RANGE
  minInclusive?: number
  maxInclusive?: number
}

export type FilterValueTypes = DictEntries | Pattern | Scalar | Range

export enum FilterType {
  "dimension" = "dimension",
  "metric" = "metric"
}

interface QueryPredicate {
  operator: FilterOperator.IN | FilterOperator.Like | FilterOperator.Match | FilterOperator.EQ | FilterOperator.LT | FilterOperator.LTE | FilterOperator.GT | FilterOperator.GTE | FilterOperator.BETWEEN
  value: FilterValueTypes
  negate: boolean
}

export interface ValuesQueryPredicate extends QueryPredicate {
  operator: FilterOperator.IN
  value: DictEntries
}

export interface LikeQueryPredicate extends QueryPredicate {
  operator: FilterOperator.Like
  value: Pattern,
}

export interface MatchQueryPredicate extends QueryPredicate {
  operator: FilterOperator.Match
  value: Pattern,
}

export interface ScalarQueryPredicate extends QueryPredicate {
  operator: FilterOperator.EQ | FilterOperator.LT | FilterOperator.LTE | FilterOperator.GT | FilterOperator.GTE
  value: Scalar,
}

export interface RangeQueryPredicate extends QueryPredicate {
  operator: FilterOperator.BETWEEN
  value: Range,
}

export type QueryPredicateTypes =
  ValuesQueryPredicate
  | LikeQueryPredicate
  | MatchQueryPredicate
  | RangeQueryPredicate
  | ScalarQueryPredicate

interface Filter {
  type: FilterType.metric | FilterType.dimension
  predicate: QueryPredicate
}

export interface DimensionFilterDtoDetail extends Filter {
  type: FilterType.dimension
  dimensionCode: string
  predicate: ValuesQueryPredicate | LikeQueryPredicate | MatchQueryPredicate | RangeQueryPredicate | ScalarQueryPredicate
}

export interface MetricFilterDtoDetail extends Filter {
  type: FilterType.metric
  metricCode: string
  predicate: RangeQueryPredicate | ScalarQueryPredicate
}

export interface DimensionFilterValueDtoDetail extends DimensionFilterDtoDetail {
  predicate: ValuesQueryPredicate
}

export interface DimensionFilterLikeDtoDetail extends DimensionFilterDtoDetail {
  predicate: LikeQueryPredicate
}

export interface DimensionFilterMatchDtoDetail extends DimensionFilterDtoDetail {
  predicate: MatchQueryPredicate
}

export interface DimensionFilterRangeDtoDetail extends DimensionFilterDtoDetail {
  predicate: RangeQueryPredicate
}

export interface DimensionFilterScalarDtoDetail extends DimensionFilterDtoDetail {
  predicate: ScalarQueryPredicate
}

export interface MetricFilterRangeDtoDetail extends MetricFilterDtoDetail {
  predicate: RangeQueryPredicate
}

export interface MetricFilterScalarDtoDetail extends MetricFilterDtoDetail {
  predicate: ScalarQueryPredicate
}

export type FilterDtoDetailTypes = DimensionFilterDtoDetail | MetricFilterDtoDetail
export type DimensionFilterTypesWithSource = DimensionFilterDtoDetail & { source: Source }

interface ConfFilterProperties {
  isValid: boolean
  dataType: SemanticType
  reference: {
    id?: string
    alias: string
    code: string
  }
}

interface ConfDimensionFilterProperties extends ConfFilterProperties {
  reference: {
    id?: string
    alias: string
    code: string
    description?: string
    dictionaryCode?: string
  }
}

interface ConfMetricFilterProperties extends ConfFilterProperties {
  reference: {
    id?: string
    alias: string
    code: string
    description?: string
    prefix?: string
    suffix?: string
    asPercentage?: boolean
  }
}

export type ConfDimensionFilterValuesDtoDetail = DimensionFilterValueDtoDetail & ConfDimensionFilterProperties
export type ConfDimensionFilterLikeDtoDetail = DimensionFilterLikeDtoDetail & ConfDimensionFilterProperties
export type ConfDimensionFilterScalarDtoDetail = DimensionFilterScalarDtoDetail & ConfDimensionFilterProperties
export type ConfDimensionFilterRangeDtoDetail = DimensionFilterRangeDtoDetail & ConfDimensionFilterProperties
export type ConfDimensionFilterMatchDtoDetail = DimensionFilterMatchDtoDetail & ConfDimensionFilterProperties
export type ConfMetricFilterScalarDtoDetail = MetricFilterScalarDtoDetail & ConfMetricFilterProperties
export type ConfMetricFilterRangeDtoDetail = MetricFilterRangeDtoDetail & ConfMetricFilterProperties

export type ConfFilterRangeTypes = ConfDimensionFilterRangeDtoDetail | ConfMetricFilterRangeDtoDetail
export type ConfFilterScalarTypes = ConfDimensionFilterScalarDtoDetail | ConfMetricFilterScalarDtoDetail

export type ConfDimensionFilterTypes = ConfDimensionFilterValuesDtoDetail | ConfDimensionFilterLikeDtoDetail |
  ConfDimensionFilterScalarDtoDetail | ConfDimensionFilterRangeDtoDetail | ConfDimensionFilterMatchDtoDetail
export type ConfMetricFilterTypes = ConfMetricFilterRangeDtoDetail | ConfMetricFilterScalarDtoDetail

export type ConfDimensionFilterTypesWithSource = ConfDimensionFilterTypes & { source: Source }
export type ConfMetricFilterTypesWithSource = ConfMetricFilterTypes & { source: Source }


export type ConfFilter = ConfDimensionFilterValuesDtoDetail
  | ConfDimensionFilterLikeDtoDetail
  | ConfDimensionFilterScalarDtoDetail
  | ConfDimensionFilterRangeDtoDetail
  | ConfDimensionFilterMatchDtoDetail
  | ConfMetricFilterScalarDtoDetail
  | ConfMetricFilterRangeDtoDetail

export type SelectorFilterTypes = Omit<ConfFilter, "type">
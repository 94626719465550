import React, {memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import useLegacyState from "hooks/useLegacyState"
import FormHeader from 'components/forms/FormHeader'
import FormWorkflow, {State} from 'classes/workflows/main-workflows/FormWorkflow'
import FormBody from '././confBodies/FormBody'
import FormFooter from 'components/forms/FormFooter'
import {Button, Modal} from 'antd'
import {strToColor} from "components/charts/Chart.Theme"
import Language from "language"
import {FormGenericProps, FormKeys} from "components/forms/Form.types"
import {BaseEditionInformation} from "components/workspace/WorkspaceBridge.SiderContainer"


export const useCssClassOptions = () => useMemo(() => Array.from(strToColor.keys()).map((key) => ({
  label: Language.get(`colors.${key}`) as string,
  value: key,
})), [])

const FormGeneric = <T extends BaseEditionInformation>({
                       formType,
                       data,
                       metaModel,
                       workspace,
                       dashboardId,
                                                         environmentId,
                       altSubmit,
                       onConfirm,
                       onCancel,
                                                       }: FormGenericProps<T>) => {
  const title = Language.get('new-chart-title')
  const cssClassOptions = useCssClassOptions()
  const [legacyState, legacySetState] = useLegacyState<State>({
    loading: true,
    availables: {
      menus: [],
      dashboardsPositions: [],
      menusPositions: [],
    },
    value: undefined,
  })
  const refFormBody = useRef<any>()

  const workflow = useMemo(
    () => new FormWorkflow(formType, {...data.extraConf, ...data}, (newState: State) => {
      legacySetState(newState)
    }, title ?? '', metaModel, workspace, dashboardId, environmentId),
    [legacySetState, formType, data, title, metaModel, workspace, dashboardId, environmentId],
  )

  const placeholder = useMemo(() => {
    switch (formType.type) {
      case FormKeys.ADD_MENU:
        return Language.get('form-title-new-menu')
      case FormKeys.ADD_DASHBOARD:
        return Language.get('form-title-new-dashboard')
      case FormKeys.CHART_CONF:
        return Language.get('new-chart-title')
      case FormKeys.EDIT_MENU:
        return Language.get('navigation-tools-edit-menu')
      default:
        return ''
    }
  }, [formType])

  const handleSubmit = useCallback(() => {
      workflow.saveConf()
      const confResult = workflow.getConfResult() as BaseEditionInformation
      onConfirm(
        {
          ...confResult,
          title: confResult.title ?? placeholder,
          name: confResult.name ?? placeholder,
          extraConf: {
            ...confResult,
            cssClass: confResult.cssClass ?? cssClassOptions[0].value,
          },
        } as unknown as T,
      )
    },
    [cssClassOptions, onConfirm, placeholder, workflow])
  const handleUpdate = useCallback((key: string, value: string) => workflow.update(key, value), [workflow])
  const handleCancel = onCancel

  useEffect(() => () => workflow.unsubscribe(), [workflow])
  useLayoutEffect(() => {
    window.setTimeout(() => {
      if (refFormBody.current && refFormBody.current.initFocus) {
        refFormBody.current.initFocus()
      }
    }, 100)
  }, [])

  const formFooter = <FormFooter {...{
    altSubmit: altSubmit ? {
      ...altSubmit,
      trigger: <Button type="primary" danger>{altSubmit.title}</Button>,
    } : undefined,
    cancel: handleCancel,
    submitting: false,
    submit: handleSubmit,
  }} />

  const isTitleEditable = useMemo(() => [
    FormKeys.ADD_MENU,
    FormKeys.EDIT_MENU,
    FormKeys.ADD_DASHBOARD,
    FormKeys.EDIT_DASHBOARD,
    FormKeys.CLONE_DASHBOARD].includes(formType.type), [formType.type])

  const titleContent: ["name" | "title", string | undefined] = useMemo(() => {
    if (FormKeys.CHART_CONF === formType.type) {
      return ["name", Language.get("new-text-chart-title")]
    } else if ([FormKeys.ADD_MENU, FormKeys.EDIT_MENU, FormKeys.DELETE_MENU].includes(formType.type)) {
      return ["name", legacyState?.value?.name]
    } else {
      return ["title", legacyState?.value?.title]
    }
  }, [formType.type, legacyState.value])

  const {loading, availables} = legacyState

  return <Modal {...{
    title: <FormHeader value={titleContent?.[1]} onCancel={onCancel}
                       editable={isTitleEditable}
                       onChange={(newValue) => {
                         handleUpdate(titleContent?.[0], newValue)
                       }} placeholder={placeholder}/>,
    open: true,
    closable: false,
    onCancel,
    width: formType.type === FormKeys.CHART_CONF ? 1500 : 750,
    className: "conf-body",
    wrapClassName: "form",
    footer: formFooter,
  }}>
    {legacyState.value && <FormBody {...{
      formType,
      availables,
      loading,
      data: legacyState.value,
      update: handleUpdate,
    }}/>}
  </Modal>
}

export default memo(FormGeneric) as typeof FormGeneric

import {useEffect} from "react"
import ResizeDetector from "element-resize-detector"
import {isEmpty} from "commons/object.utils"

export const useResizeDetector = (
  container: React.MutableRefObject<HTMLDivElement>,
  oldSize: { width?: number, height?: number },
  widthModifier: (newWidth: number, newHeight: number) => void) => {
  useEffect(() => {
    ResizeDetector().listenTo(container.current, (element: { offsetWidth: number, offsetHeight: number }) => {
      if ((!isEmpty(oldSize.height) && oldSize.height !== element.offsetHeight)
        || (!isEmpty(oldSize.width) && oldSize.width !== element.offsetWidth)) {
        widthModifier(element.offsetWidth, element.offsetHeight)
      }
    })
  }, [container, oldSize.height, oldSize.width, widthModifier])
}


import React, {memo, useMemo} from 'react'
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import {IconContainer} from "components/common/IconContainer"
import {QuestionMarkCircleIcon} from "@heroicons/react/outline"
import Language from "language"
import {TooltipPlacement} from "antd/lib/tooltip"
import MdView from "components/mdView/MdView"
import {concatInformationLinkToText} from "components/forms/description/utils"

interface ValueDescriptionParams {
  className?: string
  title?: string
  description?: string
  children?: React.ReactNode
  placement?: TooltipPlacement
  link?: string
}

// eslint-disable-next-line react/prop-types
const ValueDescription = memo<ValueDescriptionParams>(function ValueDescription({
                                                                                  className,
                                                                                  description,
                                                                                  title,
                                                                                  placement,
                                                                                  link,
                                                                                  children,
                                                                                }) {
  const consolidatedDescription = useMemo(() => concatInformationLinkToText(description, link), [description, link])
  const content = consolidatedDescription ? <MdView padding={0} description={consolidatedDescription}/> : children
  return <TooltipPopover size={'small'} content={content ?? Language.get("no-description")} title={title} placement={placement}>
    <div title={""}>
      <IconContainer disable={!content} color={"var(--border-color-base)"} clickable={true}
                     hoverColor={"var(--light-grey)"}><QuestionMarkCircleIcon
        className={className}/></IconContainer>
    </div>
  </TooltipPopover>
})

export default ValueDescription

import React, {FunctionComponent, PropsWithChildren} from "react"
import styled from "styled-components"

interface Props extends PropsWithChildren {
  text: string
}

const FormattedText: FunctionComponent<Props> = ({text, children}) => {
  return <StyledText key={text}>
    <Text>{text}</Text>
    <Suffix>{children}</Suffix>
  </StyledText>
}
export default FormattedText

const Text = styled.div`
    grid-column: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
`

const Suffix = styled.div`
    grid-column: 2;
    align-self: center;
`

const StyledText = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 8px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  align-items: center;
`
import {FONT_SIZE_PX} from "components/charts/Chart.constants"
import {Format} from "types/charts"
import {formatValue} from "commons/format/formatter"
import {Cell, formatPercent} from "components/charts/Chart.utils"

// SVG reproduces antd line chart icon
export function tooltipMetricFormat(name: string) {
  return `<span style="font-size: ${FONT_SIZE_PX}px;color: var(--dark-text);font-family: 'Poppins', sans-serif;">
<svg width="1em" height="1em" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>       
${name}
</span>
`
}

// SVG reproduces antd scissor icon
export function tooltipSlicerFormat(name: string) {
  return `<span style="font-size: ${FONT_SIZE_PX}px;color: var(--dark-text);font-family: 'Poppins', sans-serif;">
<svg width="1em" height="1em" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243 4.243 3 3 0 004.243-4.243zm0-5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z"></path></svg>       
${name}
</span>
`
}

export function tooltipLineFormat(valueFormat: Format & { summarizeValue?: boolean }, alternativeValueFormat?: Format & {
  summarizeValue?: boolean
}, marker?: string, name?: string | number, value?: number, total?: number, highlight?: boolean, alternativeValue?: number) {
  return `<span style="font-size: ${FONT_SIZE_PX}px;width: 100%; font-family: 'Poppins', sans-serif;">
    <span style="font-size: ${FONT_SIZE_PX}px;${highlight ? "font-weight: bold" : ""}">${marker ?? ""} ${name}</span> 
    <span style="float: right; padding-left: 15px;font-weight: bold;font-size: ${FONT_SIZE_PX}px">
        ${value === undefined ? "" : formatValue(value, valueFormat)}
        ${formatPercent(value, total)}
        ${alternativeValue === undefined ? "" : formatAlternativeValue(formatValue(alternativeValue, {
    ...(alternativeValueFormat ?? {} as Format),
    summarizeValue: true,
  }))}
    </span>
</span>
`
}

export function formatAlternativeValue(value?: string): string {
  return `<span style="padding-left: 10px">(${value})</span>`
}

export function tooltipLineWithStyle(content?: string | number) {
  return `<span style="width: 100%;line-height: 1.1;">
       <span style="font-size: ${FONT_SIZE_PX}px;color: var(--main-text-color);">
    ${content}
</span>
</span>
`
}

export function drawCell(format: Format, alternativeFormat: Format, cell: Cell, pad: boolean, total?: number): string {
  const style = pad ? "padding-right: 15px;" : ""
  return `<td style="${style}>"${tooltipLineFormat(
    format,
    alternativeFormat,
    cell.marker,
    cell.name,
    cell.value,
    total,
    cell.highlight,
    cell.alternativeValue,
  )}</td>`
}
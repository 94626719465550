import {parseFormat} from "classes/workflows/query-workflows/genericDataSetUtil"
import {ChartMetricWithMetricDef} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartMetricDtoDetail, MetricGrowthPeriod, MetricGrowthPeriodUnit, MetricGrowthType} from "types/savedConfs"
import MetaModel from "classes/MetaModel"
import {Format} from "types/charts"
import {MetricDef} from "components/charts/line/LineChart.types"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"

export const getMetricDef = (metaModel: MetaModel, chartMetric: {
  viewCode: string,
  metricCode: string
}): MetricDef | undefined => metaModel.getView(chartMetric.viewCode)?.metrics.find(({code}) => code === chartMetric.metricCode)

export const doesFormatDiffer = (firstFormat: Format, secondFormat: Format) => firstFormat.suffix !== secondFormat.suffix
  || firstFormat.prefix !== secondFormat.prefix

export const doesMetricHaveDifferentFormat = (firstMetric: ChartMetricWithMetricDef, secondMetric: ChartMetricWithMetricDef) => firstMetric.metricDef
  && secondMetric.metricDef
  && (doesFormatDiffer(parseFormat(firstMetric), parseFormat(secondMetric)))

const doesNewMetricHaveDifferentFormat = (newMetricWithDef: ChartMetricWithMetricDef, metrics: ChartMetricWithMetricDef[]) => Boolean(metrics.find((metric) =>
  doesMetricHaveDifferentFormat(newMetricWithDef, metric),
))

export const isNeedingToBeOnSecondaryAxis = (index: number, newValue: Omit<MetricSelectorValue, "metricAlias">, value: ChartMetricDtoDetail[], metaModel: MetaModel) => {
  const metrics = value
    .map(chartMetric => ({
      ...chartMetric,
      metricDef: getMetricDef(metaModel, chartMetric),
    }))
  const metricsOnPrimaryAxis = metrics.filter(metric => !metric.extraConf?.isDisplayedOnSecondaryAxis)
  const metricsOnSecondaryAxis = metrics.filter(metric => metric.extraConf?.isDisplayedOnSecondaryAxis)
  const newMetricWithDef = {
    ...newValue,
    metricDef: getMetricDef(metaModel, newValue),
    metricAlias: '',
  }
  if (!metrics.find(metric => !metric.metricDef) && newMetricWithDef.metricDef) {
    if ((metricsOnSecondaryAxis.length === 0 && doesNewMetricHaveDifferentFormat(newMetricWithDef as ChartMetricWithMetricDef, metrics as ChartMetricWithMetricDef[]))) {
      return true
    }
    if ((metricsOnSecondaryAxis.length > 0
      && !doesNewMetricHaveDifferentFormat(newMetricWithDef as ChartMetricWithMetricDef, metricsOnSecondaryAxis as ChartMetricWithMetricDef[]))) {
      return !(metricsOnSecondaryAxis.length > 0
        && !doesNewMetricHaveDifferentFormat(newMetricWithDef as ChartMetricWithMetricDef, metricsOnPrimaryAxis as ChartMetricWithMetricDef[]))
    }
  }
  return false
}

export const getGrowthPeriodAsLabel = (growthPeriod: MetricGrowthPeriod | undefined) => {
  if (growthPeriod?.unit === MetricGrowthPeriodUnit.DAYS && growthPeriod?.quantity === undefined) {
    return 'previous'
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.YEARS) {
    return 'y-n'
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.DAYS) {
    return 'd-n'
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.WEEKS) {
    return 'w-n'
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.MONTHS) {
    return 'm-n'
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.QUARTERS) {
    return 'q-n'
  } else {
    return null
  }
}

export const isMetricOptionResumeClickable = (setActiveKey: ((key: MetricOptionsFormKeys) => void) | undefined) => setActiveKey ? 1 : 0

export const isUnitsAllowed = (metric: {
  growthType?: MetricGrowthType,
  metricDef?: MetricDef
}) => !(metric.growthType === MetricGrowthType.RATIO
  || metric.metricDef?.formatSuffix === "%")
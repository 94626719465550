/* eslint-disable max-lines */
import React, {memo, useMemo, useRef} from "react"
import {Form, Row} from "antd"
import styled from "styled-components"
import FormRow from "components/forms/selector/metrics/options/FormRow"
import {useFields} from "hooks/useFields"
import {Dimension} from "classes/MetaModel"
import Language from "language"
import {DimensionFiltersField} from "components/forms/confItems/DimensionFilters"
import CustomDisplaySelector from "components/forms/selector/metrics/options/selectors/CustomDisplaySelector"
import {getFilterResume} from "components/forms/selector/comps/box/utils"
import {
  ConfMetricWithActivationState,
  PartialConfMetricWithActivationState,
} from "components/forms/selector/metrics/options/MetricOptionForm.types"

export interface MetricFiltersOptionSelectorProps {
  environmentId: number
  availableDimensions: Dimension[]
  temporaryMetric: ConfMetricWithActivationState
  onMetricChange: (data: PartialConfMetricWithActivationState) => void
}

// eslint-disable-next-line react/display-name
const MetricFiltersOptionSelector = memo<MetricFiltersOptionSelectorProps>(({
                                                                              availableDimensions,
                                                                              environmentId,
                                                                              temporaryMetric,
                                                                              onMetricChange,
                                                                            }) => {
  const [form] = Form.useForm()
  const fields = useFields(temporaryMetric, [])
  const ref = useRef<HTMLDivElement | null>(null)

  const defaultLabel = useMemo(() => {
    return temporaryMetric.additionalFilters.map(filter => {
      return `${filter.reference.alias}: ${getFilterResume(filter)}`
    }).join(', ')
  }, [temporaryMetric.additionalFilters])

  return <Form form={form} fields={fields} onValuesChange={(changedValue) => {
    onMetricChange({
      ...changedValue,
      ...((Object.keys(changedValue)[0] === "additionalFilters" && changedValue.additionalFilters.length === 0) ? {
        additionalFiltersLabel: undefined,
      } : {}),
    })
  }}>
    <StyledDiv ref={ref}>
      <ContainerRow>
        <FormRow
          name={"additionalFilters"}
          title={Language.get("configuration-label-filters")}
          textAlignment={"center"}>
          <DimensionFiltersField {...{
            availableDimensions,
            environmentId,
            GACategory: 'metric-options',
            span: 24,
          }}/>
        </FormRow>
        <FormRow
          name={"additionalFiltersLabel"}
          title={Language.get("configuration-metric-options.custom-display")}
          textAlignment={"center"}>
          <CustomDisplaySelector {...{
            defaultLabel,
          }}/>
        </FormRow>
      </ContainerRow>
    </StyledDiv>
  </Form>
})

export default MetricFiltersOptionSelector

const ContainerRow = styled(Row)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 6px 75px 6px 22px;
  line-height: 20px;
`

const StyledDiv = styled.div`
  width: 550px;
`

import API from './api'
import {ACES, UAC, User} from "components/admin/user/UserManager.types"

type Id = string | number

export const getAssocEnvironment = (environmentId: Id) => API.instanceData.get<void, UAC[]>(`/environment/${environmentId}/assocEnvironment`)

export const getAces = (environmentId: Id) => API.instanceData.get(`/acl/byEnvironment/${environmentId}`)

export const createUser = ({
                             powerUser = false,
                             ...data
                           }, environmentId: Id) => API.instanceData.post(`/user?environmentId=${environmentId}&powerUser=${powerUser}`, data)

export const updateUser = (id: Id, {
  powerUser = false,
  ...data
}, environmentId: Id): Promise<User> => API.instanceData.put(`/user/${id}?environmentId=${environmentId}&powerUser=${powerUser}`, data)

export const resetPassword = (id: Id, environmentId: Id) => API.instanceData.post(`/user/${id}/resetPassword?environmentId=${environmentId}`)

export const updateUserAces = (id: Id, aces: ACES[], environmentId: Id) => API.instanceData.put(`/acl/byUser/${id}?environmentId=${environmentId}`, aces)

export const createAssocClient = (userEmail: string, environmentId: Id): Promise<UAC> => API.instanceData.post(`/user/assocEnvironment?userEmail=${userEmail}&environmentId=${environmentId}`)

export const updateAssocClient = (uacId: Id, data: {
  active: boolean
}): Promise<UAC> => API.instanceData.put(`/user/assocEnvironment/${uacId}`, data)

export const deleteAssocClient = (uacId: Id) => API.instanceData.delete(`/user/assocEnvironment/${uacId}`)

import React, {forwardRef, useCallback, useMemo, useRef, useState} from 'react'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {FormPeriodConfProps} from "components/forms/Form.types"
import {Button, Form} from "antd"
import {formatToDisplayablePeriod} from "commons/period/periodsList"
import styled from "styled-components"
import Type from "components/forms/period/selectors/Type"
import Category from "components/forms/period/selectors/Category"
import NameEN from "components/forms/period/selectors/NameEN"
import NameFR from "components/forms/period/selectors/NameFR"
import Position from "components/forms/period/selectors/Position"
import DynamicPeriod from "components/forms/period/selectors/DynamicPeriod"
import FixedPeriod from "components/forms/period/selectors/FixedPeriod"
import Language from "language"
import Popconfirm from "components/common/Popconfirm"
import useDispatch from "hooks/useDispatch"
import dayjs from "dayjs"
import {useSelector} from "react-redux"
import {getPeriods} from "redux/period.selector"
import Code from "components/forms/period/selectors/Code"
import {onValuesChange, verifyUniquenessOfCode} from "components/forms/period/FormPeriod.utils"
import {PeriodExtendedConfModel, SimplifiedPeriodFormProps} from '../chart/types'
import {FormInstance} from "antd/lib/form/hooks/useForm"
import {useFields} from "hooks/useFields"
import {useHandlePartialFormDataChange} from "components/forms/chart/hooks"
import {PeriodType} from "types/period.types"

type FormComponentProps = SimplifiedPeriodFormProps & ConsolidatedFormProps<PeriodExtendedConfModel>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, FormComponentProps>(({data, errors, onValuesChange: handleValuesChange, isEditing}, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = Form.useForm()
  const periods = useSelector(getPeriods)
  const fields = useFields(data, errors)

  return <Form ref={ref} fields={fields} name={'ExportExecutionDto'} form={form}
               onValuesChange={(values: any) => onValuesChange(values, handleValuesChange)}>
    {!isEditing && <Code
      name={"code"} rules={[verifyUniquenessOfCode(periods)]}/>}
    <NameEN name={"nameEN"}
            span={24}/>
    <NameFR name={"nameFR"}
            span={24}/>
    <Position name={"position"}/>
    <Category
      name={"category"}
      span={24}/>
    {!isEditing && <Type
      name={"type"}/>}
    <PeriodInputContainer>
      {
        data.type === PeriodType.dynamic
          ? <DynamicPeriod value={data} onChange={handleValuesChange}/> : <FixedPeriod value={data} onChange={handleValuesChange}/>}
    </PeriodInputContainer>
  </Form>
})

const PeriodInputContainer = styled.div`
  margin: 15px 20px 0 20px;
`

const FormPeriod: (props: FormPeriodConfProps) => JSX.Element | null = ({
                                                                             data: originalData,
                                                                             onConfirm,
                                                                             onCancel,
                                                                             onDelete,
                                                                           }) => {
  const popupContainerRef = useRef()
  const dispatch = useDispatch()
  const data: PeriodExtendedConfModel = useMemo(() => {
    const period = formatToDisplayablePeriod(originalData)
    switch (period.type) {
      case PeriodType.dynamic:
        return {
          ...period,
          title: originalData.code,
        }
      case PeriodType.fixed:
        return {
          ...period,
          title: period.code,
          type: PeriodType.fixed,
          start: dayjs(period.start),
          end: dayjs(period.end),
        }
      default: {
        const exhaustiveCheck: never = period
        return exhaustiveCheck
      }
    }
  }, [originalData])

  const isEditing = useMemo(() => data.id !== -1, [data.id])

  const [formData, setData] = useState<PeriodExtendedConfModel>(data)

  const handleConfirm = useCallback(
    (newData: PeriodExtendedConfModel) => {
      const formDataConsolidated = {
        ...formData,
        ...newData,
      }
      switch (formDataConsolidated.type) {
        case PeriodType.dynamic: {
          onConfirm(formatToDisplayablePeriod({
            ...formDataConsolidated,
            canBeDeleted: true,
          }))
          break
        }
        case PeriodType.fixed: {
          onConfirm({
            ...formDataConsolidated,
            start: formDataConsolidated.start.format("YYYY-MM-DD"),
            end: formDataConsolidated.end.format("YYYY-MM-DD"),
          })
          break
        }
        default:
      }
    }, [formData, onConfirm],
  )

  const handleDelete = useCallback(
    (periodId: number) => {
      onDelete(periodId)
    }, [onDelete],
  )

  const [usageWarning, setUsageWarning] = useState<string>("")

  const getUsage = useCallback((period: PeriodExtendedConfModel) => {
    dispatch.periods.getPeriodExportUsage({
      periodId: period.id,
    }).then((exports) => {
      if (exports.length > 0) {
        setUsageWarning(Language.get('period.form.usage-warning'))
      }
    })
  }, [dispatch.periods])

  const extraButtons = useMemo(() => {
    if (formData && formData.id && (formData.type === PeriodType.fixed || (formData.type === PeriodType.dynamic && formData.canBeDeleted))) {
      return [
        <Popconfirm key="delete"
                    title={<PopconfirmContent>
                      <span>{Language.get('period.delete.description.question')}</span>
                      <span>{Language.get('period.delete.description.content')}</span>
                      <span>{usageWarning}</span>
                    </PopconfirmContent>}
                    onConfirm={() => handleDelete(formData.id)}
                    onOpenChange={() => getUsage(formData)}>
          <Button type="primary" danger>{Language.get('period.delete.title')}</Button>
        </Popconfirm>,
      ]
    } else {
      return []
    }
  }, [formData, getUsage, handleDelete, usageWarning])

  const formProps: SimplifiedPeriodFormProps = useMemo(() => ({
    isEditing,
  }) as SimplifiedPeriodFormProps, [isEditing])

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return useMemo(() => <FormModal<PeriodExtendedConfModel, SimplifiedPeriodFormProps> {...{
    ref: popupContainerRef,
    defaultTitle: Language.get("period.form.title"),
    width: 600,
    data: formData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    onConfirm: handleConfirm,
    extraButtons,
    onCancel,
    formProps,
  }} />, [extraButtons, formData, formProps, handleConfirm, handlePartialFormDataChange, onCancel])
}

export default FormPeriod


const PopconfirmContent = styled.div`
  display: flex;
  flex-direction: column;
`
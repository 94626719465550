import {Source} from "components/forms/selector/comps/box/filters"
import React, {FunctionComponent, PropsWithChildren} from "react"
import styled from "styled-components"

interface ColorizedSourceItemProps {
  value: {
    source: Source
  }
  baseColor?: string
}

export const ColorizedSourceItem: FunctionComponent<PropsWithChildren<ColorizedSourceItemProps>> = ({
                                                                                                      value,
                                                                                                      baseColor,
                                                                                                      children,
                                                                                                    }) => <ColorizedText
  $importance={value.source === Source.CHART ? "primary" : "secondary"}
  $basecolor={baseColor}>
  {
    children
  }
</ColorizedText>

const ColorizedText = styled.span<{ $importance: "primary" | "secondary", $basecolor?: string }>`
    color: ${props => props.$importance === "primary" ? "var(--primary)" : `var(${props.$basecolor ? props.$basecolor : '--light-grey'})`}

`



import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {ColorName} from "components/charts/Chart.Theme"
import {MetricGrowthPeriod, MetricGrowthType} from "types/savedConfs"
import {ConfDimensionFilterTypes} from "components/forms/selector/comps/box/filters"

export type ConfMetric = Omit<MetricSelectorValue, "extraConf" | "additionalFilters">
  & { additionalFilters: ConfDimensionFilterTypes[] }
  & MetricSelectorValue["extraConf"]

type BaseMetricState = ConfMetric
  & { activated: boolean }

export type ConfMetricWithActivationState =
  Omit<BaseMetricState, "color" | "units" | "growth">
  & { color: ColorName | null }
  & { units: string | null }
  & { growthPeriod?: MetricGrowthPeriod, growthType?: MetricGrowthType }
  & { additionalFiltersLabel?: string }
  & { overridePeriodLabel?: string }
  & { growthLabel?: string }

export type PartialConfMetricWithActivationState =
  Partial<Omit<BaseMetricState, "color" | "units" | "growth">
    & { color: ColorName | null }
    & { units: string | null }
    & { growthPeriod?: MetricGrowthPeriod, growthType?: MetricGrowthType }
    & { additionalFiltersLabel?: string }
    & { growthLabel?: string }>

export enum MetricOptionsFormKeys {
  "growth" = "growth",
  "period" = "period",
  "filters" = "filters",
  "display" = "display"
}
import {Col, Row} from "antd"
import React, {memo, useState} from "react"
import styled from "styled-components"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import PeriodIcon from 'themes/svg/outlined/date.svg?react'
import {useHasPeriodApplied} from "components/metricDisplay/MetricDisplay.hooks"
import Language from "language"
import {ContentCol, DeleteContainer, FlexMetricOptionRow} from "themes/SharedStyledComponent"
import {IconContainer} from "components/common/IconContainer"
import {XCircleIcon} from "@heroicons/react/solid"
import {isMetricOptionResumeClickable} from "components/forms/selector/metrics/utils"
import {ConfMetricWithActivationState, MetricOptionsFormKeys} from "components/forms/selector/metrics/options/MetricOptionForm.types"

interface MetricPeriodResumeProps {
  value?: MetricSelectorValue
  setActiveKey?: (key: MetricOptionsFormKeys) => void
  onDelete?: (key: (keyof ConfMetricWithActivationState)[]) => void
  iconSize: number
  iconSpan: number
}

// eslint-disable-next-line react/display-name
const MetricPeriodResume = memo<MetricPeriodResumeProps>(({
                                                            value,
                                                            setActiveKey,
                                                            onDelete,
                                                            iconSize,
                                                            iconSpan,
                                                          }) => {
  const [isDeleteDisplayed, setIsDeleteDisplayed] = useState(false)
  const hasOverridenPeriodProperty = useHasPeriodApplied(value)

  const isClickable = isMetricOptionResumeClickable(setActiveKey)

  return <DivWithFullWidth>
    {hasOverridenPeriodProperty && <PeriodResume>
      <PeriodPreview>
        <Row>
          <Col span={iconSpan} onClick={() => setActiveKey?.(MetricOptionsFormKeys.period)}>
            <FlexMetricOptionRow $clickable={isClickable}>
              <IconContainer size={iconSize}>
                <PeriodIcon/>
              </IconContainer>
            </FlexMetricOptionRow>
          </Col>
          <ContentCol span={19} onClick={() => setActiveKey?.(MetricOptionsFormKeys.period)}>
            <FlexMetricOptionRow $clickable={isClickable} justify={"space-between"} onMouseOver={() => setIsDeleteDisplayed(true)}
                                 onMouseLeave={() => setIsDeleteDisplayed(false)}>
              {value?.overridePeriod && <>
                {`${Language.get("configuration-metric-options.period.title")}: ${Language.getTranslatedName(value.overridePeriod)}`}
              </>}
              {isDeleteDisplayed && onDelete && <DeleteContainer>
                <IconContainer clickable={true} size={14} color={"var(--light-grey)"}>
                  <XCircleIcon onClick={() => onDelete(["overridePeriod", "overridePeriodLabel"])}/>
                </IconContainer>
              </DeleteContainer>}
            </FlexMetricOptionRow>
          </ContentCol>
        </Row>
      </PeriodPreview>
    </PeriodResume>}
  </DivWithFullWidth>
})

export default MetricPeriodResume

const PeriodPreview = styled.div`
  display: flex;
  flex-direction: column;
`

const DivWithFullWidth = styled.div`
  width: 100%;
  cursor: pointer;
`

const PeriodResume = styled.div`
  align-items: center;
  gap: 10px;
`
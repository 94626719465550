import React, {memo} from 'react'
import ValueDescription from "components/forms/description/ValueDescription"
import {TooltipContentDimension} from "components/tooltipContent/TooltipContent.Dimension"
import {Dimension} from "classes/MetaModel"

interface ValueDescriptionParams {
  dimension: Pick<Dimension, "alias" | "semanticType" | "description" | "code">
}

// eslint-disable-next-line react/prop-types
const DimensionDescription = memo<ValueDescriptionParams>(function ViewDescription({
                                                                                     dimension,
                                                                                   }) {
  return <ValueDescription>
    <TooltipContentDimension dimension={dimension}/>
  </ValueDescription>
})

export default DimensionDescription

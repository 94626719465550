import React, {memo, useMemo} from "react"
import styled from "styled-components"
import Language from "language"
import {uniq} from "lodash"
import dayjs from "dayjs"
import {EffectiveConfSlicerTypes, EffectiveConfTypes} from "components/charts/line/LineChart.types"
import {StyledItemWithHelp} from "components/itemWithHelp/ItemWithHelp"
import {FilterSummary} from "components/filterSummary/FilterSummary"
import {CHART_SUMMARY_HEIGHT} from "components/charts/Chart.constants"
import {
  extractFiltersFromEffectiveConf,
  extractMetricFiltersFromEffectiveConf,
  extractSlicerDateWithSource,
  extractSlicersDimensionWithDimensionObject,
  extractSlicersFromEffectiveConf,
} from "commons/configuration"
import {CalendarIcon, ChartBarIcon, ClockIcon, EyeIcon, ScissorsIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {TooltipContent} from "components/common/TooltipContent"
import {ColorizedSourceItem} from "components/colorizedSourceItem/ColorizedSourceItem"
import MetricDisplay from "components/metricDisplay/MetricDisplay"
import {TooltipContentMetric} from "components/tooltipContent/TooltipContent.Metric"
import {TooltipContentDimension} from "components/tooltipContent/TooltipContent.Dimension"
import View from 'themes/svg/filled/view.svg?react'

interface Props {
  effectiveConf: EffectiveConfTypes
  showMetricSlicer?: boolean
}

const ChartSummary = memo<Props>(function ChartResume({
                                                        effectiveConf,
                                                        showMetricSlicer = true,
                                                      }) {
  const {timeInterval, metrics} = effectiveConf
  const filters = useMemo(() => [
    ...extractFiltersFromEffectiveConf(effectiveConf),
    ...extractMetricFiltersFromEffectiveConf(effectiveConf),
  ], [effectiveConf])
  const slicers: EffectiveConfSlicerTypes[] | undefined = useMemo(() => extractSlicersFromEffectiveConf(effectiveConf), [effectiveConf])
  const timeIntervalStart = dayjs(timeInterval.start, 'YYYY-MM-DD').format('L')
  const timeIntervalEnd = dayjs(timeInterval.end, 'YYYY-MM-DD').format('L')
  const dateSlicer = slicers ? extractSlicerDateWithSource(slicers) : undefined
  const dimensionSlicers = slicers ? extractSlicersDimensionWithDimensionObject(slicers) : []

  return <StyledContainer>
    <StyledItemWithHelp help={<TooltipContent Icon={CalendarIcon}
                                              title={`${Language.get("enforced-legend-period")} : ${timeIntervalStart} ⇀ ${timeIntervalEnd}`}/>}>
      <IconContainer size={12}><CalendarIcon/></IconContainer>
      <LabelSpan><ColorizedSourceItem value={timeInterval}>
        {
          timeInterval.period.code
            ? Language.getTranslatedName(timeInterval.period)
            : <React.Fragment>{timeIntervalStart}<br/>{timeIntervalEnd}</React.Fragment>
        }
      </ColorizedSourceItem></LabelSpan>
    </StyledItemWithHelp>
    {
      dateSlicer && dateSlicer.granularity && <StyledItemWithHelp
        help={<TooltipContent Icon={ClockIcon}
                              title={`${Language.get('granularity')} : ${Language.get(`calendar-granularity-${dateSlicer.granularity.toLowerCase()}`)}`}/>}>
        <IconContainer size={12}><ClockIcon/></IconContainer>
        <LabelSpan><ColorizedSourceItem value={dateSlicer}>
          {
            <React.Fragment>{`${Language.get("by")} ${Language.get(`calendar-granularity-${dateSlicer.granularity.toLowerCase()}`)}`}</React.Fragment>
          }
        </ColorizedSourceItem></LabelSpan>
      </StyledItemWithHelp>
    }
    {filters.length > 0 && <FilterSummary filters={filters}/>}
    <StyledItemWithHelp
      help={<>{uniq(metrics.map(m => m.view)).map(view => <TooltipContent Icon={View} key={view.alias} code={view.code} nature={"view"}
                                                                                            title={`${Language.get('configuration-label-view')} : ${view.alias}`}
                                                                          description={view.description}/>)}</>}>
      <IconContainer size={12}><EyeIcon/></IconContainer>
      <LabelSpan>
        {uniq(metrics.map(m => m.view)).map(view => <ViewSpan key={view.code} className="part" translate="no">{view.alias}</ViewSpan>)}
      </LabelSpan>
    </StyledItemWithHelp>
    {showMetricSlicer && metrics.map((metric, i) =>
      <StyledItemWithHelp key={i}
                          help={<TooltipContentMetric metric={metric} timeInterval={effectiveConf.timeInterval}/>}>
        <IconContainer size={12}><ChartBarIcon/></IconContainer>
        <MetricDisplayWithLeftMargin metric={metric}/>
      </StyledItemWithHelp>)}
    {showMetricSlicer && dimensionSlicers.map((slicer, i) =>
      slicer && <StyledItemWithHelp key={i}
                                    help={<TooltipContentDimension dimension={slicer.dimension}/>}>
        <IconContainer size={12}><ScissorsIcon/></IconContainer>
        <LabelSpan translate="no">
          {slicer.dimension.alias}
        </LabelSpan>
      </StyledItemWithHelp>)}
  </StyledContainer>
})

export default ChartSummary

const MetricDisplayWithLeftMargin = styled(MetricDisplay)`
`

const ViewSpan = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const LabelSpan = styled.span`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  max-height: ${CHART_SUMMARY_HEIGHT}px;
  align-items: center;

  &.timeInterval {
    white-space: normal;
    min-width: 60px;
    max-height: 2em;
    overflow: hidden;
  }
  
  .part {
      max-height: 2em;
      overflow: hidden;
      text-overflow: ellipsis;
      &:not(:first-child) {
        padding-left: 4px;
        border-left: 1px dashed var(--border-color-base);
      }
      &:not(:last-child) {
        margin-right: 4px;
      }
      .values {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-style: italic;
      }
    }
`

const StyledContainer = styled.div`
  padding: 2px 0;
  margin-bottom: 1px;
  height: ${CHART_SUMMARY_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  color: var(--light-grey);
`

import React, {memo, useMemo} from 'react'
import styled from "styled-components"
import ValueDescription from "components/forms/description/ValueDescription"
import Box from 'components/forms/selector/comps/box/Box'
import Language from 'language'
import MdView from "components/mdView/MdView"
import {isFilterEmpty, useFilterValue} from "components/forms/selector/comps/box/utils"
import {ConfFilter} from "components/forms/selector/comps/box/filters"

interface Props {
  environmentId: number
  filter: ConfFilter
  close: () => void
  handleFilterChange: (filter: ConfFilter) => void
  bordered?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onEdit?: (state: number) => void
}

// eslint-disable-next-line react/display-name
const BoxWithDescription = memo<Props>(({
                                          filter, environmentId, close, handleFilterChange, bordered,
                                          getPopupContainer,
                                          onEdit,
                                        }) => {

  const filterValue = useFilterValue(filter)

  const filterTypeLabel = useMemo(() => {
    if (filter.predicate.negate) {
      return Language.get("configuration-chart-filter-description.filterType.different")
    } else {
      return Language.get("configuration-chart-filter-description.filterType.equal")
    }
  }, [filter.predicate.negate])

  const description = useMemo(() => {
    let dimensionMsg = ""
    const header = Language.get("configuration-chart-filter-description.header", {
      alias: filter.reference.alias,
      description: filter.reference.description,
    })

    if (isFilterEmpty(filter)) {
      dimensionMsg = Language.get("configuration-chart-filter-description.details.empty", filter.reference.alias)
    } else {
      dimensionMsg = Language.get("configuration-chart-filter-description.details.filled", {
        filterType: filterTypeLabel,
        filterValue,
      })
    }

    return <Description>
      <MdView description={header}/>
      <br/>
      <MdView description={dimensionMsg}/>
    </Description>
  }, [filter, filterTypeLabel, filterValue])
 
  return <div>
    <Box filter={filter}
         environmentId={environmentId}
         close={close}
         handleFilterChange={handleFilterChange}
         isResumable={false}
         bordered={bordered}
         getPopupContainer={getPopupContainer}
         onEdit={onEdit}/>
    <StyledValueDescription>
      <ValueDescription>
        {description}
      </ValueDescription>
    </StyledValueDescription>
    </div>
})

export default BoxWithDescription

const StyledValueDescription = styled.div`
  position: absolute;
  right: -24px;
  top: 0;
  height: 100%;
  width: 19px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Description = styled.div`
  strong {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
  `
import {Col, InputNumber, Row, Select} from "antd"
import React, {memo, useCallback, useMemo} from "react"
import {GrowthGroup, growthOptions as growths} from "types/growths"
import Language from "language"
import {getGrowthPeriodAsLabel} from "components/forms/selector/metrics/utils"
import {MetricGrowthPeriod} from "types/savedConfs"
import {DataSelection} from "redux/models/currentDashboard"
import {PeriodTypes} from "types/period.types"
import styled from "styled-components"

interface PeriodSelectorProps {
  id: number
  dayDifference: number
  dashboardSelection: DataSelection
  activated: boolean
  period?: PeriodTypes
  value?: MetricGrowthPeriod
  onChange?: (newState: MetricGrowthPeriod) => void
}

const PeriodSelector = memo<PeriodSelectorProps>(function PeriodSelector({
                                                    id,
                                                    dayDifference,
                                                    activated,
                                                    value,
                                                    onChange,
                                                                         }) {
  const growthOptions = useMemo(() => Object.keys(growths).map(key => <Select.OptGroup key={`${key}-groupName-${id}`}
                                                                                       label={Language.get(`growth.group.${key}`)}>
      {growths[key as GrowthGroup].map(({label: optionLabel}, index) => {
          return <Select.Option value={optionLabel}
                                key={`${optionLabel}-${index}`}>{Language.get(`growth.preset.${optionLabel}`)}</Select.Option>
        },
      )}
    </Select.OptGroup>),
    [id],
  )

  const getOptionMatchingValue = useCallback((growthLabel: string) => Object.values(growths).flat().find(option => option.label === growthLabel), [])

  const handleGrowthChange = useCallback((growthLabel: string) => {
      const opt = getOptionMatchingValue(growthLabel)
      const optValue = growthLabel === "previous" && opt?.value.unit && dayDifference !== undefined ? {
        unit: opt?.value.unit,
        quantity: undefined,
      } : opt?.value
      if (optValue) {
        onChange?.({
          unit: optValue.unit,
          quantity: optValue.quantity,
        })
      }
    },
    [getOptionMatchingValue, dayDifference, onChange],
  )

  const updateQuantity = useCallback((newValue: number) => {
    if (value) {
      onChange?.({
        ...value,
        quantity: newValue,
      })
    }
  }, [onChange, value])

  const growthPeriodAsLabel = getGrowthPeriodAsLabel(value)

  return <Row>
    <Col span={17}>
      <Select {...{
        style: {width: '100%'},
        onChange: handleGrowthChange,
        disabled: !activated,
        virtual: false,
        dropdownMatchSelectWidth: false,
        value: growthPeriodAsLabel,
      }}>
        {growthOptions}
      </Select>
    </Col>
    <Col span={7}>
      {
        growthPeriodAsLabel && getOptionMatchingValue(growthPeriodAsLabel)?.preffix && <Row>
          <Col span={10}>
            <SeparatorContainer>{"-"}</SeparatorContainer>
          </Col>
          <Col span={14}>
            <InputNumber {...{
              style: {maxWidth: '100%'},
              min: 1,
              value: value?.quantity,
              disabled: !activated,
              onChange: (newValue: number | null) => {
                if (newValue) {
                  updateQuantity(newValue)
                }
              },
            }}/>
          </Col>
        </Row>
      }
    </Col>
  </Row>
})

export default PeriodSelector


const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
`
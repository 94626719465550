import {WidgetTypes} from "commons/dashboard/dashboard.types"
import MetaModel from "classes/MetaModel"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {DataSelection} from "redux/models/currentDashboard"
import {BaseEditionInformation, DividerConf} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ExpandedWorkspace} from "redux/models/workspace"
import {FormFooterAltSubmit} from "components/forms/FormFooter"
import {ConfDimensionFilterTypes, DimensionFilterDtoDetail} from "components/forms/selector/comps/box/filters"
import {ChartMetricDtoDetail, SlicerDtoDetailTypes} from "types/savedConfs"
import {GenericChartTypes} from "types/widgets"
import {BQConf, OrderBy} from "types/charts"
import {PeriodSummary, PeriodTypes} from "types/period.types"

export enum FormKeys {
  ADD_MENU = 'addMenu',
  ADD_DASHBOARD = 'addDashboard',
  EDIT_MENU = 'editMenu',
  EDIT_DASHBOARD = 'editDashboard',
  CLONE_DASHBOARD = 'cloneDashboard',
  DELETE_MENU = 'deleteMenu',
  DELETE_DASHBOARD = 'deleteDashboard',
  EDIT_CONF = 'editConf',

  DASHBOARD_CONF = 'dashboardConf',
  CHART_CONF = 'widgetConf',
  EXPORT_CONF = 'exportConf',
}

export interface FormType {
  type: FormKeys
  chartType?: WidgetTypes | GenericChartTypes
}

export enum CommonPropsTypes {
  "ChartForm",
  "FormGeneric",
  "FormDashboard",
  "FormExportBasic",
  "FormExportExecution",
  "FormPeriod"
}

export interface CommonProps<T> {
  metaModel: MetaModel
  data: T,
  onConfirm: (data: T) => void,
  onCancel: () => void,
  formType: FormType
}

export interface ChartFormProps extends CommonProps<ChartDetailWithoutLayout> {
  dashboardId?: number,
  dashboardSelection: DataSelection,
  environmentId: number,
}

export interface FormGenericProps<T extends BaseEditionInformation> extends CommonProps<T> {
  workspace?: ExpandedWorkspace,
  title?: string,
  formType: FormType,
  dashboardId?: number,
  environmentId?: number
  altSubmit?: Omit<FormFooterAltSubmit, 'trigger'> & Partial<Pick<FormFooterAltSubmit, 'trigger'>>,
}

export interface FormDividerProps extends CommonProps<DividerConf> {
  workspace?: ExpandedWorkspace,
  title?: string,
  formType: FormType,
}

export interface FormDashboardData extends BaseEditionInformation {
  period: string,
  filters: DimensionFilterDtoDetail[]
}

export interface ConfFormDashboardData extends BaseEditionInformation {
  period: string,
  filters: ConfDimensionFilterTypes[]
}

export interface FormDashboardConfProps extends CommonProps<FormDashboardData> {
  environmentId: number,
  loadDictionaryEntries: (dictionaryCode: string) => Promise<any>
}

export enum Days {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export interface ExportDestination {
  id: number
  name: string,
  code: string,
}

export type ExportMetricWithViewCodeAndAlias = (ChartMetricDtoDetail & { viewAlias: string, viewCode: string })

export interface ExportBasicDto {
  type: WidgetTypes.EXPORT,
  displayType: GenericChartTypes.TABLES,
  id: number
  title: string,
  period: PeriodSummary,
  slicers: SlicerDtoDetailTypes[],
  filters: DimensionFilterDtoDetail[],
  metrics: ExportMetricWithViewCodeAndAlias[],
  orderBys: OrderBy[]
  ignoreMetrics0: boolean,
}

export interface RawExport extends Omit<ExportBasicDto, "period" | "slicers" | "metrics" | "filters" | "ignoreMetrics0" | "orderBys">, ExportExecutionDto {
  bqConf: BQConf
}

export interface ExportExecutionDto {
  destination: ExportDestination
  days: Days[],
  override: boolean,
}

export type Export = ExportBasicDto & ExportExecutionDto

export type ExportDtoForm = Omit<ExportBasicDto, "period" | "slicers" | "filters"> & {
  period: PeriodSummary,
  slicers: SlicerDtoDetailTypes[],
  filters: DimensionFilterDtoDetail[]
} & ExportExecutionDto

export type ExportBasicDtoForm = Omit<ExportDtoForm, "destination" | "days" | "override">

export interface FormExportBasicConfProps extends CommonProps<ExportBasicDto> {
  environmentId: number,
  dashboardId: number,
  loadDictionaryEntries: (dictionaryCode: string) => Promise<any>
}

export interface FormExportExecutionConfProps extends CommonProps<ExportExecutionDto> {
  exportDestinations: ExportDestination[]
  title: string
}

export interface FormPeriodConfProps extends CommonProps<PeriodTypes> {
  onDelete: (id: number) => void
  title?: string
}
import * as React from 'react'
import {useCallback} from 'react'
import * as Sentry from "@sentry/react"
import {Provider} from 'react-redux'
import store from 'redux/store'
import {createGlobalStyle} from 'styled-components'
import AppSecured from "AppSecured"
import CacheBuster from "CacheBuster"
import {Button, ConfigProvider, Result} from "antd"
import Language from "language"
import {ErrorBoundaryResultContainer} from "themes/SharedStyledComponent"

const App = () => {
  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  return <Sentry.ErrorBoundary fallback={<ErrorBoundaryResultContainer>
    <Result
      status={"error"}
      title={Language.get("error-occurred")}
      extra={<Button type={"primary"} key={"reload"} onClick={handleReload}>{Language.get('reload-app')}</Button>}/>
  </ErrorBoundaryResultContainer>}>
    <ConfigProvider theme={{
      token: {
        borderRadius: 4,
        colorBorder: "#EDEFF1",
        // lineHeight: 38,
        controlHeight: 40,
        colorPrimary: "#425FF0",
        motionUnit: 0.2,
        colorText: "#1B1B1D",
      },
      components: {
        Checkbox: {
          controlInteractiveSize: 16,
        },
        Tag: {
          defaultBg: "transparent",
        },
        Radio: {
          buttonCheckedBg: "#ECEFFE",
          buttonBg: "white",
          buttonSolidCheckedColor: "white",
          dotColorDisabled: "fade(@white, 20%)",
          buttonCheckedBgDisabled: "fade(@white, 20%)",
          buttonCheckedColorDisabled: "#DCE0E4",
          colorBgContainerDisabled: "#ffffff14",
          colorTextDisabled: "#DCE0E4",
          buttonColor: "#1B1B1D",
        },
        Table: {
          headerBg: "white",
        },
        DatePicker: {
          colorBgContainer: "white",
          colorBgElevated: "white",
          addonBg: "white",
          colorBorder: "transparent",
          presetsMaxWidth: 30,
          cellHeight: 24,
          cellWidth: 35,
          controlHeight: 38,
          activeShadow: "none",
        },
        Popover: {
          colorBgElevated: "white",
          colorBgBase: "white",
          colorBorder: "#3a3a3a",
        },
        Select: {
          selectorBg: "white",
          showArrowPaddingInlineEnd: 0,
          optionActiveBg: "#ECEFFE",
          optionSelectedFontWeight: 400,
          optionSelectedBg: "#ECEFFE",
        },
        Input: {
          colorBgContainer: "white",
          colorTextPlaceholder: "fade(@white, 30%)",
          colorIcon: "fade(@white, 30%)",
          colorIconHover: 'fade(@white, 85%)',
          colorBgBase: "white",
        },
        Card: {},
        Menu: {
          colorHighlight: "white",
          subMenuItemBg: "#2f2f31",
          darkItemBg: "white",
          itemBg: "#27272A",
          itemActiveBg: "#425FF0",
          itemSelectedBg: "#425FF0",
          itemSelectedColor: "white",
          itemColor: "white",
          itemMarginBlock: 0,
          popupBg: "#262626",
          itemHoverColor: "white",
          colorText: "white",
          itemHeight: 40,
          itemMarginInline: 0,
        },
        Form: {
          itemMarginBottom: 20,
        },
        Layout: {
          bodyBg: "#EDEFF1",
          headerBg: "#EDEFF1",
          triggerBg: "#262626",
          siderBg: "#27272A",
          headerPadding: "16px 20px",
          // lineHeight: 38
        },
        Modal: {
          padding: 0,
          paddingLG: 0,
          headerBg: "#F6F6F8",
          contentBg: "#F6F6F8",
          paddingContentVertical: 0,
          colorBorder: "#EDEFF1",
        },
        Button: {
          defaultBg: "transparent",
          defaultGhostColor: "#1B1B1D",
          defaultGhostBorderColor: "fade(@white, 25%)",
          defaultHoverBg: "rgba(255, 255, 255, 0.03)",
        },
        Switch: {
          handleBg: "white",
          colorBgContainerDisabled: "red",
          trackMinWidth: 32,
          trackHeight: 20,
          handleSize: 16,
        },
        Tooltip: {
          colorBgSpotlight: "white",
          colorText: "#1b1b1d",
          colorTextLightSolid: "#1b1b1d",
        },
        Alert: {
          colorInfoBg: "#F28C5A",
          colorIcon: "#425ff0",
          paddingContentVertical: 8,
          paddingContentHorizontal: 15,
        },
        Pagination: {
          itemBg: "transparent",
          itemLinkBg: "transparent",
          itemActiveBgDisabled: "fade(@white, 25%)",
          itemActiveColorDisabled: "black",
          itemInputBg: "transparent",
          itemActiveBg: "#ECEFFE",
          lineType: "none",
          fontSize: 14,
        },
      },
    }}>
      <CacheBuster>
        <Provider store={store}>
          <GlobalStyle/>
          <AppSecured/>
        </Provider>
      </CacheBuster>
    </ConfigProvider>
  </Sentry.ErrorBoundary>
}

export default App

const GlobalStyle: any = createGlobalStyle`
    #app, #app > div, section.ant-layout {
        height: 100%;
    }

    #app > div.ant-spin.ant-spin-spinning, .dashboardportlet-body > div.ant-spin.ant-spin-spinning {
        height: 100%;
        width: 100%;
    }
`

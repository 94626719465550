import {getCurrentEnvironmentId} from "redux/environment.selector"
import {returnOrExecBatch} from "commons/batch"
import MetaModel from "classes/MetaModel"
import RootModel from "redux/models/index"
import {createModel} from "@rematch/core"
import {getViews} from "services/MetaModelService"

export interface Environment {
  currentClientId: number | null
  currentMetaModel: MetaModel | null
}

const initialState: Environment = {
  currentClientId: null,
  currentMetaModel: null,
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrent(state, {currentClientId, currentMetaModel}) {
      return {
        ...state,
        currentClientId,
        currentMetaModel,
      }
    },
  },
  effects: (dispatch) => ({
    async updateCurrentClient({forBatch = false, environment: {id}}: { forBatch: boolean, environment: { id: number } }, state) {
      if (id === getCurrentEnvironmentId(state)) {
        return null
      }
      const views = await getViews(id)
      return returnOrExecBatch(forBatch,
        () => dispatch.environment.setCurrent({
          currentClientId: id,
          currentMetaModel: new MetaModel(views),
        }),
        () => dispatch({type: 'environment/cleanStates'}),
      )
    },
  }),
})

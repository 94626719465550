import React, {useCallback, useMemo} from "react"
import {Cascader} from 'antd'
import styled from "styled-components"
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import {isString} from "lodash"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"

interface Props {
  value?: SingleMetricSelectorValue,
  defaultView?: string,
  placeholder?: string,
  canRemove: boolean,
  onChange: (arg: SingleMetricSelectorValue | null) => void,
  viewsWithMetrics: ViewsWithMetrics,
  unavailableViews: ViewsWithMetrics,
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
}

const MetricSelectorCascader: (props: Props) => JSX.Element = ({
                                                                 value,
                                                                 defaultView,
                                                                 placeholder,
                                                                 canRemove,
                                                                 onChange,
                                                                 viewsWithMetrics,
                                                                 unavailableViews,
                                                                 getPopupContainer,
                                                               }) => {
  const options = useMemo(() => viewsWithMetrics.map(view => ({
      value: view.code,
      label: view.alias,
      children: view.metrics.map(({alias, code}) => ({
        value: code,
        label: alias,
      })),
    })).concat(unavailableViews.map(view => ({
      value: view.code,
      label: view.alias,
      disabled: true,
      children: view.metrics.map(({alias, code}) => ({
        value: code,
        label: alias,
      })),
    }))),
    [viewsWithMetrics, unavailableViews],
  )
  const cascaderDisplayRender = useCallback(
    (labels: string[]) => labels.length === 2
      ? labels.join(' / ')
      : undefined,
    [],
  )

  const handleMetricChange = useCallback((newValue: (string | number | null)[] | undefined) => {
      const associatedView = newValue && newValue[0] ? options.find(view => view.value === newValue[0]) : undefined
      if (newValue && associatedView && newValue.length > 1 && isString(newValue[1])) {
        onChange({
          viewCode: associatedView?.value,
          viewAlias: associatedView?.label,
          metricCode: newValue[1],
          additionalFilters: [],
          titlePartOverrides: {},
        })
      } else if (newValue === undefined) {
        onChange(null)
      }
    },
    [onChange, options],
  )
  return <StyledCascader {...{
    options,
    placeholder,
    allowClear: canRemove,
    showSearch: cascaderShowSearch,
    value: computeCascaderValue(value, defaultView) ?? [],
    displayRender: cascaderDisplayRender,
    onChange: handleMetricChange,
    getPopupContainer,
    multiple: false,
  }}/>
}

export default MetricSelectorCascader

const cascaderShowSearch = {
  filter: (inputValue: string, path: {
    disabled?: boolean;
    [name: string]: any;
  }[]) => path.some(option => option.label.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) > -1),
  matchInputWidth: false,
}
const computeCascaderValue = (value: SingleMetricSelectorValue | undefined, defaultView: string | undefined): (string | number)[] | undefined => {
  if (value) {
    return [value.viewCode, value.metricCode]
  } else if (!value) {
    return undefined
  } else if (defaultView) {
    return [defaultView]
  } else {
    return undefined
  }
}

// @ts-ignore
const StyledCascader = styled(Cascader)`
  width: 100%;
`

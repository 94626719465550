/* eslint-disable max-lines */
import React, {forwardRef, useCallback, useEffect, useMemo, useState} from 'react'
import {FilterOption, SelectableOption} from "types/select"
import {Mode, MultipleSelectWithOptionList} from "components/forms/selector/filters/addFilterPanel/MultipleSelectWithOptionList"
import {loadDictionaryEntries} from "services/MetaModelService"
import {OptionListPagination} from "components/forms/selector/filters/addFilterPanel/OptionList"
import {useDebouncedCallback} from "use-debounce"
import {DictionaryEntry} from 'types/savedConfs'
import {ConfDimensionFilterValuesDtoDetail} from "components/forms/selector/comps/box/filters"

interface FilterValueMatchProps {
  environmentId: number
  withBackground?: boolean
  filter: ConfDimensionFilterValuesDtoDetail
  handleFilterChange: (filter: ConfDimensionFilterValuesDtoDetail) => void
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onApply: (filter: ConfDimensionFilterValuesDtoDetail) => void
  onFocus: () => void
  onBlur: (filter: ConfDimensionFilterValuesDtoDetail) => void
}

// eslint-disable-next-line react/display-name
const FilterValueMatch = forwardRef<any, FilterValueMatchProps>(({
                                                                   filter,
                                                                   environmentId,
                                                                   handleFilterChange,
                                                                   withBackground,
                                                                   getPopupContainer,
                                                                   onApply: handleApply,
                                                                   onFocus,
                                                                   onBlur,
                                                                 }, ref) => {
  const [loading, setLoading] = useState(false)
  const [entries, setEntries] = useState<SelectableOption[]>([])
  const [options, setOptions] = useState<SelectableOption[]>([])
  const [entriesDictionaryCode, setEntriesDictionaryCode] = useState<string>('')
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>()
  const query: (dictionaryCode: string, search?: string, pagination?: OptionListPagination) => Promise<Omit<FilterOption, "type">[]> = useCallback((dictionaryCode: string, newSearch?: string, pagination?: OptionListPagination) => loadDictionaryEntries(environmentId, dictionaryCode, newSearch, pagination), [environmentId])

  useEffect(() => {
    setSearch("")
  }, [isSelectOpen])

  const updateOptions = useCallback(() => {
    if (filter.reference && filter.reference.dictionaryCode) {
      setLoading(true)
      query(filter.reference.dictionaryCode, undefined, {
        offset: 0,
        pageSize: 20,
      })
        .then(newOptions => {
          setOptions(newOptions)
          setEntriesDictionaryCode(filter.reference!.dictionaryCode!)
          setLoading(false)
        }).catch(() => setLoading(false))
    }
  }, [filter.reference, query])

  useEffect(() => {
    setEntries(options.map(opt => ({
      label: opt.value,
      value: opt.value,
      selected: !!filter.predicate.value.entries.find(entry => entry.id === opt.value),
    })))
  }, [options, filter])

  useEffect(() => {
    if (filter.reference && filter.reference.code && entriesDictionaryCode !== filter.reference.dictionaryCode) {
      updateOptions()
    }
  }, [query, filter.reference, entriesDictionaryCode, updateOptions])

  const updateFilterValues = useCallback((newFilterValues: {
    id: string
  }[]) => {
    handleFilterChange({
      ...filter,
      predicate: {
        ...filter.predicate,
        value: {
          ...filter.predicate.value,
          entries: newFilterValues,
        },
      },
      isValid: true,
    })
  }, [filter, handleFilterChange])


  const onApply = useCallback(() => {
    handleApply({
      ...filter,
      predicate: {
        ...filter.predicate,
        value: {
          ...filter.predicate.value,
          entries: filter.predicate.value.entries.map(v => ({
            id: v.id,
          })),
        },
      },
      isValid: true,
    })
    setSearch("")
  }, [filter, handleApply])

  const updateEntries = useCallback((newEntries: SelectableOption[], newFilterValues: DictionaryEntry[]) => {
    const selectedEntries = newEntries.filter(entry => entry.selected).map(entry => ({
      id: entry.value,
      value: entry.value,
    }))
    setEntries(newEntries)
    const newTemporaryFilters = [...newFilterValues.filter(entry => !newEntries.find(newEntry => newEntry.value === entry.id)), ...selectedEntries]

    updateFilterValues(newTemporaryFilters.map(v => ({
      id: v.id,
    })))
  }, [updateFilterValues])

  const handleOptionStateChange = useCallback((option: Pick<FilterOption, 'value'>, selectionState: boolean) => {
    const entriesWithNewSelection: SelectableOption[] = Array.from(entries)
    const index = entriesWithNewSelection.findIndex(entry => entry.value === option.value)
    entriesWithNewSelection[index] = {
      ...entriesWithNewSelection[index],
      selected: selectionState,
    }
    updateEntries(entriesWithNewSelection, filter.predicate.value.entries)
  }, [entries, filter.predicate.value.entries, updateEntries])

  const handleSelectionChange = useCallback((optionValues: string[]) => {
    const entriesWithNewSelection: SelectableOption[] = Array.from(entries).map(e => ({
      ...e,
      selected: optionValues.includes(e.value),
    }))
    const newFilterValues = optionValues.map(value => ({
      id: value,
      value,
    }))
    updateEntries(entriesWithNewSelection, newFilterValues)
  }, [entries, updateEntries])

  const filterValues = useMemo(() => filter.predicate.value.entries.map(entry => entry.id), [filter])

  const onPaginationChange = useCallback((pagination: OptionListPagination) => {
    if (filter.reference && filter.reference.dictionaryCode) {
      query(filter.reference.dictionaryCode, search, pagination)
        .then(newOptions => {
            setOptions(old => ([...old, ...newOptions]))
          setEntriesDictionaryCode(filter.reference!.dictionaryCode!)
            setLoading(false)
          },
        ).catch(() => setLoading(false))
    }
  }, [filter.reference, query, search])

  const queryNewOptionWithSearchValue = useCallback((newSearch: string) => {
    if (filter.reference && filter.reference.dictionaryCode) {
      query(filter.reference.dictionaryCode, newSearch, {
        offset: 0,
        pageSize: 20,
      })
        .then(newOptions => {
            setOptions(newOptions)
          setEntriesDictionaryCode(filter.reference!.dictionaryCode!)
            setLoading(false)
          },
        ).catch(() => setLoading(false))
    }
  }, [filter.reference, query])

  const searchChangeDebounced = useDebouncedCallback(queryNewOptionWithSearchValue, 500)

  const onSearch = useCallback((newSearch: string) => {
    if (filter.reference) {
      setSearch(newSearch)
      searchChangeDebounced(newSearch)
    }
  }, [filter.reference, searchChangeDebounced])

  const clear = useCallback(() => {
    if (filter.predicate.value.entries.length > 0) {
      const newEntriesUnselected = entries.map(entry => ({
        ...entry,
        selected: false,
      }))
      setEntries(newEntriesUnselected)
      updateFilterValues([])
    }
  }, [entries, filter.predicate.value.entries.length, updateFilterValues])

  const handleVisibilityChange = useCallback((visibility: boolean) => {
    setIsSelectOpen(visibility)
  }, [])

  const handleBlur = useCallback(() => {
    onBlur({
      ...filter,
      predicate: {
        ...filter.predicate,
        value: {
          ...filter.predicate.value,
          entries: filter.predicate.value.entries.map(v => ({
            id: v.id,
          })),
        },
      },
      isValid: true,
    })
  }, [filter, onBlur])

  return <MultipleSelectWithOptionList ref={ref} getPopupContainer={getPopupContainer}
                                       mode={Mode.MULTIPLE}
                                       size={'small'}
                                       isSimpleInput={true}
                                       withBackground={withBackground}
                                       onApply={onApply}
                                       value={filterValues}
                                       placement={"rightTop"}
                                       options={entries}
                                       loading={loading}
                                       onChange={handleOptionStateChange}
                                       onSelectionChange={handleSelectionChange}
                                       onClear={clear}
                                       onVisibilityChange={handleVisibilityChange}
                                       onPaginationChange={onPaginationChange}
                                       onFocus={onFocus}
                                       onBlur={handleBlur}
                                       onSearch={onSearch}/>
})

export default FilterValueMatch

import React, {useCallback, useMemo, useRef} from 'react'
import {OrderBy, ShapeDimension, TableColumn} from "types/charts"
import styled from "styled-components"
import {Pagination, TableQueryWorkflowResult} from "classes/workflows/query-workflows/QueryWorkflow"
import Loader from "components/loader/Loader"
import TableChartHeaderRow from "components/charts/table/TableChart.HeaderRow"
import TableChartDataRow from "components/charts/table/TableChart.DataRow"
import Language from "language"

interface Props {
  chartData: TableQueryWorkflowResult
  columns: TableColumn[]
  chartDimensions: ShapeDimension
  loading?: boolean
  onSort: (sorters: OrderBy[]) => void
  sorters: OrderBy[]
  withTotalRow: boolean
  printPercentage: boolean
  pagination: Pagination
  lineCount: number
}


const TableChartDom = ({
                         chartData,
                         columns,
                         chartDimensions,
                         loading = false,
                         onSort,
                         sorters,
                         withTotalRow,
                         printPercentage,
                         pagination,
                         lineCount,
                       }: Props) => {
  const tableWrapper = useRef<HTMLDivElement>(null)

  const cellSize = useCallback(() => {
    return Math.floor((chartDimensions.width
      - 36
      // scroll
      - (tableWrapper.current ? tableWrapper.current.offsetWidth - tableWrapper.current.clientWidth : 0)
    ) / columns.filter(c => !c.hidden).length) - 16
  }, [chartDimensions.width, columns])

  const widths = useMemo(() => columns.map(() => cellSize()), [cellSize, columns])

  const {width, height} = chartDimensions

  return <BironTableContainer style={{
    width,
    height,
  }}>
    <Loader loading={loading}>
      <FlexWrapper ref={tableWrapper}>
        <BironTableWrapper>
          <BironTable style={{
            width,
            maxHeight: height,
          }}>
            <tbody>
            {/* header row */}
            <TableChartHeaderRow {...{
              widths,
              columns,
              sorters,
              onSort,
            }}/>
            {/* total row */}
            {withTotalRow && (
              <TableChartDataRow key="total" {...{
                row: chartData.totalData,
                widths,
                columns,
                totalData: chartData.totalData,
                printPercentage,
                isTotal: true,
              }}/>
            )}
            {chartData.data.map((d, i) => <TableChartDataRow key={i} {...{
                row: d,
                widths,
                columns,
                totalData: chartData.totalData,
                printPercentage,
                isOther: d[0] === Language.get("chart-others-series"),
              }}/>,
            )}
            </tbody>
          </BironTable>
        </BironTableWrapper>
      </FlexWrapper>
    </Loader>
  </BironTableContainer>
}

export default TableChartDom

const FlexWrapper = styled.div`
    display: flex;
    max-height: inherit;
    flex-flow: row nowrap;
`

const BironTableContainer = styled.div`
    line-height: 20px;
`

const BironTableWrapper = styled.div`
    overflow-x: auto;
    overflow-y: auto;
    width: inherit;
    padding-bottom: 10px;
`

const BironTable = styled.table`
    overflow-y: auto;
    border-spacing: 0;

    th:not(.table-expand-cell), td:not(.table-expand-cell) {
        padding: 8px;
    }
`
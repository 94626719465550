import React, {forwardRef, FunctionComponent} from 'react'
import WrapperRoot from './WrapperRoot'
import MetricSelector, {Props as MetricSelectorProps} from "components/forms/selector/metrics/MetricSelector"
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {CogIcon} from "@heroicons/react/outline"

interface Props {
  children: React.ReactNode
  name: string
}

export const OptionsWrapper: FunctionComponent<Props> = ({children, name}) => (
  <WrapperRoot icon={CogIcon}
               label="configuration-label-options">
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OptionsField = forwardRef<any, MetricSelectorProps>(function Field({...props}, ref) {
  return <MetricSelector {...props} />
})

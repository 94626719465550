import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import sizeMe, {SizeMe} from 'react-sizeme'
import WidgetHeader from 'components/widgets/Widget.Header'
import WidgetContent from "components/widgetContainer/WidgetContainer.Content"
import styled from "styled-components"
import {ShapeDimension} from "types/charts"
import {ChartDetailWithoutLayout, OnSelectionChange, OnSnapshot} from "components/widgetContainer/WidgetContainer"
import {GetDynamicHeight} from "components/mdView/MdView.types"
import {WCBodyRef} from "components/widgetContainer/WidgetContainer.Body"
import {ChartSelection, EmptyObject, WorkflowResultTypes} from "classes/workflows/query-workflows/QueryWorkflow"
import {CHART_PADDING_X, CHART_PADDING_Y} from "components/charts/Chart.constants"
import {CardEffectDiv} from "themes/SharedStyledComponent"
import {UseGetChartLayoutForAddType} from "hooks/useGetLayoutForAddChart"

export default forwardRef<WidgetRef, Props>(function Widget({...props}, ref) {
  return <SizeMe monitorHeight monitorWidth>{({size}) =>
    <SizedWidget {...{
      ref,
      ...props,
      size,
    }}/>
  }</SizeMe>
})

interface Props {
  chart: ChartDetailWithoutLayout,
  isLinkedTo?: boolean,
  loading: boolean,
  data?: WorkflowResultTypes | EmptyObject,
  selection?: ChartSelection,
  resizing?: boolean,
  editable?: boolean,
  inLoadingBounds: boolean
  getBqlRequest: () => void,
  onDownload?: () => any
  onSnapshot?: OnSnapshot
  onConfirm: (newConf: ChartDetailWithoutLayout) => Promise<any>
  onConfEdit?: () => any
  onDelete?: () => Promise<void>
  onLinkCopy: () => any
  onSelectionChange: OnSelectionChange
  size?: sizeMe.SizeMeProps['size']
  getChartLayoutForAdd: ReturnType<UseGetChartLayoutForAddType>
  scrollToChart: (chartId: number) => void
  handleChartDuplicate: () => void,
  onConfCopy: () => void,
}

type GetDraggableDomNode = () => HTMLElement | null

export interface WidgetRef {
  getDynamicHeight: GetDynamicHeight
  getDraggableDomNode: GetDraggableDomNode
  getDataUrl: () => string | undefined
}

const SizedWidget = forwardRef<WidgetRef, Props>(function SizedWidget({
                                                                        chart,
                                                                        loading,
                                                                        data,
                                                                        selection,
                                                                        resizing,
                                                                        editable,
                                                                        inLoadingBounds,
                                                                        getBqlRequest,
                                                                        onDownload,
                                                                        onSnapshot,
                                                                        onConfirm,
                                                                        onConfEdit,
                                                                        isLinkedTo,
                                                                        onDelete,
                                                                        onLinkCopy,
                                                                        onSelectionChange,
                                                                        size,
                                                                        getChartLayoutForAdd,
                                                                        scrollToChart,
                                                                        handleChartDuplicate,
                                                                        onConfCopy,
                                                                      }, ref) {
  const refDomNode = useRef<HTMLDivElement>(null)
  const refWidgetContent = useRef<WCBodyRef>(null)

  useImperativeHandle(ref, () => ({
    // Return the highest div before the grid
    getDraggableDomNode: () => (refDomNode.current?.parentNode?.parentNode ?? null) as HTMLElement | null,
    getDynamicHeight: () => {
      const dynamicHeight = refWidgetContent.current?.getDynamicHeight?.()
      return dynamicHeight === undefined ? undefined : dynamicHeight + 38
    },
    getDataUrl: () => refWidgetContent.current?.getDataUrl?.(),
  }), [refWidgetContent, refDomNode])

  const refLastDimensions = useRef<ShapeDimension | null>(null)

  if (!resizing) {
    refLastDimensions.current = size?.width && size?.height
      ? {
        width: size.width - (CHART_PADDING_X * 2),
        height: size.height - 36 - (CHART_PADDING_Y * 2),
      }
      : null
  }

  return <CardEffectDiv ref={refDomNode}
                        className={`widget widget-${chart.type} widget-to-config`}>
    {/* Error boundary here so we are inside styled div */}
      <WidgetHeader {...{
        chart,
        editable,
        selection,
        meta: {meta: undefined, ...data}?.meta,
        onSelectionChange,
        onDownload,
        onConfirm,
        onSnapshot,
        onLinkCopy,
        isLinkedTo,
        getBqlRequest,
        onConfEdit: editable ? onConfEdit : undefined,
        onClose: editable ? onDelete : undefined,
        getChartLayoutForAdd,
        scrollToChart,
        handleChartDuplicate,
        onConfCopy,
      }} />
    {refLastDimensions.current && selection && inLoadingBounds && (
        <WidgetContentContainer>
          <WidgetContent ref={refWidgetContent} {...{
            withSummary: true,
            chart,
            data,
            selection,
            loading,
            dimensions: refLastDimensions.current,
            onSelectionChange,
          }} />
        </WidgetContentContainer>
      )}
  </CardEffectDiv>
})

const WidgetContentContainer = styled.div`
  padding: 0 ${CHART_PADDING_X}px ${CHART_PADDING_Y}px ${CHART_PADDING_X}px;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
`


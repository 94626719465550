import React, {memo, useMemo} from 'react'
import Language from 'language'
import {Col, Row} from 'antd'
import {DictionaryEntry} from 'types/savedConfs'
import {StyledDimensionDescription} from 'components/widgets/topper/help-popover/HelpPopoverBody'
import styled from 'styled-components'
import {IconContainer} from "components/common/IconContainer"
import {CheckIcon, XIcon} from "@heroicons/react/outline"
import {getFilterResume} from "components/forms/selector/comps/box/utils"
import {ColorizedSourceItem} from "components/colorizedSourceItem/ColorizedSourceItem"
import {
  ConfDimensionFilterTypesWithSource,
  ConfMetricFilterTypesWithSource,
  FilterOperator,
} from "components/forms/selector/comps/box/filters"

interface Props {
  filters: (ConfDimensionFilterTypesWithSource | ConfMetricFilterTypesWithSource)[]
}

export const HelpPopoverFilters = memo<Props>(function HelpPopoverFilters({filters}) {
  return <>{filters.map((filter, index) => <FilterLine key={index} filter={filter}/>)}</>
})

interface FilterLineProps {
  filter: ConfDimensionFilterTypesWithSource | ConfMetricFilterTypesWithSource,
}

const FilterLine = memo<FilterLineProps>(function FilterLine({filter}) {
  const filterValue = useMemo(() => {
    switch (filter.predicate.operator) {
      case FilterOperator.IN:
        return displayFiltersValue(filter.predicate.value.entries)
      default:
        return getFilterResume(filter)
    }
  }, [filter])

  const separator = useMemo(() => {
    switch (filter.predicate.operator) {
      case FilterOperator.IN:
        return `${Language.get(`chart-definition-filters.type.${filter.predicate.negate ? 'not-included' : 'included'}`)} :`
      default:
        return ""
    }
  }, [filter.predicate.negate, filter.predicate.operator])

  return <React.Fragment key={filter.reference?.code}>
    <FilterRow>
      <AliasCol span={24}>
        <Alias translate="no">{filter.reference?.alias}</Alias>
        {filter.reference && <StyledDimensionDescription dimension={{
          alias: filter.reference.alias,
          description: filter.reference.description ?? "",
          code: filter.reference.code,
          semanticType: filter.dataType,
        }}/>}
      </AliasCol>
    </FilterRow>
    <FilterRow>
      <TypeCol span={24}>
        <IconContainer color={"var(--medium-grey)"}>
          {filter.predicate.negate ? <XIcon/> : <CheckIcon/>}
        </IconContainer>
        <Separator>
          {separator}
        </Separator>
        <ColorizedSourceItem value={filter}>
          {filterValue}
        </ColorizedSourceItem>
      </TypeCol>
    </FilterRow>
  </React.Fragment>
})

const displayFiltersValue = (filtersValue: DictionaryEntry[]) => filtersValue.length > 0
  ? filtersValue.flatMap((filterValue, index) => <React.Fragment key={filterValue.id}>
    {index > 0 && <FilterValue>
      {index === filtersValue.length - 1 ? ` ${Language.get('chart-definition-filters.conditions.or')} ` : ', '}
    </FilterValue>
    }
    <FilterValue>{filterValue.id}</FilterValue>
  </React.Fragment>)
  : 'Ø'

const Alias = styled.div`
  margin-right: 5px;
`

const Separator = styled.div`
  margin-right: 10px;
  display: flex;
  white-space: nowrap;
  align-items: center;
`

const FilterRow = styled(Row)`
  line-height: 20px;
`

const FilterValue = styled.span`
  font-style: normal;
  font-size: 13px;
`

const AliasCol = styled(Col)`
    display: flex;

    color: var(--light-text);
    padding: 0 !important;
`

const TypeCol = styled(Col)`
    display: flex;
    
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    padding: 0 !important;
    text-align: start;
    color: var(--light-text);
`
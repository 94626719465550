/* eslint-disable max-lines */

import dayjs, {Dayjs} from 'dayjs'
import locale from 'dayjs/locale/en-gb' // gb is important as it sets firstDayOfWeek at Monday
import moment from 'moment'
// use the dist version of the locale to avoid the bundle of the umd version
// https://github.com/vitejs/vite/issues/3755#issuecomment-860548526
import 'moment/dist/locale/en-gb'
import {ColorName} from "components/charts/Chart.Theme"
import {MetricTarget, SlicerTarget, TargetAffect} from 'components/forms/chart/types'
import {Days} from "components/forms/Form.types"
import {User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {ChartDtoDetailTypes} from "types/charts"
import {FilterNegationType, FilterOperator} from "components/forms/selector/comps/box/filters"
import {FilterInputType} from "types/filter"
import {ChartSource, DataSourceObjectType, ObjectType} from "components/dataSourceDoc/DataSource.types"

export const configure = () => {
  dayjs.locale(locale)

  moment.defineLocale('en-fixed', {
    parentLocale: 'en-gb',
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      l: 'M/D/YYYY',
      ll: 'MMM D, YYYY',
      lll: 'MMM D, YYYY h:mm A',
      llll: 'ddd, MMM D, YYYY h:mm A',
    },
  });
  moment.locale('en-fixed') // used only by antd
}

export default {
  'by': 'by',
  'by-default': 'By default',
  'all': 'All',
  'axis': 'Axis',
  'day': 'day',
  'chart': 'Chart',
  'preview': 'Preview markdown',
  'date-slicer-option-label': 'Reference date',
  'button-save': 'Save',
  'button-apply': 'Apply',
  'button-cancel': 'Cancel',
  'button-confirm': 'Confirm',
  'button-search': 'Search',
  'button-reset': 'Reset',
  'button-edit': 'Edit',
  'button-yes': 'Yes',
  'button-no': 'No',
  'search': 'Search',
  'reset': 'Reset',
  'no-data': 'No data',
  'no-description': 'No description',
  'authentication': {
    'signin': 'Log in progress...',
    'signout': 'Log out in progress...',
    'redirect': 'Redirecting to the login page...',
  },
  'granularity': 'Granularity',
  'calendar-granularity-day': 'Day',
  'calendar-granularity-week': 'Week',
  'calendar-granularity-month': 'Month',
  'calendar-granularity-quarter': 'Quarter',
  'calendar-granularity-year': 'Year',
  'chart-definition-button': 'Description',
  'chart-copy-button': 'Copy (single click) / Duplicate below (double-click)',
  'chart-download-button': 'Download',
  'chart-download-data-button': 'Download chart data',
  'chart-download-png-button': 'Download chart render',
  'chart-link-button': 'Copy link to clipboard',
  'chart-link-button-clicked': 'Link copied to clipboard',
  'chart-remove-button': 'Remove',
  'chart-definition': 'Chart definition',
  'chart-definition-period': 'Period',
  'chart-definition-from-chart': 'from the chart configuration',
  'chart-definition-from-dashboard': 'from the dashboard configuration',
  'chart-definition-period-from-dashboard': 'Period of the dashboard',
  'chart-definition-granularity-from-dashboard': 'Granularity of the dashboard',
  'chart-definition-sort': 'Sort',
  'chart-definition-slicers': 'Slicers',
  'chart-definition-metrics': 'Metrics',
  'chart-definition-filters': {
    "title": "Filters",
    "conditions": {
      'and': 'AND',
      'or': 'OR',
    },
    "type": {
      "included": "Included",
      "not-included": "Not included",
    },
  },
  'chart-message-concurrent-query': 'Request was interrupted by the concurrent loading of the same chart',
  'chart-message-query-too-complex': 'Request was interrupted because either the query was too complex or the time frame was too wide.',
  'chart-message-query-error': 'An unexpected error occurred.',
  'chart-message-query-memory-exceed': 'Solving this request required too much memory',
  'chart-message-invalid-conf': 'The configuration is invalid.',
  'chart-others-series': 'Others',
  'chart-growth-d-1': (quantity: number) => `D-${quantity}`,
  'chart-growth-w-1': (quantity: number) => `W-${quantity}`,
  'chart-growth-m-1': (quantity: number) => `M-${quantity}`,
  'chart-growth-q-1': (quantity: number) => `Q-${quantity}`,
  'chart-growth-s-1': 'S-1',
  'chart-growth-y-1': (quantity: number) => `Y-${quantity}`,
  'chart-growth-d-30': 'D-30',
  'chart-growth-translated': 'Translated',
  'click-to-configure-chart': 'click to configure the chart',
  'configuration': 'Configuration',
  'configuration-add-filter': (isFirstLetterUpperCase = false) => `${isFirstLetterUpperCase ? "A" : "a"}dd filter`,
  'configuration-search-filter': (isFirstLetterUpperCase = false) => `${isFirstLetterUpperCase ? "S" : "s"}earch a filter`,
  'configuration-add-value': 'add value',
  'configuration-search-value': 'search a value',
  'configuration-add-metric': 'add metric',
  'configuration-add-slicer': 'add slicer',
  'configuration-add-sort': 'add sort',
  'configuration-no-sort-selected': 'No sort',
  'configuration-sort-types': {
    'asc': 'Ascending',
    'desc': 'Descending',
  },
  'configuration-add-target': 'add target',
  'configuration-see-definitions': 'See definitions',
  'configuration-definitions-more-informations': 'More informations',
  'configuration-hide-definitions': 'Hide definitions',
  'configuration-footer-help': () => `**Need help ?** See the [guide](${window.location.origin}/delegate/kb){:target="_blank"} and the [available datas list](./help){:target="_blank"}`,
  'configuration-label-portlet-title': 'Title',
  'configuration-label-portlet-size': 'Size',
  'configuration-label-portlet-title-class': 'Style',
  'configuration-label-color': 'Color',
  'configuration-label-text': 'Text',
  'configuration-label-view': 'View',
  'configuration-label-slicers': 'Slicers',
  'configuration-label-slicers-single': 'Slicer',
  'configuration-label-metrics-filters': 'Filter Results',
  'configuration-label-filters': 'Filters',
  'configuration-label-metrics': 'Metrics',
  'configuration-label-orderBys': 'Sorts',
  'configuration-label-date': 'Date',
  'configuration-label-dimensions': 'Dimensions',
  'configuration-label-metrics-single': 'Metric',
  'configuration-label-period': 'Period',
  'configuration-label-targets': 'Targets',
  'configuration-label-options': 'Options',
  'configuration-label-limits': 'Limits',
  'configuration-label-destination': 'Destination',
  'configuration-label-days': 'Execution days',
  'configuration-label-override': 'Override file',
  'configuration-description-view': `**A view (or a data source)** is a **compilation of information about an object**. Data sources have explicit names in Biron describing the underlying object : "**orders**", "**products sold**", "**customers**", etc. Chosing a view is the very first step required to configure a news dashboard.\n\n To see the list of availables views, you can look at [the definitions list](./help){:target="_blank"}.`,
  'configuration-description-metrics': `**A metric** is a quantity that can be **added** or **counted** like the **number of active customers** or the revenue generated over a period. Both of them are named "metrics" in Biron.\n\nAs an example, chosing the data source "Orders" and the metric "Net Revenue" enables to display the net revenues. Chosing the data source "Orders" and the metric "Delivery time" enables to display the average delivery time. \n\n To see the list of availables metrics, you can look at [the definitions list](./help){:target="_blank"}.`,
  'configuration-description-slicer': `A slicer is a way to get a breakdown of any metric. Eg if your orders can be divided into French orders, German orders and Belgium orders, then the **"Order country"** is a slicer.\n\nYou could also use the **"Recurring / New customers"** slicer to divide customers or make a product breakdown using **"Price range"** : "0-10 €", "10-20 €", etc.\n\n To see the list of availables slicers, you can look at [the definitions list](./help){:target="_blank"}.`,
  'configuration-description-metrics-filters': `Filter Results allow to use the results of metric as a filter of the chart.`,
  'configuration-description-filters': `Filters allow to limit the display to certain data (for example a brand, a country, etc.).\n\n To see the list of availables filters dimensions, you can look at [the definitions list](./help){:target="_blank"}.`,
  'configuration-description-md': 'You can write here any description.\n\nYour text can be formatted (bold, underline, etc.) using the markdown syntax  (see [this link](https://daringfireball.net/projects/markdown/syntax){:target="_blank"} for more informations)',
  'configuration-description-orderBys': 'Allow you to set a default sort on a metric/dimension or even on date when the print a line per date is selected.',
  'configuration-chart-type-disable': {
    description: (reasons: string[]) => `Disabled type because ${reasons.join(' and ')}`,
    reasons: {
      withMetricMultiple: "multiple metrics are selected",
      withMetricAsRatio: "a selected metric is a ratio",
      withMetricWithGrowthAsRatio: "a selected metric has a growth as percentage",
      withSlicer: "a slicer is selected",
      withSlicerMultiple: "several slicers are selected",
      withSlicerAsSimple: "a selected slicer has a too high cardinality",
      withLessThanTwoMetric: "less than two metrics are selected",
      withMetricUnique: "only one metric is selected",
      withSlicerUnique: "only one slicer is selected",
      withoutSlicer: "no slicer is selected",
      withMoreThanTwoSlicers: "more than two slicers are selected",
      withLessThanTwoSlicers: "less than two slicers are selected",
      withFilter: "a filter is selected",
      withSlicerAndMetricMultiple: "a slicer is selected with multiple metrics",
      withSlicerMultipleAndMetricMultiple: "multiple slicers and multiple metrics",
      withMoreThanTwoSlicersAndMetricMultiple: "more than two slicers and multiple metrics",
      withMetricAndWithSlicer: "only one metric or with slicer",
      withMultipleSlicerAndMetric: "multiple slicers or multiple metrics",
      withOnlyOneSlicer: "only one slicer",
      withSlicerMultipleWithoutDefaultSlicer: "multiple slicers except default value are selected",
      withSlicerAsSimpleWithoutDefaultSlicer: "a selected slicer has a too high cardinality",
      withMoreThanOneSlicer: "more than two slicers are selected",
      withSlicerWithoutDefaultSlicer: "a slicer is selected except default value",
      withMoreThanOneSlicerNotAnAxis: "multiple slicers except the axis are selected",
      withMultipleMetricAndMultipleSlicers: "multiple slicers and multiple metrics are selected at the same time",
    },
  },
  'configuration-chart-format-disable': {
    description: (reasons: string[]) => `Disabled format because ${reasons.join(' and ')}`,
    reasons: {
      hasMultipleFormat: "selected metrics have different format",
      notEnoughData: "not enough slicer or metric are selected",
      withMetricAsRatio: "a selected metric is a ratio",
    },
  },
  'configuration-chart-additional-detail': {
    asPercentage: {
      label: 'Display percentages',
    },
    ignoreMetrics0: {
      label: 'Exclude zero rows',
      description: "<strong>Exclude zero rows</strong><br/>Exclude all rows where every metric value is zero",
    },
    withDateSlicer: {
      label: 'Print a line per date',
      description: 'By default, the chart will not display the data detailed by date. By selecting this option, the chart will display **one line per date**.',
    },
    sortSeries: {
      label: 'Sort series by descending order',
      description: 'Sort Series by descending order (instead of not sorting them)',
    },
    limitSeries: {
      label: 'Print only ### series',
      shortDescription: `Other series will be displayed in the "Others" serie`,
      description: `In order to make it more readable, you can limit the chart to a number of series. The chart will select the top X series and display them. \n\nRemaining series will be displayed in a serie named **'Others'**`,
    },
    displayLabels: {
      label: 'Display the series labels',
      description: `In order to add details you can add the series labels, this will bring more details`,
    },
    hideOthers: {
      label: 'Hide "Others" serie',
      description: `In order to make it more readable, you can hide the "Others" serie`,
    },
    ignoreSeasonality: {
      label: 'Ignore seasonality',
      description: `Compare past/remaining period instead of considering seasonality of past year.`,
    },
    printPrevisions: {
      label: 'Print previsions',
      description: `Print the expected result for the end of the period (estimated from the time left)`,
    },
  },
  'configuration-chart-filter-description': {
    header: ({alias, description}: { alias?: string, description?: string }) => `<strong>${alias} :</strong> ${description}`,
    details: {
      empty: (alias: string) => `No filter value selected, the filter will return all values related to <strong>${alias}</strong>`,
      filled: ({
                 filterType,
                 filterValue,
               }: { filterType: string, filterValue: string }) => `Displays all rows where value <strong>${filterType} :</strong> <br/>${filterValue}`,
    },
    filterType: {
      "equal": "is included in",
      "different": "is not included in",
    },
    operator: {
      "or": "or",
    },
  },
  'configuration-metric-target': {
    [MetricTarget.TARGET]: 'Target',
    [MetricTarget.COLUMN]: 'Column',
    [MetricTarget.AREA]: 'Area',
    [MetricTarget.BAR]: 'Bar',
    [MetricTarget.PART]: 'Part',
    [MetricTarget.NUMBER]: 'Number',
    [MetricTarget.LINE]: 'Line',
  },
  'configuration-slicer-target': {
    [SlicerTarget.AXE]: 'Horizontal axis',
    [SlicerTarget.COLOR]: 'Color',
    [SlicerTarget.COLUMN]: 'Column',
    [SlicerTarget.PART]: 'Part',
  },
  'configuration-affect': {
    [TargetAffect.AXIS]: 'Axis',
    [TargetAffect.BAR]: 'Bar',
    [TargetAffect.AREA]: 'Area',
    [TargetAffect.LINE]: 'Lines',
    [TargetAffect.PART]: 'Parts',
    [TargetAffect.TABLE]: 'Table',
    [SlicerTarget.COLOR]: 'Couleur',
  },
  'configuration-preview-insufficient-configuration': 'Add a metric to display a preview',
  'configuration-export-select-destination': 'Please select your destination',
  'configuration-export-select-days': 'Please select your execution days',
  'configuration-chart-target-value': 'Target value',
  'configuration-chart-target-duplicate-entry': 'This month has already a associated value.',
  'configuration-invert-color': 'Invert colors (growth in red)',
  'configuration-chart-uniqueView-all': 'Multiple',
  'configuration-chart-sort': {
    'metric-asc': 'Ascending metrics',
    'metric-desc': 'Descending metrics',
    'slicer-asc': 'Alphabetical slicers',
  },
  'configuration-chart-cannot-modify-axis': 'You can\'t modify the axis of this metric',
  'configuration-chart-set-to-first-axis': 'Remove metric from the secondary axis',
  'configuration-chart-set-to-second-axis': 'Set this metric to the secondary axis',
  'configuration-warning-information-hidden': 'Warning, some values are equal or below 0 and can\'t be displayed.',
  'configuration-metric-options': {
    'custom-display': 'Custom display',
    'custom': 'Custom',
    'metric-copy-fail': 'Nothing copied',
    'metric-copy-fail-description': 'Nothing has been copied because the clipboard is empty',
    'copied-metric': 'Metric copied',
    'copied-metric-description': 'Paste it via the button next to the metric input',
    'info': {
      'options': 'Edit metric\'s options',
      'paste/duplicate': 'Copy/Duplicate metric and options',
      'more': 'More actions',
      'paste': 'Paste metric',
    },
    'paste': {
      'metric-options': 'Paste metric options',
      'filters': 'Paste filters',
      'period': 'Paste period',
      'growth': 'Paste growth',
      'display': 'Paste display',
    },
    'growth': {
      'title': 'Growth',
      'activated': 'Activated',
      'period': 'Period',
      'type': 'Type',
      'options': 'Options',
      'display': 'Custom display',
    },
    'period': {
      'title': 'Period',
      'override-option-label': 'Période du chart',
    },
    'filters': {
      'title': 'Filters',
    },
    'display': {
      'title': 'Display',
      'custom-title': 'Custom display',
      'decimals': 'Decimals',
      'units': {
        'title': 'Value display',
        'options': {
          'units': 'Units',
          'k': 'Thousands',
          'm': 'Millions',
        },
      },
      'color': 'Color',
      'color-warning': 'Color overriding is only available for Bars charts without slicers and Lines and Areas charts with a maximum of one slicer',
      'details': {
        'title': 'Units',
        'options': {
          'suffix': 'Suffix',
          'prefix': 'prefix',
          'none': 'None',
        },
      },
    },
  },
  'dashboard-edit': 'Settings',
  'dashboard-edit-mode': 'Edit mode',
  'dashboard-add-chart': 'New chart',
  'dashboard-addFromClipboard-label': 'Paste',
  'dashboard-addFromClipboard-newTitle': (chart: ChartDtoDetailTypes) => `Copy of: ${chart.title}`,
  'dashboard-chartCopied-confirmation-title': 'Chart copied',
  'dashboard-chartCopied-confirmation-description': 'Paste it via the button "+ chart"',
  'dashboard-nexusQlCopy-title': "Copy NexusQL",
  'dashboard-nexusQlCopied-confirmation-title': 'NexusQL request copied',
  'dashboard-nexusQlCopied-confirmation-description': 'Paste it anywhere using your clipboard',
  'dashboard-add-boxes': (displayImage: boolean) => '### **Boxes**\nText Boxes can be used to add context to the data and guide users through the dashboards\n'
    + `${displayImage ? '\n![](/img/screen-boxes.png)' : ''}`
    + '\n###Options:'
    + '\n- **N metrics** - and their growth'
    + '\n- **N filters**'
    + '\n- **No slicer**',
  'dashboard-add-boxes-label': 'Boxes',
  'dashboard-add-bars': (displayImage: boolean) => '### **Bar Charts**\nBar Charts display a metric\'s total value over the selected period, sliced by a dimension (e.g. sales per country).'
    + `${displayImage ? '\n![](/img/screen-barres.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 metric** - and its growth'
    + '\n- **N filters**'
    + '\n- **1 slicer**',
  'dashboard-add-bars-label': 'Bar Charts',
  'dashboard-add-evolution-label': 'Evolution',
  'dashboard-add-evolution': (displayImage: boolean) => '### **Evolution Charts**\nEvolution Charts display a metric sliced by a date dimension with another optional dimension (e.g. avg basket per country).'
    + `${displayImage ? '\n![](/img/screen-lines.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 metric** - or its growth'
    + '\n- **N filters**'
    + '\n- **1 slicer**',
  'dashboard-add-divider': (displayImage: boolean) => '### **Text Box**\n You can add a text box to give more informations about the displayed dataor guide your coworkers. '
    + `${displayImage ? '\n![](/img/screen-text.png)' : ''}`,
  'dashboard-add-divider-label': 'Text Box',
  'dashboard-add-lines': (displayImage: boolean) => '### **Line Charts**\nLign Charts display the evolution of a metric sliced by a dimension (e.g. avg basket per country).'
    + `${displayImage ? '\n![](/img/screen-lines.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 metric** - or its growth'
    + '\n- **N filters**'
    + '\n- **1 slicer**',
  'dashboard-add-lines-label': 'Lines',
  'dashboard-add-area': (displayImage: boolean) => '### **Area Charts**\nLign Charts display the evolution of a cumulated metric sliced by a dimension (e.g. sales per country)'
    + `${displayImage ? '\n![](/img/screen-area.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 metric** - no growth or non cumulative metrics (averages...)'
    + '\n- **N filtres**'
    + '\n- **1 slicer**',
  'dashboard-add-area-label': 'Areas',
  'dashboard-add-tables': (displayImage: boolean) => '### **Tables**\nTables display detailed data, with as many slicers and metrics as possible.'
    + `${displayImage ? '\n![](/img/screen-tables.png)' : ''}`
    + '\n###Options:'
    + '\n- **N metrics**'
    + '\n- **N filters**'
    + '\n- **N slicers** - inc. the date ',
  'dashboard-add-tables-label': 'Table',
  'dashboard-add-pie': (displayImage: boolean) => '### **Pie charts**\nPie charts display a metric\'s total value over the selected period, sliced by a dimension (e.g. sales per country)'
    + `${displayImage ? '\n![](/img/screen-pie.png)' : ''}`
    + '\n###Options:'
    + '\n- **1 metric** - and their growth'
    + '\n- **N filters**'
    + '\n- **1 slicer**',
  'dashboard-add-pie-label': 'Pie',
  'dashboard-add-target': '### **Targets**\nTargets display and compare a metric total value vs. goals set for the period.'
    + '\n![](/img/screen-target.png)'
    + '\n###Options:'
    + '\n- **1 metric**'
    + '\n- **No filter**'
    + '\n- **No slicer**'
    + '\n- **N targets**, set as a month and the corresponding goal.',
  'dashboard-add-target-label': 'Target',
  'definitions-link-label': 'Data dictionnary',
  'definitions-link-text': 'You can access the list of metrics, dimensions and view in the data dictionnary.',
  'enforced-legend-view': 'View',
  'enforced-legend-period': 'Period',
  'enforced-legend-slicers': 'Slicers',
  'enforced-legend-filters': 'Filters',
  'enforced-legend-metrics': 'Metrics',
  'error-occurred': 'An error occurred',
  'form-position-after-xxx': ({ref}: { ref: string }) => `After ${ref}`,
  'form-position-at-the-start': 'At the start',
  'form-position-at-the-end': 'At the end',
  'form-title-new-dashboard': 'New Dashboard',
  'form-title-new-menu': 'New Menu',
  'form-title-copy-of-xxx': ({ref}: { ref: string }) => `Copy of ${ref}`,
  'form-label-chart-type': 'Type',
  'form-label-parent-menu': 'Menu',
  'form-label-name-fr': 'Name FR',
  'form-label-name-en': 'Name EN',
  'form-label-code': 'Code',
  'form-label-position': 'Position',
  'granularity-label-year': 'per year',
  'granularity-label-quarter': 'per quarter',
  'granularity-label-month': 'per month',
  'granularity-label-week': 'per week',
  'granularity-label-day': 'per day',
  'growth': {
    'group': {
      "last-period": 'Last period',
      "dynamic-period": 'Period dynamic',
      "fixed-period": 'Fixed period',
    },
    'value': {
      'd-n': (quantity: number) => `D-${quantity}`,
      'w-n': (quantity: number) => `W-${quantity}`,
      'm-n': (quantity: number) => `M-${quantity}`,
      'q-n': (quantity: number) => `Q-${quantity}`,
      'y-n': (quantity: number) => `Y-${quantity}`,
      'previous': 'Previous',
    },
    'preset': {
      'd-n': 'VS Day',
      'w-n': 'VS Week',
      'm-n': 'VS Month',
      'q-n': 'VS Quarter',
      'y-n': 'VS Year',
      'previous': 'Previous',
    },
    'type-preset': {
      'ratio': 'Percentage',
      'difference': 'Difference',
      'value': 'Value',
    },
  },
  'input-type-to-search': (searchedField?: string) => `Type to search ${searchedField ? `a ${searchedField.toLowerCase()}` : ''}...`,
  'login-error': 'Login Error',
  'login-retry': 'Retry',
  'login-clock-correct': "Make sure your computer's clock is set correctly",
  'navigation-admin': 'Administration',
  'navigation-admin-user-list': 'Users',
  'navigation-newVersion-title': 'New version available',
  'navigation-newVersion-description': 'A new version of Biron is available, click on this message to reload current page.',
  'navigation-noAuthorization': "You don't have any authorization on this application",
  'navigation-help': 'Help',
  'navigation-knowledge-base': 'Knowledge base',
  'navigation-tickets': 'Tickets',
  'navigation-log-out': 'Log out',
  'navigation-tools-add-dashboard': 'Add a dashboard',
  'navigation-tools-add-menu': 'Add a menu',
  'navigation-tools-clone-dashboard': 'Clone dashboard',
  'navigation-tools-edit-dashboard': 'Edit dashboard',
  'navigation-tools-edit-menu': 'Edit menu',
  'navigation-tools-delete-dashboard': 'Delete dashboard',
  'navigation-tools-delete-dashboard-confirmation': 'Are you sure to delete the dashboard ?',
  'navigation-tools-delete-menu': 'Delete menu',
  'navigation-tools-delete-menu-confirmation': 'Are you sure to delete the menu ? This will also delete all it\'s dashboards',
  'new-chart-title': 'New chart',
  'new-text-chart-title': 'New text chart',
  'enter-description': 'Enter description',
  'new-export-title': 'New export',
  'new-period-title': 'New period',
  'no-target-configured': 'No target has been set for this period. Please configure one.',
  'chartConfSwitches': {
    'asPercentage': {
      'on': 'Print total value percentages',
      'off': 'Print values only',
    },
    'sortSeries': {
      'on': 'Sort by descending value',
      'off': 'Do not sort',
    },
    'withDateSlicer': {
      'on': 'Print a line per date',
      'off': 'Do not group by date',
    },
    'displayLabels': {
      'on': 'Display label',
      'off': 'Hide label',
    },
    'withChartOverriddenPeriod': {
      'on': 'Restore the chart period and granularity',
      'off': 'Ignore the chart period and granularity',
    },
  },
  'reload-app': "Reload application",
  'switch-deactivate-edit-mode': 'Deactivate edit mode',
  'switch-activate-edit-mode': 'Activate edit mode',
  'table-expand-show-less': 'Show less',
  'table-expand-show-more': 'Show more',
  'table-footer-lines-count': '# line(s)',
  'target-achieved-of': 'of',
  'target-legend-achieved': 'Achieved',
  'target-legend-target': 'Target',
  'time-preset-selector-futures-date': 'futures dates ',
  'time-preset-category-days': 'Days',
  'time-preset-category-months': 'Months',
  'time-preset-category-years': 'Years',
  'time-preset-category-others': 'Others',
  'time-preset-category-days-future': 'Futures days',
  'time-preset-category-months-future': 'Futures months',
  'time-preset-category-years-future': 'Futures years',
  'time-preset-category-others-future': 'Futures Others',
  'chart-delete-confirmation': 'Delete the chart ?',
  'timePreset': {
    'custom': 'custom',
    'yesterday': 'yesterday',
    'today': 'today',
    'tomorrow': 'tomorrow',
    'currentWeek': 'since monday',
    'totalCurrentWeek': 'complete current week',
    '7lastDays': 'last 7 days',
    '7nextDays': 'next 7 days',
    '30lastDays': 'last 30 days',
    '30nextDays': 'next 30 days',
    '90lastDays': 'last 90 days',
    '180lastDays': 'last 180 days',
    'currentMonth': 'current month (MTD)',
    'totalCurrentMonth': 'complete current month',
    'untilEndMonth': 'tod. until end of month',
    'nextMonth': `next month`,
    'currentQuarter': 'current quarter (QTD)',
    'lastQuarter': `previous quarter`,
    '3lastMonths': 'last 3 months',
    '3nextMonths': 'next 3 months',
    '6lastMonths': 'last 6 months',
    '6nextMonths': 'next 6 months',
    '12lastMonths': 'last 12 months',
    '12nextMonths': 'next 12 months',
    '24lastMonths': 'last 24 months',
    'previousMonth': 'previous month',
    'previousWeek': 'previous week (W-1)',
    'lastYear': () => `previous year (${dayjs().subtract(1, 'year').format('YY')})`,
    'nextYear': 'next year',
    'currentYear': 'current year (YTD)',
    'lastYearToDate': `last year to date (LYTD)`,
    'currentAndLastYear': () => `previous and current year (${dayjs().subtract(1, 'year').format('YY')}-${dayjs().format('YY')})`,
    'totalCurrentYear': () => `complete current year (${dayjs().format('YY')})`,
    'untilEndYear': 'until 31st. Dec',
    '2lastYears': () => `2 previous years (${dayjs().subtract(2, 'year').format('YY')}-${dayjs().subtract(1, 'year').format('YY')})`,
    '2nextYears': '2 next years',
    'lastYearCurrentWeek': 'current week last year',
    'lastYearCurrentMonth': 'current month last year',
    'last': 'last',
    '2last': 'last 2',
    '3last': 'last 3',
    '6last': 'last 6',
    '7last': 'last 7',
    '12last': 'last 12',
    '30last': 'last 30',
  },
  common: {
    formModal: {
      actionSave: 'Save',
      actionCancel: 'Cancel',
    },
    popconfirm: {
      okText: 'OK',
      cancelText: 'Cancel',
    },
    notificationSuccess: {
      message: 'Confirmation',
      description: {
        action: 'Action saved',
      },
    },
  },
  entityField: {
    user: {
      '$': 'Users',
      email: 'Email',
      fullName: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      locale: 'Language',
      powerUser: 'Tickets access',
      aces: 'Roles',
    },
    userAssocClient: {
      active: 'Active ?',
    },
    accessControlEntry: {
      role: 'Role',
      target: 'Target',
    },
  },
  enum: {
    aceTargetType: {
      environment: 'All workspaces',
      workspace: (w: WorkspacesForAce) => w.name,
    },
    role: {
      ENVIRONMENT_SUPERADMIN: 'SuperAdmin',
      ENVIRONMENT_ADMIN: 'Admin',
      WORKSPACE_MANAGER: 'Writer',
      WORKSPACE_USER: 'Reader',
    },
    roleDescription: {
      ENVIRONMENT_ADMIN: `user administration authorization. An admin can Create/Modify/Delete a user, and manage the authorizations of all users including itself.`,
      WORKSPACE_MANAGER: `editing authorization on all dashboards of the Workspace, including Creating/Editing/Deleting of Charts, Dashboards and Menus. A writer can thus completely modify a workspace.`,
      WORKSPACE_USER: `read-only authorization on all dashboards of the Workspace, without the possibility to edit the existing charts, dashboards or menus.`,
    },
  },
  filter: {
    separator: {
      and: "And",
    },
    spaceWarning: "Warning value contains trailing or leading spaces",
    matchCase: "Match case",
    operator: {
      [FilterOperator.Like]: "Like",
      [FilterOperator.IN]: "In",
      [FilterOperator.Match]: "Regex",
      [FilterOperator.BETWEEN]: (value?: {
        min: number,
        max: number
      }) => `Between ${value ? `${value.min ?? ""} and ${value.max ?? ""}` : ""}`,
      [FilterOperator.EQ]: (value?: number) => `Equal to ${value ?? ""}`,
      [FilterOperator.LTE]: (value?: number) => `Less than or Equal to ${value ?? ""}`,
      [FilterOperator.LT]: (value?: number) => `Less than ${value ?? ""}`,
      [FilterOperator.GTE]: (value?: number) => `Greater than or Equal to ${value ?? ""}`,
      [FilterOperator.GT]: (value?: number) => `Greater than ${value ?? ""}`,
    },
    type: {
      [FilterInputType.START_WITH]: {
        title: "Start with",
        description: "Returns all values that starts with the provided string.",
      },
      [FilterInputType.END_WITH]: {
        title: "End with",
        description: "Returns all values that ends with the provided string.",
      },
      [FilterInputType.INCLUDE]: {
        title: "Includes",
        description: "Returns all values that contain the provided string.",
      },
      [FilterInputType.VALUE_SELECTION]: {
        title: "Value selection",
        description: "Choose among all possible dimensions’ values.\nYou can select several values simultaneously.",
      },
      [FilterInputType.REGEX]: {
        title: "Regex",
        description: "Returns all values that match the provided regular expression.<br/><br/><a href='https://regex101.com/' target=”_blank”>Regex101</a> for more information about regular expressions",
        warning: "Regular expression is incorrect",
      },
      [FilterInputType.EQUAL]: {
        title: "Equal to",
        description: "Display all values equal the one inputed",
        warning: "The value must be numeric",
        placeholder: "Enter a value",
      },
      [FilterInputType.GREATER]: {
        title: "Greater than",
        description: "Display all values greater than the one inputed",
        warning: "The value must be numeric",
        placeholder: "Enter a value",
      },
      [FilterInputType.GREATER_OR_EQ]: {
        title: "Greater than or Equal to",
        description: "Display all values greater than or Equal to the one inputed",
        warning: "The value must be numeric",
        placeholder: "Enter a value",
      },
      [FilterInputType.LOWER]: {
        title: "Less than",
        description: "Display all values less than the one inputed",
        warning: "The value must be numeric",
        placeholder: "Enter a value",
      },
      [FilterInputType.LOWER_OR_EQ]: {
        title: "Less than or Equal to",
        description: "Display all values less than or Equal to the one inputed",
        warning: "The value must be numeric",
        placeholder: "Enter a value",
      },
      [FilterInputType.BETWEEN]: {
        title: "Between",
        description: "Display all values between the two inputed",
        warning: {
          notNumeric: "The value must be numeric",
          maxBeforeMin: "The minimum is greater than the maximum",
        },
        placeholder: {
          min: "Minimum",
          max: "Maximum",
        },
      },
    },
  },
  filterType: {
    [FilterNegationType.default]: "Filter data",
    [FilterNegationType.negate]: "Exclude data",
  },
  dataDoc: {
    views: "All views",
    usages: "Usages in the current workspace",
    links: "Links",
    nav: {
      name: 'Datasources',
      type: (type: DataSourceObjectType) => {
        switch (type) {
          case DataSourceObjectType.DIMENSION:
            return 'Dimension'
          case DataSourceObjectType.METRIC:
            return 'Metric'
          case DataSourceObjectType.VIEW:
            return 'View'
          default:
            return 'not found'
        }
      },
    },
    columns: {
      object: "Object",
      name: "Name",
      objectName: "Object name",
      dashboardName: "Dashboard name",
      menuName: "Menu name",
      usage: "Usage",
      view: "View",
      code: "Code",
      alias: "Alias",
      description: "Description",
      type: "Type",
      id: "NexusQL ID",
      value: "Value",
    },
    lists: {
      dimensions: "Dimensions",
      metrics: "Metrics",
      all: "All"
    },
    details: {
      availableObject: "Available objects",
      usages: "Usages",
      values: "Values",
      dimension: "Dimension",
      metric: "Metric",
      stats: (source: string) => `${source} usages`,
      linkTitle: {
        dimension: "Link of the views where this dimension is available",
        metric: "Link of the views using the same metric",
      },
      informations: {
        title: "Informations",
        description: "Description",
        suffix: "Suffix",
        decimales: "Decimals",
        asRatio: "Ratio",
        linkedViews: "Linked views",
        idNexusQL: "NexusQL ID",
      },
      sources: (source: ChartSource) => {
        switch (source) {
          case ChartSource.FILTER:
            return 'Filter'
          case ChartSource.METRIC:
            return 'Metric'
          case ChartSource.SLICER:
            return "Slicer"
          default:
            return 'not found'
        }
      },
      object: (object: ObjectType) => {
        switch (object) {
          case ObjectType.EXPORT:
            return "Export"
          case ObjectType.CHART:
            return "Chart"
          case ObjectType.DASHBOARD:
            return "Dashboard"
          default:
            return "not found"
        }
      },
      type: (type: DataSourceObjectType) => {
        switch (type) {
          case DataSourceObjectType.VIEW:
            return "View"
          case DataSourceObjectType.METRIC:
            return 'Metric'
          case DataSourceObjectType.DIMENSION:
            return "Dimension"
          default:
            return "not found"
        }
      },
    },
  },
  export: {
    "sider-title": "Exports",
    "title": "Exports management",
    "add": (destination: string) => `Add ${destination} export`,
    "informations": "Export information",
    "execution": "Execution options",
    "action": "Action",
    'execution-day': 'Execution days',
    'override': 'Override',
    'override-checked': 'The file will be overridden each time with the name of the export',
    'override-notchecked': 'A new file will be created each time with the date as a prefix before the export name',
    'ignoreMetrics0': 'Ignore metrics with 0',
    'destination': 'Export destination',
    'next-execution': 'Next execution in',
    'file-name': 'Example of file name',
    alert: {
      "no-destination": "There is no export destinations for this client",
    },
    "days": {
      [Days.MONDAY]: "Monday",
      [Days.TUESDAY]: "Tuesday",
      [Days.WEDNESDAY]: "Wednesday",
      [Days.THURSDAY]: "Thursday",
      [Days.FRIDAY]: "Friday",
      [Days.SATURDAY]: "Saturday",
      [Days.SUNDAY]: "Sunday",
    },
  },
  period: {
    "sider-title": "Periods",
    "title": "Periods management",
    "add": "Add a period",
    "delete": {
      title: "Delete",
      description: {
        question: "Are you sure you want to delete this period ?",
        content: "This operation is irreversible. If the period is used in a dashboard it will be replaced by Month To Date and it will be replaced by the dashboard period on the charts.",
      },
    },
    "informations": "Informations",
    "period": "Period",
    "minus": "minus",
    "start": "Start",
    "end": "End",
    "form": {
      "title": "New period",
      "usage-warning": "Warning this period is used in an export",
      "start-after-end-warning": "Warning: The end date of the period precedes the start date",
      "dynamic": {
        "preview": {
          "title": (date: Dayjs) => `Resulting period as of ${date.format("DD MMM")} :`,
        },
      },
      "code-error": "The code should have a unique value",
    },
    "type": {
      "fix": "Fix",
      "dynamic": "Dynamic",
    },
    "category": {
      "DAY": "Day",
      "MONTH": "Month",
      "YEAR": "Year",
      'OTHER': "Other",
    },
    'period-edge-start-point': {
      TODAY: "Today",
      YESTERDAY: "Yesterday",
      WEEK: "Week",
      MONTH: "Month",
      QUARTER: "Quarter",
      YEAR: "Year",
    },
    'period-edge-end-point': {
      TODAY: "Today",
      YESTERDAY: "Yesterday",
      WEEK: "Week",
      MONTH: "Month",
      QUARTER: "Quarter",
      YEAR: "Year",
    },
    'granularity': {
      YEAR: 'Year',
      QUARTER: 'Quarter',
      MONTH: 'Month',
      WEEK: 'Week',
      DAY: 'Day',
    },
  },
  admin: {
    user: {
      homeTitle: `Users management`,
      actionCreateUser: `Create a new user`,
      actionCreateUac: `Import an existing user`,
      actionManageUserAces: `Manage roles`,
      actionEditUser: `Edit user`,
      actionActiveUser: `Reactivate`,
      actionInactiveUser: `Inactivate`,
      actionResetPassword: `Reset password`,
      actionResetPasswordSuccess: {
        message: "Password reset",
        description: ({user}: { user: User }) => `an e-mail was sent to ${user.email}`,
      },
      actionDeleteUser: `Delete`,
      actionDeleteUserConfirm: `Are you sure you want to delete this user ? This operation is irreversible.`,
      formCreateTitle: 'Create new user',
      formUpdateTitle: ({user}: { user: User }) => `Edit user ${user.email}`,
      formNewUacTitle: 'Import an existing user',
      formAcesTitle: ({user}: { user: User }) => `Roles management for ${user.email}`,
      filterOnAces: `Filter on roles`,
      uacInactiveFlag: `Inactive`,
      userNoAces: `none`,
    },
  },
  colors: {
    [ColorName["blue-border"]]: "Blue Border",
    [ColorName["blue-pastel"]]: "Blue Filled",
    [ColorName["orange-border"]]: "Orange Border",
    [ColorName["orange-pastel"]]: "Orange Filled",
    [ColorName["red-border"]]: "Red Border",
    [ColorName["red-pastel"]]: "Red Filled",
    [ColorName["green-border"]]: "Green Border",
    [ColorName["green-pastel"]]: "Green Filled",
    [ColorName["bluegreen-border"]]: "Purple Border",
    [ColorName["bluegreen-pastel"]]: "Purple Filled",
  },
  sessions: {
    'expired': 'Session expired',
    'expiredDescription': 'Press the button below to reconnect',
    'expiredContinue': 'Continue',
  },
}

/* eslint-disable max-lines */
import React, {memo, useMemo, useRef} from "react"
import {Col, Form, Row} from "antd"
import Language from "language"
import styled from "styled-components"
import {MetricGrowthPeriodUnit, MetricGrowthType} from "types/savedConfs"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {DataSelection} from "redux/models/currentDashboard"
// import {useDefaultGrowth} from "components/forms/selector/metrics/hooks"
import {GenericChartTypes} from "types/widgets"
import {intervalToString, toInterval} from "commons/parsers/queries"
import {PeriodTypes} from "types/period.types"
import {consolidateTimeInterval} from "commons/parsers/utils"
import FormRow from "components/forms/selector/metrics/options/FormRow"
import ActionSelector from "components/forms/selector/metrics/options/selectors/ActionSelector"
import TypeSelector from "components/forms/selector/metrics/options/selectors/TypeSelector"
import PeriodSelector from "components/forms/selector/metrics/options/selectors/PeriodSelector"
import GrowthInvertSelector from "components/forms/selector/metrics/options/selectors/GrowthInvertSelector"
import {useFields} from "hooks/useFields"
import CustomDisplaySelector from "components/forms/selector/metrics/options/selectors/CustomDisplaySelector"
import {getGrowthLabel} from "classes/workflows/query-workflows/genericDataSetUtil"
import {
  ConfMetricWithActivationState,
  PartialConfMetricWithActivationState,
} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import {useDefaultGrowth} from "components/forms/selector/metrics/hooks"

export interface MetricGrowthOptionSelectorProps {
  id: number
  temporaryMetric?: ConfMetricWithActivationState
  onMetricChange: (data: PartialConfMetricWithActivationState) => void
  configuration?: ChartTypeWithDisabledReason
  dashboardSelection: DataSelection
  period?: PeriodTypes
  displayType?: GenericChartTypes
  canInvert: boolean
  dayDifference: number
}

// eslint-disable-next-line react/display-name
const MetricGrowthOptionSelector = memo<MetricGrowthOptionSelectorProps>(({
                                                                            id,
                                                                            temporaryMetric,
                                                                            configuration,
                                                                            onMetricChange,
                                                                            displayType,
                                                                            dayDifference,
                                                                            canInvert,
                                                                            dashboardSelection,
                                                                            period,
                                                                          }) => {
  const [form] = Form.useForm()
  const fields = useFields(temporaryMetric, [])
  const ref = useRef<HTMLDivElement | null>(null)

  const defaultGrowth = useDefaultGrowth(displayType)

  const growthLabel = useMemo(() => temporaryMetric?.growthType && temporaryMetric.growthPeriod ? getGrowthLabel(
    {
      growth: {
        type: temporaryMetric.growthType,
        period: temporaryMetric.growthPeriod,
      },
      titlePartOverrides: temporaryMetric.titlePartOverrides,
    },
  ) : undefined, [temporaryMetric?.growthPeriod, temporaryMetric?.growthType, temporaryMetric?.titlePartOverrides])

  return <Form form={form} fields={fields} onValuesChange={(changedValue) => {
    if (Object.keys(changedValue)[0] === "activated") {
      if (changedValue.activated) {
        onMetricChange({
          growthPeriod: {
            unit: MetricGrowthPeriodUnit.WEEKS,
            quantity: 1,
          },
          growthType: defaultGrowth,
          activated: true,
        })
      } else {
        onMetricChange({
          growthType: undefined,
          growthPeriod: undefined,
          activated: false,
          growthLabel: undefined,
        })
      }
    } else {
      if (Object.keys(changedValue)[0] === "growthPeriod") {
        onMetricChange({
          growthType: temporaryMetric?.growthType ?? MetricGrowthType.VALUE,
          growthPeriod: changedValue.growthPeriod ? {
            ...changedValue.growthPeriod,
            quantity: changedValue.growthPeriod.quantity === null ? 1 : changedValue.growthPeriod.quantity,
          } : undefined,
        })
      } else if (Object.keys(changedValue)[0] === "growthType") {
        onMetricChange({
          growthType: changedValue.growthType ?? MetricGrowthType.VALUE,
          growthPeriod: temporaryMetric?.growthPeriod ?? {
            unit: MetricGrowthPeriodUnit.WEEKS,
            quantity: 1,
          },
        })
      } else {
        onMetricChange(changedValue)
      }
    }
  }}>
    <StyledDiv ref={ref}>
      <ContainerRow>
        <FormRow name={"activated"} title={Language.get('configuration-metric-options.growth.activated')}>
          <ActionSelector/>
        </FormRow>
        <FormRow name={"growthType"} title={Language.get('configuration-metric-options.growth.type')}
                 activated={!!temporaryMetric?.activated}>
          <TypeSelector configuration={configuration} activated={!!temporaryMetric?.activated}/>
        </FormRow>
        <FormRow name={"growthPeriod"} title={Language.get('configuration-metric-options.growth.period')}
                 activated={!!temporaryMetric?.activated && temporaryMetric?.growthType !== undefined}
                 additionalComponent={
                   temporaryMetric?.growthPeriod && <Col span={17} offset={7} style={{
                     marginTop: 10,
                     display: 'flex',
                     alignItems: 'center',
                   }}>
                     {
                       dashboardSelection && <IntervalContainer>
                         {intervalToString(toInterval(
                           temporaryMetric.growthPeriod.unit,
                           temporaryMetric.growthPeriod.quantity,
                           consolidateTimeInterval({dashboardSelection, chartPeriod: period}, undefined),
                           dayDifference,
                         ))}
                       </IntervalContainer>
                     }
                   </Col>
                 }>
          <PeriodSelector id={id} dayDifference={dayDifference} period={period}
                          dashboardSelection={dashboardSelection} activated={!!temporaryMetric?.activated}/>
        </FormRow>
        {canInvert && temporaryMetric && temporaryMetric.growthPeriod && temporaryMetric.growthType !== MetricGrowthType.VALUE
          && <FormRow
            name={"growthInvert"}
            title={Language.get('configuration-metric-options.growth.options')}>
            <GrowthInvertSelector/>
          </FormRow>
        }
        <FormRow
          name={"growthLabel"}
          title={Language.get("configuration-metric-options.growth.display")}
          activated={!!temporaryMetric?.activated && temporaryMetric?.growthType !== undefined}>
          <CustomDisplaySelector
            activated={!!temporaryMetric?.activated && temporaryMetric?.growthType !== undefined}
            defaultLabel={growthLabel}/>
        </FormRow>
      </ContainerRow>
    </StyledDiv>
  </Form>
})

export default MetricGrowthOptionSelector

const ContainerRow = styled(Row)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 6px 75px 6px 22px;
    line-height: 20px;
`

const StyledDiv = styled.div`
    width: 550px;
`

const IntervalContainer = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;
    text-align: right;
    color: rgba(0, 0, 0, 0.2);
`
import {preventInfinite, seriesToNonInfiniteValues} from "components/charts/Chart.constants"
import {ConsolidatedSerie, GenericEffectiveConf, OriginalSerie, Param, SerieType} from "components/charts/line/LineChart.types"
import {Label} from "classes/MetricDataNode"
import {SerieInfo} from "components/charts/Chart.utils"
import {Format} from "types/charts"
import {Hoverdata} from "components/charts/bar/BarChart"
import {asArray} from "commons/asArray"
import {getCategoryAxisIndex, tooltipBaseParameters, tooltipFormatter} from "components/charts/Chart.tooltip"
import Tooltip = echarts.EChartOption.Tooltip

export const FORMATTING_ERROR = "Unable to format series as values length doesn't match xAxisExpectedValues length"

export const formatSeriesFromMetricOnAxisToSlicerOnAxis = (series: OriginalSerie[], xAxisExpectedValues: Label[]): ConsolidatedSerie[] => {
  const nonInfiniteValues = seriesToNonInfiniteValues(series)
  const min = Math.min(...nonInfiniteValues)
  const max = Math.max(...nonInfiniteValues)

  return series.flatMap(({values, ...otherSerieInformation}) => {
    if (values.length !== xAxisExpectedValues.length) {
      throw new Error(FORMATTING_ERROR)
    }
    return values.map((v, i) => {
      const data: (number | undefined)[] = Array(xAxisExpectedValues.length).fill(undefined)
      data[i] = preventInfinite(v, min, max)
      return {
        ...otherSerieInformation,
        type: "consolidated",
        label: xAxisExpectedValues[i],
        labelAlt: xAxisExpectedValues[i],
        values: data,
      }
    })
  })
}

export const barChartTooltip: (yAxisLength: number, renderedSeries: Pick<SerieInfo<SerieType>, "format" | "axisIndex" | "series">[], effectiveConf: Pick<GenericEffectiveConf, "slicers" | "metrics">, formats: Format[], alternativeFormats: Format[], total?: number, hoverData?: Hoverdata) => Tooltip = (yAxisLength, renderedSeries, effectiveConf, formats, alternativeFormats, total, hoverData) => ({
  ...tooltipBaseParameters(),
  formatter: (params) => {
    let result = asArray(params) as Param[]
    if (renderedSeries.length === 0 || renderedSeries[0].series.length === 0) {
      return ""
    }
    switch (renderedSeries[0].series[0].type) {
      case "consolidated": {
        const indexOfAxis = getCategoryAxisIndex(result)
        result = result.filter((param, i) => i === hoverData?.dataIndex && param.data.findIndex((d: any, di: number) => d !== undefined && di !== indexOfAxis) !== -1).map(param => ({
          ...param,
          data: param.data,
        }))
        break
      }
      default:
        break
    }
    return tooltipFormatter(yAxisLength, renderedSeries, effectiveConf, formats, alternativeFormats, result, total, hoverData)
  },
})

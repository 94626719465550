import {getResolvedFromPeriod} from "commons/period/periodsList"
import dayjs from "dayjs"
import {DateInterval} from "redux/models/currentDashboard"
import {isEmpty} from "commons/object.utils"

export const saveSessionTime = (conf: DateInterval) => {
  sessionStorage.setItem('session_time', toStorableSession(conf))
}

export const getSessionTime = (): DateInterval | null => {
  const storedSession = sessionStorage.getItem('session_time')
  return storedSession ? fromStorableSession(storedSession) : null
}


const toStorableSession = (conf: DateInterval) => JSON.stringify(conf)

const fromStorableSession = (sessionConf: string): DateInterval | null => {
  // Process the period from the session storage and return null if there is an error with the retrieved data
  try {
    const conf = JSON.parse(sessionConf)
    const preset = !conf.name && getResolvedFromPeriod(conf.period)
    return isEmpty(preset) && isEmpty(conf?.period) ? null : {
      start: preset ? preset.start : dayjs(conf.start),
      end: preset ? preset.end : dayjs(conf.end),
      granularity: conf.granularity,
      period: preset ? preset.period : conf.period,
    }
  } catch {
    return null
  }
}

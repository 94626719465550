import React, {memo} from 'react'
import {Form} from 'antd'
import styled from "styled-components"
import {CheckboxAdditionalDetails} from "components/forms/chart/useRequirements"
import AdditionalDetailSelector from "components/forms/selector/additional-details/AdditionalDetailSelector"

interface WrapperProps {
  children?: React.ReactNode
  additionalDetail: CheckboxAdditionalDetails
}

export const AdditionalDetailWrapper: (props: WrapperProps) => JSX.Element = ({additionalDetail, children}) => (
  <Container>
    <FormItem name={additionalDetail.textKey}>
      {children}
    </FormItem>
  </Container>
)

interface FieldProps {
  additionalDetail: CheckboxAdditionalDetails
  value?: any
  onChange?: (value: any) => void
}

// eslint-disable-next-line react/display-name
export const AdditionalDetailField = memo<FieldProps>(({additionalDetail, ...props}) => {
  return additionalDetail.type && <AdditionalDetailSelector {...{
    additionalDetail,
    ...props,
  }} />
})

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
`
const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  `
import IconAnimated from "components/common/IconAnimated"
import {IconContainer} from "components/common/IconContainer"
import React, {memo} from "react"
import useClickPrevention from "hooks/useClickPrevention"
import {captureEvent} from "services/GoogleAnalyticsService"

interface CopyDuplicateProps {
  handleDuplicate: () => void
  onCopy: () => void
  GACategory?: string
}

// eslint-disable-next-line react/display-name
const CopyDuplicate = memo<CopyDuplicateProps>(({
                                                  onCopy,
                                                  handleDuplicate,
                                                  GACategory,
                                                }) => {
  const [handleConfClick, handleConfDoubleClick] = useClickPrevention({
    onClick: () => {
      if (GACategory) {
        captureEvent({
          category: GACategory,
          action: `copy`,
        })
      }
      onCopy()
    },
    onDoubleClick: () => {
      if (GACategory) {
        captureEvent({
          category: GACategory,
          action: `duplicate`,
        })
      }
      handleDuplicate()
    },
  })

  return <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><IconAnimated onClick={handleConfClick}
                                                                                                              onDoubleClick={handleConfDoubleClick}/></IconContainer>
})

export default CopyDuplicate
import React, {FunctionComponent, useCallback} from 'react'
import useMediaDesktop from "hooks/useMediaDesktop"
import styled from "styled-components"
import {Dimension} from "classes/MetaModel"
import BoxesWrapperWithResume from "components/forms/selector/comps/BoxesWrapperWithResume"
import {ConfDimensionFilterTypes, ConfFilter, FilterType} from "components/forms/selector/comps/box/filters"
import {converterFilterToConfModel, useDimensionFilterConverterToConfModel} from "components/forms/chart/utils"

interface Props {
  environmentId: number
  filters: ConfDimensionFilterTypes[]
  availableDimensions: Dimension[]
  onFiltersChange: (filter: ConfDimensionFilterTypes, index: number) => void
  onFiltersDelete: (index: number) => void
  query: (dictionaryCode: string) => Promise<any>
  isFilterLineFullSize: boolean
  toggleFilterLine: () => void
  onEdit?: (state: number) => void
}

const FilterLine: FunctionComponent<Props> = ({
                                                filters = [],
                                                environmentId,
                                                availableDimensions,
                                                onFiltersChange,
                                                onFiltersDelete,
                                                query,
                                                isFilterLineFullSize,
                                                toggleFilterLine,
                                                onEdit,
                                              }) => {
  const isMediaDesktop = useMediaDesktop()
  const filterConverter = useDimensionFilterConverterToConfModel(filters, availableDimensions)

  const handleChange = useCallback((confFilter: ConfFilter, index: number) => {
    if (confFilter.type === FilterType.dimension) {
      onFiltersChange(converterFilterToConfModel([confFilter], availableDimensions)[0], index)
    }
  }, [availableDimensions, onFiltersChange])

  return filters.length > 0 && isMediaDesktop
    ? <StyledFilterLine>
      <StyledBoxesWrapper {...{
        filters: filterConverter(),
        environmentId,
        onFiltersChange: handleChange,
        onFiltersDelete,
        layout: 'line',
        query,
        isFilterLineFullSize,
        toggleFilterLine,
        onEdit,
      }}/>
    </StyledFilterLine>
    : null
}

export default FilterLine;

const StyledBoxesWrapper = styled(BoxesWrapperWithResume)`
  background-color: var(--main-background-color);
`

const StyledFilterLine = styled.div`
  position: fixed;
  width: calc(100% - 240px);
  max-height: 80vh;
  z-index: 10;
  border-bottom: 1px solid var(--light-grey-2);
    line-height: var(--line-height);
   
   @media (max-width: 1200px) {
    width: 100%;
  }
`

import styled from "styled-components"
import {CHART_PADDING_X, CHART_PADDING_Y} from "components/charts/Chart.constants"
import {Col, Row} from "antd"

export const CardEffectDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; // used to color widget header after link animation
  background-color: white;
  border-radius: var(--big-border-radius);
  box-shadow: 0px 8px 12px rgba(27, 27, 29, 0.02);

  -webkit-touch-callout: none; /* iOS Safari */
  /* Non-prefixed version, currently supported by Chrome and Opera */
`

export const CardContentContainer = styled.div`
  padding: ${CHART_PADDING_Y}px ${CHART_PADDING_X}px ${CHART_PADDING_Y}px ${CHART_PADDING_X}px;
`

export const ColoredElement = styled.div<{ $color: string }>`
  ${({$color}) => `background-color: ${$color};`}
  height: 32px;
  width: 32px;
`

export const FlexMetricOptionRow = styled(Row) <{ $clickable: number, $justify?: string, $gap?: number }>`
  display: flex;
  justify-content: ${({justify}) => justify ?? "space-between"};
  ${({$gap}) => $gap ? `gap: ${$gap}px;` : ''}
  height: 100%;
  width: 100%;
  align-items: center;

    ${({$clickable}) => $clickable ? `
    color: var(--primary);
  
    &:hover {
      opacity: 0.7;
      color: var(--primary);
    }
  ` : ""}
`

export const ContentCol = styled(Col)`
    margin-left: 5px;
`


export const TooltipText = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`

export const TooltipContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
`

export const TooltipContentElement = styled.div`
    margin-right: 30px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const TooltipAlias = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 8px;
`

export const DeleteContainer = styled.div`
    display: flex;
    justify-content: end;
    position: absolute;
    right: 0;
    width: 75px;
    background: rgb(246, 246, 248);
    background: linear-gradient(90deg, rgba(246, 246, 248, 0) 0%, rgba(246, 246, 248, 0.48503151260504207) 35%, rgba(246, 246, 248, 1) 100%);
    padding-right: 5px;
`

export const ErrorBoundaryResultContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;
`

export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 600 !important;
    border-bottom: 1px solid var(--border-color-base);
    margin-bottom: 8px;
`
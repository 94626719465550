/* eslint-disable max-lines */
import React, {memo, useMemo, useRef} from "react"
import {Form, Row} from "antd"
import Language from "language"
import styled from "styled-components"
import FormRow from "components/forms/selector/metrics/options/FormRow"
import {useFields} from "hooks/useFields"
import MetaModel from "classes/MetaModel"
import {
  ConfMetricWithActivationState,
  PartialConfMetricWithActivationState,
} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import PeriodSelector from "components/forms/selector/period/PeriodSelector"
import CustomDisplaySelector from "components/forms/selector/metrics/options/selectors/CustomDisplaySelector"
import {isEmpty} from "lodash"

export interface MetricPeriodOptionSelectorProps {
  environmentId: number,
  temporaryMetric: ConfMetricWithActivationState
  onMetricChange: (data: PartialConfMetricWithActivationState) => void
  metaModel: MetaModel
}

export enum Units {
  "K" = "K",
  "M" = "M"
}

// eslint-disable-next-line react/display-name
const MetricPeriodOptionSelector = memo<MetricPeriodOptionSelectorProps>(({
                                                                            environmentId,
                                                                            temporaryMetric,
                                                                            onMetricChange,
                                                                          }) => {
  const [form] = Form.useForm()
  const fields = useFields(temporaryMetric, [])
  const ref = useRef<HTMLDivElement | null>(null)

  const defaultLabel = useMemo(() => {
    if (temporaryMetric.overridePeriod) {
      return Language.getTranslatedName(temporaryMetric.overridePeriod)
    }
    return ""
  }, [temporaryMetric.overridePeriod])

  return <Form form={form} fields={fields} onValuesChange={(changedValue) => {
    onMetricChange({
      ...changedValue,
      ...((Object.keys(changedValue)[0] === "overridePeriod" && isEmpty(changedValue.overridePeriod)) ? {
        overridePeriodLabel: undefined,
      } : {}),
    })
  }}>
    <StyledDiv ref={ref}>
      <ContainerRow>
        <FormRow
          name={"overridePeriod"}
          title={Language.get("configuration-metric-options.period.title")}>
          <PeriodSelector
            name={"overridePeriod"}
            environmentId={environmentId}
            overrideOptionLabel={Language.get('configuration-metric-options.period.override-option-label')}
            span={24}
            forTarget={false}
            withDateSlicerGranularity={false}
            withOverrideOption={true}
            GACategory={'metric-options'}/>
        </FormRow>
        <FormRow
          name={"overridePeriodLabel"}
          title={Language.get("configuration-metric-options.custom-display")}
          textAlignment={"center"}>
          <CustomDisplaySelector {...{
            defaultLabel,
          }}/>
        </FormRow>
      </ContainerRow>
    </StyledDiv>
  </Form>
})

export default MetricPeriodOptionSelector

const ContainerRow = styled(Row)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 6px 75px 6px 22px;
    line-height: 20px;
`

const StyledDiv = styled.div`
  width: 550px;
`

import React, {FunctionComponent, PropsWithChildren, useMemo} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {ViewOption} from "components/forms/chart/types"
import {EyeIcon} from "@heroicons/react/outline"
import {Col, Row} from "antd"
import styled from "styled-components"
import ViewDescription from "components/forms/description/ViewDescription"
import BironSelect from "components/forms/selector/select/BironSelect"
import {ColWithLeftMargin} from "components/forms/selector/utils"

interface WrapperProps extends PropsWithChildren {
  name: string
}

export const ViewWrapper: FunctionComponent<WrapperProps> = ({children, name}) => (
  <WrapperRoot icon={EyeIcon}
               label="configuration-label-view"
               description="configuration-description-view">
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

interface FieldProps {
  name: string
  options: ViewOption[]
  value?: string
  onChange?: (newValue: string) => void
}

export const ViewField: FunctionComponent<FieldProps> = ({...props}) => {
  const relatedOption = useMemo(() => props.options.find(opt => opt.value === props.value), [props.options, props.value])
  return <Row>
    <Col span={14}>
      <BironSelect {...props} span={24} showSearch={true}/>
    </Col>
    <StyledCol $value={3} span={1}>
      {relatedOption && relatedOption.value && typeof relatedOption.label === "string" && <ViewDescription
        description={relatedOption.description}
        title={relatedOption.label}
        code={relatedOption.value}/>}
    </StyledCol>
  </Row>
}

const StyledCol = styled(ColWithLeftMargin)`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
`
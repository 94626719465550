import {isSlicerDate} from "commons/configuration"
import {isEmpty} from "commons/object.utils"
import {ChartDividerDtoDetail, ChartGenericDtoDetail, ChartTargetDtoDetail} from "types/charts"
import {SlicerDtoDetailTypes} from "types/savedConfs"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {PeriodTypes} from "types/period.types"

export const getWhenGeneric = (chart: Omit<ChartGenericDtoDetail, "x" | "y" | "h" | "w" | "extraConf">
  | Omit<ChartTargetDtoDetail, "x" | "y" | "h" | "w" | "extraConf" | "metricCode" | "metricAlias">
  | Omit<ChartDividerDtoDetail, "x" | "y" | "h" | "w" | "extraConf">) => {
  if (chart.type === WidgetTypes.GENERIC) {
    return chart
  } else {
    return undefined
  }
}

export const isPeriodOverridden = (chart: { period?: PeriodTypes, slicers: SlicerDtoDetailTypes[] } | undefined) => chart && (chart.period !== undefined
  || chart.slicers.filter(isSlicerDate).filter(slicer => !isEmpty(slicer.granularity)).length > 0)
/* eslint-disable max-lines */
import MetaModel, {Dimension, MetaModelView} from "classes/MetaModel"
import {ChartGenericDtoDetail, ChartTargetDtoDetail, ConfChartGenericDtoDetail, ConfSlicer, Format} from "types/charts"
import {MetricGrowthPeriod, MetricGrowthType, SlicerDateDtoDetail} from "types/savedConfs"
import {ConfOrderBy, GenericChartTypes, Limit} from "types/widgets"
import {TargetExtendedConfModel} from "components/forms/chart/FormChartTargetCmp"
import {ReactNode} from "react"
import {CheckboxAdditionalDetails} from "components/forms/chart/useRequirements"
import {Granularity, PeriodDynamic, PeriodFixed, PeriodTypes} from "types/period.types"
import {Export, ExportDestination, ExportExecutionDto} from "components/forms/Form.types"
import {DataSelection} from "redux/models/currentDashboard"
import {Dayjs} from "dayjs"
import {ExtendedWidgetTypes, WidgetTypes} from "commons/dashboard/dashboard.types"
import {Environment, User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {ConfDimensionFilterTypes, ConfMetricFilterTypes, DimensionFilterDtoDetail} from "components/forms/selector/comps/box/filters"
import {ConfMetric} from "components/forms/selector/metrics/options/MetricOptionForm.types"

export interface ConfigCache {
  metricFilters: ConfMetricFilterTypes[]
  metrics: MetricCacheElement[]
  slicers: DimensionCacheElement[]
}

export interface Option {
  label: string
  value: number
  id: string
  disabled: boolean
}

type OptionSource = "date" | "slicers" | "metrics"
export type GroupedOptions = Partial<Record<OptionSource, Option[]>>

export enum CacheElementType {
  SLICER = "SLICER",
  METRIC = "METRIC"
}

interface CacheElement {
  type: CacheElementType.METRIC | CacheElementType.SLICER
  id: string
  code: string | number
  label?: string
  orderByOrder?: number
  orderByAsc?: boolean
  orderByDefault?: boolean
  orderByIndex?: number
  orderByValue?: string
  limit?: number
  limitDefault?: boolean
  hideOthers?: boolean
  isAxis: boolean
  displayLabel?: boolean
  isDefault: boolean,
}

export interface MetricCacheElement extends CacheElement {
  type: CacheElementType.METRIC
  growth?: {
    period: MetricGrowthPeriod,
    type: MetricGrowthType
  }
  growthInvert?: boolean
  viewAlias: string
  viewCode: string
  metricAlias: string
  isRatio?: boolean
  format?: Format
  additionalFilters: DimensionFilterDtoDetail[]
}

export interface DimensionCacheElement extends CacheElement {
  type: CacheElementType.SLICER
  hasRefDate: boolean
  isSimple?: boolean
}

export type CacheElementTypes = DimensionCacheElement | MetricCacheElement

export enum TargetAffect {
  AXIS = "AXIS",
  BAR = "BAR",
  AREA = "AREA",
  LINE = "LINE",
  PART = "PART",
  TABLE = "TABLE",
  COLOR = "COLOR",
}

export enum MetricTarget {
  TARGET = "TARGET",
  PART = "PART",
  BAR = "BAR",
  AREA = "AREA",
  LINE = "LINE",
  NUMBER = "NUMBER",
  COLUMN = "COLUMN",
}

export enum SlicerTarget {
  PART = "PART",
  AXE = "AXE",
  COLOR = "COLOR",
  COLUMN = "COLUMN",
}

export type ChartTypes = { disabledReasons: string[]; type: ExtendedWidgetTypes }[]

export interface DisablingReason {
  // category?: "METRIC" | "SLICER" | "FILTER"
  [k: string]: boolean
}

export enum SortType {
  CLASSIC = "Classic",
  DOUBLE = "Double",
  ARRAY = "Array"
}

export enum LimitType {
  SIMPLE = "Simple",
  DOUBLE = "Double"
}

interface ConfigurationSort {
  default: {
    column: number,
    asc: boolean
  }
  editable: boolean
  type: SortType
  affect: TargetAffect
}

export type ConfigurationOrderByWithDisablingReasons = (ConfigurationSort & {
  disablingReasons: DisablingReason
})

interface ConfigurationLimit {
  type: LimitType,
  mandatory: boolean,
  default?: {
    enabled: boolean,
    value?: number
  },
  displaySlicerOtherOption: boolean,
  affect: TargetAffect
}

export type ConfigurationLimitWithDisablingReasons = (ConfigurationLimit & {
  disablingReasons: DisablingReason
})

export interface SlicerConfiguration {
  value: SlicerDateDtoDetail,
  disablingReasons: DisablingReason
}

export interface Configuration<T> {
  displayOrder: number
  type: T,
  metricTarget?: MetricTarget,
  slicerTargets?: SlicerTarget[],
  defaultSlicer?: SlicerConfiguration,
  enableDateSlicer?: boolean,
  orderBys?: ConfigurationOrderByWithDisablingReasons[],
  limits?: ConfigurationLimitWithDisablingReasons[],
  isMetricsFilterEnabled: boolean
  isColorOverridable: boolean
}

export interface ConfigurationWithDisabledReasons<T> extends Configuration<T> {
  disablingReasons: DisablingReason
  disabledReasons: string[]
}

export interface ChartType extends Configuration<GenericChartTypes | WidgetTypes.TARGET | WidgetTypes.EXPORT> {
  format: (Configuration<ChartFormat> & {
    disablingReasons: DisablingReason
  })[]
  disablingReasons: DisablingReason
}

export interface ChartTypeWithDisabledReason extends ConfigurationWithDisabledReasons<GenericChartTypes | WidgetTypes.TARGET | WidgetTypes.EXPORT> {
  format?: ConfigurationWithDisabledReasons<ChartFormat>[]
}

export enum ChartFormat {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
  V_GROUPED = "V_GROUPED",
  H_GROUPED = "H_GROUPED",
  V_STACKED = "V_STACKED",
  H_STACKED = "H_STACKED",
  PERCENTAGE_H_STACKED = "PERCENTAGE_H_STACKED",
  PERCENTAGE_V_STACKED = "PERCENTAGE_V_STACKED",
  AREA_STANDARD = "AREA_STANDARD",
  LINE_STANDARD = "LINE_STANDARD",
  PIE_STANDARD = "PIE_STANDARD",
  ECLATE = "ECLATE",
  DONUT = "DONUT",
  AREA_PERCENTAGE = "AREA_PERCENTAGE"
}

export interface ExtendedConfModel extends ChartGenericDtoDetail {
  uniqueView: string | null
}

export interface GenericExtendedConfModel extends Omit<ConfChartGenericDtoDetail, "extraConf" | "x" | "y" | "h" | "w" | "type" | "orderBys"> {
  uniqueView: string | null
  dateSlicerGranularity: Granularity | null
  format?: ChartFormat | null
  type: WidgetTypes.GENERIC | WidgetTypes.EXPORT,
  displayType: GenericChartTypes
  displayLabels?: boolean,
  limits?: ConfLimit[]
  asPercentage?: boolean
  orderBys: ConfOrderBy[]
}

export interface ExportExtendedConfModel extends Omit<Export, "filters" | "orderBys"> {
  slicers: ConfSlicer[]
  metrics: ConfMetric[]
  filters: ConfDimensionFilterTypes[]
  metricFilters: ConfMetricFilterTypes[]
  period: PeriodTypes
  uniqueView: string | null
  dateSlicerGranularity: Granularity
  displayType: GenericChartTypes.TABLES
  orderBys: ConfOrderBy[]
  limits?: ConfLimit[]
  ignoreMetrics0: boolean
}

export interface ViewOption {
  label: string | ReactNode
  value: string | null
  description?: string
}


export type PeriodExtendedConfModel = (PeriodDynamic | (Omit<PeriodFixed, "start" | "end"> & { start: Dayjs, end: Dayjs })) & {
  title?: string
}
export type EmptyConfModel = NonNullable<unknown>
export type UserConfModel = User | NonNullable<unknown>
export type FormContent = GenericExtendedConfModel | TargetExtendedConfModel | PeriodExtendedConfModel | ExportExecutionDto | EmptyConfModel


export type TemplateChartFormProps = {
  uniqueViewOptions: ViewOption[];
  metricInvertible: boolean;
  loadDictionaryEntries: (dictionaryCode: string) => Promise<any>
  additionalDetails: CheckboxAdditionalDetails[]
  viewsWithMetrics: MetaModelView[];
  unavailableViews: MetaModelView[];
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  metaModel: MetaModel;
  dashboardSelection: DataSelection
  environmentId: number
}

export type TargetExtendedConf = ChartTargetDtoDetail & {
  uniqueView: string | null
}

export interface SimplifiedChartTargetFormProps extends TemplateChartFormProps {
}

export type SimplifiedChartGenericFormProps = TemplateChartFormProps & {
  availableDimensions: Dimension[]
  unavailableDimensions: Dimension[]
  dashboardId: number
}

export type SimplifiedPeriodFormProps = {
  isEditing: boolean
}

export type SimplifiedExportExecutionFormProps = {
  exportDestinations: ExportDestination[]
}

export type SimplifiedUserFormProps = {
  userCanSetPowerUser: boolean
  defaultEmailFqdn: string
}

export type SimplifiedDashboardFormProps = {
  availableDimensionsForFilters: Dimension[],
  loadDictionaryEntries: (dictionaryCode: string) => Promise<any>
  environmentId: number
}

export type SimplifiedUserAcesFormProps = {
  userCanManageAdvancedFeature: boolean
  isBironUser: boolean
  environment: Environment
  availableWorkspacesForAce: WorkspacesForAce[]
}

export type ACESConfModel = { [key: string]: string[] }
export type EmptyProps = NonNullable<unknown>
export type FormProps =
  TemplateChartFormProps
  | SimplifiedPeriodFormProps
  | SimplifiedExportExecutionFormProps
  | EmptyProps
  | SimplifiedUserFormProps

export interface ConfLimit extends Limit {
  isDefault: boolean
}
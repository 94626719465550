import React, {memo, useCallback, useEffect, useMemo} from 'react'
import useFixValue from "hooks/useFixValue"
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import styled from "styled-components"
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import SortableContainerContext, {getIdFromIndex, getIndexFromID} from 'components/common/sortable/SortableContainerContext'
import SortableOverlay from 'components/common/sortable/SortableOverlay'
import SortableItem from 'components/common/sortable/SortableItem'
import {ChartFormat, ChartTypeWithDisabledReason} from "components/forms/chart/types"
import MetaModel from "classes/MetaModel"
import {GenericChartTypes} from "types/widgets"
import {isEqual} from "lodash"
import {DataSelection} from "redux/models/currentDashboard"
import {getMetricDef} from "components/forms/selector/metrics/utils"
import {buildMetricLabel} from "classes/workflows/query-workflows/genericDataSetUtil"
import {PeriodTypes} from "types/period.types"
import {getMetricLabel} from "components/forms/chart/utils"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {notification} from "antd"
import Language from "language"
import useDispatch from "hooks/useDispatch"
import {useSelector} from "react-redux"
import {getCopiedMetric} from "redux/clipboard.selector"
import {State} from "redux/models/clipboard"
import {useAddSecondaryAxisOption, useIsAxisOptionAllowed, useModifiedSecondaryAxisOption} from "components/forms/selector/metrics/hooks"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"
import MetricUpdate from "components/forms/selector/metrics/MetricUpdate"
import MetricAdd from "components/forms/selector/metrics/MetricAdd"

export interface Props {
  environmentId: number
  value?: MetricSelectorValue[]
  onChange?: (metrics: MetricSelectorValue[]) => void
  groupMetricsByView?: boolean
  viewsWithMetrics: ViewsWithMetrics
  unavailableViews: ViewsWithMetrics
  optionsDisabled?: boolean
  multiple?: boolean
  invertible?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  configuration?: ChartTypeWithDisabledReason
  numberOfSlicer?: number
  metaModel: MetaModel
  displayType?: GenericChartTypes
  format?: ChartFormat | null
  dashboardSelection: DataSelection
  period?: PeriodTypes
}

const MetricSelector = memo<Props>(function MetricSelector({
                                                             environmentId,
                                                             value: originalValue = [],
                                                             onChange,
                                                             viewsWithMetrics,
                                                             unavailableViews,
                                                             groupMetricsByView = false,
                                                             optionsDisabled = false,
                                                             multiple = false,
                                                             invertible = false,
                                                             getPopupContainer,
                                                             configuration,
                                                             numberOfSlicer,
                                                             metaModel,
                                                             displayType,
                                                             format,
                                                             dashboardSelection,
                                                             period,
                                                           }) {
  const dispatch = useDispatch()
  const isAxisOptionAllowed = useIsAxisOptionAllowed(displayType, format)
  const copiedMetric = useSelector(getCopiedMetric())

  // validate the value prop
  const value = useMemo(() => {
      const validValue = originalValue
        .filter((_, index) => multiple || index === 0)
        .filter(m => viewsWithMetrics.find(({code}) => code === m.viewCode)?.metrics.find(({code}) => code === m.metricCode))
      return isEqual(validValue, originalValue) ? originalValue : validValue
    },
    [originalValue, multiple, viewsWithMetrics],
  )
  useFixValue(onChange, originalValue, value)

  useEffect(() => {
    if (value && value.length > 0 && value[0]?.extraConf?.isDisplayedOnSecondaryAxis) {
      onChange?.([{
        ...value[0],
        extraConf: {
          ...value[0].extraConf,
          isDisplayedOnSecondaryAxis: false,
        },
      }, ...value.slice(1)])
    }
  }, [onChange, value])

  const handleDelete = useCallback((i: number) => {
      onChange?.(value.slice(0, i).concat(value.slice(i + 1)))
    },
    [value, onChange],
  )

  const addSecondaryAxisOption = useAddSecondaryAxisOption()
  const modifiedSecondaryAxisOption = useModifiedSecondaryAxisOption()

  const handleChange = useCallback((indexOfModifiedMetric: number, modifiedMetric: SingleMetricSelectorValue) => {
      const metricDef = getMetricDef(metaModel, modifiedMetric)
    const metricAlias = modifiedMetric && metricDef ? buildMetricLabel({...modifiedMetric, metricDef}) : undefined
      const metricLabel = modifiedMetric && viewsWithMetrics && metricAlias ? getMetricLabel(viewsWithMetrics, {
        metricCode: `${modifiedMetric.metricCode}`,
        metricAlias,
        viewCode: modifiedMetric.viewCode,
      }, groupMetricsByView) : undefined
      onChange?.(value.slice(0, indexOfModifiedMetric).concat([{
        ...modifiedMetric,
        ...modifiedSecondaryAxisOption(indexOfModifiedMetric, modifiedMetric, value, metaModel),
        metricAlias: metricLabel ?? '',
        metricDef,
      }], value.slice(indexOfModifiedMetric + 1)))
    }, [groupMetricsByView, metaModel, modifiedSecondaryAxisOption, onChange, value, viewsWithMetrics],
  )

  const handleAdd = useCallback((m: SingleMetricSelectorValue) => {
      const metricDef = getMetricDef(metaModel, m)
      const metricAlias = m && metricDef ? buildMetricLabel({...m, metricDef}) : undefined
      const metricLabel = m && viewsWithMetrics && metricAlias ? getMetricLabel(viewsWithMetrics, {
        metricCode: `${m.metricCode}`,
        metricAlias,
        viewCode: m.viewCode,
      }, groupMetricsByView) : undefined
      onChange?.([...value, {
        ...m,
        ...addSecondaryAxisOption(value.length, m, value, metaModel),
        metricAlias: metricLabel ?? '',
        metricDef,
      }])
    },
    [metaModel, viewsWithMetrics, groupMetricsByView, onChange, value, addSecondaryAxisOption],
  )

  const isAxisOptionAvailable = useMemo(() => value.length > 1 && displayType && isAxisOptionAllowed, [displayType, isAxisOptionAllowed, value.length])

  const onCopy = useCallback((metric: MetricSelectorValue) => {
    notification.info({
      duration: 2.5,
      key: 'copied-metric',
      message: Language.get(`configuration-metric-options.copied-metric`),
      description: Language.get(`configuration-metric-options.copied-metric-description`),
      placement: 'bottomRight',
    })
    dispatch.clipboard.copyMetric({metric} as State)
  }, [dispatch.clipboard])

  return <div>
    <SortableContainerContext
      value={value}
      handleChange={onChange}
      overlayRender={(activeId) => <SortableOverlay activeId={activeId}>
        <MetricUpdate {...{
          environmentId,
          id: activeId,
          value: value[getIndexFromID(activeId)],
          groupMetricsByView,
          viewsWithMetrics,
          unavailableViews,
          optionsDisabled,
          canInvert: invertible,
          canRemove: multiple,
          onChange: handleChange,
          onDelete: handleDelete,
          configuration,
          numberOfSlicer,
          getPopupContainer,
          displayType,
          metaModel,
          dashboardSelection,
          period,
          copiedMetric,
          handleAdd,
          onCopy: () => onCopy(value[getIndexFromID(activeId)]),
        }} />
      </SortableOverlay>}>
      <SortableContext items={value.map((v, index) => ({
        ...v,
        id: getIdFromIndex(index),
      }))} strategy={verticalListSortingStrategy}>
        {value.map((valueItem, index) => (
          <SortableItem id={getIdFromIndex(index)}
                        key={`${valueItem.metricCode}-${valueItem.growth?.period}-${valueItem.growth?.type}-${index}`}>
            <MetricUpdate {...{
              environmentId,
              id: getIdFromIndex(index),
              value: valueItem,
              groupMetricsByView,
              viewsWithMetrics,
              unavailableViews,
              optionsDisabled,
              canInvert: invertible,
              canRemove: multiple,
              onChange: handleChange,
              onDelete: handleDelete,
              handleAdd,
              configuration,
              numberOfSlicer,
              getPopupContainer,
              isAxisOptionAvailable,
              isAxisOptionDisabled: index === 0,
              displayType,
              metaModel,
              dashboardSelection,
              period,
              copiedMetric,
              onCopy: () => onCopy(valueItem),
            }} />
          </SortableItem>
        ))}
      </SortableContext>
    </SortableContainerContext>

    <MetricSelectorContainer>
      {(multiple || value.length === 0) && <MetricAdd {...{
        environmentId,
        id: value.length,
        value: undefined,
        defaultView: value.length > 0 ? value[value.length - 1].viewCode : undefined,
        groupMetricsByView,
        viewsWithMetrics,
        unavailableViews,
        canInvert: invertible,
        canRemove: false,
        handleAdd,
        getPopupContainer,
        displayType,
        metaModel,
        dashboardSelection,
        period,
      }}/>
      }
    </MetricSelectorContainer>
  </div>
})

export default MetricSelector

const MetricSelectorContainer = styled.div`
    display: flex;
`
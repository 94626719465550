/* eslint-disable max-lines */
import {targetQueryWorkflow} from 'classes/workflows/query-workflows/targetQueryWorkflow'
import {aggregatedQueryWorkflow} from 'classes/workflows/query-workflows/aggregatedQueryWorkflow'
import {tablesQueryWorkflow} from 'classes/workflows/query-workflows/tablesQueryWorkflow'
import {captureError} from "services/SentryService"
import InvalidConfError from "classes/InvalidConfError"
import {uniq} from 'lodash'
import MetaModel from "classes/MetaModel"
import {DataSelection} from "redux/models/currentDashboard"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {ChartMetricDtoDetail, SlicerDateDtoDetail, SlicerDtoDetailTypes, SlicerWithDimension} from "types/savedConfs"
import {
  ChartDetailWithoutLayout,
  ChartGenericDetailWithoutLayout,
  ChartTargetDetailWithoutLayout,
  ChartWithoutDetailLayoutTypes,
} from "components/widgetContainer/WidgetContainer"
import {GenericChartTypes} from "types/widgets"
import {
  GenericEffectiveConf,
  Meta,
  MetricDef,
  MetricWithView,
  Serie,
  TargetEffectiveConf,
  TimeIntervalWithSource,
} from "components/charts/line/LineChart.types"
import {ChartQueryParams, getChartQueryParams} from "commons/parsers/queries"
import {NormalizedDashboard} from "schemas/workspace"
import {genericBigQuery, QueryResponseData, QueryResponseDataWithCategory, targetBigQuery} from "services/QueryService"
import axios from "axios"
import {BusinessEx} from "services/api"
import {consolidateFiltersAndAddSource, consolidateSlicers, consolidateTimeInterval} from "commons/parsers/utils"
import {extractSlicerDate, isSlicerDimension} from "commons/configuration"
import {ChartFormat} from "components/forms/chart/types"
import {BoxColumn, Column, Format, OrderBy} from "types/charts"
import {MetricDataTree} from "classes/MetricDataTree"
import {ReactNode, useMemo} from "react"
import {getMetricDef} from "components/forms/selector/metrics/utils"
import {buildMetricLabel} from "classes/workflows/query-workflows/genericDataSetUtil"
import {PeriodTypes} from "types/period.types"
import {
  ConfDimensionFilterTypesWithSource,
  ConfMetricFilterTypesWithSource,
  DimensionFilterDtoDetail,
  DimensionFilterTypesWithSource,
  Source,
} from "components/forms/selector/comps/box/filters"
import {converterFilterToConfModel, convertFilterDetailToConfFilter, convertMetricHavingToConfFilters} from "components/forms/chart/utils"
import {getAvailableDimensions} from "components/forms/chart/useRequirements"


interface QueryWorkflowParams {
  metaModel: MetaModel
  dashboard: Pick<NormalizedDashboard, "id" | "title">
  dashboardSelection: DataSelection
  chart: Omit<ChartDetailWithoutLayout, "id"> & Partial<Pick<ChartDetailWithoutLayout, "id">>
  chartSelectionRaw: ChartSelection
  prevChartData?: WorkflowResultTypes | EmptyObject
  queryId: string
}

export type EmptyObject = Record<string, never>

export const queryWorkflow: (params: QueryWorkflowParams, onLoad: () => any) => Promise<WorkflowResultTypes | EmptyObject> = async ({
                                                                                                                                      metaModel,
                                                                                                                                      dashboard,
                                                                                                                                      dashboardSelection,
                                                                                                                                      chart,
                                                                                                                                      chartSelectionRaw,
                                                                                                                                      prevChartData,
                                                                                                                                      queryId,
                                                                                                                                    }, onLoad) => {
  const workflow = getWorkflow(chart)
  if (workflow) {
    onLoad()
    const result = await query(workflow, {
      metaModel,
      dashboard,
      dashboardSelection,
      chart,
      chartSelectionRaw,
      prevChartData,
      queryId,
    })
    return {chartSelection: chartSelectionRaw, ...result}
  } else {
    return {}
  }
}

interface BasicWorkflow {
  type: "table" | "target" | "generic"
}

interface WorkflowResult {
  type: "table" | "target" | "generic"
  message?: {
    type?: "info" | "success" | "error" | "warning"
    extra: string | ReactNode
    message: string
  }
  chartSelection?: ChartSelection
}

export interface TableQueryWorkflowResult extends WorkflowResult {
  type: "table"
  totalData: QueryResponseDataWithCategory
  loadedCache: LoadedCache
  data: QueryResponseDataWithCategory[]
  meta: Meta
  columns: Column[]
  chartSelection: ChartSelection
  lineCount: number
}

export interface BoxQueryWorkflowResult extends WorkflowResult {
  type: "table"
  totalData: QueryResponseDataWithCategory
  loadedCache: LoadedCache
  data: QueryResponseDataWithCategory[]
  meta: Meta
  columns: BoxColumn[]
  chartSelection: ChartSelection
  lineCount: number
}

export type TableWorkflowFunction = (qc: ChartTableQueryContext) => Promise<TableQueryWorkflowResult>

export interface TableWorkflow extends BasicWorkflow {
  type: "table"
  workflow: TableWorkflowFunction
}

export interface TargetSerie {
  format: Format
  label: string
  values: number[]
}

export interface TargetQueryWorkflowResult extends WorkflowResult {
  type: "target"
  meta: {
    effectiveConf: TargetEffectiveConf
    format: Format
  }
  series: TargetSerie[]
  noTargetConfigured: boolean
}

export type TargetWorkflowFunction = (qc: ChartTargetQueryContext) => Promise<TargetQueryWorkflowResult>

export interface TargetWorkflow extends BasicWorkflow {
  type: "target"
  workflow: TargetWorkflowFunction
}

export interface GenericWorkflowResult extends WorkflowResult {
  type: "generic"
  loadedCache: { dashboardSelection: DataSelection }
  chartSelection: ChartSelection
  parsedData: MetricDataTree[]
  meta: Meta
  series: Serie[]
  expectedDates: string[]
}

export type GenericWorkflowFunction = (qc: ChartGenericQueryContext) => Promise<Partial<GenericWorkflowResult>>

export interface GenericWorkflow extends BasicWorkflow {
  type: "generic"
  workflow: GenericWorkflowFunction
}

export type WorkflowTypes = TableWorkflow | TargetWorkflow | GenericWorkflow


export type WorkflowResultTypes = TableQueryWorkflowResult | TargetQueryWorkflowResult | GenericWorkflowResult

class HoratioEx {
  horatioCause: string

  constructor(horatioCause: string) {
    this.horatioCause = horatioCause
  }
}

async function query(workflow: WorkflowTypes, {
  metaModel,
  dashboard,
  dashboardSelection,
  chart,
  chartSelectionRaw,
  prevChartData,
  queryId,
}: {
  metaModel: MetaModel
  dashboard: Pick<NormalizedDashboard, "id" | "title">
  dashboardSelection: DataSelection
  chartSelectionRaw: ChartSelection
  prevChartData?: WorkflowResultTypes | EmptyObject
  chart: any,
  queryId: string
}) {
  try {
    const effectiveConf = computeGenericEffectiveConf(metaModel, dashboardSelection, chartSelectionRaw, chart)
    try {
      if (!effectiveConf) {
        throw new InvalidConfError('EffectiveConf is undefined')
      }
      switch (workflow?.type) {
        case "table":
          return await workflow.workflow(new ChartTableQueryContext(metaModel, dashboard, dashboardSelection, chart, chartSelectionRaw, prevChartData as TableQueryWorkflowResult, effectiveConf, queryId))
        case "generic":
          return await workflow.workflow(new ChartGenericQueryContext(metaModel, dashboard, dashboardSelection, chart, chartSelectionRaw, prevChartData as GenericWorkflowResult, effectiveConf, queryId))
        case "target":
          return await workflow.workflow(new ChartTargetQueryContext(metaModel, dashboard, dashboardSelection, chart, chartSelectionRaw, prevChartData as TargetQueryWorkflowResult, effectiveConf))
        default: {
          // eslint-disable-next-line no-case-declarations
          const exhaustiveCheck: never = workflow
          return exhaustiveCheck
        }
      }
    } catch (error) {
      if (error instanceof InvalidConfError) {
        throw error
      } else if (axios.isAxiosError(error) && error.code === 'ECONNABORTED') {
        return undefined
      }
      if (error instanceof BusinessEx) {
        const {type} = error
        if (type === 'urn:problem-type:chartNotWellConfigured') {
          throw new InvalidConfError(error.detail ?? '')
        } else if (type === 'urn:problem-type:resolveHoratioBadRequest') {
          return queryError(chart, dashboardSelection, {horatioCause: error.detail_horatioCause ?? ''})
        } else if (type === 'urn:problem-type:resolveMemoryExceed') {
          return {
            message: {
              type: 'warning',
              message: 'chart-message-query-memory-exceed',
            },
          }
        } else if (type === 'urn:problem-type:unexpected') {
          return queryError(chart, dashboardSelection, error)
        } else if (type === 'urn:problem-type:resolveHoratioIncompleteResponse') {
          return {
            message: {
              type: 'warning',
              message: 'chart-message-concurrent-query',
            },
          }
        } else if (type === 'urn:problem-type:resolveHoratioTimeoutResponse') {
          return {
            message: {
              type: 'error',
              message: 'chart-message-query-too-complex',
            },
          }
        } else if (error instanceof Error && error.message === 'Network Error') {
          return queryError(chart, dashboardSelection, 'Network Error')
        } else {
          return queryError(chart, dashboardSelection, error, 'Big Query Error')
        }
      }
      return undefined
    }
  } catch (error) {
    if (error instanceof InvalidConfError) {
      return {
        message: {
          type: 'warning',
          message: 'chart-message-invalid-conf',
          extra: error.message,
        },
      }
    } else if (typeof error === "string" || error instanceof Error || error instanceof BusinessEx) {
      return queryError(chart, dashboardSelection, error, 'Big Query Error : getQueryParameters')
    }
    return undefined
  }
}

export interface Pagination {
  pageSize: number;
  current: number;
}

export interface ChartSelection {
  asPercentage?: boolean
  ignoreMetrics0?: boolean
  sorters?: OrderBy[]
  withDateSlicer?: boolean
  sortSeries?: boolean
  pagination?: Pagination
  search?: string
  displayLabels?: boolean
  format?: ChartFormat | null
  withChartOverriddenPeriod?: boolean
}

export interface LoadedCache {
  dashboardSelection: DataSelection
  pageOffset?: number
  data: QueryResponseDataWithCategory[]
}

export interface ChartMetricWithMetricDef extends ChartMetricDtoDetail {
  metricDef: MetricDef
}

export interface ChartMetricWithMetricLabel extends ChartMetricWithMetricDef {
  metricAlias: string
}

class QueryContext {
  metaModel: MetaModel
  dashboard: Pick<NormalizedDashboard, "id" | "title">
  dashboardSelection: DataSelection
  chartSelectionRaw: ChartSelection
  effectiveConf: GenericEffectiveConf

  constructor(metaModel: MetaModel, dashboard: Pick<NormalizedDashboard, "id" | "title">, dashboardSelection: DataSelection, chartSelectionRaw: ChartSelection, effectiveConf: GenericEffectiveConf) {
    this.metaModel = metaModel
    this.dashboard = dashboard
    this.dashboardSelection = dashboardSelection
    this.chartSelectionRaw = chartSelectionRaw
    this.effectiveConf = effectiveConf
  }
}

export class ChartGenericQueryContext extends QueryContext {
  chart: ChartGenericDetailWithoutLayout
  queryId: string
  prevChartData?: GenericWorkflowResult

  constructor(metaModel: MetaModel, dashboard: Pick<NormalizedDashboard, "id" | "title">, dashboardSelection: DataSelection, chart: ChartGenericDetailWithoutLayout, chartSelectionRaw: ChartSelection, prevChartData: GenericWorkflowResult | undefined, effectiveConf: GenericEffectiveConf, queryId: string) {
    super(metaModel, dashboard, dashboardSelection, chartSelectionRaw, effectiveConf)
    this.chart = chart
    this.queryId = queryId
    this.prevChartData = prevChartData
  }

  getChartQueryParams(chartSelection = this.chartSelectionRaw) {
    return getChartQueryParams(this.dashboardSelection, chartSelection, this.chart)
  }

  query<T extends QueryResponseData>(chartQueryParam?: ChartQueryParams, queryIdSuffix?: string): Promise<T[]> {
    const consolidatedQueryId = `${this.queryId}${queryIdSuffix ? `-${queryIdSuffix}` : ''}`
    return genericBigQuery<T>(this.chart, this.dashboard.id, chartQueryParam || this.getChartQueryParams(), consolidatedQueryId)
  }

  async prepareQuery<T extends QueryResponseData>(promisedQuery: Promise<T[]>): Promise<{ effectiveConf: GenericEffectiveConf; data: T[] }> {
    const data = await promisedQuery
    return Object.freeze({data, effectiveConf: this.effectiveConf})
  }
}

export class ChartTableQueryContext extends QueryContext {
  chart: ChartGenericDetailWithoutLayout
  queryId: string
  prevChartData?: TableQueryWorkflowResult

  constructor(metaModel: MetaModel, dashboard: Pick<NormalizedDashboard, "id" | "title">, dashboardSelection: DataSelection, chart: ChartGenericDetailWithoutLayout, chartSelectionRaw: ChartSelection, prevChartData: TableQueryWorkflowResult | undefined, effectiveConf: GenericEffectiveConf, queryId: string) {
    super(metaModel, dashboard, dashboardSelection, chartSelectionRaw, effectiveConf)
    this.chart = chart
    this.queryId = queryId
    this.prevChartData = prevChartData
  }

  getChartQueryParams(chartSelection = this.chartSelectionRaw) {
    return getChartQueryParams(this.dashboardSelection, chartSelection, this.chart)
  }

  query<T extends QueryResponseData>(chartQueryParam?: ChartQueryParams, queryIdSuffix?: string): Promise<T[]> {
    const consolidatedQueryId = `${this.queryId}${queryIdSuffix ? `-${queryIdSuffix}` : ''}`
    return genericBigQuery<T>(this.chart, this.dashboard.id, chartQueryParam || this.getChartQueryParams(), consolidatedQueryId)
  }

  async prepareQuery<T extends QueryResponseData>(promisedQuery: Promise<T[]>): Promise<{ effectiveConf: GenericEffectiveConf; data: T[] }> {
    const data = await promisedQuery
    return Object.freeze({data, effectiveConf: this.effectiveConf})
  }
}

export class ChartTargetQueryContext extends QueryContext {
  chart: ChartTargetDetailWithoutLayout
  prevChartData?: TargetQueryWorkflowResult

  constructor(metaModel: MetaModel, dashboard: Pick<NormalizedDashboard, "id" | "title">, dashboardSelection: DataSelection, chart: ChartTargetDetailWithoutLayout, chartSelectionRaw: ChartSelection, prevChartData: TargetQueryWorkflowResult | undefined, effectiveConf: GenericEffectiveConf) {
    super(metaModel, dashboard, dashboardSelection, chartSelectionRaw, effectiveConf)
    this.chart = chart
    this.prevChartData = prevChartData
  }

  getChartQueryParams(chartSelection = this.chartSelectionRaw) {
    return getChartQueryParams(this.dashboardSelection, chartSelection, this.chart)
  }

  query<T extends QueryResponseData>(chartQueryParam?: ChartQueryParams): Promise<T[]> {
    return targetBigQuery<T>(this.chart, chartQueryParam || this.getChartQueryParams())
  }

  async prepareQuery<T extends QueryResponseData>(promisedQuery: Promise<T[]>): Promise<T[]> {
    return promisedQuery
  }
}

function getWorkflow(chart: Omit<ChartDetailWithoutLayout, "id"> & { id?: number }): (WorkflowTypes | undefined) {
  switch (chart.type) {
    case WidgetTypes.DIVIDER:
      return undefined
    case WidgetTypes.GENERIC:
      switch (chart.extraConf.displayType) {
        case GenericChartTypes.AREA:
          return {
            type: "generic",
            workflow: aggregatedQueryWorkflow,
          }
        case GenericChartTypes.LINE:
          return {
            type: "generic",
            workflow: aggregatedQueryWorkflow,
          }
        case GenericChartTypes.BOXES:
          return {
            type: "table",
            workflow: tablesQueryWorkflow,
          }
        case GenericChartTypes.BARS:
          return {
            type: "generic",
            workflow: aggregatedQueryWorkflow,
          }
        case GenericChartTypes.TABLES:
          return {
            type: "table",
            workflow: tablesQueryWorkflow,
          }
        case GenericChartTypes.PIE:
          return {
            type: "generic",
            workflow: aggregatedQueryWorkflow,
          }
        case undefined:
          return undefined
        default:
          // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = chart.extraConf.displayType
          return undefined
      }
    case WidgetTypes.TARGET:
      return {
        type: "target",
        workflow: targetQueryWorkflow,
      }
    case WidgetTypes.EXPORT:
      return {
        type: "table",
        workflow: tablesQueryWorkflow,
      }
    case WidgetTypes.UNKNOWN:
      return undefined
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = chart.type
      return undefined
  }
}

const getMetricsWithPartialDef = (metaModel: MetaModel, dashboardSelection: DataSelection, chart: ChartWithoutDetailLayoutTypes): (ChartMetricDtoDetail & {
  metricDef?: MetricDef
})[] => {
  switch (chart.type) {
    case WidgetTypes.GENERIC: {
      return chart.metrics
        .map(chartMetric => {
          const metricDef = getMetricDef(metaModel, chartMetric)
          return {
            ...chartMetric,
            metricAlias: metricDef ? buildMetricLabel({
              ...chartMetric,
              metricDef,
              additionalFilters: converterFilterToConfModel(chartMetric.additionalFilters, getAvailableDimensions(metaModel, chart.metrics.map(m => m.viewCode))),
              titlePartOverrides: chartMetric.titlePartOverrides,
            }) : '',
            metricDef,
          }
        })
    }
    case WidgetTypes.TARGET:
      if (chart.viewCode) {
        const metricDef = getMetricDef(metaModel, chart)
        return [
          {
            viewCode: chart.viewCode,
            viewAlias: metaModel.views[chart.viewCode]?.alias,
            metricCode: chart.metricCode,
            metricAlias: metricDef ? buildMetricLabel({
              titlePartOverrides: {},
              additionalFilters: [],
              metricDef,
            }) : '',
            metricDef,
            extraConf: {},
            additionalFilters: [],
            titlePartOverrides: {},
          },
        ]
      } else {
        return []
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = chart
      return exhaustiveCheck
  }
}

const queryError = (chart: ChartDetailWithoutLayout, dashboardSelection: DataSelection, cause: Error | string | BusinessEx | HoratioEx, reportMessage?: string) => {
  if (reportMessage) {
    // eslint-disable-next-line no-console
    console.error(reportMessage, cause)
    captureError(reportMessage, queryErrorReport(chart, dashboardSelection), cause instanceof Error ? cause : JSON.stringify(cause))
  }
  return {
    message: {
      type: 'error',
      message: 'chart-message-query-error',
      extra: cause,
    },
  }
}
const queryErrorReport = (chart: ChartDetailWithoutLayout, dashboardSelection: DataSelection) => ({
  chartId: chart.id,
  chartType: chart.type,
  dashboardSelection,
})

export const computeGenericEffectiveConf = (metaModel: MetaModel, dashboardSelection: DataSelection, chartSelection: ChartSelection, chart: ChartWithoutDetailLayoutTypes): GenericEffectiveConf | undefined => {
  const metrics = getMetricsWithPartialDef(metaModel, dashboardSelection, chart)
  switch (chart.type) {
    case WidgetTypes.GENERIC:
      return buildEffectiveConf(
        metaModel,
        consolidateTimeInterval({dashboardSelection, chartPeriod: chart.period}, chartSelection.withChartOverriddenPeriod),
        metrics,
        consolidateSlicers(
          chart.slicers,
          dashboardSelection,
          chartSelection.withDateSlicer === undefined ? Boolean(extractSlicerDate(chart.slicers)) : Boolean(chartSelection.withDateSlicer),
          chartSelection.withChartOverriddenPeriod,
        ).map(slicer => ({...slicer, isDefault: false})),
        consolidateFiltersAndAddSource(dashboardSelection, chart.filters),
        convertMetricHavingToConfFilters(metrics).map(filter => ({
          ...filter,
          source: Source.CHART,
        })),
        chart.orderBys,
      )
    case WidgetTypes.TARGET:
      return buildEffectiveConf(
        metaModel,
        consolidateTimeInterval({dashboardSelection, chartPeriod: chart.period}, true),
        metrics,
        [],
        [],
        convertMetricHavingToConfFilters(metrics).map(filter => ({
          ...filter,
          source: Source.CHART,
        })),
        [],
      )
    default:
      return undefined
  }
}

export const useExportEffectiveChartConf = (metaModel: MetaModel, period: PeriodTypes, metrics: ChartMetricDtoDetail[], slicers: SlicerDtoDetailTypes[], filters: DimensionFilterDtoDetail[], orderBys: OrderBy[]): GenericEffectiveConf => useMemo(() =>
    buildEffectiveConf(metaModel,
      consolidateTimeInterval({chartPeriod: period}, true),
      metrics
        .map(chartMetric => {
          const metricDef = getMetricDef(metaModel, chartMetric)
          return {
            ...chartMetric,
            metricAlias: metricDef ? buildMetricLabel({
              ...chartMetric,
              metricDef,
              additionalFilters: converterFilterToConfModel(chartMetric.additionalFilters, getAvailableDimensions(metaModel, metrics.map(m => m.viewCode))),
              titlePartOverrides: chartMetric.titlePartOverrides,
            }) : '',
            metricDef,
          }
        }),
      slicers,
      filters.map(f => ({
        ...f,
        source: Source.CHART,
      })),
      convertMetricHavingToConfFilters(metrics).map(filter => ({
        ...filter,
        source: Source.CHART,
      })),
      orderBys)
  , [filters, metaModel, metrics, orderBys, period, slicers])

type PartialMetricWithView =
  Omit<MetricWithView, "view" | "viewAlias" | "viewCode" | "metricDef" | "metricAlias">
  & Partial<Pick<MetricWithView, "view" | "viewAlias" | "viewCode" | "metricDef">>
type PartialSlicerWithDimension = Omit<SlicerWithDimension, "dimension"> & Partial<Pick<SlicerWithDimension, "dimension">>
type PartialSlicer = PartialSlicerWithDimension | SlicerDateDtoDetail
type PartialConfDimensionFilter =
  Omit<ConfDimensionFilterTypesWithSource, "reference">
  & Partial<Pick<ConfDimensionFilterTypesWithSource, "reference">>
type PartialConfMetricFilter =
  Omit<ConfMetricFilterTypesWithSource, "reference">
  & Partial<Pick<ConfMetricFilterTypesWithSource, "reference">>
type PartialEffectiveConf = Omit<GenericEffectiveConf, "metrics" | "slicers" | "filters"> & {
  metrics: PartialMetricWithView[],
  slicers: PartialSlicer[],
  filters: PartialConfDimensionFilter[]
  metricFilters: PartialConfMetricFilter[]
}


export const buildEffectiveConf = (
  metaModel: MetaModel,
  timeInterval: TimeIntervalWithSource,
  metrics: (Omit<ChartMetricDtoDetail, "metricAlias"> & { metricDef?: MetricDef })[],
  slicers: SlicerDtoDetailTypes[],
  filters: DimensionFilterTypesWithSource[],
  metricFilters: ConfMetricFilterTypesWithSource[],
  orderBys: OrderBy[]): GenericEffectiveConf => {
  const consolidatedMetrics = metrics.map(m => {
    const view = metaModel.getView(m.viewCode)
    return {
      ...m,
      view,
      viewAlias: view?.alias,
      viewCode: view?.code,
      metricDef: getMetricDef(metaModel, m),
    }
  })
  const effectiveConf: PartialEffectiveConf = {
    type: "generic",
    timeInterval,
    metrics: consolidatedMetrics.map(metric => ({
      ...metric,
      additionalFilters: convertFilterDetailToConfFilter(metric.additionalFilters, metaModel.listDimensions()),
    })),
    slicers: slicers.map(slicer => {
      switch (slicer.type) {
        case "dimension":
          return {...slicer, dimension: metaModel.getDimension(slicer.dimensionCode)}
        case "date":
          return slicer
        default:
          // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = slicer
          return exhaustiveCheck
      }
    }),
    filters: convertFilterDetailToConfFilter<DimensionFilterTypesWithSource, ConfDimensionFilterTypesWithSource>(filters, metaModel.listDimensions())
      .sort((a, b) => Number(a.dimensionCode) - Number(b.dimensionCode)),
    metricFilters,
    orderBys,
  }

  const erroneousSlicers = effectiveConf.slicers.filter(isSlicerDimension).filter(({dimension}) => !dimension)
  if (erroneousSlicers.length > 0) {
    throw new InvalidConfError("slicers", uniq(erroneousSlicers.map(({dimensionCode}: { dimensionCode: string }) => dimensionCode)))
  }
  const erroneousFilters = effectiveConf.filters.filter(({reference}) => !reference?.code)
  if (erroneousFilters.length > 0) {
    throw new InvalidConfError("filters", uniq(erroneousFilters.map(({dimensionCode}: { dimensionCode: string }) => dimensionCode)))
  }
  const erroneousMetrics = effectiveConf.metrics.filter(({metricDef, view}) => !metricDef || !view)
  if (erroneousMetrics.length > 0) {
    throw new InvalidConfError('metrics', erroneousMetrics.map(({metricCode}) => metricCode))
  }

  return effectiveConf as GenericEffectiveConf
}

export enum PeriodType {
  dynamic = "dynamic",
  fixed = "fixed"
}

export enum Category {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
  OTHER = "OTHER",
}

export enum Granularity {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY'
}

interface Period {
  type: PeriodType.dynamic | PeriodType.fixed
  category: Category
  code?: string
  nameFR: string
  nameEN: string
  id: number
  position: number
}

export interface PeriodDynamic extends Period {
  type: PeriodType.dynamic
  start: PeriodEdge
  end: PeriodEdge
  canBeDeleted: boolean
}

export interface PeriodFixed extends Period {
  type: PeriodType.fixed
  start: string
  end: string
}

export type PeriodTypes = PeriodDynamic | PeriodFixed
export type PeriodSummary = Pick<Period, "code">

export enum PeriodEdgePoint {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export interface PeriodEdge {
  value: PeriodEdgePoint
  offset?: PeriodOffset
}

export enum EnumGranularity {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR"
}

export interface PeriodOffset {
  granularity: EnumGranularity
  quantity: number
}
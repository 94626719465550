import React, {FunctionComponent, PropsWithChildren} from "react"
import styled from "styled-components"
import Language from "language"

interface Props {
  titleKey?: string
  size?: number
  margin?: number
  containerSize?: number
  clickable?: boolean
  color?: string
  hoverColor?: string
  onClick?: () => void
  disable?: boolean
}

export const IconContainer: FunctionComponent<PropsWithChildren<Props>> = ({
                                                                             size = 19,
                                                                             margin = 0,
                                                                             containerSize,
                                                                             clickable = false,
                                                                             color,
                                                                             hoverColor,
                                                                             onClick,
                                                                             children,
                                                                             disable,
                                                                             titleKey,
                                                                           }) =>
  <StyledIconContainer onClick={onClick} $containersize={containerSize} title={titleKey ? Language.get(titleKey) : undefined}>
    <StyledIcon $margin={margin} $size={size} $color={color} $clickable={clickable ? 1 : 0} $disable={disable} $hovercolor={hoverColor}>
      {children}
    </StyledIcon>
  </StyledIconContainer>

const StyledIcon = styled.div<{
  $size: number,
  $margin: number,
  $color?: string,
  $clickable: number,
  $hovercolor?: string,
  $disable?: boolean
}>`
  display: flex;
  max-width: fit-content;
    width: ${props => `${props.$size}px`};
    min-width: ${props => `${props.$size}px`};
    height: ${props => `${props.$size}px`};
    min-height: ${props => `${props.$size}px`};
    margin-right: ${props => props.$margin}px;
    line-height: ${props => `${props.$size}px`};

    ${props => props.$color ? `color: ${props.$color};` : ''}
  
  &&& svg {
        width: ${props => `${props.$size}px`};
        min-width: ${props => `${props.$size}px`};
        height: ${props => `${props.$size}px`};
        min-height: ${props => `${props.$size}px`};
  }

    ${props => props.$clickable ? `
      cursor: pointer;
      
      &:hover {
         color: ${props.$hovercolor ? `${props.$hovercolor}` : 'var(--primary)'};
      }
      ` : ''}

    ${({$disable}) => $disable ? 'cursor: not-allowed;' : ''}
`

const StyledIconContainer = styled.div<{ $containersize?: number }>`
  &&& {
    height: 100%;
    ${({$containersize}) => $containersize ? `width: ${$containersize}px;height: ${$containersize}px;` : ''}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
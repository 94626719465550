import {createModel} from "@rematch/core"
import RootModel from "redux/models/index"
import {StringIndex} from "redux/models/workspace"
import {ChartDtoDetail} from "types/charts"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"

const bc: any = typeof BroadcastChannel === 'undefined'
  ? {
    onmessage: null,
    postMessage(data: any) {
      if (this.onmessage) {
        this.onmessage({data})
      }
    },
  }
  : new BroadcastChannel('clipboard')

export interface Clipboard {
  chart?: ChartDtoDetail
  metric?: MetricSelectorValue
}

export type State = StringIndex<Clipboard>

const initialState: State = {}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    copy(state: State, copied: State) {
      return {
        ...state,
        ...copied,
      }
    },
  },
  effects: (dispatch) => {
    bc.onmessage = (ev: any) => {
      dispatch.clipboard.copy(ev.data)
    }
    const copyAndBroadcast = (copied: State) => {
      dispatch.clipboard.copy(copied)
      bc.postMessage(copied)
    }
    return {
      copyChart({chart}: State) {
        copyAndBroadcast({chart})
      },
      copyMetric({metric}: State) {
        copyAndBroadcast({metric})
      },
    }
  },
})

import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react'
import {Popconfirm as AntPopconfirm} from 'antd'
import Language from "language"
import {PopconfirmProps} from "antd/lib/popconfirm"

interface Props extends PropsWithChildren, PopconfirmProps {
  title: string | ReactNode
  onConfirm: (e?: React.MouseEvent<HTMLElement>) => void
}

const Popconfirm: FunctionComponent<Props> = ({children, ...props}) =>
  <AntPopconfirm {...{
    okText: Language.get('common.popconfirm.okText'),
    cancelText: Language.get('common.popconfirm.cancelText'),
    okButtonProps: {
      danger: true,
    },
    icon: <div></div>,
    ...props,
  }}>
    {children}
  </AntPopconfirm>

export default Popconfirm

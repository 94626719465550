import {FormError} from "components/forms/Form.Modal"
import {useMemo} from "react"

interface FieldData {
  name: [string],
  errors: string[],
  value: any,
}

export const useFields = <T>(data: T, errors: FormError[]): FieldData[] => useMemo(() => {
  if (data && typeof data === 'object') {
    return Object.assign([], Object.entries(data)).map(([key, value]: [key: string, value: any]) => {
      const errorMessages = errors ? errors.filter(err => err.field === key).map(error => error.message) : []
      return {
        name: [key],
        errors: errorMessages,
        value,
      }
    })
  }
  return []
}, [data, errors])
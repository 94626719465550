import React from "react"
import Language, {Formatter} from "../../language"
import {Format} from "types/charts"
import dayjs from "dayjs"
import {MetricGrowthType} from 'types/savedConfs'
import {Units} from "components/forms/selector/metrics/options/selectors/MetricDisplayOptionSelector"

export const percentageFormat: Format = Object.freeze({
  prefix: "",
  suffix: "%",
  nDecimal: 2,
  growth: false,
  asRatio: true,
})
export const textFormat: Format = Object.freeze({
  prefix: '',
  suffix: '',
  nDecimal: 0,
  growth: false,
  asRatio: false,
})

/**
 * format the value to a string|ReactNode
 * @param {string|number} value: value to be printed
 * @param {object} format: {prefix, suffix, nDecimal, growth} object that describe
 * the way the value has to be formatted
 * @return {string|ReactNode} value to print
 */
export const formatValue = (value: string | number, format: Format & { summarizeValue?: boolean }) => {
  if (typeof value === 'string') {
    // should occurs only with table/box data
    if (value === 'Infinity') {
      return '+inf'
    } else if (value === '-Infinity') {
      return '-inf'
    } else {
      return value
    }
  } else {
    if (value === Infinity) {
      return '+inf'
    } else if (value === -Infinity) {
      return '-inf'
    } else {
      const {prefix, nDecimal, growth, type} = format
      const plusSign = value >= 0 && growth && type !== MetricGrowthType.VALUE ? '+' : ''
      const suffix = (format.suffix && format.suffix.trim()) || ''

      let summarizedSuffix = ''
      if (format.summarizeValue || format.unit) {
        switch (format.unit) {
          case Units.M:
            summarizedSuffix = 'M'
            value /= 1000000
            break
          case Units.K:
            summarizedSuffix = 'k'
            value /= 1000
            break
          default: {
            if (Math.abs(value) > 999 && Math.abs(value) < 1000000) {
              summarizedSuffix = 'k'
              value /= 1000
            } else if (Math.abs(value) >= 1000000) {
              summarizedSuffix = 'M'
              value /= 1000000
            }
          }
        }
      }

      // if suffix is %, we multiply the value by 100%
      const valueFactor = suffix === '%' ? 100 : 1
      const spaceBeforeSuffix = (suffix || summarizedSuffix) ? ' ' : ''
      const metricPrecision = getMetricPrecision(nDecimal, value * valueFactor, format.summarizeValue)
      return `${prefix}${plusSign}${Language.getFormatter().format(`,${metricPrecision}f`)(value * valueFactor)}${spaceBeforeSuffix}${summarizedSuffix}${suffix}`
    }
  }
}

function getMetricPrecision(nDecimal: number, value: number, summarizedSuffix?: boolean) {
  // If a summarized suffix exists it means we did divide base value

  if (summarizedSuffix) {
    nDecimal = value % 1 === 0 ? 0 : 1 // we want to show 1 decimal if not a round number
  }

  return `.${nDecimal}`
}

export const formatValueForRatio = (value: number, format: Format, total: number) => {
  const {suffix, growth, type} = format
  const isWrongFormat = suffix.includes('%') || format.asRatio || (growth && type !== MetricGrowthType.VALUE)
  if (total === 0) {
    return undefined
  } else if (isWrongFormat) {
    return undefined
  } else if (!isFinite(value) || !isFinite(total)) {
    return undefined
  } else {
    return formatValue(value / total, percentageFormat)
  }
}

export const formatAsText = (text: string, asAlt: boolean) => {
  const imageSrc = extractAsImageSrc(text)
  if (imageSrc) {
    return <img src={imageSrc} alt="" style={asAlt ? undefined : {
      maxHeight: 100,
      maxWidth: 150,
    }}/>
  } else {
    return text
  }
}

export const extractAsImageSrc = (text: string) => {
  const matchImage = text.match(/<img src="(.*)">/)
  return matchImage && matchImage[1]
}

export const formatForAxis = (format: Format, localeFormatter: Formatter) => (d: number) => {
  const {prefix, suffix} = format
  // if suffix is %, we multiply the value by 100%
  let mult = 1
  let formatString = '.2s'
  if (suffix === '%') {
    mult = 100
    formatString = '.0f'
  }
  return `${prefix}${localeFormatter.format(formatString)(d * mult)}${suffix}`
}

// Simulate time axis behaviour by applying different format base on granularity
export function formatAxisDate(value: string) {
  const date = dayjs(value)
  return date.format('DD MMM\nYYYY')
}

import React, {FunctionComponent, useCallback} from 'react'
import {Layout, Switch} from 'antd'
import DashboardDateSelector from "components/forms/selector/dashboardDate/DashboardDateSelector"
import styled from "styled-components"
import {DataSelection, DateInterval} from "redux/models/currentDashboard"
import Language from "language"
import {useSelector} from "react-redux"
import {getDashboardEditMode} from "redux/appEnvironment.selector"
import useMediaDesktop from "hooks/useMediaDesktop"

interface Props {
  onDashboardSelectionChange?: (selection: DataSelection) => void,
  onEditModeChange?: (state: boolean) => void
  dashboardSelection: DataSelection,
  editMode?: boolean,
  canEdit?: boolean,
}

const MainLayoutHeader: FunctionComponent<Props> = ({
                                                      onDashboardSelectionChange,
                                                      dashboardSelection,
                                                      onEditModeChange,
                                                      canEdit,
                                                    }) => {
  const editMode = useSelector(getDashboardEditMode)
  const isMediaDesktop = useMediaDesktop()

  const handleDashboardCalendarChange = useCallback(
    (date: Omit<DateInterval, 'name'>) => onDashboardSelectionChange?.({
      ...dashboardSelection as DataSelection, // We consider dashboardSelection is filled here
      date,
    }),
    [dashboardSelection, onDashboardSelectionChange],
  )

  return <StyledHeader>
    {dashboardSelection.date && <div>
      <DashboardDateSelector value={dashboardSelection.date} onChange={handleDashboardCalendarChange}/>
    </div>}
    {canEdit && (
      <EditMode $editmode={editMode ? 1 : 0} $enabled={isMediaDesktop ? 1 : 0} onClick={() => {
        onEditModeChange?.(!editMode)
      }}>
        <Switch {...{
          checked: editMode,
        }}/>
        <SwitchState>
          {editMode ? Language.get('dashboard-edit-mode') : Language.get('dashboard-edit-mode')}
        </SwitchState>
      </EditMode>
    )}
  </StyledHeader>
}

export default MainLayoutHeader

const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const SwitchState = styled.div`
  margin-left: 8px;
`

const EditMode = styled.div<{ $editmode: number, $enabled: number }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
    cursor: pointer;
  
  &:hover {
    color: var(--primary);

      ${({$editmode}) => $editmode ? '' : `
      .ant-switch{
          background-image: linear-gradient(to right, var(--light-grey), var(--light-grey)), linear-gradient(to right, #fff, #fff);
      }
    `}
  }

    ${({$enabled}) => $enabled ? '' : `
    display: none;
  `}
}
`

import API from './api'
import {Dashboard, RawDashboard, RawExpandedWorkspace, SummarizedWorkspace} from "redux/models/workspace"
import {NormalizedDashboard} from "schemas/workspace"
import {DashboardDto, MenuDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {PeriodSummary} from "types/period.types"

export const getWorkspaces = () =>
  API.instanceData.get<void, SummarizedWorkspace[]>(`/workspace/authorized`)
export const getWorkspace = async (workspaceId: number) => API.instanceData.get<void, RawExpandedWorkspace>(`/workspace/${workspaceId}`)

export const addMenu = ({positionMenu, ...data}: MenuDto, workspaceId: number) =>
  API.instanceData.post(`/menu?workspaceId=${workspaceId}&position=${positionMenu}`, data)
export const editMenu = ({positionMenu, workspaceId, ...data}: MenuDto & { workspaceId: number }, menuId: number | string) =>
  API.instanceData.put(`/menu/${menuId}?position=${positionMenu}&workspaceId=${workspaceId}`, data)
export const deleteMenu = (menuId: string | number) =>
  API.instanceData.delete(`/menu/${menuId}`)

export const addDashboard = ({positionDashboard, ...data}: DashboardDto, menuId: number) =>
  API.instanceData.post<void, RawDashboard>(`/dashboard?menuId=${menuId}&position=${positionDashboard}`, data)

export const updateDashboardPeriodAndFilters = (
  dashboardId: number,
  dashboard: Pick<NormalizedDashboard, 'filters'> & {
    period: PeriodSummary
  }) => API.instanceData.put<void, RawDashboard>(`/dashboard/${dashboardId}`, dashboard)

export const updateDashboardTitleAndPosition = (
  dashboardId: number,
  dashboard: Omit<Dashboard, 'relativeId' | 'charts' | 'slicers' | 'filters'>,
  uriParams: {
    menuId?: number,
    newMenuId: number,
    position: number
  }) => API.instanceData.put<void, RawDashboard>(`/dashboard/${dashboardId}`,
  dashboard,
  {params: uriParams},
)

export const cloneDashboard = ({parentMenu, title, positionDashboard, originDashboard}: DashboardDto & { originDashboard: number }) =>
  API.instanceData.post(`/dashboard/clone?menuId=${parentMenu}&newTitle=${title}&position=${positionDashboard}&refId=${originDashboard}`)
export const deleteDashboard = ({dashboardId, menuId}: { dashboardId: number, menuId: number }) =>
  API.instanceData.delete(`/dashboard/${dashboardId}?menuId=${menuId}`)



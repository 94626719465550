import React, {forwardRef, memo, PropsWithRef} from 'react'
import Loader from "components/loader/Loader"
import {ShapeDimension} from "types/charts"
import WCInnerLoader from "components/widgetContainer/WidgetContainer.InnerLoader"
import {ChartSelection, EmptyObject, WorkflowResultTypes} from "classes/workflows/query-workflows/QueryWorkflow"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import styled from "styled-components"

interface Props extends PropsWithRef<any> {
  chart: ChartDetailWithoutLayout
  data?: WorkflowResultTypes | EmptyObject
  selection: ChartSelection
  loading: boolean
  dimensions: ShapeDimension
  onSelectionChange: (newSelection: ChartSelection) => any
  withSummary: boolean
}

// eslint-disable-next-line react/display-name
export default memo<Props>(forwardRef<any, Props>(function WidgetContent({
                                                                           chart,
                                                                           data,
                                                                           selection,
                                                                           loading,
                                                                           dimensions,
                                                                           onSelectionChange,
                                                                           withSummary,
                                                                         }, ref) {
  return <Container>
    <Loader loading={loading}/>
    <WCContainer $loading={loading && !data} className="widget-content">
      <WCInnerLoader ref={ref} {...{
        chart,
        data,
        selection,
        dimensions,
        onSelectionChange,
        withSummary,
      }} />
    </WCContainer>
  </Container>
}))

const WCContainer = styled.div<{ $loading: boolean }>`
    ${({$loading}) => $loading ? 'display: none;' : ''}
`

const Container = styled.div`
  overflow: hidden;
`
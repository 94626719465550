import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react"
import Language from "language"
import styled from "styled-components"
import {Input} from "antd"
import {IconContainer} from "components/common/IconContainer"
import {ExclamationIcon} from "@heroicons/react/outline"
import {
  formatFilterValueToHumanReadable,
  formatFilterValueToLego,
  useIsMinUnderMax,
  useIsValueNumeric,
} from "components/forms/selector/comps/box/utils"
import {ConfFilter, ConfFilterRangeTypes, FilterType} from "components/forms/selector/comps/box/filters"
import {FilterInputType} from "types/filter"
import useForwardFocusOnInput from "hooks/useForwardFocusOnInput"
import SuffixPrefixWrapper from "components/forms/selector/comps/box/inputs/SuffixPrefixWrapper"
import {convertNumberToString, convertStringToNumber} from "commons/object.utils"

interface FilterPatternProps {
  filter: ConfFilterRangeTypes
  temporaryFilter: ConfFilterRangeTypes
  handleTemporaryFilterChange: (filter: ConfFilter) => void
  onFocus: () => void
  onBlur: () => void
}

// eslint-disable-next-line react/display-name
const FilterRange = forwardRef<any, FilterPatternProps>(({
                                                           filter,
                                                           temporaryFilter,
                                                           handleTemporaryFilterChange,
                                                           onFocus,
                                                           onBlur,
                                                         }, ref) => {
  const [minValue, setMinValue] = useState(formatFilterValueToHumanReadable(temporaryFilter, temporaryFilter.predicate.value.minInclusive))
  const [maxValue, setMaxValue] = useState(formatFilterValueToHumanReadable(temporaryFilter, temporaryFilter.predicate.value.maxInclusive))
  const [temporaryMinInclusive, setTemporaryMinInclusive] = useState<string>(convertNumberToString(minValue))
  const [temporaryMaxExclusive, setTemporaryMaxExclusive] = useState<string>(convertNumberToString(maxValue))
  const isMinNumeric = useIsValueNumeric(temporaryMinInclusive)
  const isMaxNumeric = useIsValueNumeric(temporaryMaxExclusive)
  const isMinUnderMax = useIsMinUnderMax(temporaryMinInclusive, temporaryMaxExclusive)
  const minInputRef = useRef<any>()
  const isFieldValid = useMemo(() => isMinNumeric && isMaxNumeric && isMinUnderMax, [isMaxNumeric, isMinNumeric, isMinUnderMax])

  useForwardFocusOnInput(ref, minInputRef)

  useEffect(() => {
    if (isMinNumeric && isMaxNumeric && isMinUnderMax) {
      setTemporaryMinInclusive(convertNumberToString(formatFilterValueToHumanReadable(filter, filter.predicate.value.minInclusive)))
      setTemporaryMaxExclusive(convertNumberToString(formatFilterValueToHumanReadable(filter, filter.predicate.value.maxInclusive)))
    }
    // Here we want to listen to filter change (updated by the dashboard conf or after validation) in order to update the local state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (isFieldValid) {
      setMinValue(convertStringToNumber(temporaryMinInclusive))
      setMaxValue(convertStringToNumber(temporaryMaxExclusive))
    } else {
      setMinValue(undefined)
      setMaxValue(undefined)
    }
  }, [isFieldValid, temporaryMaxExclusive, temporaryMinInclusive])

  useEffect(() => {
    const minInclusiveFormattedToLego = formatFilterValueToLego(temporaryFilter, convertStringToNumber(temporaryMinInclusive))
    const maxInclusiveFormattedToLego = formatFilterValueToLego(temporaryFilter, convertStringToNumber(temporaryMaxExclusive))
    if (temporaryFilter.predicate.value.minInclusive !== minInclusiveFormattedToLego
      || temporaryFilter.predicate.value.maxInclusive !== maxInclusiveFormattedToLego) {
      handleTemporaryFilterChange({
        ...temporaryFilter,
        predicate: {
          ...temporaryFilter.predicate,
          value: {
            ...temporaryFilter.predicate.value,
            "@type": FilterType.RANGE,
            minInclusive: minInclusiveFormattedToLego,
            maxInclusive: maxInclusiveFormattedToLego,
          },
        },
        isValid: isFieldValid,
      })
    }
  }, [temporaryFilter, handleTemporaryFilterChange, isFieldValid, temporaryMinInclusive, temporaryMaxExclusive])


  const handleMinInputChange = useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryMinInclusive(v.target.value)
  }, [])

  const handleMaxInputChange = useCallback((v: React.ChangeEvent<HTMLInputElement>) => {
    setTemporaryMaxExclusive(v.target.value)
  }, [])

  return <div>
    <SuffixPrefixWrapper
      suffix={filter.type === FilterType.metric ? filter.reference.suffix : undefined}
      prefix={filter.type === FilterType.metric ? filter.reference.prefix : undefined}>
      <StyledInput
        ref={minInputRef}
        value={temporaryMinInclusive}
        status={isFieldValid ? undefined : "error"}
        onChange={handleMinInputChange}
        placeholder={Language.get(`filter.type.${FilterInputType.BETWEEN}.placeholder.min`)}
        onFocus={onFocus}
        onBlur={() => onBlur()}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onBlur()
          }
        }}
      />
      <Separator>{Language.get("filter.separator.and")}</Separator>
      <StyledInput
        value={temporaryMaxExclusive}
        status={isFieldValid ? undefined : "error"}
        placeholder={Language.get(`filter.type.${FilterInputType.BETWEEN}.placeholder.max`)}
        onChange={handleMaxInputChange}
        onFocus={onFocus}
        onBlur={() => onBlur()}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onBlur()
          }
        }}
      />
    </SuffixPrefixWrapper>
    {
      isMinNumeric && isMaxNumeric ? <></> : <ErrorContainer><IconContainer
        size={12}><ExclamationIcon/></IconContainer><span>{Language.get(`filter.type.${FilterInputType.BETWEEN}.warning.notNumeric`)}</span></ErrorContainer>
    }
    {
      isMinNumeric && isMaxNumeric && !isMinUnderMax ? <ErrorContainer><IconContainer
        size={12}><ExclamationIcon/></IconContainer><span>{Language.get(`filter.type.${FilterInputType.BETWEEN}.warning.maxBeforeMin`)}</span></ErrorContainer> : <></>
    }
  </div>
})

export default FilterRange

const Separator = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6px;
`
const ErrorContainer = styled.div`
  display: flex;
  color: var(--target-red);
  font-size: 10px;
  align-content: center;
  align-items: center;
  gap: 4px;
`

const StyledInput = styled(Input)`
  line-height: 28px;
  padding: 0 6px;
  box-shadow: none !important;
`
import {useEffect, useRef} from "react"

// see https://www.debuggr.io/react-update-unmounted-component/
export default () => {
  const refMounted = useRef(true)
  useEffect(
    () => () => { refMounted.current = false },
    [],
  )
  return refMounted
}

import React, {FunctionComponent, memo, PropsWithChildren, useCallback, useEffect, useMemo, useState} from "react"
import {predefinedPeriod, usePeriodCategories} from "commons/period/periodsList"
import {Granularity, PeriodType, PeriodTypes} from "types/period.types"
import {Col, Row} from 'antd'
import SimpleSelect, {BaseOption} from "../select/SimpleSelect"
import WrapperRoot from "components/forms/confItems/WrapperRoot"
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {CalendarIcon, ClockIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import styled from "styled-components"
import useDispatch from "hooks/useDispatch"
import {captureEvent} from "services/GoogleAnalyticsService"
import Language from "language"

interface WrapperProps extends PropsWithChildren {
  name: string
}

export const PeriodWrapper: FunctionComponent<WrapperProps> = ({children, name}) => (
  <WrapperRoot {...{
    icon: ClockIcon,
    label: "configuration-label-period",
  }}>
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

interface PeriodSelectorProps {
  value?: PeriodTypes
  onChange?: (newValue?: PeriodTypes) => void
  environmentId: number
  forTarget: boolean
  withOverrideOption: boolean
  overrideOptionLabel?: string
  withDateSlicerGranularity: boolean
  span?: number,
  getPopupContainer?: any
  GACategory?: string
  name: string
}

const toOptions = (periods: PeriodTypes[]) => {
  return periods.map(period => {
    return {
      value: period.id.toString(),
      label: Language.getTranslatedName(period),
    }
  })
}


const PeriodSelector = memo<PeriodSelectorProps>(function PeriodSelector({
                                                                           value,
                                                                           onChange,
                                                                           forTarget,
                                                                           withOverrideOption,
                                                                           overrideOptionLabel = Language.get('chart-definition-period-from-dashboard'),
                                                                           withDateSlicerGranularity,
                                                                           span = 12,
                                                                           getPopupContainer,
                                                                           environmentId,
                                                                           GACategory,
                                                                           name,
                                                                         }) {
  const dispatch = useDispatch()
  const [periods, setPeriods] = useState<PeriodTypes[]>()
  useEffect(() => {
    dispatch.periods.loadPeriods({environment: {id: environmentId}}).then((data) => {
      setPeriods(data.payload)
    })
  }, [environmentId, dispatch.periods])

  const presetCategories = usePeriodCategories(periods ?? [])

  const options: BaseOption[] = useMemo(() =>
      forTarget
        ? toOptions((periods ?? []).filter(period => period.type === PeriodType.dynamic && period.canBeDeleted === false))
        : [
          ...(withOverrideOption ? [{
            value: null,
            label: overrideOptionLabel,
          }] : []),
          ...presetCategories.map(({title, presets}) => ({
            label: Language.get(title),
            value: null,
            options: toOptions(presets),
          })),
        ],
    [forTarget, overrideOptionLabel, periods, presetCategories, withOverrideOption],
  )

  useEffect(() => {
    if (value && periods && !periods.find(period => period.code === value.code)) {
      onChange?.(withOverrideOption ? undefined : periods.find((period) => period.code === predefinedPeriod.MONTH_TO_DATE.code))
    }
  }, [onChange, periods, value, withOverrideOption])

  const dateSlicerGranularityOptions: BaseOption[] = useMemo(
    () => withDateSlicerGranularity ? [
      withOverrideOption && {
        value: null,
        label: Language.get('chart-definition-granularity-from-dashboard'),
      },
      ...[Granularity.DAY, Granularity.WEEK, Granularity.MONTH, Granularity.QUARTER, Granularity.YEAR].map(v => ({
        value: v,
        label: Language.get(`calendar-granularity-${v.toLowerCase()}`),
      })),
    ].filter(Boolean) as BaseOption[] : [],
    [withDateSlicerGranularity, withOverrideOption],
  )

  const handlePresetSelect = useCallback(
    (id: string) => {
      const newPeriod = (periods ?? []).find(period => period.id.toString() === id)
      if (GACategory) {
        if (newPeriod) {
          captureEvent({
            category: GACategory,
            action: `add_period`,
          })
        } else {
          captureEvent({
            category: GACategory,
            action: `remove_period`,
          })
        }
      }

      onChange?.(newPeriod)
    }, [GACategory, onChange, periods],
  )
  return <Row>
    <ColContainer span={span}>
      <SimpleSelect {...{
        name,
        style: {width: '100%', margin: 0},
        showSearch: true,
        labelIcon: <IconContainer color={"var(--light-grey)"}>
          <CalendarIcon/>
        </IconContainer>,
        options,
        getPopupContainer,
        value: value?.id?.toString() ?? null,
        onSelect: handlePresetSelect,
      }} />
    </ColContainer>
    {dateSlicerGranularityOptions.length > 0 && <>
      <ColContainer span={11} offset={1}>
        <WrapperItem name={'dateSlicerGranularity'}>
          <SimpleSelect {...{
            name,
            style: {width: '100%'},
            options: dateSlicerGranularityOptions,
            labelIcon: <IconContainer color={"var(--light-grey)"}>
              <ClockIcon/>
            </IconContainer>,
            getPopupContainer,
          }} />
        </WrapperItem>
      </ColContainer>
    </>
    }
  </Row>
})

export default PeriodSelector

const ColContainer = styled(Col)`
    &&& .ant-select-selection-item {
        margin-left: 0px;
        align-content: center;
        align-items: center;
        display: flex;
    }
`
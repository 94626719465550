import React, {forwardRef, FunctionComponent} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {XIcon} from "@heroicons/react/outline"
import LimitsSelector from "components/forms/selector/limits/LimitsSelector"
import {GenericChartTypes} from "types/widgets"
import {ConfigurationLimitWithDisablingReasons} from "components/forms/chart/types"

interface Props {
  children: React.ReactNode
  name: string
}

export const LimitsWrapper: FunctionComponent<Props> = ({children, name}) => (
  <WrapperRoot icon={XIcon}
               label="configuration-label-limits">
    <WrapperItem name={name}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

interface FieldProps {
  displayType?: GenericChartTypes
  configurations: ConfigurationLimitWithDisablingReasons[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LimitsField = forwardRef<any, FieldProps>(function Field({...props}, ref) {
  return <LimitsSelector {...props} />
})

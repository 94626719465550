/* eslint-disable max-lines */
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react"
import styled from "styled-components"
import {Select} from "antd"
import {FilterInputType} from "types/filter"
import {
  ConfDimensionFilterLikeDtoDetail,
  ConfDimensionFilterRangeDtoDetail,
  ConfDimensionFilterScalarDtoDetail,
  ConfDimensionFilterValuesDtoDetail,
  ConfFilter,
  FilterOperator,
  FilterType,
} from "components/forms/selector/comps/box/filters"
import {getFilterValue, useFilterTypeOptions, useGetFilterType, useGetFilterValue} from "components/forms/selector/comps/box/utils"
import FilterValueMatch from "components/forms/selector/comps/box/inputs/FilterValueMatch"
import FilterPattern from "components/forms/selector/comps/box/inputs/FilterPattern"
import FilterScalar from "components/forms/selector/comps/box/inputs/FilterScalar"
import FilterRange from "components/forms/selector/comps/box/inputs/FilterRange"
import FilterTypeDescription from "components/forms/selector/comps/box/FilterTypeDescription"

export interface FilterTypeDescriptionProps {
  filter: ConfFilter
  temporaryFilter: ConfFilter
  isEditable: number
  environmentId: number,
  handleTemporaryFilterChange: (filter: ConfFilter) => void
  handleFilterChange: (filter: ConfFilter) => void
  withBackground?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onApply: (filter: ConfFilter) => void
  onFocus: () => void
  onBlur: (filter?: ConfFilter) => void
}

// eslint-disable-next-line react/display-name
const FilterInputs = memo<FilterTypeDescriptionProps>(({
                                                         filter,
                                                         temporaryFilter,
                                                         isEditable,
                                                         environmentId,
                                                         handleFilterChange,
                                                         handleTemporaryFilterChange,
                                                         withBackground,
                                                         getPopupContainer,
                                                         onApply,
                                                         onFocus,
                                                         onBlur,
                                                       }) => {
  const getFilterType = useGetFilterType()
  const filterTypeOptions = useFilterTypeOptions(filter)
  const [filterType, setFilterType] = useState<FilterInputType>(getFilterType(temporaryFilter))
  const getFilterFormattedValue = useGetFilterValue(filterType)
  const inputRef = useRef<any>()
  const isModifiedByUser = useRef<boolean>(false)

  useEffect(() => {
    if (inputRef && inputRef.current && isModifiedByUser.current) {
      inputRef.current.focus()
    }
  }, [filterType])

  useEffect(() => {
    isModifiedByUser.current = false
    setFilterType(getFilterType(filter))
    // Here we want to listen to filter change (updated by the dashboard conf or after validation) in order to update the local state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, getFilterType])

  const selector = useMemo(() => {
    switch (temporaryFilter.predicate.operator) {
      case FilterOperator.IN:
        return <FilterValueMatch {...{
          ref: inputRef,
          filter: temporaryFilter as ConfDimensionFilterValuesDtoDetail,
          environmentId,
          handleFilterChange: handleTemporaryFilterChange,
          withBackground,
          getPopupContainer,
          onApply,
          onFocus,
          onBlur,
        }}/>
      case FilterOperator.Like:
      case FilterOperator.Match:
        return <FilterPattern {...{
          ref: inputRef,
          filter: filter as ConfDimensionFilterLikeDtoDetail,
          temporaryFilter: temporaryFilter as ConfDimensionFilterLikeDtoDetail,
          type: filterType,
          handleFilterChange,
          handleTemporaryFilterChange,
          onFocus,
          onBlur,
        }}/>
      case FilterOperator.GT:
      case FilterOperator.EQ:
      case FilterOperator.GTE:
      case FilterOperator.LT:
      case FilterOperator.LTE:
        return <FilterScalar {...{
          ref: inputRef,
          filter: filter as ConfDimensionFilterScalarDtoDetail,
          temporaryFilter: temporaryFilter as ConfDimensionFilterScalarDtoDetail,
          type: filterType,
          handleFilterChange,
          handleTemporaryFilterChange,
          onFocus,
          onBlur,
        }}/>
      case FilterOperator.BETWEEN:
        return <FilterRange {...{
          ref: inputRef,
          filter: filter as ConfDimensionFilterRangeDtoDetail,
          temporaryFilter: temporaryFilter as ConfDimensionFilterRangeDtoDetail,
          type: filterType,
          handleFilterChange,
          handleTemporaryFilterChange,
          onFocus,
          onBlur,
        }}/>
      default: {
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = temporaryFilter.predicate
        return exhaustiveCheck
      }

    }

  }, [temporaryFilter, environmentId, handleTemporaryFilterChange, withBackground, getPopupContainer, onApply, onFocus, onBlur, filter, filterType, handleFilterChange])

  const onFilterTypeChange = useCallback((value: FilterInputType) => {
    isModifiedByUser.current = true
    setFilterType(value)
    switch (value) {
      case FilterInputType.VALUE_SELECTION: {
        handleTemporaryFilterChange({
          ...temporaryFilter,
          type: FilterType.dimension,
          dimensionCode: temporaryFilter.reference.code,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.IN,
            value: {
              "@type": FilterType.DICT_ENTRIES,
              entries: temporaryFilter.predicate.value["@type"] === FilterType.DICT_ENTRIES ? temporaryFilter.predicate.value.entries : [],
            },
          },
        })
        break
      }
      case FilterInputType.REGEX: {
        handleTemporaryFilterChange({
          ...temporaryFilter,
          type: FilterType.dimension,
          dimensionCode: temporaryFilter.reference.code,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.Match,
            value: {
              "@type": FilterType.PATTERN,
              pattern: temporaryFilter.predicate.value["@type"] === FilterType.PATTERN ? getFilterValue(temporaryFilter) : "",
              caseSensitive: false,
            },
          },
        })
        break
      }
      case FilterInputType.START_WITH:
      case FilterInputType.END_WITH:
      case FilterInputType.INCLUDE:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          type: FilterType.dimension,
          dimensionCode: temporaryFilter.reference.code,
          predicate: {
            operator: FilterOperator.Like,
            value: {
              "@type": FilterType.PATTERN,
              pattern: temporaryFilter.predicate.value["@type"] === FilterType.PATTERN ? getFilterFormattedValue(getFilterValue(temporaryFilter), value) : getFilterFormattedValue(" ", value),
              caseSensitive: false,
            },
            negate: temporaryFilter.predicate.negate,
          },
        })
        break
      case FilterInputType.EQUAL:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.EQ,
            value: {
              "@type": FilterType.SCALAR,
              value: temporaryFilter.predicate.value["@type"] === FilterType.SCALAR ? temporaryFilter.predicate.value.value : undefined,
            },
          },
        })
        break
      case FilterInputType.GREATER:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.GT,
            value: {
              "@type": FilterType.SCALAR,
              value: temporaryFilter.predicate.value["@type"] === FilterType.SCALAR ? temporaryFilter.predicate.value.value : undefined,
            },
          },
        })
        break
      case FilterInputType.GREATER_OR_EQ:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.GTE,
            value: {
              "@type": FilterType.SCALAR,
              value: temporaryFilter.predicate.value["@type"] === FilterType.SCALAR ? temporaryFilter.predicate.value.value : undefined,
            },
          },
        })
        break
      case FilterInputType.LOWER:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.LT,
            value: {
              "@type": FilterType.SCALAR,
              value: temporaryFilter.predicate.value["@type"] === FilterType.SCALAR ? temporaryFilter.predicate.value.value : undefined,
            },
          },
        })
        break
      case FilterInputType.LOWER_OR_EQ:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.LTE,
            value: {
              "@type": FilterType.SCALAR,
              value: temporaryFilter.predicate.value["@type"] === FilterType.SCALAR ? temporaryFilter.predicate.value.value : undefined,
            },
          },
        })
        break
      case FilterInputType.BETWEEN:
        handleTemporaryFilterChange({
          ...temporaryFilter,
          predicate: {
            ...temporaryFilter.predicate,
            operator: FilterOperator.BETWEEN,
            value: {
              "@type": FilterType.RANGE,
              maxInclusive: temporaryFilter.predicate.value["@type"] === FilterType.RANGE ? temporaryFilter.predicate.value.maxInclusive : undefined,
              minInclusive: temporaryFilter.predicate.value["@type"] === FilterType.RANGE ? temporaryFilter.predicate.value.minInclusive : undefined,
            },
          },
        })
        break
      default: {
        // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
        const exhaustiveCheck: never = value
      }
    }
  }, [handleTemporaryFilterChange, temporaryFilter, getFilterFormattedValue])
  return <>
    {isEditable ? <FlexContainer>
        <StyledSelect
          value={filterType}
          onFocus={onFocus}
          onBlur={() => onBlur()}
          options={filterTypeOptions}
          onSelect={(selectedType: any) => onFilterTypeChange(selectedType)}
        />
        <FilterTypeDescription type={filterType}/>
      </FlexContainer>
      : <></>
    }
    <StyledDiv $iseditable={isEditable}>
      {selector}
    </StyledDiv>
  </>
})

export default FilterInputs

const StyledDiv = styled.div<{ $iseditable: number }>`
  width: calc(100% - 25px);
    ${({$iseditable}) => `opacity: ${$iseditable};`}
}

.ant-select-selector {
  box-shadow: none !important;
}
`

const FlexContainer = styled.div`
  display: flex;
  gap: 6px;

  .ant-select-selector {
    box-shadow: none !important;
  }
`
const StyledSelect = styled(Select)`
  &&& {
    height: 100%;
    max-height: 100%;
    width: 100%;
    line-height: 20px;

    &&& .ant-select-single {
      line-height: 20px;
    }

    &&& .ant-select-selector {
      width: 100%;
      height: 32px;
      align-items: center;
      padding: 0 2px;
    }

    &&& .ant-select {
      line-height: 20px;
    }

    &&& .ant-select-selection-item {
      height: 24px;
      line-height: 24px;
    }
  }
`

import React, {memo, PropsWithChildren} from "react"
import styled from "styled-components"

interface SuffixPrefixWrapperProps {
  prefix?: string
  suffix?: string
}

// eslint-disable-next-line react/display-name
const SuffixPrefixWrapper = memo<PropsWithChildren<SuffixPrefixWrapperProps>>(({
                                                                                 prefix,
                                                                                 suffix,
                                                                                 children,
                                                                               }) => {

  return <FlexContainer>
    {prefix && <Prefix>{prefix}</Prefix>}
    {children}
    {suffix && <Suffix>{suffix}</Suffix>}
  </FlexContainer>
})

export default SuffixPrefixWrapper

const FlexContainer = styled.div`
  display: flex;
  gap: 6px;
`

const Prefix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-right: 7px;
`

const Suffix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-left: 7px;
`
import {useMemo} from "react"

// one ref per item in deps array
export default function useRefs<T>(deps: unknown[]): [T[], ((cmp: T) => void)[]] {
  const refs = useMemo<T[]>(() => ([]), [deps]) // eslint-disable-line react-hooks/exhaustive-deps
  const setARefs = useMemo<((cmp: T) => void)[]>(() => deps.map((_, index) => (cmp: T) => {
    refs[index] = cmp
  }), [deps]) // eslint-disable-line react-hooks/exhaustive-deps

  return [refs, setARefs]
}

import React from 'react'
import Language from 'language'
import {ConfChartMetricWithMetricDef, Format} from "types/charts"
import {EffectiveConfTypes, MetricDef, MetricWithView} from "components/charts/line/LineChart.types"
import {MetricGrowthPeriod, MetricGrowthPeriodUnit, MetricGrowthType} from "types/savedConfs"
import {ChartMetricWithMetricDef} from "classes/workflows/query-workflows/QueryWorkflow"
import {isEmpty} from "commons/object.utils"
import {TooltipContentMetric} from "components/tooltipContent/TooltipContent.Metric"
import {getFilterResume} from "components/forms/selector/comps/box/utils"

enum ChartGrowth {
  DAY_MINUS_ONE = 'd-n',
  WEEK_MINUS_ONE = 'w-n',
  MONTH_MINUS_ONE = 'm-n',
  QUARTER_MINUS_ONE = 'q-n',
  SEMESTER_MINUS_ONE = 's-1',
  YEAR_MINUS_ONE = 'y-n',
  DAY_MINUS_THIRTY = 'd-30',
  TRANSLATED = 'previous'
}

export const consolidateFormatWithOptions = (metric: Pick<ChartMetricWithMetricDef, "metricDef" | "extraConf">): {
  formatPrefix: string
  formatSuffix: string
  formatPrecision: number
  growthInvert: boolean
  asRatio: boolean
  unit?: string
} => {
  const {metricDef, extraConf} = metric
  const {formatPrecision, formatPrefix, formatSuffix, asRatio} = metricDef

  const consolidateFormatPrefix = extraConf?.details?.type === "prefix" ? extraConf.details.value : formatPrefix
  const consolidateFormatSuffix = extraConf?.details?.type === "suffix" ? extraConf.details.value : formatSuffix
  const consolidateFormatPrecision = extraConf?.decimals ?? formatPrecision

  return {
    formatPrefix: consolidateFormatPrefix,
    formatSuffix: consolidateFormatSuffix,
    formatPrecision: consolidateFormatPrecision,
    growthInvert: extraConf?.growthInvert ?? false,
    asRatio,
    unit: extraConf?.units,
  }
}

/**
 * Compute the format of one of the metrics of the query
 * function manages :
 *  - time/slicers format:
 *  - default growth
 *  - and metrics base/growth's
 * @param {object} metric : getMetrics() element, leave empty for a default text, or write 'date' for a date scale
 * @returns {object} format objects
 */
// metric type is a hybrid of ChartMetricDtoDetail and effectiveConf
export const parseFormat = (metric: ChartMetricWithMetricDef): Format => {
  const {growth} = metric
  const {unit, formatPrefix, formatSuffix, formatPrecision, growthInvert, asRatio} = consolidateFormatWithOptions(metric)
  if (growth) {
    return {
      prefix: growth.type === MetricGrowthType.RATIO ? "" : formatPrefix,
      suffix: growth.type === MetricGrowthType.RATIO ? "%" : formatSuffix,
      nDecimal: growth.type === MetricGrowthType.RATIO ? 2 : formatPrecision,
      asRatio: growth.type === MetricGrowthType.RATIO,
      growth: true,
      unit,
      growthInvert: Boolean(growthInvert),
      type: growth.type,
    }
  } else {
    return {
      prefix: formatPrefix,
      suffix: formatSuffix,
      nDecimal: formatPrecision,
      growth: false,
      asRatio,
      unit,
    }
  }
}

export const getGrowthLabel = (metric: Pick<MetricWithView, "titlePartOverrides" | "growth">) => {
  if (metric.titlePartOverrides.GROWTH) {
    return metric.titlePartOverrides.GROWTH
  }
  if (metric.growth === undefined) {
    return ''
  }
  switch (metric.growth?.type) {
    case MetricGrowthType.DIFFERENCE:
      return `(vs ${Language.get(`growth.value.${getLabelKeyForGrowthPeriod(metric.growth?.period)}`, metric.growth?.period.quantity)})`
    case MetricGrowthType.VALUE:
      return `(${Language.get(`growth.value.${getLabelKeyForGrowthPeriod(metric.growth?.period)}`, metric.growth?.period.quantity)})`
    case MetricGrowthType.RATIO:
      return `(% vs ${Language.get(`growth.value.${getLabelKeyForGrowthPeriod(metric.growth?.period)}`, metric.growth?.period.quantity)})`
    case undefined:
      return ''
    case null:
      return ''
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = metric.growth?.type
      return undefined
  }
}

export const getMetricPeriodLabel = (metric: Pick<MetricWithView, "titlePartOverrides" | "overridePeriod">) => {
  if (metric.titlePartOverrides.PERIOD) {
    return metric.titlePartOverrides.PERIOD
  }
  if (metric.overridePeriod === undefined) {
    return ''
  }
  return Language.getTranslatedName(metric.overridePeriod)
}

export const getMetricFilterLabel = (metric: Pick<ConfChartMetricWithMetricDef, "titlePartOverrides" | "additionalFilters">) => {
  if (metric.titlePartOverrides.FILTER) {
    return metric.titlePartOverrides.FILTER
  }
  if (metric.additionalFilters.length === 0) {
    return ''
  }
  return metric.additionalFilters.map(filter => `${filter.reference.alias}: ${getFilterResume(filter)}`).join(', ')
}

export function buildMetricLabel(metric: Pick<ConfChartMetricWithMetricDef, "titlePartOverrides" | "additionalFilters" | "overridePeriod"> & {
  metricDef?: Pick<MetricDef, "alias">
}): string {
  const {metricDef} = metric
  if (!metricDef) {
    return ""
  }
  return `${metric.metricDef?.alias} ${getMetricFilterLabel(metric)} ${getMetricPeriodLabel(metric)} ${getGrowthLabel(metric)}`
}

export function parseMetricAsAlt(metric: MetricWithView, effectiveConf: EffectiveConfTypes): JSX.Element | string {
  const {metricDef} = metric
  const labelWithView = `${metric.view.alias} - ${metric.metricAlias}`
  if (metricDef.description) {
    return <TooltipContentMetric metric={metric} timeInterval={effectiveConf.timeInterval}/>
  } else {
    return labelWithView
  }
}

export const getLabelKeyForGrowthPeriod = (growthPeriod: MetricGrowthPeriod | undefined): ChartGrowth | null => {
  if (growthPeriod?.unit === MetricGrowthPeriodUnit.DAYS && isEmpty(growthPeriod?.quantity)) {
    return ChartGrowth.TRANSLATED
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.DAYS) {
    return ChartGrowth.DAY_MINUS_ONE
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.WEEKS) {
    return ChartGrowth.WEEK_MINUS_ONE
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.MONTHS) {
    return ChartGrowth.MONTH_MINUS_ONE
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.QUARTERS) {
    return ChartGrowth.QUARTER_MINUS_ONE
  } else if (growthPeriod?.unit === MetricGrowthPeriodUnit.YEARS) {
    return ChartGrowth.YEAR_MINUS_ONE
  } else {
    return null
  }
}

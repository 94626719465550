import _ from "lodash"
import {Dimension} from "classes/MetaModel"
import styled from "styled-components"
import {Col, Row} from "antd"

export interface Option {
  type: "date" | "dimension"
  label: string
  selectorLabel: string
  value: string
  disabled?: boolean
}

export interface DateOption extends Option {
  type: "date"
}

export interface DimensionOption extends Option {
  type: "dimension"
  description: string
}

export const getDimensionOption = (availableDimensions: Dimension[], unavailableDimensions: Dimension[]): DimensionOption[] =>
  _(availableDimensions)
    .map(dimension => ({
      label: dimension.alias,
      value: dimension.code,
      description: dimension.description,
      type: "dimension",
    } as DimensionOption))
    .sortBy('label')
    .value()
    .concat(_(unavailableDimensions).map(dimension => ({
      label: dimension.alias,
      value: dimension.code,
      description: dimension.description,
      disabled: true,
      type: "dimension",
    } as DimensionOption)).sortBy('label').value())

export const SortableRow = styled(Row)`
  margin-bottom: 6px;
  user-select: none;
  &&& {
    z-index: 1500;
  }
`

export const RowWithDefaultValue = styled(Row)<{ $isdefault: number }>`
    ${props => props.$isdefault ? `
  font-style: italic;
  color: var(--light-grey);
  
  * {
    color: var(--light-grey);
  }
  ` : ''}
`

export const SortableRowWithDefaultValue = styled(SortableRow)<{ $isdefault: number }>`
    ${props => props.$isdefault ? `
  font-style: italic;
  color: var(--light-grey);
  
  .ant-select-selection-item{
    color: var(--light-grey);
  }
  ` : ''}
`


export const ColWithLeftMargin = styled(Col)<{ $value: number }>`
    margin-left: ${({$value}) => $value}px;
`

import {useCallback, useMemo} from "react"
import {Option} from "types/select"

const useSearch = <T extends Option>() => {
  const matchOperatorsRegex = useMemo(() => /[|\\{}()[\]^$+*?.-]/g, []) // from https://github.com/sindresorhus/escape-string-regexp/blob/master/index.js

  const getSearchPattern = useCallback((search?: string) => {
    if (search && search !== '') {
      const filterRegexpPattern = search.replace(matchOperatorsRegex, (ch) => {
        if (ch === '*') {
          return '.*'
        }
        if (ch === '?') {
          return '.'
        }
        return `\\${ch}`
      })
      return new RegExp(`${filterRegexpPattern}`, 'i')
    } else {
      return null
    }
  }, [matchOperatorsRegex])


  return useCallback((options: T[], search?: string): T[] => {
    const searchPattern = getSearchPattern(search)
    return options.filter(o => o.label && (!searchPattern || searchPattern.test(o.label.toLocaleLowerCase())))
  }, [getSearchPattern])
}
export default useSearch
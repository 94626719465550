import {Col, Row, Select} from "antd"
import React, {memo, useCallback} from "react"
import {ExtraConfDetail} from "types/savedConfs"
import {isEmpty} from "lodash"
import Language from "language"
import GAInput from "components/GAInput/GAInput"

interface DetailsSelectorProps {
  value?: ExtraConfDetail
  onChange?: (newState?: ExtraConfDetail) => void
  gaevent: {
    category: string
    action: string
  }
}

const DetailsSelector = memo<DetailsSelectorProps>(function DetailsSelector({
                                                      value,
                                                      onChange,
                                                                              gaevent,
                                                                            }) {

  const handleChangeType = useCallback((newType: "prefix" | "suffix") => {
    onChange?.({
      value: value?.value ?? "",
      type: newType,
    })
  }, [onChange, value])

  const handleChangeValue = useCallback((newValue: string) => {
    onChange?.({
      value: newValue,
      type: value?.type ?? "prefix",
    })
  }, [onChange, value])

  return <Row>
    <Col span={8}>
      <Select options={[{
        value: null,
        label: Language.get("configuration-metric-options.display.details.options.none"),
      }, {
        value: "prefix",
        label: Language.get("configuration-metric-options.display.details.options.prefix"),
      }, {
        value: "suffix",
        label: Language.get("configuration-metric-options.display.details.options.suffix"),
      }]} value={value?.type ?? null} onChange={handleChangeType}/>
    </Col>
    <Col span={15} offset={1}>
      <GAInput type={undefined} value={value?.value} onChange={(e) => handleChangeValue(e.target.value)} disabled={isEmpty(value?.type)}
               gaevent={gaevent}/>
    </Col>
  </Row>
})

export default DetailsSelector

/* eslint-disable max-lines */
import React, {memo, PropsWithChildren, useCallback, useMemo} from 'react'
import Language from 'language'
import MetricSelectorSelect from "./MetricSelectorSelect"
import withSortableElement from "components/common/sortable/withSortableElement"
import {ViewsWithMetrics} from "types/viewsWithMetrics"
import {getIndexFromID} from "components/common/sortable/SortableContainerContext"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {GenericChartTypes} from "types/widgets"
import {DataSelection} from "redux/models/currentDashboard"
import {PeriodTypes} from "types/period.types"
import MetaModel from "classes/MetaModel"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"
import MetricSelectorCascader from "components/forms/selector/metrics/MetricSelectorCascader"

interface SingleMetricSelectorProps extends PropsWithChildren {
  environmentId: number
  id: number
  value?: MetricSelectorValue
  defaultView?: string
  canInvert: boolean
  canRemove: boolean
  onChange?: (id: number, data: SingleMetricSelectorValue) => void
  onDelete?: (id: number) => void
  groupMetricsByView: boolean
  viewsWithMetrics: ViewsWithMetrics
  unavailableViews: ViewsWithMetrics
  growthDisabled?: boolean
  configuration?: ChartTypeWithDisabledReason
  numberOfSlicer?: number
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  isAxisOptionAvailable?: boolean
  isAxisOptionDisabled?: boolean
  displayType?: GenericChartTypes
  dashboardSelection: DataSelection
  period?: PeriodTypes
  metaModel: MetaModel
}


const SingleMetricSelector = memo<SingleMetricSelectorProps>(function SingleMetricSelector({
                                                                                             id,
                                                                                             value: originalData,
                                                                                             defaultView,
                                                                                             canRemove,
                                                                                             onChange,
                                                                                             onDelete,
                                                                                             groupMetricsByView,
                                                                                             viewsWithMetrics,
                                                                                             unavailableViews,
                                                                                             getPopupContainer,
                                                                                           }) {
  const value: MetricSelectorValue | undefined = useMemo(() => originalData ? ({
    ...originalData,
    growth: originalData.growth ? {
      ...originalData.growth,
      period: {
        ...originalData.growth.period,
        quantity: originalData.growth.period.quantity,
      },
    } : undefined,
  }) : undefined, [originalData])

  const handleMetricChange = useCallback((newValue: SingleMetricSelectorValue | null) => {
      if (newValue) {
        onChange?.(getIndexFromID(id), {
          ...(value || {}),
          ...newValue,
        })
      } else {
        if (onDelete) {
          onDelete(getIndexFromID(id))
        }
      }
    },
    [value, onChange, id, onDelete],
  )

  const placeholder = `${Language.get('configuration-add-metric')} ...`

  return groupMetricsByView
    ? <MetricSelectorCascader {...{
      value,
      defaultView,
      placeholder,
      canRemove,
      onChange: handleMetricChange,
      viewsWithMetrics,
      unavailableViews,
      getPopupContainer,
    }} />
    : <MetricSelectorSelect {...{
      value,
      placeholder,
      canRemove,
      onChange: handleMetricChange,
      viewsWithMetrics,
      getPopupContainer,
    }} />
})

export default SingleMetricSelector

// @ts-ignore
SingleMetricSelector.Sortable = withSortableElement(SingleMetricSelector)

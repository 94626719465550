import React from 'react'
import {FormItemKeys} from 'commons/keywords/form'
import PositionDashboard from '.././confItems/PositionDashboard'
import ParentMenu from '.././confItems/ParentMenu'
import {FormBodyComponentProps} from "components/forms/confBodies/FormBody"
import Loader from "components/loader/Loader"

const NavEditDashboardFormBody = <T, >({
                                         loading,
                                         getPopupContainer,
                                         availables: {menus = [], dashboardsPositions = []},
                                       }: FormBodyComponentProps<T>) => {
  return <Loader loading={loading}>
    <ParentMenu
      span={24}
      name={FormItemKeys.PARENT_MENU}
      options={menus}
      {...{
        getPopupContainer,
      }}/>
    <PositionDashboard
      name={FormItemKeys.POSITION_DASHBOARD}
      options={dashboardsPositions}
      span={24}
      {...{
        getPopupContainer,
      }}/>
  </Loader>
}

export default NavEditDashboardFormBody

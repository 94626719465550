import {
  SlicerDateDtoDetail,
  SlicerDateDtoDetailWithSource,
  SlicerDimensionDtoDetail,
  SlicerDtoDetailTypes,
  SlicerWithDimension,
} from "types/savedConfs"
import {EffectiveConfSlicerTypes, EffectiveConfTypes, SlicerTypes} from "components/charts/line/LineChart.types"
import {ConfDimensionFilterTypesWithSource, ConfMetricFilterTypesWithSource} from "components/forms/selector/comps/box/filters"
import {OrderBy} from "types/charts"

type SlicerOrDtoDetail = SlicerTypes | SlicerDtoDetailTypes

export const isSlicerDate = <T extends SlicerDimensionDtoDetail>(slicer: SlicerDateDtoDetail | T): slicer is SlicerDateDtoDetail => slicer.type === "date"
export const isSlicerDateWithSource = <T extends SlicerDimensionDtoDetail>(slicer: SlicerDateDtoDetailWithSource | T): slicer is SlicerDateDtoDetailWithSource => slicer.type === "date"

export const isSlicerDimension = <T extends SlicerDimensionDtoDetail>(slicer: SlicerDateDtoDetail | T): slicer is T => slicer.type === "dimension"

export const extractSlicerDate = (slicers: SlicerOrDtoDetail[]): SlicerDateDtoDetail | undefined => {
  const slicersDate = slicers.filter(isSlicerDate)
  return slicersDate?.[0]
}
export const extractSlicerDateWithSource = (slicers: EffectiveConfSlicerTypes[]): SlicerDateDtoDetailWithSource | undefined => {
  const slicersDate = slicers.filter(isSlicerDateWithSource)
  return slicersDate?.[0]
}
export const extractSlicersDimensionWithDimensionObject = (slicers: SlicerTypes[]): SlicerWithDimension[] => slicers.filter(isSlicerDimension)
export const extractSlicersDimension = (slicers: SlicerDtoDetailTypes[]): SlicerDimensionDtoDetail[] => slicers.filter(isSlicerDimension)

export const extractSlicersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): EffectiveConfSlicerTypes[] | undefined => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.slicers
    case "target":
      return undefined
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}

export const extractFiltersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): ConfDimensionFilterTypesWithSource[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.filters
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}

export const extractMetricFiltersFromEffectiveConf = (effectiveConf: EffectiveConfTypes): ConfMetricFilterTypesWithSource[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.metricFilters
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}

export const extractOrderByFromEffectiveConf = (effectiveConf: EffectiveConfTypes): OrderBy[] => {
  switch (effectiveConf.type) {
    case "generic":
      return effectiveConf.orderBys
    case "target":
      return []
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = effectiveConf
      return exhaustiveCheck
  }
}
/* eslint-disable max-lines */
import {Popover, Select} from 'antd'
import {TooltipPlacement} from "antd/lib/tooltip"
import Language from "language"
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {SelectableOption} from "types/select"
import Loader from "components/loader/Loader"
import OptionList, {OptionListPagination} from "components/forms/selector/filters/addFilterPanel/OptionList"
import styled from "styled-components"
import {ChevronDownIcon, PlusIcon, SearchIcon, XIcon} from "@heroicons/react/outline"
import {SizeType} from "antd/lib/config-provider/SizeContext"
import {IconContainer} from "components/common/IconContainer"
import usePrevious from "hooks/usePrevious"
import {useResizeDetector} from "hooks/useResizeDetector"

export enum Mode {
  MULTIPLE = "multiple",
  TAGS="tags"
}

export interface MultipleSelectWithOptionListProps {
  withBackground?: boolean
  mode?: Mode
  size?: SizeType
  isSimpleInput?: boolean
  style?: any
  value?: string[]
  loading?: boolean
  placeholder?: {
    add: string
    search: string
  }
  options: SelectableOption[]
  placement: TooltipPlacement
  trigger?: "click" | "hover" | "focus" | "contextMenu"
  disabled?: boolean
  onApply?: () => void
  onClear?: () => void
  onChange: (option: Pick<SelectableOption, 'value' | 'label'>, selectionState: boolean) => void
  onVisibilityChange?: (newVisibility: boolean) => void
  placementOverride?: (placement: TooltipPlacement) => TooltipPlacement
  allowMultipleSelection?: boolean
  noDataTextKey?: string
  onSelectionChange?: (options: string[]) => void
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onPaginationChange: (newPagination: OptionListPagination) => void
  onSearch: (search: string) => void
  onFocus?: () => void
  onBlur?: () => void
}

// eslint-disable-next-line react/display-name
export const MultipleSelectWithOptionList = forwardRef<any, MultipleSelectWithOptionListProps>(({
                                                                                                  mode,
                                                                                                  size,
                                                                                                  withBackground = true,
                                                                                                  isSimpleInput = false,
                                                                                                  loading = false,
                                                                                                  value,
                                                                                                  options,
                                                                                                  placement: originalPlacement,
                                                                                                  placeholder = {
                                                                                                    add: Language.get("configuration-add-value"),
                                                                                                    search: Language.get("configuration-search-value"),
                                                                                                  },
                                                                                                  placementOverride,
                                                                                                  trigger = "click",
                                                                                                  onApply,
                                                                                                  onChange,
                                                                                                  onClear,
                                                                                                  onVisibilityChange,
                                                                                                  disabled,
                                                                                                  allowMultipleSelection = true,
                                                                                                  style,
                                                                                                  onSelectionChange,
                                                                                                  getPopupContainer,
                                                                                                  onPaginationChange,
                                                                                                  onSearch,
                                                                                                  onFocus,
                                                                                                  onBlur,
                                                                                                }, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const previousIsVisible = usePrevious(isVisible)
  const [search, setSearch] = useState<string>()
  const [isHoverContainer, setIsHoverContainer] = useState<boolean>(false)
  const [placement, setPlacement] = useState<TooltipPlacement>(originalPlacement)
  const [selectorSize, setSelectorSize] = useState({
    width: 0,
  })
  const selectContainerRef = useRef<any>()
  const selectWithFocus = useRef<boolean>()
  const inputRef = useRef<any>()

  useImperativeHandle(ref,
    () => {
      return {
        focus() {
          inputRef.current.focus()
        },
        blur() {
          inputRef.current.blur()
        },
      }
    }, [])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === 13) {
      onBlur?.()
      setIsVisible(false)
    }
  }, [onBlur])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    if (!isVisible && previousIsVisible) {
      onBlur?.()
    }
  }, [isVisible, onBlur, previousIsVisible])

  useEffect(() => {
    if (search && search !== '' && !isVisible) {
      setIsVisible(true)
    }
  }, [isVisible, search])

  useEffect(() => {
    if (search !== undefined) {
      onSearch(search)
    }
  }, [onSearch, search])

  const changeVisibility = useCallback((newVisibility: boolean) => {
    setIsVisible(newVisibility)
    onVisibilityChange?.(newVisibility)
    if (!newVisibility && options.length > 0) {
      onApply?.()
      setSearch("")
    }
  }, [onApply, onVisibilityChange, options.length])

  const handleSelectionChange = useCallback((option: SelectableOption, selectionState: boolean) => {
    onChange(option, selectionState)
    if (!allowMultipleSelection) {
      changeVisibility(false)
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [allowMultipleSelection, changeVisibility, onChange])

  const handleOptionSelect = useCallback((option: SelectableOption, newSelectedState: boolean) => {
    // fixes #1362, simulate click on the antd selector in order to keep the search value displayed
    const selectors = selectContainerRef.current.getElementsByClassName("ant-select-selector")
    if (selectors.length > 0) {
      selectors[0].dispatchEvent(new MouseEvent('mousedown', {
        bubbles: true, // Allow the event to bubble up through the DOM tree
      }))
    }
    handleSelectionChange(option, newSelectedState)
  }, [handleSelectionChange])

  const dropDownContent = useMemo(() => (
    // eslint-disable-next-line react/no-find-dom-node
    <DropdownContent
      $selectwidth={["bottom", "top"].includes(placement) && selectContainerRef.current ? selectContainerRef.current?.getBoundingClientRect().width : undefined}>
      <Loader className="edit" loading={loading}>
        <OptionList
          {...{
            options,
            search,
            pagination: {
              offset: 1,
              pageSize: 20,
            },
            onPaginationChange,
            onSelect: handleOptionSelect,
          }}/>
      </Loader>
    </DropdownContent>
  ), [placement, loading, options, search, onPaginationChange, handleOptionSelect])

  useEffect(() => {
    if (selectContainerRef.current && placementOverride) {
      const newPlacementRaw: TooltipPlacement = selectContainerRef.current?.getBoundingClientRect().y < 360
        ? 'bottom'
        : 'top'
      const newPlacement = placementOverride ? placementOverride(newPlacementRaw) : newPlacementRaw
      if (newPlacement !== placement) {
        setPlacement(newPlacement)
      }
    } else if (window.innerWidth < 750 && placement !== "bottom") {
      setPlacement("bottom")
    }
  }, [placement, placementOverride])

  const handleOnBlur = useCallback(() => {
    if (!isVisible) {
      onBlur?.()
    } else if (!isHoverContainer) {
      setIsVisible(false)
    }
  }, [isHoverContainer, isVisible, onBlur])

  const selectTriggerComponent = useMemo(() => {
    return <div ref={selectContainerRef}><StyledSelect
      {...({
        $withbackground: withBackground ? 1 : 0,
        $issimpleinput: isSimpleInput ? 1 : 0,
        $isvisible: isVisible ? 1 : 0,
        ref: inputRef,
        $size: size,
        disabled,
        mode,
        virtual: false,
        onDropdownVisibleChange: (visible) => {
          selectWithFocus.current = visible
        },
        autoClearSearchValue: false,
        searchValue: search,
        onFocus,
        onBlur: handleOnBlur,
        onSearch: (searchValue) => {
          if (searchValue === "") {
            if (selectWithFocus.current) {
              setSearch('')
            }
          } else {
            setSearch(searchValue)
          }
        },
        dropdownStyle: {display: 'none'},
        onMouseDown: (event) => {
          event.stopPropagation()
        },
        ...(
          mode === Mode.MULTIPLE ? {
            allowClear: {clearIcon: <IconContainer><XIcon/></IconContainer>},
            placeholder: Language.get("configuration-add-value"),
            onChange: (v) => {
              if (inputRef && inputRef.current) {
                inputRef.current.focus()
              }
              onSelectionChange?.(v)
            },
            onClear: () => {
              if (inputRef && inputRef.current) {
                inputRef.current.focus()
              }
              onClear?.()
            },
            value,
          } : {
            showSearch: true,
            placeholder: <FlexDiv>
              <Placeholder $issimpleinput={isSimpleInput ? 1 : 0}>
                {isSimpleInput ? undefined : <PlaceholderIconContainer>
                  {isVisible ? <SearchIcon style={{fontSize: "18px"}}/> : <PlusIcon style={{fontSize: "18px"}}/>}
                </PlaceholderIconContainer>}
                <Placeholder $issimpleinput={isSimpleInput ? 1 : 0}>
                  {isVisible ? placeholder.search : placeholder.add}
                </Placeholder>
              </Placeholder>
              {isSimpleInput ? <IconContainer color={"var(--border-color-base)"} size={14}>
                <ChevronDownIcon/>
              </IconContainer> : undefined}
            </FlexDiv>,
            allowClear: isVisible,
            onClear: () => setSearch(''),
          }
        ),
      })
      }>
    </StyledSelect></div>
  }, [withBackground, isSimpleInput, isVisible, size, disabled, mode, search, onFocus, handleOnBlur, value, placeholder.search, placeholder.add, onSelectionChange, onClear])

  const onPopupVisibilityChange = useCallback((vis: boolean) => {
    if (vis) {
      changeVisibility(true)
    } else if (!isHoverContainer) {
      changeVisibility(false)
    }
  }, [changeVisibility, isHoverContainer])

  useResizeDetector(selectContainerRef, selectorSize, (newWidth) => {
    setSelectorSize({
      width: newWidth,
    })
  })

  return <FlexContainer
    {...{
      style,
      onMouseLeave: () => {
        setIsHoverContainer(false)

      },
      onMouseEnter: () => {
        setIsHoverContainer(true)
      },
    }}
  >
    <Popover
      {...{
        placement,
        trigger,
        overlayClassName: "box-overlay",
        onOpenChange: onPopupVisibilityChange,
        open: isVisible,
        content: dropDownContent,
        overlayInnerStyle: {
          minWidth: selectorSize.width,
        },
        getPopupContainer,
      }}>
      {selectTriggerComponent}
    </Popover>
  </FlexContainer>
})

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledSelect = styled(Select)<{ $withbackground: number, $issimpleinput: number, $isvisible: number, $size: SizeType }>`
  /* force antd select to be smaller than everywhere else */

  &&& {
    display: ${props => props.$withbackground ? `inherit` : 'flex'};
    height: 100%;
    max-height: 100%;
    width: 100%;
    line-height: 20px;

    ${({$issimpleinput}) => $issimpleinput ? "" : `border: 1px solid var(--border-color-base);
    border-radius: var(--main-border-radius);`}
    ${({$isvisible, $issimpleinput}) => $isvisible && !$issimpleinput ? `min-width: 300px;` : ""}
    .ant-select-clear{
      background-color: transparent;
      color: var(--light-grey);
    }
  }

  &&& .ant-select-selector {
    background: ${props => props.$withbackground ? `white` : 'transparent'};
    width: 100%;
    ${({size}) => size === "small" ? 'min-height: 32px;' : ''}
  }
    
  &&& .ant-select-arrow { 
      display: none; 
  }

  ${({$isvisible}) => $isvisible ? "" : `&&:hover * {
      color: var(--primary);
  }`}
  &&& .ant-select {
    line-height: 20px;
  }

  &&& .ant-select-selection-item {
    height: 24px;
    line-height: 24px;
  }

  &&& .ant-select-selector input{
    cursor: ${({$isvisible}) => $isvisible ? "text" : "pointer"} !important;
    color: ${({$isvisible}) => $isvisible ? "var(--medium-grey)" : ""} !important;
  }
` as unknown as typeof Select

const DropdownContent = styled.div<{ $selectwidth?: number }>`
  min-width: ${props => props.$selectwidth ? `${props.$selectwidth}px` : 'unset'}
`

const Placeholder = styled.div<{ $issimpleinput: number }>`
  color: ${props => props.$issimpleinput ? "inherit" : "rgb(89, 89, 89)"};
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;     
`

const PlaceholderIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18px;
  margin-right: 11px;
`

import React, {FunctionComponent} from "react"
import {Popover, PopoverProps} from "antd"

interface TooltipPopoverProps extends PopoverProps {
  size: "small" | "large"
}

export const TooltipPopover: FunctionComponent<TooltipPopoverProps> = (props) => <Popover {...props} overlayStyle={{
  ...props.style,
  maxWidth: props.size === "small" ? 450 : 785,
}}>
  {props.children}
</Popover>

import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {AdjustmentsIcon} from "@heroicons/react/outline"
import {Rule} from "antd/lib/form"
import MetricFilterSelector from "components/forms/selector/filters/MetricFilterSelector/MetricFilterSelector"

interface WrapperProps extends PropsWithChildren {
  name: string
  rules?: Rule[]
}

export const MetricFiltersWrapper: FunctionComponent<WrapperProps> = ({children, name, rules}) => (
  <WrapperRoot {...{
    icon: AdjustmentsIcon,
    label: "configuration-label-metrics-filters",
    description: "configuration-description-metrics-filters",
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MetricFiltersField = forwardRef<any, any>(function Field(props, ref) {
  return <MetricFilterSelector {...props} />
})

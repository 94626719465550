import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from "react"
import Language from "language"
import {Table, TableColumnProps, TablePaginationConfig} from 'antd'
import styled from "styled-components"
import UserManagerAceList from "components/admin/user/UserManager.AceList"
import UserManagerInfo from "components/admin/user/UserManager.Info"
import {ACES, Environment, UAC, User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {isEmpty} from "commons/object.utils"
import {filterData, Search, SearchRef} from "components/search/Search"
import {FilterIcon} from "@heroicons/react/outline"
import {FilterValue} from "antd/lib/table/interface"

enum SortOrder {
  DESCEND = 'descend',
  ASCEND = 'ascend'
}

interface DataSourceEntry {
  user: User,
  permUserCanEdit: boolean | "",
  active: boolean,
  id: number
}

interface Props {
  uacs: UAC[]
  acesByUserId: { [p: string]: ACES[] }
  loading: boolean
  getPermission: (key: string) => boolean
  clientAllowedEmailFqdns: string[]
  environment: Environment
  availableWorkspacesForAce: WorkspacesForAce[]
  onUserEdit: (user: any) => void
  onUserAcesEdit: (user: User) => void
}

const UserManagerAssocClientList: FunctionComponent<Props> = ({
                                                                uacs,
                                                                acesByUserId,
                                                                loading,
                                                                getPermission,
                                                                clientAllowedEmailFqdns,
                                                                environment,
                                                                availableWorkspacesForAce,
                                                                onUserEdit,
                                                                onUserAcesEdit,
                                                              }) => {
  const userCanManageAdvancedFeature = getPermission('userCanManageAdvancedFeature')
  const searchRef = useRef<SearchRef>(null)
  const [filteredInfo, setFilteredInfo] = useState<{
    'user.aces': FilterValue | null | undefined
  }>({
    'user.aces': undefined,
  })

  const data: DataSourceEntry[] = useMemo(() => uacs.map(({user, ...uac}) => ({
      ...uac,
      user: {
        ...user,
        aces: acesByUserId[String(user.id)] || [],
      },
      permUserCanEdit: userCanManageAdvancedFeature
        || (user.email && clientAllowedEmailFqdns.includes(user.email.substr(user.email.indexOf('@') + 1))),
    }),
  ), [uacs, acesByUserId, userCanManageAdvancedFeature, clientAllowedEmailFqdns])

  const [dataSource, setDataSource] = useState<DataSourceEntry[]>(data)

  useEffect(() => {
    setDataSource(data)
    searchRef.current?.clearSearch()
  }, [data])

  type AcesFilters = {
    text: string
    value: string
  }

  const acesFilters = useMemo(() => {
      return (Object.entries({
        role: ['WORKSPACE_USER', 'WORKSPACE_MANAGER', 'ENVIRONMENT_ADMIN', userCanManageAdvancedFeature && 'ENVIRONMENT_SUPERADMIN']
          .filter(Boolean)
          .map(role => ({
            text: Language.get(`enum.role.${role}`),
            value: role,
          })),
        target: [
          {
            text: Language.get(`enum.aceTargetType.environment`, environment),
            value: `environment:${environment.id}`,
          },
          ...availableWorkspacesForAce.map(w => ({
            text: Language.get(`enum.aceTargetType.workspace`, w),
            value: `workspace:${w.id}`,
          })),
        ],
      })).reduce((acc, [filterType, filterDefs]) => ([
        ...acc,
        ...filterDefs.map(({text, value}) => ({
          text: `${Language.get(`entityField.accessControlEntry.${filterType}`)} : ${text}`,
          value: `${filterType}:${value}`,
        })) as AcesFilters[],
      ]), [] as AcesFilters[])
    }
    , [environment, availableWorkspacesForAce, userCanManageAdvancedFeature])

  const columns: TableColumnProps<DataSourceEntry>[] = useMemo(() => ([
    {
      title: Language.get('entityField.user.$'),
      dataIndex: 'user',
      width: '40%',
      sorter: (a, b) => {
        const activeComparison = Number(b.active) - Number(a.active)
        if (activeComparison) {
          return activeComparison
        }
        return `${a.user.firstName} ${a.user.lastName}`.localeCompare(`${b.user.firstName} ${b.user.lastName}`)
      },
      defaultSortOrder: SortOrder.ASCEND,
      sortDirections: [SortOrder.ASCEND, SortOrder.DESCEND, SortOrder.ASCEND],
      render: function render(user, uac) {
        return <UserManagerInfo user={user} active={uac.active}
                                onUserEdit={uac.permUserCanEdit ? onUserEdit : undefined}/>
      },
    },
    {
      title: Language.get('entityField.user.aces'),
      dataIndex: ['user', 'aces'],
      className: 'verticalAlignTop',
      render: function render(aces, uac) {
        return <UserManagerAceList user={uac.user} aces={aces} onUserAcesEdit={onUserAcesEdit}/>
      },
      filters: acesFilters.map(filter => ({
        text: filter.text,
        value: filter.value,
      })),
      filteredValue: filteredInfo['user.aces'],
      onFilter: (filter, uac) => {
        const matchArray = filter.toString().match(/^(\w+):(.*)$/)
        if (!matchArray) {
          return false
        }
        const [, filterType, filterValue] = matchArray
        return !isEmpty(uac.user.aces.find(ace => {
          if (filterType === 'role') {
            return ace.roles.includes(filterValue)
          } else if (filterType === 'target') {
            return ace.target === filterValue
          } else {
            return false
          }
        }))
      },
      filterIcon: <StyledIcon/>,
    },
  ]), [onUserEdit, onUserAcesEdit, filteredInfo, acesFilters])

  const handleChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      setFilteredInfo({
        ...filters,
        'user.aces': filters['user.aces'],
      })
    }
    , [setFilteredInfo])

  return <div>
    <Search ref={searchRef} onChange={(newValues) => setDataSource(filterData(data, (record) => [
      record.user.email,
      record.user.firstName,
      record.user.fullName,
      record.user.lastName,
    ], newValues))} width={'calc(40% - 5px)'} searchedFieldKey={"entityField.user.$"}/>
    <Table {...{
      size: 'small',
      bordered: true,
      pagination: false,
      columns,
      loading,
      dataSource,
      rowKey: 'id',
      onChange: handleChange,
      scroll: {y: 650},
    }}/>
  </div>
}

export default UserManagerAssocClientList

const StyledIcon = styled(FilterIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  
  &&& {
    border: 1px solid var(--border-color-base);
    border-radius: 3px;
    height: 28px;
    width: 28px;
    padding: 4px;
  }
`
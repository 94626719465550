import {Switch} from "antd"
import React, {memo} from "react"

interface ActionSelectorProps {
  value?: boolean
  onChange?: (newState: boolean) => void
}

const ActionSelector = memo<ActionSelectorProps>(function ActionSelector({
                                                    value,
                                                    onChange,
                                                                         }) {
  return <Switch checked={value ?? false} onChange={onChange}/>
})

export default ActionSelector
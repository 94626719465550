import {useEffect, useMemo, useRef, useState} from "react"

type Callback = () => void

/**
 * provides a custom React Hook that behaves the same way as this.setState from React component classes, which may help
 * you transition a component from a class to a function when you want to use hooks in that component.
 * @param {any} initialState the initial state
 * @return {[any, function, function]} Returns a stateful value, a function to update it and a function to read it.
 */
export default <T>(initialState: T): [T, (newPartialState: T, callback?: (() => void)) => void, () => void] => {
  const [state, setState] = useState<T>(initialState)

  const ref = useRef<{
    state: T,
    callbacks: Callback[]
  }>({
    state,
    callbacks: [],
  })

  const [legacySetState, legacyGetState] = useMemo(() => [
    (newPartialState: T, callback?: (() => void)) => {
      if (callback) {
        ref.current.callbacks.push(callback)
      }
      setState({...ref.current.state, ...newPartialState})
    },
    () => ref.current.state
  ], []);

  useEffect(() => {
    ref.current.state = state

    const {callbacks} = ref.current
    ref.current.callbacks = []
    callbacks.forEach(callback => {
      callback()
    })
  }, [ref, state])

  return [state, legacySetState, legacyGetState]
}

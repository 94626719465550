import React, {useCallback, useEffect, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import Home from './Home'
import Delegate from 'Delegate'
import useDispatch from "./hooks/useDispatch"
import {useAuth, withAuthenticationRequired} from "react-oidc-context"
import API from "services/api"
import {useSelector} from "react-redux"
import {Outlet} from "react-router"
import {getTargetUrl, storeTargetUrl, useRenewToken} from "@biron-data/react-oidc"
import {captureError, captureWarn} from "services/SentryService"
import {Modal} from "antd"
import Language from "language"
import {getConsts} from "redux/appEnvironment.selector"
import Loader from "components/loader/Loader"

export const RequiredAuth: React.FC = withAuthenticationRequired(Outlet, {
  OnRedirecting: () => {
    return <Loader/>
  },
  onBeforeSignin: () => {
    storeTargetUrl()
  },
})

const AppContainer = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const consts = useSelector(getConsts)
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [hasNotifiedSessionExpired, setHasNotifiedSessionExpired] = useState<boolean>(false)

  useRenewToken(() => {
    setSessionExpired(true)
  }, consts?.OIDC_ISSUER)

  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.access_token) {
      const authToken = `Bearer ${auth.user?.access_token}`
      API.setToken(authToken)
      dispatch.appEnvironment.setAuthToken({
        auth,
        authToken,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.environment, auth.user?.access_token]);

  const triggerNewAuthFlow = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      dispatch.appEnvironment.initAuth({
          accessToken: auth.user?.access_token,
          auth,
        })
        .catch((err: Error) => {
          // eslint-disable-next-line no-console
          console.warn('fail to checkAuthentication -> logout', err)
          dispatch.appEnvironment.logout({auth})
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.user?.profile.sub, dispatch]) // we don't list auth as a dependency as it is modified in initAuth and would infinite loop

  // We use this style of modal as it is a better looking modal and it is seemingly the only way to have only one button
  const mustTriggerNewAuthFlow = useCallback(() => {
    Modal.info({
      title: Language.get('sessions.expired'),
      content: (
        <div>
          <p>{Language.get('sessions.expiredDescription')}.</p>
        </div>
      ),
      okText: Language.get('sessions.expiredContinue'),
      onOk: triggerNewAuthFlow,
    });
  }, [triggerNewAuthFlow]);

  useEffect(() => {
    if (sessionExpired && !hasNotifiedSessionExpired) {
      setHasNotifiedSessionExpired(true)
      mustTriggerNewAuthFlow()
    }
  }, [hasNotifiedSessionExpired, mustTriggerNewAuthFlow, sessionExpired]);

  if (auth.activeNavigator === "signoutRedirect") {
    return <Loader/>
  }

  if (auth.error && !sessionExpired) {
    if (auth.error.message === "No matching state found in storage") {
      // user has tried to login in two different tabs at the same time: local storage cannot follow
      captureWarn(auth.error.message)
      window.location.replace(window.location.origin + getTargetUrl())
    } else {
      captureError(auth.error.message)
      dispatch.appEnvironment.logout({auth})
    }
    return <Loader/>
  }

  return <Routes>
    <Route path="*" element={<RequiredAuth/>}>
      <Route path="delegate/:target" element={<Delegate/>}/>
      <Route path="nav/*" element={<Home/>}/>
      <Route
        path="*"
        element={<Navigate to="app" replace/>}
      />
    </Route>
    <Route path="app" element={<RequiredAuth/>}>
      <Route path="" element={<Home/>}/>
      <Route path="*" element={<Home/>}/>
    </Route>
  </Routes>
}

export default AppContainer
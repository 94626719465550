import React, {memo, useCallback, useEffect, useMemo} from 'react'
import {Col, Row} from 'antd'
import FilterPopover from 'components/forms/selector/filters/FilterPopover'
import BoxesWrapper from 'components/forms/selector/comps/BoxesWrapper'
import styled from "styled-components"
import {Dimension} from "classes/MetaModel"
import {isEqual} from "lodash"
import {useDimensionOptions} from "hooks/useDimensionOptions"
import {ConfFilter, SelectorFilterTypes} from 'components/forms/selector/comps/box/filters'
import {getDefaultDimensionPredicate} from "components/forms/selector/filters/FilterPopover.utils"
import {notEmpty} from "commons/object.utils"
import {
  consolidateUniversalConfFilterToDimension,
} from "components/forms/selector/filters/DimensionFilterSelector/DimensionFilterSelector.utils"
import {captureEvent} from "services/GoogleAnalyticsService"

export interface FilterSelectorProps {
  environmentId: number
  value?: ConfFilter[]
  availableDimensions: Dimension[]
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onChange: (filters: ConfFilter[]) => void
  span?: number
  GACategory: string
}

const DimensionFilterSelector = memo<FilterSelectorProps>(function FilterSelector({
                                                                           value = [],
                                                                                    environmentId,
                                                                           availableDimensions: originalAvailableDimensions,
                                                                           getPopupContainer,
                                                                           onChange,
                                                                           span = 14,
                                                                                    GACategory,
                                                                         }) {
  const handleChange = useCallback((newFilters: SelectorFilterTypes[]) => onChange(newFilters.map(filter => consolidateUniversalConfFilterToDimension(filter)).filter(notEmpty)), [onChange])

  useEffect(() => {
    const filtersWithAvailableDimension = value.filter(filter => originalAvailableDimensions.find(dimension => dimension.code === filter.reference.code))
    if (!isEqual(filtersWithAvailableDimension, value)) {
      handleChange(filtersWithAvailableDimension)
    }
  }, [originalAvailableDimensions, handleChange, value])

  const availableDimensions = useMemo(
    () => originalAvailableDimensions.filter(dimension => Boolean(dimension.dictionaryCode)),
    [originalAvailableDimensions],
  )

  const getOptions = useDimensionOptions(availableDimensions)

  const handleAddEmptyFilter = useCallback((newFilters: SelectorFilterTypes[]) => {
    captureEvent({
      category: GACategory,
      action: `add_dimension_filter`,
    })
    handleChange(newFilters)
  }, [GACategory, handleChange])

  return <div className="filter-selector">
    <Row>
      <Col span={span}>
        <StyledBoxesWrapper {...{
          environmentId,
          filters: value,
          seeDescription: true,
          layout: "column",
          onFiltersChange: handleChange,
          numberOfFilterPerLine: 1,
          bordered: true,
          getPopupContainer,
          GACategory,
          $nbfilters: value.length,
        }}/>
        <StyledFilterPopover>
          <FilterPopover {...{
            isSimpleInput: true,
            filters: value,
            getPopupContainer,
            getOptions,
            onTemporaryFiltersChange: handleAddEmptyFilter,
            colorized: true,
            allowMultipleSelection: false,
            getDefaultValue: getDefaultDimensionPredicate,
          }}/>
        </StyledFilterPopover>
      </Col>
    </Row>
  </div>
})

export default DimensionFilterSelector

const StyledBoxesWrapper = styled(BoxesWrapper)<{ $nbfilters: number }>`
    ${({$nbfilters}) => $nbfilters > 0 ? 'margin-bottom: 5px;' : ''}
`

const StyledFilterPopover = styled.div`

  &&& .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
    border-color: var(--border-color-input) !important;
  }
`

import React, {FunctionComponent, memo, useCallback} from "react"
import {StyledItemWithHelp} from "components/itemWithHelp/ItemWithHelp"
import styled from "styled-components"
import {CHART_SUMMARY_HEIGHT} from "components/charts/Chart.constants"
import _ from "lodash"
import {IconContainer} from "components/common/IconContainer"
import {FilterIcon as FilterIconOutline} from "@heroicons/react/outline"
import {TooltipContent} from "components/common/TooltipContent"
import Language from "language"
import {getFilterResume} from "components/forms/selector/comps/box/utils"
import {ColorizedSourceItem} from "components/colorizedSourceItem/ColorizedSourceItem"
import {
  ConfDimensionFilterTypesWithSource,
  ConfMetricFilterTypesWithSource,
  FilterNegationType,
  filterTypeToIcon,
  Source,
} from "components/forms/selector/comps/box/filters"

interface FilterSummaryProps {
  filters: (ConfDimensionFilterTypesWithSource | ConfMetricFilterTypesWithSource)[]
}

export const FilterSummary: FunctionComponent<FilterSummaryProps> = ({filters}) => {
  const displayFilters = useCallback((Component: typeof SummaryFilter | typeof HelpFilter) => {
    return _.sortBy(filters, (filter) => filter.reference.alias).map((filter, y) => {
      return <Component key={y}
                        source={filter.source}
                        dimensionAlias={filter.reference.alias}
                        negate={filter.predicate.negate}
                        text={getFilterResume(filter)}/>
    })
  }, [filters])


  return <StyledItemWithHelp help={<TooltipContent Icon={FilterIconOutline} title={Language.get("configuration-label-filters")}
                                                   description={displayFilters(HelpFilter)}/>}>
    <IconContainer size={12}><MainFilterIcon type="filter"/></IconContainer>
    <LabelSpan>
      {
        displayFilters(SummaryFilter)
      }
    </LabelSpan>
  </StyledItemWithHelp>
}

const MainFilterIcon = styled(FilterIconOutline)`
    width: 10px;
    height: 10px;
`

interface FilterSummaryComponentProps {
  dimensionAlias?: string
  source: Source
  negate: boolean
    text: string
}

const HelpFilter = memo<FilterSummaryComponentProps>(function HelpFilter({dimensionAlias, negate, text, source}) {
  const FilterIcon = filterTypeToIcon.get(negate ? FilterNegationType.negate : FilterNegationType.default)

  return <div>
    <ColorizedSourceItem value={{source}} baseColor={'--main-text-color'}>
      {dimensionAlias}
      <FlexDiv>
        <div>
          {FilterIcon && <IconContainer size={15}><FilterIcon/></IconContainer>}
        </div>
        <br/>
        <i>{text}</i>
      </FlexDiv>
    </ColorizedSourceItem>
  </div>
})

const FlexDiv = styled.div`
    display: flex;
`

const SummaryFilter = memo<FilterSummaryComponentProps>(function SummaryFilter({dimensionAlias, source, negate, text}) {
    const FilterIcon = filterTypeToIcon.get(negate ? FilterNegationType.negate : FilterNegationType.default)

    return <StyledSpan>
      <ColorizedSourceItem value={{source}}>
        <AliasSpan>{dimensionAlias}</AliasSpan>
        <StyledDiv>
          {FilterIcon && <SmallFilterIcon><FilterIcon/></SmallFilterIcon>}
          <ValueSpan>
            {text}
          </ValueSpan>
        </StyledDiv>
      </ColorizedSourceItem>
    </StyledSpan>
})
const SmallFilterIcon = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    svg {
        width: 7px;
        height: 7px;
        margin-right: 3px;
    }
`
const LabelSpan = styled.span`

  margin-left: 5px;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  max-height: ${CHART_SUMMARY_HEIGHT}px;

  &.timeInterval {
    white-space: normal;
    min-width: 60px;
    max-height: 2em;
    overflow: hidden;
  }
`

const AliasSpan = styled.span`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding-bottom: 2px;
`

const ValueSpan = styled.span`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    width: 100%;
`

const StyledDiv = styled.div`
    display: flex;
    font-size: 11px;
    flex-direction: row;
    height: 13px;
`

const StyledSpan = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child) {
        padding-left: 4px;
        border-left: 1px dashed var(--border-color-base);
    }

    &:not(:last-child) {
        margin-right: 4px;
    }
`
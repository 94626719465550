import React, {memo, useCallback, useMemo} from 'react'
import SingleSlicerSelector from './SingleSlicerSelector'
import {SlicerDtoDetailTypes} from "types/savedConfs"
import {Dimension} from "classes/MetaModel"
import {DateOption, getDimensionOption} from "components/forms/selector/utils"
import Language from "language"
import {ConfSlicer} from "types/charts"
import SortableContainerContext, {getIdFromIndex, getIndexFromID} from "components/common/sortable/SortableContainerContext"
import SortableOverlay from "components/common/sortable/SortableOverlay"
import SortableItem from "components/common/sortable/SortableItem"
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import styled from "styled-components"
import useFixValue from "hooks/useFixValue"
import {ChartTypes, ConfigurationWithDisabledReasons, SlicerConfiguration} from "components/forms/chart/types"

interface Props {
  value: ConfSlicer[]
  availableDimensions: Dimension[]
  unavailableDimensions: Dimension[]
  disabled?: boolean
  multiple?: boolean
  onChange: (value: any) => void
  getPopupContainer: () => any
  defaultSlicer?: SlicerConfiguration,
  configuration: ConfigurationWithDisabledReasons<ChartTypes>
}

const DATE_SLICER_VALUE = "date_slicer"

const SlicerSelector = memo<Props>(function SlicerSelector({
                                                             value: originalValue,
                                                             availableDimensions,
                                                             unavailableDimensions,
                                                             disabled,
                                                             multiple = false,
                                                             onChange,
                                                             getPopupContainer,
                                                             configuration,
                                                           }) {
  const value = useMemo(() => {
      const validValue = originalValue
        .filter((__, index) => multiple || index === 0)
        .filter(slicer => {
          switch (slicer.type) {
            case "dimension":
              return availableDimensions.find(dimension => dimension.code === slicer.dimensionCode)
            case "date":
              return true
            default: {
              const exhaustiveCheck: never = slicer
              return exhaustiveCheck
            }
          }
        })
      return validValue.length === originalValue.length ? originalValue : validValue
    },
    [availableDimensions, originalValue, multiple],
  )
  useFixValue(onChange, originalValue, value)

  const handleDelete = useCallback((i: number) => {
      onChange(value.slice(0, i).concat(value.slice(i + 1)))
    },
    [value, onChange],
  )

  const handleChange = useCallback((i: number, v: string) => {
      if (v === DATE_SLICER_VALUE) {
        onChange(value.slice(0, i).concat([{type: "date", isDefault: false}], value.slice(i + 1)))
      } else {
        onChange(value.slice(0, i).concat([{dimensionCode: v, type: "dimension", isDefault: false}], value.slice(i + 1)))
      }
    },
    [value, onChange],
  )

  const handleAdd = useCallback((v: string) => {
      if (v === DATE_SLICER_VALUE) {
        const indexOfDateSlicer = value.findIndex(slicer => slicer.type === "date")
        if (indexOfDateSlicer === -1) {
          onChange([...value, {type: "date", isDefault: false}])
        } else {
          const newValue = Object.assign([] as ConfSlicer[], value)
          newValue[indexOfDateSlicer].isDefault = false
          onChange(newValue)
        }
      } else {
        onChange([...value, {dimensionCode: v, type: "dimension", isDefault: false}])
      }
    },
    [value, onChange],
  )


  const handleMove = useCallback((slicers: ConfSlicer[], oldIndex: number, newIndex: number) => {
      const newValue = value
      newValue[oldIndex].isDefault = false
      newValue[newIndex].isDefault = false
      onChange(slicers)
    },
    [onChange, value],
  )

  const options = useMemo(
    () => {
      const dateSlicer = value.find(slicer => slicer.type === "date")
      return [
        {
          label: Language.get("date-slicer-option-label"),
          value: DATE_SLICER_VALUE,
          selectorLabel: `${Language.get("date-slicer-option-label")}${dateSlicer?.isDefault ? ` - ${Language.get("by-default")}` : ''}`,
          disabled: dateSlicer && !dateSlicer.isDefault,
        } as DateOption,
        ...getDimensionOption(availableDimensions, unavailableDimensions).map((option) => ({
          ...option,
          selectorLabel: option.label,
        })),
      ]
    }, [value, availableDimensions, unavailableDimensions])

  const getSlicerRepresentation = useCallback((slicer: SlicerDtoDetailTypes) => {
    switch (slicer.type) {
      case "dimension":
        return slicer.dimensionCode
        break
      case "date":
        return DATE_SLICER_VALUE
        break
      default: {
        const exhaustiveCheck: never = slicer
        return exhaustiveCheck
      }
    }
  }, [])

  return <div>
    <SortableContainerContext
      value={value}
      handleChange={handleMove}
      overlayRender={(activeId) => {
        const slicer = value[getIndexFromID(activeId)]
        return <SortableOverlay activeId={activeId}>
          <SingleSlicerSelector
            {...{
              id: activeId,
              value: getSlicerRepresentation(slicer),
              availableDimensions,
              disabled,
              options,
              canRemove: true,
              getPopupContainer,
              configuration,
              isDefault: slicer.isDefault,
            }}/>
        </SortableOverlay>
      }
      }>
      <SortableContext items={value?.map((v, index) => ({
        ...v,
        id: getIdFromIndex(index),
      })) ?? []} strategy={verticalListSortingStrategy}>
        {value?.map((valueItem, index) => <SortableItem id={getIdFromIndex(index)}
                                                        key={`${getSlicerRepresentation(valueItem)}-${getIdFromIndex(index)}`}>
          <SingleSlicerSelector
            {...{
              id: getIdFromIndex(index),
                value: getSlicerRepresentation(valueItem),
              availableDimensions,
                disabled,
                options,
                canRemove: true,
                onDelete: handleDelete,
                onSelect: (selectedValue) => handleChange(index, selectedValue),
                getPopupContainer,
                configuration,
                isDefault: valueItem.isDefault,
              }}/>
          </SortableItem>,
        )}
      </SortableContext>
    </SortableContainerContext>
    <SlicerSelectorContainer>
      {!disabled && (multiple || value.length === 0) && <SingleSlicerSelector {...{
        id: value.length,
        availableDimensions,
        options,
        canRemove: false,
        onSelect: (newValue) => handleAdd(newValue),
        getPopupContainer,
      }}/>
      }
    </SlicerSelectorContainer>
  </div>
})

export default SlicerSelector

const SlicerSelectorContainer = styled.div`
  width: ${100 - (100 / 24) - 3}%;
  margin-left: ${100 / 24}%;
  `
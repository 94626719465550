import React, {memo, useCallback, useMemo} from 'react'
import styled from "styled-components"
import {Checkbox, Col, InputNumber} from 'antd'
import Language from "language"
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import {isEmpty} from "commons/object.utils"
import {RowWithDefaultValue} from "components/forms/selector/utils"
import {ConfLimit} from "components/forms/chart/types"

interface Props {
  value?: ConfLimit
  onSortChange?: (value: ConfLimit) => void
  mandatory: boolean
  affect: string
  displaySlicerOtherOption: boolean
  multiple: boolean
}

const SingleLimitSelector = memo<Props>(function SortSelector({
                                                                value,
                                                                onSortChange,
                                                                mandatory,
                                                                affect,
                                                                displaySlicerOtherOption,
                                                                multiple,
                                                              }) {
  const [prefix, suffix] = Language.get("configuration-chart-additional-detail.limitSeries.label").split("###")

  const isLimitFilled = useMemo(() => value?.limitSeries !== undefined, [value])
  const isHideOtherOptionAvailable = useMemo(() => displaySlicerOtherOption && isLimitFilled, [displaySlicerOtherOption, isLimitFilled])

  const handleChange = useCallback((type: "limitSeries" | "hideOthers", newValue: number | boolean | undefined) => {
    onSortChange?.({
      ...value,
      hideOthers: value?.hideOthers ?? !isHideOtherOptionAvailable,
      isDefault: false,
      [type]: newValue,
    })
  }, [isHideOtherOptionAvailable, onSortChange, value])

  return <StyledRow $isdefault={value?.isDefault ? 1 : 0} $limitapplied={value?.limitSeries === undefined ? 0 : 1}>
    {
      multiple && <Col span={3}>
        {Language.get(`configuration-affect.${affect}`)}
      </Col>
    }
    <Col span={21}>
      {
        mandatory ? prefix : <Checkbox checked={isLimitFilled}
                                       onChange={() => {
                                         handleChange("limitSeries", isEmpty(value?.limitSeries) ? 20 : undefined)
                                       }}>
          {prefix}
        </Checkbox>
      }
      <InputNumber {...{
        min: 1,
        max: 100,
        value: value?.limitSeries,
        style: {width: 70},
        size: 'small',
        onChange: (newValue: number | null) => {
          if (newValue) {
            handleChange("limitSeries", newValue)
          }
        },
      }}/>
      {suffix}
      {isHideOtherOptionAvailable && <StyledDescription>
        {Language.get("configuration-chart-additional-detail.limitSeries.shortDescription")}
      </StyledDescription>}
    </Col>
    {isHideOtherOptionAvailable && <Col span={21} offset={multiple ? 3 : 0}>
      <Checkbox
        checked={value?.hideOthers}
        onChange={(event: CheckboxChangeEvent) => handleChange("hideOthers", event.target.checked)}>
        {Language.get("configuration-chart-additional-detail.hideOthers.label")}
      </Checkbox>
      <StyledDescription>{Language.get("configuration-chart-additional-detail.hideOthers.description")}</StyledDescription>
    </Col>}
  </StyledRow>
})

export default SingleLimitSelector

const StyledRow = styled(RowWithDefaultValue)<{ $isdefault: number, $limitapplied: number }>`
  margin-bottom: 6px;
  user-select: none;
    line-height: var(--line-height);


    &&& .ant-input-number {
        line-height: var(--line-height);
    }

    ${(props) => props.$isdefault && props.$limitapplied ? `
  .ant-checkbox-inner {
    border-color: var(--medium-grey) !important;
    background-color: var(--medium-grey) !important;
  }
  ` : ``}
`

const StyledDescription = styled.span`
  margin-left: 10px;
  font-size: 12px;
  line-height: 16px;
  color: var(--medium-grey);
`

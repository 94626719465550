import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react"
import * as echarts from "echarts"
import {EChartOption, ECharts, LineSeriesOption} from "echarts"
import {LEGEND_ID, THEME} from "components/charts/Chart.constants"
import {EChartTheme} from "components/charts/Chart.Theme"
import ReactECharts from "echarts-for-react"
import {EChartsReactProps} from "echarts-for-react/lib/types"
import {asArray} from "commons/asArray"
import {formatValue} from "commons/format/formatter"
import {Format} from "types/charts"
import {
  standardGridOptions,
  standardLegendOption,
  standardSerieBlur,
  standardSerieEmphasis,
  standardTextStyle,
} from "components/charts/Chart.options"
import * as _ from "lodash"
import {IconContainer} from "components/common/IconContainer"
import {ExclamationIcon} from "@heroicons/react/solid"
import styled from "styled-components"

echarts.registerTheme(THEME, EChartTheme)

interface Props extends EChartsReactProps {
  dimensions: {
    height: number,
    width: number,
  },
  axisFormats: Format[]
  isValueAxisOnYAxis?: boolean
  includeLegend?: boolean,
  sideLegend?: boolean
  events?: Record<string, (...data: any) => any>
  warning?: string
}

type EChartsInstance = {
  getEchartsInstance: () => ECharts
}

export interface BaseChartRef {
  getDataUrl: () => string | undefined
  getEchartsInstance: () => echarts.ECharts | undefined
}

const ChartBase = forwardRef<BaseChartRef, Props>(function BaseChart({
                                                                       isValueAxisOnYAxis = true,
                                                                       ...props
                                                                     }, ref) {
  const echartRef = useRef<EChartsInstance>(null)

  const clickCallback = useCallback((data: { seriesType: string, dataIndex?: number, seriesIndex?: number }) => {
    echartRef.current?.getEchartsInstance().dispatchAction({
      type: 'legendScroll',
      scrollDataIndex: data.seriesType === 'pie' ? data.dataIndex : data.seriesIndex,
      legendId: LEGEND_ID,
    })
  }, [echartRef])

  // To prevent user from unselecting we reselect automatically
  // SelectedMode breaks other interactive aspects
  // https://github.com/apache/echarts/issues/11883
  const legendSelectCallback = useCallback((data: { name: string }) => {
    // Re-select what the user unselected
    echartRef.current?.getEchartsInstance().dispatchAction({
      type: 'legendSelect',
      name: data.name,
    })
  }, [])
  const events = {
    click: clickCallback,
    legendselectchanged: legendSelectCallback,
    ...props.events,
  }

  useImperativeHandle(ref, () => ({
    getDataUrl: (): string | undefined => {
      return echartRef.current?.getEchartsInstance().getDataURL({
        excludeComponents: ["toolbox"],
      })
    },
    getEchartsInstance: (): echarts.ECharts | undefined => {
      return echartRef.current?.getEchartsInstance()
    },
  }), [echartRef])

  const legendAndToolbarHeight = (props.includeLegend && !props.sideLegend) ? 30 : 0
  const padding = 10
  const sideLegendWidth = props.sideLegend && props.includeLegend ? 120 : 0
  const {option}: { option: EChartOption<LineSeriesOption> } = props

  option.grid = _.merge(
    standardGridOptions(padding, legendAndToolbarHeight),
    {
      width: props.dimensions.width - (padding * 4) - sideLegendWidth - (props.sideLegend ? 10 : 0),
    }, props.option.grid,
  )

  option.yAxis = props.option.yAxis ?? [{
    type: 'value',
  }]

  option.series = option.series?.map((serie) => _.merge({
    emphasis: standardSerieEmphasis(),
    blur: standardSerieBlur(),
    label: standardTextStyle(),
  }, serie))

  option.yAxis = asArray(option.yAxis).map((axis: echarts.EChartOption.YAxis, index) => _.merge({
    axisLabel: {
      formatter: isValueAxisOnYAxis ? (value: string) => {
        return formatValue(value, {...props.axisFormats[index], summarizeValue: true})
      } : undefined,
      ...standardTextStyle(),
    },
    splitLine: {
      lineStyle: {
        color: "#eee",
      },
    },
  }, axis))

  option.xAxis = asArray(option.xAxis).map((axis: echarts.EChartOption.XAxis, index) => _.merge({
    axisLabel: {
      formatter: isValueAxisOnYAxis ? undefined : (value: string) => {
        return formatValue(value, {...props.axisFormats[index], summarizeValue: true})
      },
      ...standardTextStyle(),
    },
    axisLine: {
      lineStyle: {
        color: "#DCE0E4",
      },
    },
  }, axis))

  option.legend = _.merge(
    standardLegendOption(Boolean(props.sideLegend), sideLegendWidth),
    {show: props.includeLegend},
    props.option.legend)

  const [warningHeight, setWarningHeight] = useState(0)
  const chartHeight = useMemo(() => props.dimensions.height - warningHeight, [props.dimensions.height, warningHeight])

  return <><ReactECharts
    {...props}
    // @ts-ignore
    ref={echartRef}
    option={option}
    style={{height: chartHeight}}
    theme={THEME}
    onEvents={events}
    notMerge={props.notMerge ?? true}
  />
    {props.warning && <WarningMessage ref={(node) => setWarningHeight(node?.offsetHeight ?? 0)}>
      <IconContainer><ExclamationIcon/></IconContainer>
      {props.warning}
    </WarningMessage>}</>
})

export default ChartBase

const WarningMessage = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  font-size: 0.7em;
  line-height: 1em;
  color: var(--light-text);
  align-items: center;
  gap: 8px;
  `
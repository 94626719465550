import React, {memo, useCallback, useEffect} from 'react'
import {Col, Row} from 'antd'
import styled from "styled-components"
import MetaModel, {MetaModelView} from "classes/MetaModel"
import {ChartMetricDtoDetail} from "types/savedConfs"
import {hasMultipleViews} from "components/charts/Chart.utils"
import {isEqual} from "lodash"
import {notEmpty} from "commons/object.utils"
import {getMetricLabel} from "components/forms/chart/utils"
import {captureEvent} from "services/GoogleAnalyticsService"
import {ConfFilter, SelectorFilterTypes} from "components/forms/selector/comps/box/filters"
import {consolidateUniversalConfFilterToMetric} from "components/forms/selector/filters/MetricFilterSelector/MetricFilterSelector.utils"
import {useMetricOptions} from "components/forms/selector/filters/MetricFilterSelector/MetricFilterSelector.hooks"
import FilterPopover from "components/forms/selector/filters/FilterPopover"
import {getDefaultMetricPredicate} from "components/forms/selector/filters/FilterPopover.utils"
import BoxesWrapper from "components/forms/selector/comps/BoxesWrapper"

export interface FilterSelectorProps {
  environmentId: number
  value?: ConfFilter[]
  metrics: Pick<ChartMetricDtoDetail, "metricCode" | "viewCode" | "metricAlias" | "titlePartOverrides" | "additionalFilters">[]
  viewsWithMetrics: Pick<MetaModelView, "code" | "metrics" | "alias">[],
  metaModel: MetaModel,
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  onChange?: (filters: ConfFilter[]) => void
  span?: number
  GACategory: string
  seeDescription?: boolean
}

const MetricFilterSelector = memo<FilterSelectorProps>(function FilterSelector({
                                                                                 value = [],
                                                                                 metrics,
                                                                                 viewsWithMetrics,
                                                                                 metaModel,
                                                                                 environmentId,
                                                                                 getPopupContainer,
                                                                                 onChange,
                                                                                 span = 14,
                                                                                 GACategory,
                                                                                 seeDescription,
                                                                               }) {
  const isMultiView = hasMultipleViews(metrics)

  const handleChange = useCallback((newFilters: SelectorFilterTypes[]) => {
    onChange?.(newFilters.map(filter => consolidateUniversalConfFilterToMetric(filter)).filter(notEmpty))
    }, [onChange],
  )
  useEffect(() => {
    const filtersWithMetrics = value.filter(filter => metrics.find(metric => {
      const metricAlias = metric && viewsWithMetrics ? getMetricLabel(viewsWithMetrics, {
        metricCode: metric.metricCode,
        metricAlias: metric.metricAlias,
        viewCode: metric.viewCode,
      }, true) : undefined
      return filter.reference.id === undefined || metricAlias === filter.reference.id
    }))
    if ((filtersWithMetrics.length > 0 || value.length > 0) && !isEqual(filtersWithMetrics, value)) {
      handleChange(filtersWithMetrics)
    }
  }, [handleChange, metrics, value, viewsWithMetrics])

  const getMetricFilters = useMetricOptions(metrics)

  const getOptions = useCallback(() => getMetricFilters(metaModel, viewsWithMetrics, isMultiView, value),
    [getMetricFilters, isMultiView, metaModel, value, viewsWithMetrics],
  )

  const handleAddEmptyFilter = useCallback((newFilters: SelectorFilterTypes[]) => {
    handleChange(newFilters)
    captureEvent({
      category: 'charts',
      action: 'add_metric_filter',
    })
  }, [handleChange])

  return <div className="filter-selector">
    <Row>
      <Col span={span}>
        <StyledBoxesWrapper {...{
          environmentId,
          filters: value,
          seeDescription,
          layout: "column",
          onFiltersChange: handleChange,
          numberOfFilterPerLine: 1,
          bordered: true,
          getPopupContainer,
          GACategory,
          isResumable: false,
        }}/>
        <StyledFilterPopover>
          <FilterPopover {...{
            isSimpleInput: true,
            filters: value,
            getPopupContainer,
            getOptions,
            onTemporaryFiltersChange: handleAddEmptyFilter,
            colorized: true,
            allowMultipleSelection: false,
            getDefaultValue: getDefaultMetricPredicate,
          }}/>
        </StyledFilterPopover>
      </Col>
    </Row>
  </div>
})

export default MetricFilterSelector

const StyledBoxesWrapper = styled(BoxesWrapper)`
  margin-bottom: 5px;
`

const StyledFilterPopover = styled.div`

  &&& .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
    border-color: var(--border-color-input) !important;
  }
`

import React, {memo, useCallback, useMemo} from 'react'
import Language from 'language'
import {Col, Select} from 'antd'
import styled from "styled-components"
import {isEmpty} from "commons/object.utils"
import {getIndexFromID} from "components/common/sortable/SortableContainerContext"
import {ChartTypes, ConfigurationWithDisabledReasons, SlicerTarget} from "components/forms/chart/types"
import DimensionDescription from "components/forms/description/DimensionDescription"
import {Dimension} from "classes/MetaModel"
import {ColWithLeftMargin, DateOption, DimensionOption, SortableRowWithDefaultValue} from "components/forms/selector/utils"

interface Props {
  id: any
  value?: string
  sortable?: boolean
  availableDimensions: Dimension[]
  disabled?: boolean
  canRemove: boolean
  onSelect?: (value: any) => void
  onDelete?: (id: any) => void
  options: (DimensionOption | DateOption)[]
  getPopupContainer: () => any
  configuration?: ConfigurationWithDisabledReasons<ChartTypes>
  isDefault?: boolean
}

const SingleSlicerSelector = memo<Props>(function SingleSlicerSelector({
                                                                         id,
                                                                         value,
                                                                         disabled,
                                                                         availableDimensions,
                                                                         canRemove,
                                                                         onSelect,
                                                                         onDelete,
                                                                         options,
                                                                         getPopupContainer,
                                                                         configuration,
                                                                         isDefault,
                                                                       }) {
  const handleChange = useCallback((newValue: unknown) => {
      if (!newValue) {
        onDelete?.(getIndexFromID(id))
      }
    },
    [id, onDelete],
  )

  const getSlicerTargetFormatted = useCallback((slicerTargets: SlicerTarget[] | undefined, index: number) => {
    if (!configuration || isEmpty(slicerTargets) || configuration.slicerTargets === undefined) {
      return ''
    }
    const correspondingTarget = slicerTargets?.[index < configuration.slicerTargets.length ? index : configuration.slicerTargets.length - 1]
    switch (correspondingTarget) {
      case SlicerTarget.COLUMN:
        return `${Language.get(`configuration-slicer-target.${SlicerTarget.COLUMN}`)} ${index + 1}`
      default:
        return isEmpty(correspondingTarget) ? '' : Language.get(`configuration-slicer-target.${correspondingTarget}`)
    }
  }, [configuration])

  const placeholder = `${Language.get('configuration-add-slicer')} ...`

  const relatedDimension = useMemo(() => availableDimensions.find(dimension => dimension.code === value), [availableDimensions, value])

  return <SortableRowWithDefaultValue $isdefault={isDefault ? 1 : 0}>
    <Col span={14}>
      <StyledSelect {...{
        value: value ?? null,
        disabled,
        placeholder,
        showSearch: true,
        allowClear: canRemove,
        optionFilterProp: "children",
        onChange: handleChange,
        onSelect,
        virtual: false,
        getPopupContainer,
        optionLabelProp: "label",
      }}>
        {options.map(option => <Select.Option key={option.value} disabled={option.disabled}
                                              label={option.selectorLabel}>{option.label}</Select.Option>)}
      </StyledSelect>
    </Col>
    <StyledCol $value={5} span={1}>
      {relatedDimension && <DimensionDescription dimension={relatedDimension}/>}
    </StyledCol>
    <ColWithLeftMargin $value={5}>
      <TargetContainer>
        {configuration && getSlicerTargetFormatted(configuration.slicerTargets, getIndexFromID(id))}
      </TargetContainer>
    </ColWithLeftMargin>
  </SortableRowWithDefaultValue>
})

export default SingleSlicerSelector

const TargetContainer = styled.div`
  color: var(--light-grey);
    height: 100%;
    align-content: center;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

const StyledCol = styled(ColWithLeftMargin)`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
`


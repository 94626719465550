import React, {FunctionComponent, useCallback} from 'react'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import WrapperRoot from "components/forms/confItems/WrapperRoot"
import {Radio} from "antd"
import Language from "language"
import styled from "styled-components"
import {PeriodType} from "types/period.types"
import {RadioChangeEvent} from "antd/lib/radio/interface"

interface Props {
  name: string
  value?: PeriodType
  onChange?: (newValue: PeriodType) => void
}

const Type: FunctionComponent<Props> = ({
                                          name,
                                          onChange,
                                          value,
                                        }) => {
  const handleChange = useCallback((e: RadioChangeEvent) => {
    onChange?.(e.target.value)
  }, [onChange])

  return <WrapperRoot>
    <WrapperItem name={name}>
      <RadioGroup onChange={handleChange}>
        <Radio value={PeriodType.fixed} checked={value === PeriodType.fixed}>{Language.get("period.type.fix")}</Radio>
        <Radio value={PeriodType.dynamic} checked={value === PeriodType.dynamic}>{Language.get("period.type.dynamic")}</Radio>
      </RadioGroup>
    </WrapperItem>
  </WrapperRoot>
}

export default Type

const RadioGroup = styled(Radio.Group)`
  border: none;
  display: flex;
  flex-direction: row;
  gap: 15%;
  margin-right: 5px;
  margin-left: 5px;
`
import {useEffect, useRef} from "react";

// from https://medium.com/trabe/preventing-click-events-on-double-click-with-react-the-performant-way-1416ab03b835

const noop = () => {};

const requestTimeout = (fn: (() => void) | undefined, delay: number, registerCancel: ((fn: () => void) => void)) => {
  const start = new Date().getTime()

  const loop = () => {
    const delta = new Date().getTime() - start

    if (delta >= delay) {
      fn?.()
      registerCancel(noop)
      return
    }

    const raf = requestAnimationFrame(loop);
    registerCancel(() => cancelAnimationFrame(raf));
  };

  const raf = requestAnimationFrame(loop);
  registerCancel(() => cancelAnimationFrame(raf));
};

const useCancelableScheduledWork = () => {
  const cancelCallback = useRef(noop)
  const registerCancel = (fn: () => void) => (cancelCallback.current = fn)
  const cancelScheduledWork = () => cancelCallback.current()

  // Cancels the current sheduled work before the "unmount"
  useEffect(() => {
    return cancelScheduledWork;
  }, []);

  return [registerCancel, cancelScheduledWork];
};

const useClickPrevention = ({
                              onClick,
                              onDoubleClick,
                              delay = 300,
                            }: { onClick?: () => void, onDoubleClick?: () => void, delay?: number }) => {
  const [registerCancel, cancelScheduledRaf] = useCancelableScheduledWork()

  const handleClick = () => {
    // @ts-ignore
    cancelScheduledRaf()
    requestTimeout(onClick, delay, registerCancel)
  }

  const handleDoubleClick = () => {
    // @ts-ignore
    cancelScheduledRaf()
    onDoubleClick?.()
  };

  return [handleClick, handleDoubleClick];
};

export default useClickPrevention

import React, {memo, useCallback} from 'react'
import SingleLimitSelector from "components/forms/selector/limits/SingleLimitSelector"
import {ConfigurationLimitWithDisablingReasons, ConfLimit} from "components/forms/chart/types"

interface Props {
  value?: ConfLimit[]
  onChange?: (value: ConfLimit[]) => void
  configurations: ConfigurationLimitWithDisablingReasons[]
}

const LimitsSelector = memo<Props>(function SortSelector({
                                                           value: originalValue,
                                                           onChange,
                                                           configurations,
                                                         }) {
  const handleChange = useCallback((newValue: ConfLimit, index: number) => {
    const oldValue = originalValue ?? []
    onChange?.(oldValue.slice(0, index).concat([{
      ...newValue,
      isDefault: false,
    }], oldValue.slice(index + 1)))
  }, [onChange, originalValue])

  return originalValue && originalValue.length === configurations.length ? <>
    {
      configurations.map((configuration, index) => configuration.default?.enabled ? <SingleLimitSelector key={configuration.affect}
                                                                                                         value={originalValue?.[index]}
                                                                                                         multiple={configurations.length > 1}
                                                                                                         mandatory={configuration.mandatory}
                                                                                                         affect={configuration.affect}
                                                                                                         displaySlicerOtherOption={configuration.displaySlicerOtherOption}
                                                                                                         onSortChange={(newValue) => handleChange(newValue, index)}/> : <></>)
    }
    </> : <></>
})

export default LimitsSelector

import {useCallback} from "react"
import {FilterOption} from "types/select"
import {Dimension, SemanticType} from "classes/MetaModel"
import {isEmpty} from "commons/object.utils"
import {ConfFilter} from "components/forms/selector/comps/box/filters"

export const useDimensionOptions = (availableDimensions: Pick<Dimension, "alias" | "code" | "asSimple">[]) => useCallback((value: Pick<ConfFilter, "reference">[]): FilterOption[] => availableDimensions
    .filter(({asSimple}) => !asSimple)
    .map(availableDimension => ({
      type: SemanticType.NUMBER,
      label: availableDimension.alias,
      value: availableDimension.code,
      selected: !isEmpty(value.find(filter => filter.reference.code === availableDimension.code)),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
  , [availableDimensions])
import React, {memo, useCallback, useMemo} from 'react'
import styled from "styled-components"
import {Col, Select} from 'antd'
import {isEmpty} from "commons/object.utils"
import {ConfOrderBy} from "types/widgets"
import Language from "language"
import MultipleSortSelector from "components/forms/selector/sorts/MultipleSortSelector"
import {SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {RowWithDefaultValue} from "components/forms/selector/utils"
import {ConfSlicer} from "types/charts"
import {ConfigurationOrderByWithDisablingReasons, GroupedOptions, SortType} from "components/forms/chart/types"
import {getSortOptionByIndex} from "components/forms/chart/utils"

interface Props {
  value?: ConfOrderBy[]
  disabled?: boolean
  multiple?: boolean
  onChange?: (sort: Partial<ConfOrderBy>[]) => void
  slicers: ConfSlicer[]
  configurations: ConfigurationOrderByWithDisablingReasons[]
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  groupedOptions: GroupedOptions
}

enum SortDirection {
  DESC = "DESC",
  ASC = "ASC"
}

const OrderBySelector = memo<Props>(function SortSelector({
                                                         value,
                                                         onChange,
                                                         slicers,
                                                         configurations,
                                                         getPopupContainer,
                                                         groupedOptions,
                                                       }) {
  const OPTIONS = useMemo(() => [
    {
      label: <FlexDiv><IconContainer><SortDescendingIcon/></IconContainer>{Language.get("configuration-chart-sort.metric-desc")}</FlexDiv>,
      value: `${slicers.length}-${SortDirection.DESC}`,
    },
    {
      label: <FlexDiv><IconContainer><SortAscendingIcon/></IconContainer>{Language.get("configuration-chart-sort.metric-asc")}</FlexDiv>,
      value: `${slicers.length}-${SortDirection.ASC}`,
    },
  ].concat(slicers.length > 0 ? [{
    label: <FlexDiv><IconContainer><SortAscendingIcon/></IconContainer>{Language.get("configuration-chart-sort.slicer-asc")}</FlexDiv>,
    value: `${0}-${SortDirection.ASC}`,
  }] : []), [slicers.length])

  const getValueAtIndex = useCallback((index: number) => {
    if (!value || value.length === 0) {
      return undefined
    }
    if (value[index].column === 0 && value[index].asc) {
      return `0-${SortDirection.ASC}`
    } else if (value[index].column === slicers.length) {
      return `${slicers.length}-${value[index].asc ? SortDirection.ASC : SortDirection.DESC}`
    }
    return undefined
  }, [value, slicers.length])

  const placeholder = `${Language.get('configuration-add-sort')} ...`

  const handleDelete = useCallback((i: number) => {
      if (value) {
        onChange?.(value.slice(0, i).concat(value.slice(i + 1)))
      }
    },
    [value, onChange],
  )

  const handleChange = useCallback((i: number, v: ConfOrderBy) => {
      const oldValue = value ?? []
      onChange?.(oldValue.slice(0, i).concat([{
        ...v,
        isDefault: false,
      }], oldValue.slice(i + 1)))
    },
    [value, onChange],
  )

  const handleSelect = useCallback((index: number, orderByValue: string) => {
    const [orderByIndex, sortDirection] = orderByValue.split('-')
    const opt = getSortOptionByIndex(groupedOptions, Number(orderByIndex))
    handleChange(index, {
      asc: sortDirection === SortDirection.ASC,
      column: Number(orderByIndex),
      value: opt?.label,
      id: opt?.id,
      isDefault: false,
    })
  }, [groupedOptions, handleChange])

  const getSortInput = useCallback((index: number) => <Select {...{
    options: OPTIONS,
    getPopupContainer,
    value: getValueAtIndex(index),
    placeholder,
    style: {width: '100%'},
    onSelect: (newValue: string) => handleSelect(index, newValue),
    onChange: (orderByValue) => {
      if (isEmpty(orderByValue)) {
        handleDelete(index)
      }
    },
  }} />, [OPTIONS, getPopupContainer, getValueAtIndex, placeholder, handleSelect, handleDelete])

  const getAffectLabel = (configuration: ConfigurationOrderByWithDisablingReasons) => {
    return Language.get(`configuration-affect.${configuration.affect}`)
  }

  return useMemo(() => {
    if (configurations.length === 0) {
      return <></>
    }
    switch (configurations[0].type) {
      case SortType.ARRAY:
        return <MultipleSortSelector {...{
          value,
          onChange,
          placeholder,
          handleDelete,
          handleChange,
          groupedOptions,
        }} />
      case SortType.CLASSIC:
        return <RowWithDefaultValue $isdefault={(value && value.length > 0) ? Number(value[0].isDefault) : 0}>
          <Col span={2}>{getAffectLabel(configurations[0])}</Col>
          <Col span={12}>{getSortInput(0)}</Col>
        </RowWithDefaultValue>
      case SortType.DOUBLE: {
        if (configurations.length < 2) {
          return <></>
        } else {
          return <RowWithDefaultValue $isdefault={(value && value.length > 0) ? Number(value[0].isDefault) : 0}>
            <Col span={2}>{getAffectLabel(configurations[0])}</Col>
            <Col span={14}>{getSortInput(0)}</Col>
            <Col span={8}/>
            <Col span={2}>{getAffectLabel(configurations[1])}</Col>
            <Col span={14}>{getSortInput(1)}</Col>
          </RowWithDefaultValue>
        }
      }
      default: {
        const exhaustiveCheck: never = configurations[0] as never
        return exhaustiveCheck
      }
    }
  }, [configurations, getSortInput, groupedOptions, handleChange, handleDelete, onChange, value, placeholder])
})

export default OrderBySelector

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

import {Dimension} from "classes/MetaModel"
import {DimensionFilterDtoDetail, RangeQueryPredicate, ScalarQueryPredicate, Source} from "components/forms/selector/comps/box/filters"
import {ColorName} from "components/charts/Chart.Theme"
import {Granularity, PeriodTypes} from "types/period.types"

export enum MetricGrowthType {
  VALUE = "VALUE",
  DIFFERENCE = "DIFFERENCE",
  RATIO = "RATIO"
}

export enum MetricGrowthPeriodUnit {
  DAYS = "DAY",
  WEEKS = "WEEK",
  MONTHS = "MONTH",
  QUARTERS = "QUARTER",
  YEARS = "YEAR"
}

export interface MetricGrowthPeriod {
  quantity?: number
  unit: MetricGrowthPeriodUnit
}

export enum MetricExtraConfKeys {
  "growthInvert" = "growthInvert",
  "isDisplayedOnSecondaryAxis" = "isDisplayedOnSecondaryAxis",
  "growthLabel" = "growthLabel",
  "units" = "units",
  "color" = "color",
  "decimals" = "decimals",
}

export interface ExtraConfDetail {
  value: string,
  type: "prefix" | "suffix"
}

export interface ExtraConf {
  growthInvert?: boolean;
  isDisplayedOnSecondaryAxis?: boolean;
  units?: string
  color?: ColorName
  decimals?: number
  unit?: string
  details?: ExtraConfDetail
}

export interface ChartMetricGrowth {
  period: MetricGrowthPeriod;
  type: MetricGrowthType;
}

export enum MetricTitlePart {
  FILTER = "FILTER",
  PERIOD = "PERIOD",
  GROWTH = "GROWTH"
}

export interface ChartMetricDtoDetail {
  viewCode: string;
  viewAlias: string;
  metricCode: string;
  metricAlias: string;
  overridePeriod?: PeriodTypes
  growth?: ChartMetricGrowth
  extraConf?: ExtraConf;
  having?: RangeQueryPredicate | ScalarQueryPredicate
  additionalFilters: DimensionFilterDtoDetail[]
  titlePartOverrides: { [key in MetricTitlePart]?: string }
}

export interface DictionaryEntry {
  id: string
}

export interface SlicerDtoDetail {
  id?: number
  type: "date" | "dimension"
}

export interface SlicerDimensionDtoDetail extends SlicerDtoDetail {
  type: "dimension"
  dimensionCode: string
}

export interface SlicerDateDtoDetail extends SlicerDtoDetail {
  type: "date"
  granularity?: Granularity
}

export interface SlicerDateDtoDetailWithSource extends SlicerDateDtoDetail {
  type: "date"
  granularity: Granularity
  source: Source
}

export interface SlicerWithDimension extends SlicerDimensionDtoDetail {
  dimension: Dimension;
}

export type SlicerDtoDetailTypes = (SlicerDimensionDtoDetail | SlicerDateDtoDetail)

import React, {memo, useCallback} from 'react'
import Language from 'language'
import {Modal} from 'antd'

interface Props {
  title: string
  trigger: JSX.Element
  on: {
    open: () => void
    ok: () => void
    cancel: () => void
  }
}

// eslint-disable-next-line react/display-name
const Confirm = memo<Props>(({trigger, on, title}) => {
  const confirm = useCallback(() => {
    const {open, ok, cancel} = on
    open()
    Modal.confirm({
      title,
      okText: Language.get('button-yes'),
      cancelText: Language.get('button-no'),
      okType: 'primary',
      onOk: ok,
      onCancel: cancel,
      maskClosable: true,
      icon: <div></div>,
      okButtonProps: {
        danger: true,
      },
      className: 'widget-delete-confirmation',
    })
  }, [on, title])

  return <trigger.type {...trigger.props} onClick={confirm}/>
})

export default Confirm


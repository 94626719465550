import React from 'react'
import Language from 'language'
import {Button, Col, Input, message, Row, Select} from 'antd'
import dayjs from 'dayjs'
import styled from "styled-components"
import {IconContainer} from "components/common/IconContainer"
import {PlusCircleIcon, TrashIcon} from "@heroicons/react/outline"

interface Props {
  value: {
    refDate: string
    value: number
  }[]
  getPopupContainer: () => any
  onChange: (newValue: any) => any
}

export default class TargetSelector extends React.Component<Props, any> {
  months

  constructor(props: Props) {
    super(props)
    this.state = {
      draftMonth: dayjs().get('month'),
      draftYear: dayjs().get('year'),
      draftValue: 0,
    }
    // @ts-ignore
    this.months = new Array(12).fill().map((_, i) => ({
      month: i,
      label: dayjs().month(i).format('MMMM'),
    }))
  }

  changeTarget = (targetRefDate: string, v: string) => {
    const {value = []} = JSON.parse(JSON.stringify(this.props))
    if (!isNaN(parseFloat(v))) {
      const index = value.findIndex(({refDate}: { refDate: string }) => refDate === targetRefDate)
      if (index !== -1) {
        value[index].value = parseFloat(v)
        this.props.onChange(value)
      }
    }
  }
  deleteTarget = (targetRefDate: string) => {
    const {value = []} = JSON.parse(JSON.stringify(this.props))
    const index = value.findIndex(({refDate}: { refDate: string }) => refDate === targetRefDate)
    if (index !== -1) {
      value.splice(index, 1)
    }
    this.props.onChange(value)
  }
  addTarget = () => {
    const value = [...this.props.value || []]

    const draftRefDate = dayjs().year(this.state.draftYear).month(this.state.draftMonth).endOf('month').format('YYYY-MM-DD')
    const index = value.findIndex(({refDate}) => refDate === draftRefDate)
    if (index === -1) {
      value.push({refDate: draftRefDate, value: this.state.draftValue})
      this.props.onChange(value)
    } else {
      message.error(Language.get('configuration-chart-target-duplicate-entry'))
    }
  }

  render() {
    const {value = [], getPopupContainer} = this.props
    const {addTarget, changeTarget, deleteTarget} = this
    const {draftMonth, draftValue, draftYear} = this.state
    return <TargetSelectorContainer>
      {/* new target Row */}
      <Row gutter={[10, 10]}>
        <Col span={8}>{Language.get('configuration-label-period')}</Col>
        <Col span={13} offset={1}>{Language.get('configuration-chart-target-value')}</Col>
        {value.map((target) =>
          <React.Fragment key={target.refDate}>
            <Col span={4}>{dayjs(target.refDate).format('MMMM')}</Col>
            <Col span={4}>{dayjs(target.refDate).format('YYYY')}</Col>
            <Col span={7} offset={1}><Input type={'number'} step="any" defaultValue={target.value}
                                            onChange={e => changeTarget(target.refDate, e.target.value)}/></Col>
            <Col span={1} offset={1}><IconContainer clickable={true}><TrashIcon
              onClick={() => deleteTarget(target.refDate)}/></IconContainer></Col>
            <Col span={6}/>
          </React.Fragment>)}
        {/* select month */}
        <Col span={4}><Select
          style={{width: '100%'}}
          getPopupContainer={getPopupContainer}
          defaultValue={draftMonth}
          virtual={false}
          onChange={v => {
            this.setState({draftMonth: v})
          }}>
          {this.months.map(({label, month}) => <Select.Option value={month} key={month}>{label}</Select.Option>)}
        </Select></Col>
        {/* select year */}
        <Col span={4}>
          <Input type={'number'} defaultValue={draftYear} onChange={e => {
            this.setState({draftYear: e.target.value})
          }}/>
        </Col>
        {/* definitions*/}
        <Col span={7} offset={1}>
          <Input type={'number'} step="any" defaultValue={draftValue} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              addTarget()
            }
          }} onChange={e => {
            this.setState({draftValue: e.target.value})
          }}/>
        </Col>
        {/* add target button */}
        <Col span={24}>
          <StyledAddButton onClick={addTarget}>
            <IconContainer margin={10}><PlusCircleIcon/></IconContainer>
            {Language.get('configuration-add-target')}
          </StyledAddButton>
        </Col>
      </Row>
    </TargetSelectorContainer>
  }
}

const StyledAddButton = styled(Button)`
  display: flex;
`

const TargetSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`


import {
  ConfDimensionFilterLikeDtoDetail,
  ConfDimensionFilterMatchDtoDetail,
  ConfDimensionFilterValuesDtoDetail,
  ConfFilterRangeTypes,
  ConfFilterScalarTypes,
  FilterOperator,
  FilterType,
  SelectorFilterTypes,
} from "components/forms/selector/comps/box/filters"

export const consolidateUniversalConfFilterToDimension = (filter: SelectorFilterTypes) => {
  switch (filter.predicate.operator) {
    case FilterOperator.Match: {
      const matchFilter: ConfDimensionFilterMatchDtoDetail = {
        type: FilterType.dimension,
        predicate: filter.predicate,
        dimensionCode: filter.reference.code,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return matchFilter
    }
    case FilterOperator.Like: {
      const matchFilter: ConfDimensionFilterLikeDtoDetail = {
        type: FilterType.dimension,
        predicate: filter.predicate,
        dimensionCode: filter.reference.code,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return matchFilter
    }
    case FilterOperator.IN: {
      const matchFilter: ConfDimensionFilterValuesDtoDetail = {
        type: FilterType.dimension,
        predicate: filter.predicate,
        dimensionCode: filter.reference.code,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return matchFilter
    }
    case FilterOperator.EQ:
    case FilterOperator.GTE:
    case FilterOperator.GT:
    case FilterOperator.LT:
    case FilterOperator.LTE: {
      const scalarFilter: ConfFilterScalarTypes = {
        predicate: filter.predicate,
        dimensionCode: filter.reference.code,
        type: FilterType.dimension,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return scalarFilter
    }
    case FilterOperator.BETWEEN: {
      const rangeFilter: ConfFilterRangeTypes = {
        predicate: filter.predicate,
        dimensionCode: filter.reference.code,
        type: FilterType.dimension,
        reference: filter.reference,
        isValid: filter.isValid,
        dataType: filter.dataType,
      }
      return rangeFilter
    }
    default: {
      const exhaustiveCheck = filter.predicate
      return exhaustiveCheck
    }
  }
}
import {Select} from "antd"
import React, {memo, useMemo} from "react"
import {growthTypes} from "types/growths"
import {MetricGrowthType} from "types/savedConfs"
import Language from "language"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"

interface TypeSelectorProps {
  value?: boolean
  activated: boolean
  onChange?: (newState: boolean) => void
  configuration?: ChartTypeWithDisabledReason
}

const TypeSelector = memo<TypeSelectorProps>(function TypeSelector({
                                                value,
                                                activated,
                                                onChange,
                                                configuration,
                                                                   }) {

  const growthTypesOptions = useMemo(() => growthTypes.filter(type => !configuration || (configuration && !(Object.keys(configuration.disablingReasons).find(key => key === "withMetricWithGrowthAsRatio") && type.value === MetricGrowthType.RATIO)))
      .map((e, i) => <Select.Option key={i} value={e.value}>{Language.get(e.label)}</Select.Option>),
    [configuration],
  )

  return <Select {...{
    style: {width: '100%'},
    onChange,
    virtual: false,
    dropdownMatchSelectWidth: false,
    disabled: !activated,
    value,
  }}>
    {growthTypesOptions}
  </Select>
})

export default TypeSelector
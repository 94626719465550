import React, {memo} from 'react'
import {TooltipContentMetric} from "components/tooltipContent/TooltipContent.Metric"
import {TimeIntervalWithSource} from "components/charts/line/LineChart.types"
import ValueDescription from "components/forms/description/ValueDescription"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"

interface ValueDescriptionParams {
  metric: MetricSelectorValue
  timeInterval: TimeIntervalWithSource
}

// eslint-disable-next-line react/prop-types
const MetricDescription = memo<ValueDescriptionParams>(function MetricDescription({
                                                                                    metric,
                                                                                    timeInterval,
                                                                                  }) {
  return <ValueDescription>
    <TooltipContentMetric metric={metric} timeInterval={timeInterval}/>
  </ValueDescription>
})

export default MetricDescription

import {RootState} from "redux/store"
import {ChartDtoDetail} from "types/charts"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"

export const getCopiedChart = () => (state: RootState): ChartDtoDetail => {
  return state.clipboard.chart as ChartDtoDetail
}

export const getCopiedMetric = () => (state: RootState): MetricSelectorValue => {
  return state.clipboard.metric as MetricSelectorValue
}



/* eslint-disable react/display-name */
import React, {FunctionComponent, useCallback} from 'react'
import Language from 'language'
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import {IconContainer} from "components/common/IconContainer"
import styled from "styled-components"

interface Props {
  switchState?: boolean
  clickable?: boolean
  change: (switchState: boolean, event: React.MouseEvent<HTMLSpanElement>) => void
  switchItem: SwitchItem
}

interface SwitchItem {
  key?: string
  icon: string | FunctionComponent
  unchecked: string
  checked: string
}

const Switch: FunctionComponent<Props> = ({switchState, clickable = true, change, switchItem}) => {
  const handleClick: React.MouseEventHandler<HTMLSpanElement> = useCallback(e => {
    change(!switchState, e)
  }, [switchState, change])

  return <TooltipPopover
    size={'small'}
    content={Language.get(switchItem[switchState ? 'checked' : 'unchecked'])}>
    <StyledSpan $highlighted={switchState && clickable ? 1 : 0}
                onClick={handleClick}>
      {
        typeof switchItem.icon === "string" ? <IconSpan>
          {switchItem.icon}
        </IconSpan> : <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}>
          <switchItem.icon/>
        </IconContainer>
      }
    </StyledSpan>
  </TooltipPopover>
}

export default Switch

const StyledSpan = styled.span<{ $highlighted: number }>`
    min-width: 28px;
    min-height: 28px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${props => props.$highlighted ? 'var(--primary)' : 'inherit'};
`

const IconSpan = styled.span`
    &:hover {
        color: var(--primary-light);
        cursor: pointer;
    }
`
import React, {FunctionComponent, PropsWithChildren} from "react"
import {useNavigate} from "react-router-dom"
import styled from "styled-components"

interface Props extends PropsWithChildren {
  link?: string
  isTargetBlank?: boolean
}

export const TableLink: FunctionComponent<Props> = ({link, isTargetBlank = false, children}) => {
  const navigate = useNavigate()
  const linkProps: Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "ref"> = isTargetBlank ? {
    target: "_blank",
    rel: "noreferrer",
  } : {
    onClick: (e) => {
      e.preventDefault()
      if (link) {
        if (e.ctrlKey) {
          window.open(link)
        } else {
          navigate(link)
        }
      }
    },
  }

  return link ? <StyledLink {...linkProps} href={link}>{children}</StyledLink> : <div>{children}</div>
}

const StyledLink = styled.a`
  color: var(--primary);

    :hover {
        background-color: transparent;
    }
`

import {useCallback} from "react"
import {captureEvent} from "services/GoogleAnalyticsService"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"

export const useHandleAddMetricFromClipboard = (
  copiedMetric: MetricSelectorValue | undefined,
  handleMetricAdd: (copiedMetric: MetricSelectorValue) => void,
) => {
  return useCallback(
    () => {
      if (copiedMetric) {
        captureEvent({
          category: 'metrics',
          action: 'copy_metric',
        })
        return handleMetricAdd(copiedMetric)
      } else {
        return null
      }
    },
    [copiedMetric, handleMetricAdd])
}

import React, {FunctionComponent, memo, useCallback, useMemo} from 'react'
import {useSelector} from "react-redux"
import {getPermission} from "redux/appEnvironment.selector"
import {getCurrentWorkspaceForBuildingUri} from "redux/workspace.selector"
import {buildWorkspaceUri} from "components/workspace/Workspace.types"
import WorkspaceBridgeSiderContainer from "components/workspace/WorkspaceBridge.SiderContainer"
import {Route, Routes} from "react-router-dom"
import DataSourceDocDashboard from "components/dataSourceDoc/DataSource"
import AdminHome from "components/admin/AdminHome"
import DashboardBridge from "components/dashboard/Dashboard.Bridge"
import MainLayout from "components/mainLayout/MainLayout"
import useDispatch from "hooks/useDispatch"
import {NormalizedDashboard} from "schemas/workspace"

// eslint-disable-next-line react/display-name
const WorkspaceBridgeContainer: FunctionComponent = memo(() => {
  const dispatch = useDispatch()
  const workspaceForBuildingUri = useSelector(getCurrentWorkspaceForBuildingUri)
  const canEdit = useSelector(getPermission)('workspaceCanEdit')

  const workspaceUriBuilder = useCallback(
    (pathOrDashboard?: string | NormalizedDashboard) => buildWorkspaceUri(workspaceForBuildingUri, pathOrDashboard),
    [workspaceForBuildingUri],
  )

  const sideContent = useMemo(() => <WorkspaceBridgeSiderContainer {...{
    workspaceUriBuilder,
  }}/>, [workspaceUriBuilder])

  return useMemo(() => <MainLayout {...{
    sideContent,
    canEdit,
    onEditModeChange: dispatch.appEnvironment.setDashboardEditMode,
    onDashboardSelectionChange: dispatch.currentDashboard.selectionUpdate,
  }}>
    <Routes>
      <Route path={`help/*`} element={<DataSourceDocDashboard/>}/>
      <Route path={`admin/*`} element={<AdminHome/>}/>
      <Route path={`:dashboard`} element={<DashboardBridge canEdit={canEdit}/>}/>
    </Routes>
  </MainLayout>, [canEdit, dispatch.currentDashboard.selectionUpdate, dispatch.appEnvironment.setDashboardEditMode, sideContent])
})

export default WorkspaceBridgeContainer

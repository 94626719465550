import {MetricDef} from "components/charts/line/LineChart.types"
import {View} from "services/MetaModelService"

export interface MetaModelView {
  code: string
  tableName: string
  alias: string
  description: string
  metrics: MetricDef[];
  dimensions: Dimension[];
}

export enum SemanticType {
  "STRING" = "STRING",
  "NUMBER" = "NUMBER",
  "DATE" = "DATE",
  "DATETIME" = "DATETIME",
  "UUID" = "UUID",
}

export interface Dimension {
  code: string
  alias: string
  description: string
  semanticType: SemanticType
  viewCodes: string[]
  dictionaryCode: string
  asSimple: boolean
  "@type": string
}

type Views = {
  [k: string]: MetaModelView
}

type Dimensions = {
  [k: string]: Dimension
}

export default class MetaModel {
  public views: Views
  public dimensions: Dimensions

  constructor(views: View[]) {
    this.dimensions = views.reduce(
      (acc: Dimensions, view: View) => view.dimensions.reduce((acc2, dimension) => ({
        ...acc2,
        [dimension.code]: {
          ...dimension,
          asSimple: dimension["@type"] === 'simple',
          viewCodes: [...acc2[dimension.code]?.viewCodes || [], view.code],
        },
      }), acc),
      {},
    )
    this.views = views.reduce(
      (accView: Views, view: View) => {
        return {
          ...accView,
          [view.code]: {
            ...view,
            dimensions: view.dimensions.map(dimension => this.dimensions[dimension.code]),
          },
        }
      },
      {},
    )
  }

  listViews(): MetaModelView[] {
    return Object.values(this.views).sort((a: MetaModelView, b: MetaModelView) => a.alias.localeCompare(b.alias))
  }

  getView(code: string): MetaModelView | undefined {
    return this.views[code]
  }

  listDimensions(): Dimension[] {
    return Object.values(this.dimensions)
  }

  getDimension(code: string | number): Dimension | undefined {
    return this.dimensions[code]
  }

  checkDimensionInView(dimension: string | Dimension, view: string | MetaModelView): boolean {
    return (typeof dimension === 'string' ? this.dimensions[dimension] : dimension)?.viewCodes.includes(typeof view === 'string' ? view : view.code) || false
  }
}

import React, {memo, ReactNode, useCallback, useMemo} from 'react'
import {Select} from 'antd'
import useFixValue from "hooks/useFixValue"
import styled from "styled-components"

export interface BaseOption {
  value: string | null | undefined
  label: ReactNode | string
  disabled?: boolean
  options?: BaseOption[]
}

const toSelectOption = (option: BaseOption, name: string, labelIcon?: ReactNode) => <Select.Option value={option.value}
                                                                                                   key={`${name}-${option.value}`}
                                                                                                   label={<FlexDiv>
                                                                                                     {labelIcon}
                                                                                                     {option.label}
                                                                                                   </FlexDiv>}>{option.label}</Select.Option>

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

interface Props {
  style: { [key: string]: string | number }
  name: string
  options: BaseOption[]
  value?: string | null
  disabled?: boolean
  onChange?: (newValue: string) => void
  labelIcon?: ReactNode,
  getPopupContainer?: () => HTMLDivElement
  showSearch?: boolean
}

// eslint-disable-next-line react/display-name
const BironSelect = ({
                       value: originalValue,
                       options,
                       onChange,
                       labelIcon,
                       ...props
                     }: Props) => {
  const foundFirstOption = useCallback(
    (predicate: (option: BaseOption) => boolean) => options.find(level1 =>
      level1.options?.find(level2 => predicate(level2)) ?? predicate(level1),
    ),
    [options],
  )

  const value = useMemo(() => {
      if (originalValue) {
        const originalOptionFound = foundFirstOption((option) => option.value === originalValue)
        if (!originalOptionFound) {
          return foundFirstOption(() => true)?.value
        }
      }
        return originalValue
      },
      [originalValue, foundFirstOption])
    useFixValue(onChange, originalValue, value)

    return <Select {...{
      optionFilterProp: "children",
      ...props,
      value,
      onChange,
      virtual: false,
      optionLabelProp: "label",
    }}>
      {
        options.map(level1 =>
          level1.options
            ? <Select.OptGroup label={level1.label} key={typeof level1.label === "string" ? level1.label : level1.value?.toString()}>
              {level1.options.map(option => toSelectOption(option, props.name, labelIcon))}
            </Select.OptGroup>
            : toSelectOption(level1, props.name, labelIcon)
        )
      }
    </Select>
}


export default memo(BironSelect) as typeof BironSelect

import {omit} from 'lodash'
import {NormalizedModel, RawDashboard, RawHomepage} from "redux/models/workspace"
import {getDefaultPeriod} from "commons/period/periodsList"
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {RawChartTypes} from "types/charts"
import {PeriodTypes} from "types/period.types"

export function setEntities<S extends NormalizedModel>(state: S, entitiesByType: Record<string, any>) {
  return {
    ...state,
    entities: Object.entries(entitiesByType).reduce((acc, [type, records]) => ({
      ...acc,
      [type]: {
        ...acc[type],
        ...records,
      },
    }), state.entities),
  }
}

export const deleteEntity: (state: any, data: { type: any, id: any }) => any = (state, {type: entityType, id: entityId}) => ({
  ...state,
  entities: {
    ...state.entities,
    [entityType]: omit(state.entities[entityType] || {}, [String(entityId)]),
  },
})

export const consolidateRawDashboardPeriod = <T extends (RawDashboard | RawHomepage)>(dashboard: T, periods: PeriodTypes[]) => {
  const defaultPeriod = getDefaultPeriod()
  return {
    ...dashboard,
    period: periods.find((period: any) => period.code === dashboard.period.code) ?? defaultPeriod,
    charts: dashboard.charts.map(chart => {
      switch (chart.type) {
        case WidgetTypes.GENERIC:
          return {
            ...chart,
            bqConf: {
              ...chart.bqConf,
              period: chart.bqConf.period ? periods.find((period) => period.code === chart.bqConf.period?.code) : undefined,
              metrics: chart.bqConf.metrics.map(metric => ({
                ...metric,
                overridePeriod: metric.overridePeriod ? periods.find((period) => period.code === metric.overridePeriod?.code) : undefined,
              })),
            },
          }
        case WidgetTypes.TARGET:
          return {
            ...chart,
            period: periods.find((period) => period.code === chart.period.code) ?? defaultPeriod,
          }
        default:
          return chart
      }
    }),
  }
}

export const consolidatedChartPeriod = (chart: RawChartTypes, periods: PeriodTypes[]): RawChartTypes => {
  const defaultPeriod = getDefaultPeriod()
  switch (chart.type) {
    case WidgetTypes.GENERIC: {
      chart.bqConf.period = periods.find(period => period.code === chart.bqConf.period?.code)
      chart.bqConf.metrics = chart.bqConf.metrics.map(metric => ({
        ...metric,
        overridePeriod: periods.find(period => period.code === metric.overridePeriod?.code),
      }))
      break
    }
    case WidgetTypes.TARGET: {
      chart.period = periods.find(period => period.code === chart.period?.code) ?? defaultPeriod
      break
    }
    default:
  }

  return chart
}

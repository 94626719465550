import {Checkbox} from "antd"
import React, {memo, useCallback} from "react"
import {CheckboxChangeEvent} from "antd/lib/checkbox"
import styled from "styled-components"
import GAInput from "components/GAInput/GAInput"
import {isEmpty} from "commons/object.utils"

interface DecimalsSelectorProps {
  value?: number
  onChange?: (newState?: any) => void
  activated?: boolean
  defaultLabel: number | undefined
  gaevent?: {
    category: string
    action: string
  }
}

// eslint-disable-next-line react/display-name
const DecimalsSelector = memo<DecimalsSelectorProps>(({
                                                        value,
                                                        onChange,
                                                        activated = true,
                                                        defaultLabel,
                                                        gaevent,
                                                      }) => {

  const handleSwitch = useCallback((event: CheckboxChangeEvent) => {
    if (event.target.checked && defaultLabel) {
      onChange?.(defaultLabel)
    } else {
      onChange?.(undefined)
    }
  }, [defaultLabel, onChange])

  return <FlexDiv>
    <Checkbox checked={!isEmpty(value)} onChange={handleSwitch}/>
    <GAInput value={isEmpty(value) ? defaultLabel : value}
             type={"numeric"}
             min={0}
             onChange={(event) => onChange?.(event)}
             disabled={!activated || !value}
             gaevent={gaevent}/>
  </FlexDiv>
})

export default DecimalsSelector

const FlexDiv = styled.div`
    display: flex;
    gap: 8px;
`
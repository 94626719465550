import React, {memo, useRef} from 'react'
import Box from './box/Box'
import BoxWithDescription from './boxWithDescription/BoxWithDescription'
import {NUMBER_OF_FILTER_PER_LINE} from "components/dashboard/Dashboard.constants"
import styled from "styled-components"
import _ from "lodash"
import {IconContainer} from "components/common/IconContainer"
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline"
import Language from "language"
import {ConfFilter} from "components/forms/selector/comps/box/filters"

interface Props {
  environmentId: number
  filters: ConfFilter[]
  seeDescription?: boolean
  onFiltersChange: (filter: ConfFilter, index: number) => void
  onFiltersDelete: (index: number) => void
  numberOfFilterPerLine?: number
  isFilterLineFullSize: boolean
  toggleFilterLine: () => void
  onEdit?: (state: number) => void
}

const GAP_BETWEEN_FILTERS = 10

const BoxesWrapperWithResume = memo<Props>(function BoxesWrapperWithResume({
                                                                             filters = [],
                                                                             environmentId,
                                                                             seeDescription,
                                                                             onFiltersChange,
                                                                             onFiltersDelete,
                                                                             numberOfFilterPerLine = NUMBER_OF_FILTER_PER_LINE,
                                                                             isFilterLineFullSize,
                                                                             toggleFilterLine,
                                                                             onEdit,
                                                                           }) {
  const ref = useRef<any>()

  return <BoxWrapperLayout>
    <div>
      <FlexCol onClick={() => toggleFilterLine()}>
        <StyledSpan>{`${Language.get("configuration-label-filters")}`}</StyledSpan> <FilterNumber>({filters.length})</FilterNumber>
        <IconContainer color={"var(--light-grey)"} size={16}>
          {isFilterLineFullSize ? <ChevronUpIcon/> : <ChevronDownIcon/>}
        </IconContainer>
      </FlexCol>
    </div>
    {isFilterLineFullSize && <FilterContainer>
      <DivWithMargin>
        <FlexDiv ref={ref}>
          {filters.map((filter, index) => {
            const BoxComponent = seeDescription ? BoxWithDescription : Box
            const boxCmp = <BoxComponent key={index}
                                         environmentId={environmentId}
                                         filter={filter}
                                         close={() => onFiltersDelete(index)}
                                         handleFilterChange={(newFilter) => onFiltersChange(newFilter, index)}
                                         onEdit={onEdit}
            />
            return <StyledCol key={index} $nb={numberOfFilterPerLine}><React.Fragment key={index}>{boxCmp}</React.Fragment></StyledCol>
          })}
        </FlexDiv>
      </DivWithMargin>
    </FilterContainer>}
  </BoxWrapperLayout>
})


export default BoxesWrapperWithResume

const FlexCol = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  padding-right: 17px;
  padding-left: 10px;
  &:hover {
    color: var(--primary) !important;
    svg {
      color: var(--primary) !important;
    }
  }
`
const StyledSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
`

const FilterNumber = styled(StyledSpan)`
  font-weight: bold;
  color: var(--primary);
`

const DivWithMargin = styled.div`
  margin-left: 12px;
  margin-right: 12px;
`

const FilterContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const BoxWrapperLayout = styled.div`
  display: flex;
  background-color: var(--main-background-color);
  padding-left: 10px;
  padding-right: 10px;
`

const FlexDiv = styled.div`
  display: flex;
  gap: ${GAP_BETWEEN_FILTERS}px;
  width: 100%;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 5px;
`

const StyledCol = styled.div<{ $nb: number }>`
    flex: 0 0 calc(${({$nb}) => 100 / $nb}%);
    max-width: calc(${({$nb}) => 100 / $nb}%);
  
  ${props => {
  const mediaQueryPoints = [1550, 1250, 950, 750]
  return _.compact(mediaQueryPoints.map((point, index) => {
      const newNb = props.$nb - (index + 1)
    if (newNb > 0) {
      return `@media (max-width: ${point}px) {
              flex: 0 0 calc(${100 / newNb}% );
              max-width: calc(${100 / newNb}%);
          }`
    } else {
      return undefined
    }
  })).join('')
}}
`

